@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600|Playfair+Display");
.home-gradient,
.home-gradient-hover:hover,
.home-gradient-hover.active {
  background: #444444;
  background: -webkit-linear-gradient(left top, #333333, #666666);
  background: linear-gradient(to right bottom, #333333, #666666);
  color: #fff !important;
}

.sale-gradient,
.sale-gradient-hover:hover {
  background: #ee6565;
  background: -webkit-linear-gradient(left top, #ee6565, #ff888a);
  background: linear-gradient(to right bottom, #ee6565, #ff888a);
  color: #fff !important;
}

.profile-gradient {
  background: #469ad4;
  background: -webkit-linear-gradient(to right bottom, #469ad4, #00b4db);
  background: linear-gradient(to right bottom, #469ad4, #00b4db);
  color: #fff !important;
}

.favorites-gradient {
  background: #1da69b;
  background: -webkit-linear-gradient(to right bottom, #1da69b, #2dbbb0);
  background: linear-gradient(to right bottom, #1da69b, #2dbbb0);
  color: #fff !important;
}

.reviews-gradient {
  background: #d6ae7b;
  background: -webkit-linear-gradient(to right bottom, #d6ae7b, #eacda3);
  background: linear-gradient(to right bottom, #d6ae7b, #eacda3);
  color: #fff !important;
}

.about-gradient {
  background: #4ca1af;
  background: -webkit-linear-gradient(to right bottom, #4ca1af, #c4e0e5);
  background: linear-gradient(to right bottom, #4ca1af, #c4e0e5);
  color: #fff !important;
}

.settings-gradient {
  background: #aaaaaa;
  background: -webkit-linear-gradient(to right bottom, #aaaaaa, #cccccc);
  background: linear-gradient(to right bottom, #aaaaaa, #cccccc);
  color: #fff !important;
}

.social-gradient,
.social-gradient-hover:hover,
.social-gradient-hover.active {
  background: #00b4db;
  background: -webkit-linear-gradient(left top, #0083b0, #00b4db);
  background: linear-gradient(to right bottom, #0083b0, #00b4db);
  color: #fff !important;
}

.blog-gradient,
.blog-gradient-hover:hover,
.blog-gradient-hover.active {
  background: #00b4db;
  background: -webkit-linear-gradient(to right bottom, #0083b0, #00b4db);
  background: linear-gradient(to right bottom, #0083b0, #00b4db);
  color: #fff !important;
}

.photos-gradient,
.photos-gradient-hover:hover,
.photos-gradient-hover.active {
  background: #06beb6;
  background: -webkit-linear-gradient(to right bottom, #48b1bf, #06beb6);
  background: linear-gradient(to right bottom, #48b1bf, #06beb6);
  color: #fff !important;
}

.videos-gradient,
.videos-gradient-hover:hover,
.videos-gradient-hover.active {
  background: #fc5c7d;
  background: -webkit-linear-gradient(to right bottom, #6a82fb, #fc5c7d);
  background: linear-gradient(to right bottom, #6a82fb, #fc5c7d);
  color: #fff !important;
}

.events-gradient,
.events-gradient-hover:hover,
.events-gradient-hover.active {
  background: #ff9966;
  background: -webkit-linear-gradient(to right bottom, #ff5e62, #ff9966);
  background: linear-gradient(to right bottom, #ff5e62, #ff9966);
  color: #fff !important;
}

.groups-gradient,
.groups-gradient-hover:hover,
.groups-gradient-hover.active {
  background: #00b09b;
  background: -webkit-linear-gradient(to right bottom, #96c93d, #00b09b);
  background: linear-gradient(to right bottom, #96c93d, #00b09b);
  color: #fff !important;
}

.store-gradient,
.store-gradient-hover:hover,
.store-gradient-hover.active {
  background: #7367f0;
  background: -webkit-linear-gradient(left top, #7367f0, #ce9ffc);
  background: linear-gradient(to right bottom, #7367f0, #ce9ffc);
  color: #fff !important;
}

.auto-gradient,
.auto-gradient-hover:hover,
.auto-gradient-hover.active {
  background: #9f44d3;
  background: -webkit-linear-gradient(left top, #9f44d3, #e2b0ff);
  background: linear-gradient(to right bottom, #9f44d3, #e2b0ff);
  color: #fff !important;
}

.food-gradient,
.food-gradient-hover:hover,
.food-gradient-hover.active {
  background: #65a2e7;
  background: -webkit-linear-gradient(left top, #496ad4, #65a2e7);
  background: linear-gradient(to right bottom, #496ad4, #65a2e7);
  color: #fff !important;
}

.real-estate-gradient,
.real-estate-gradient-hover:hover,
.real-estate-gradient-hover.active {
  background: #1d6fa3;
  background: -webkit-linear-gradient(left top, #1d6fa3, #65fdf0);
  background: linear-gradient(to right bottom, #1d6fa3, #65fdf0);
  color: #fff !important;
}

.jobs-gradient,
.jobs-gradient-hover:hover,
.jobs-gradient-hover.active {
  background: #fcb69f;
  background: -webkit-linear-gradient(left top, #fcb69f, #ffecd2);
  background: linear-gradient(to right bottom, #fcb69f, #ffecd2);
  color: #fff !important;
}

.services-gradient,
.services-gradient-hover:hover,
.services-gradient-hover.active {
  background: #fe5196;
  background: -webkit-linear-gradient(left top, #f77062, #fe5196);
  background: linear-gradient(to right bottom, #f77062, #fe5196);
  color: #fff !important;
}

.travel-gradient,
.travel-gradient-hover:hover,
.travel-gradient-hover.active {
  background: #8ddad5;
  background: -webkit-linear-gradient(left top, #00cdac, #8ddad5);
  background: linear-gradient(to right bottom, #00cdac, #8ddad5);
  color: #fff !important;
}

.healthcare-gradient,
.healthcare-gradient-hover:hover,
.healthcare-gradient-hover.active {
  background: #4a90e2;
  background: -webkit-linear-gradient(left top, #4a90e2, #50e3c2);
  background: linear-gradient(to right bottom, #4a90e2, #50e3c2);
  color: #fff !important;
}

button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  font-size: 1.3rem;
  color: #469ad4;
  line-height: 1.3rem !important;
}
button:hover {
  color: #425caf;
}
button:focus {
  outline: none;
}

.button {
  text-transform: uppercase;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: 1.2rem 3.2rem;
  font-size: 1.3rem;
}
.button-regular {
  background-color: #469ad4;
  border: 1px solid #469ad4;
  color: #ffffff;
}
.button-regular:hover {
  background-color: #425caf;
  border: 1px solid #425caf;
  color: #ffffff;
}
.button-white {
  background-color: #ffffff;
  border: 1px solid #ddd;
  color: #444444;
}
.button-white:hover {
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
}
.button-clear {
  background-color: transparent;
  border: 1px solid #ddd;
  color: #444444;
}
.button-clear:hover {
  background-color: #469ad4;
  border: 1px solid #469ad4;
  color: #ffffff !important;
}
.button-clear-white {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.button-clear-white:hover {
  background-color: #fff;
  border: 1px solid #fff;
  color: #999 !important;
}
.button-smsg {
  background-color: transparent;
  border: 1px solid #ccc;
  color: #444444 !important;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1.5rem;
}
.button-smsg:hover {
  background-color: #469ad4;
  border: 1px solid #469ad4;
  color: #ffffff !important;
}
.button-sale {
  background-color: #fff;
  border: 1px solid #ee6565;
  color: #ee6565;
  cursor: pointer !important;
}
.button-sale:hover {
  background-color: #ee6565;
  border: 1px solid #ee6565;
  color: #ffffff !important;
}
.button-disabled,
.button:disabled {
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #ffffff;
  cursor: default;
}
.button-disabled:hover,
.button:disabled:hover {
  background-color: #ccc;
  border: 1px solid #ccc;
  color: #ffffff;
}
.button-small {
  padding: 1rem 2.4rem;
  font-size: 1.1rem;
  line-height: 1.3rem !important;
  font-weight: 600;
}
.button-favorites {
  border: 1px solid #1da69b;
  background: #fff;
  color: #199288;
}
.button-favorites i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.button-favorites:hover {
  color: #ffffff;
  border: 1px solid #199288;
  background: #199288;
}
.button-favorites-added {
  border: 1px solid #1da69b;
  background: #1da69b;
  color: #ffffff;
}
.button-favorites-added i {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.button-favorites-added:hover {
  color: #ffffff;
  border: 1px solid #199288;
  background: #199288;
}
.button-followone {
  margin-top: 0;
  color: #444444;
  background: #ffffff;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 600;
  border-color: #ededed;
  transition: all 0.3s ease-in-out;
}
.button-followone span {
  color: #469ad4;
  transition: all 0.3s ease-in-out;
}
.button-followone:hover {
  background: #469ad4;
  border-color: #469ad4;
  color: #ffffff;
}
.button-followone:hover span {
  opacity: 0;
  margin-left: -3rem;
}
.button-you-love {
  color: #444444;
  background: #ffffff;
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 600;
  border-color: #ededed;
  transition: all 0.3s ease-in-out;
}
.button-you-love i {
  margin-left: 0.5rem;
}
.button-you-love:hover {
  background: #ee6565;
  border-color: #ee6565;
  color: #ffffff;
}
.button-you-love:hover i {
  color: #ffffff;
}

a.button-regular {
  color: #ffffff !important;
}

.engage_buttons {
  flex-wrap: wrap;
  height: auto !important;
  padding: 0.4rem 0 !important;
}
.engage_buttons span {
  font-size: 1.4rem;
  color: #777;
  vertical-align: middle;
}
.engage_buttons span i {
  font-size: 1.8rem;
  margin-left: 0.2rem;
  vertical-align: text-top;
}
.engage_buttons span:not(:last-child) {
  margin-right: 1rem;
}
.engage_buttons_rating .fa-star {
  color: #469ad4 !important;
}
.engage_buttons_rating_number {
  margin-right: 0.4rem !important;
  display: inline-block;
  line-height: 1 !important;
  margin-top: -0.3rem;
}
.engage_buttons_rating button {
  margin-right: 0.1rem !important;
}
.engage_buttons button {
  font-size: 1.4rem;
  color: #777;
  vertical-align: middle;
  margin-right: 1rem;
}
.engage_buttons button i {
  font-size: 1.8rem;
  margin-left: 0.2rem;
  vertical-align: initial;
  color: #777;
}
.engage_buttons button i.fa-star {
  color: #469ad4;
}
.engage_buttons_share {
  display: inline-block;
}
.engage_buttons_share_modal {
  order: 1;
  background: #469ad4;
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid #ededed;
  margin-top: 1.6rem;
  width: 100% !important;
  -webkit-box-shadow: 0px 0px 1.6rem -2px rgba(196, 196, 196, 0.54);
  -moz-box-shadow: 0px 0px 1.6rem -2px rgba(196, 196, 196, 0.54);
  box-shadow: 0px 0px 1.6rem -2px rgba(196, 196, 196, 0.54);
}
.engage_buttons_share_modal button i,
.engage_buttons_share_modal a i {
  color: #fff !important;
  transition: all 0.2s ease-in-out;
}
.engage_buttons_share_modal button:hover i,
.engage_buttons_share_modal a:hover i {
  transform: scale(1.4);
}
.engage_buttons_share_modal button:not(:last-child),
.engage_buttons_share_modal a:not(:last-child) {
  margin-right: 1.5rem !important;
}
.engage_buttons_share_modal button:last-child,
.engage_buttons_share_modal a:last-child {
  margin-right: 0 !important;
}
.engage_buttons_share_modal button:last-child i,
.engage_buttons_share_modal a:last-child i {
  margin-right: 0 !important;
}
.engage_buttons_share_modal button i,
.engage_buttons_share_modal a i {
  font-size: 2.5rem;
}
.engage_buttons_share_modal button span,
.engage_buttons_share_modal a span {
  font-size: 2.4rem;
  color: #fff;
  background: #469ad4;
  border-radius: 0.5rem;
  padding: 0.2rem 0.8rem;
}
.engage_buttons_share_modal button span:hover,
.engage_buttons_share_modal a span:hover {
  background: #425caf;
}

.back-top-top {
  display: none;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  background: #ffffff;
  padding: 0.5rem;
  border-radius: 50%;
  z-index: 99;
}
.back-top-top i {
  color: #469ad4;
  font-size: 3rem;
}

.autoCancelSubscription {
  display: block;
  color: #fff !important;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
}

/* BASIC RESET */
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:focus {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input:not([type="checkbox"]):not([type="radio"]) {
  -webkit-appearance: none;
}

textarea {
  -webkit-appearance: none !important;
}

html {
  font-size: 62.5%;
  /* For rem measurements always use the root.  */
}

body {
  box-sizing: border-box;
  background-color: #fff;
  min-width: 32rem;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.location-modal-closed .modal-wrapper {
  transition: all 0.3s ease-in-out;
  left: -100%;
}

.location-modal-closed .Backdrop {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  left: -100%;
}

.location-modal-open .modal-wrapper {
  transition: all 0.3s ease-in-out;
  left: 0%;
}

.location-modal-open .Backdrop {
  transition: opacity 0.1s ease-in-out;
  left: 0;
  opacity: 1;
}

::placeholder {
  color: #b5b5b5;
}

.form-input-text {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
}
.form-input-text:focus {
  border: 1px solid #999 !important;
}

.form-input-textarea {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
  min-height: 12rem;
}
.form-input-textarea:focus {
  border: 1px solid #999 !important;
}

.form-input-select {
  position: relative;
  min-width: 14rem;
}
.form-input-select::before {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 2rem;
  right: 2rem;
  position: absolute;
  top: 0.7rem;
  color: #b5b5b5;
  pointer-events: none;
}
.form-input-select select {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
  display: inline-block;
}
.form-input-select select:focus {
  border: 1px solid #999 !important;
}

.add-listing .form-input-select {
  min-width: initial;
}

.form-input-full {
  border: 1px solid #dddddd !important;
  border-radius: 1rem;
}

.form-input-lists {
  margin: 0.8rem 0;
  cursor: pointer;
}
.form-input-lists input {
  visibility: hidden;
  margin-left: -1rem;
}
.form-input-lists input[type="radio"]:checked + label {
  color: #444444;
}
.form-input-lists input[type="radio"] + label i {
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 2.6rem;
  height: 2.6rem;
  padding: 0.5rem 0 0 0.4rem;
  vertical-align: middle;
}
.form-input-lists input[type="radio"]:checked + label i {
  color: #6ec568;
  font-weight: 400;
}
.form-input-lists input[type="checkbox"]:checked + label {
  color: #444444;
}
.form-input-lists input[type="checkbox"] + label i {
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  width: 2.6rem;
  height: 2.6rem;
  padding: 0.5rem 0 0 0.5rem;
  vertical-align: middle;
}
.form-input-lists input[type="checkbox"]:checked + label i {
  color: #6ec568;
  font-weight: 400;
}
.form-input-lists label {
  color: #444444;
  font-size: 1.5rem;
  font-weight: 400;
  cursor: pointer;
  line-height: 1.5rem;
}
.form-input-lists label i {
  color: #f8f8f8;
  margin-right: 1rem;
  cursor: pointer;
}
.form-input-lists:hover label {
  color: #444444;
}
.form-input-lists:hover label i {
  color: #f8f8f8;
}
.form-input-lists_row {
  display: inline-block;
}
.form-input-lists_row:not(:last-child) {
  margin-right: 1.4rem;
  margin-top: 0;
  margin-bottom: 0;
}

textarea {
  resize: none !important;
}

.input-range {
  height: 6rem !important;
}

.input-range__slider {
  appearance: none;
  background: #469ad4;
  border: 1px solid #469ad4;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1.4rem;
  margin-left: -0.5rem;
  margin-top: -0.8rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1.4rem;
}
@media all and (max-width: 1000px) {
  .input-range__slider {
    height: 2rem;
    width: 2rem;
    margin-top: -1.1rem;
  }
}

.input-range__slider:active {
  transform: scale(1.3);
}

.input-range__slider:focus {
  box-shadow: 0 0 0 0.5rem rgba(63, 81, 181, 0.2);
}

.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-size: 1.3rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -0.5rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -3.5rem;
}

.input-range__label-container {
  left: -30%;
  position: relative;
}

.input-range__label--max .input-range__label-container {
  left: 80%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}

.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #469ad4;
}

.input-range {
  height: 5rem;
  position: relative;
  margin: 0 auto;
}

.react-autosuggest__suggestions-list {
  display: block;
  position: absolute;
  max-height: 18.5rem;
  background: white;
  overflow-y: scroll;
  width: 100%;
  margin-top: 0.2rem;
  z-index: 22;
  pointer-events: auto;
  box-shadow: 0px 3px 15px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 3px 15px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 3px 15px -4px rgba(0, 0, 0, 0.5);
  border-radius: 1rem;
}

.react-autosuggest__suggestion {
  display: block;
  color: #444;
  cursor: pointer;
  padding: 1rem;
  pointer-events: auto;
}
.react-autosuggest__suggestion:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}

.price-range-box {
  display: flex !important;
  width: 100%;
  float: none;
  justify-content: space-between;
}
.price-range-box input {
  width: 49% !important;
}

.noShowCheck {
  display: none !important;
}

.wrapper-cat-home .header-search,
.wrapper-cat-page .header-search {
  display: none;
}

.wrapper-cat-home .breadcrumb,
.wrapper-cat-page .breadcrumb {
  position: relative;
  color: #fff;
  z-index: 1;
}
.wrapper-cat-home .breadcrumb a,
.wrapper-cat-home .breadcrumb span,
.wrapper-cat-page .breadcrumb a,
.wrapper-cat-page .breadcrumb span {
  color: #fff;
}

.store-home {
  position: relative;
}

.cat-loader {
  margin-bottom: 4rem;
}
.cat-loader-spinner {
  font-size: 4rem;
  position: absolute;
  top: 5rem;
  z-index: 20;
  left: 50%;
  width: 10rem;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateX(-50%);
  color: #496ad4;
}
.cat-loader-image {
  width: 100%;
  height: 22rem;
  background: #eeeeee;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
@media all and (max-width: 600px) {
  .cat-loader-image {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
  }
}
.cat-loader-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  min-height: 10rem;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 2rem;
}
.cat-loader-price span {
  display: block;
  height: 7rem;
  max-width: 7rem;
  background: #eeeeee;
  width: 100%;
  border-radius: 0.5rem;
}
.cat-loader-title {
  width: 80%;
  height: 2.4rem;
  background: #eeeeee;
  border-radius: 0.5rem;
  margin: 0 2rem 0.4rem 2rem;
}
.cat-loader-meta {
  width: 60%;
  height: 1.8rem;
  background: #eeeeee;
  border-radius: 0.5rem;
  margin: 0 2rem 3rem 2rem;
}
.cat-loader-button {
  width: 40%;
  height: 3.5rem;
  background: #eeeeee;
  border-radius: 5rem;
  margin: 0 2rem 3rem 2rem;
}
.cat-loader-social {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0 2rem;
  border-top: 1px solid #eeeeee;
}
.cat-loader-social span {
  display: block;
  height: 3rem;
  max-width: 3rem;
  background: #eeeeee;
  width: 100%;
  border-radius: 0.5rem;
}

.seller-rev {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
}
.seller-rev-box {
  border: 1px solid #ededed;
  border-radius: 1rem;
  padding: 0rem;
  overflow: hidden;
}
.seller-rev-title {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0 !important;
  text-align: center;
  margin-top: 3rem;
  padding: 0 2rem;
}
.seller-rev-amount {
  font-size: 3.6rem;
  line-height: 1.2;
  text-align: center;
  padding: 0 2rem;
  margin-bottom: 3rem !important;
}
.seller-rev table {
  width: 100%;
}
.seller-rev table tr:first-child td {
  font-weight: 600;
  color: #fff;
  background: #333;
  text-align: left;
  padding-left: 1.4rem !important;
}
.seller-rev table td {
  padding: 0.8rem;
  border: 1px solid #ffffff;
  background: #f8f8f8;
  text-align: right;
}
.seller-rev table td span {
  color: #999 !important;
}
.seller-rev table td.basic {
  background: linear-gradient(to right bottom, #48b1bf, #06beb6) !important;
}
.seller-rev table td.premium {
  background: linear-gradient(to right bottom, #ffafbd, #ffc3a0) !important;
}
.seller-rev table td.enterprise {
  background: linear-gradient(to right bottom, #f77062, #fe5196) !important;
}

.seller-payment {
  margin-top: 3rem;
  text-align: center;
}
.seller-payment p {
  display: flex;
  align-items: center;
  justify-content: center;
}
.seller-payment p span {
  font-size: 2rem;
  display: inline-block;
  margin-left: 1rem;
}

.seller-findbiz-search {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
}

.seller-findbiz-results {
  margin-top: 2rem;
}
.seller-findbiz-results table {
  width: 100%;
}
@media all and (max-width: 1000px) {
  .seller-findbiz-results table thead {
    display: none;
  }
}
.seller-findbiz-results table thead tr td {
  background: #ededed;
  font-weight: 600;
  border: 1px solid #ededed !important;
  padding: 0.8rem;
}
@media all and (max-width: 1000px) {
  .seller-findbiz-results table tbody {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
  }
}
@media all and (max-width: 1000px) {
  .seller-findbiz-results table tbody tr {
    display: block;
    border: 1px solid #ededed !important;
    border-radius: 1rem;
    padding: 1.4rem;
  }
}
.seller-findbiz-results table tbody tr td {
  border: 1px solid #ededed !important;
  padding: 0.8rem;
  vertical-align: center;
}
@media all and (max-width: 1000px) {
  .seller-findbiz-results table tbody tr td {
    display: block;
    border: 0px solid #ededed !important;
    padding: 0px 0px 0.8rem 0px;
  }
  .seller-findbiz-results table tbody tr td:nth-child(3):before {
    content: "Listings: ";
    color: #999999;
  }
  .seller-findbiz-results table tbody tr td:nth-child(4):before {
    content: "Sub. : ";
    color: #999999;
  }
  .seller-findbiz-results table tbody tr td.status:before {
    content: "Status : ";
    color: #999999;
  }
}
.seller-findbiz-results table tbody tr td:nth-child(2) {
  width: 20rem;
}
.seller-findbiz-results table tbody tr td span {
  display: block;
}
.seller-findbiz-results table tbody tr td span:not(:last-child) {
  margin-bottom: 0.8rem;
}
.seller-findbiz-results table tbody tr td button {
  border: 1px solid #ededed;
  width: 4.4rem;
  height: 4.4rem;
  display: inline-flex;
  margin-right: 0.4rem;
  align-items: center;
  justify-content: center;
  color: #444;
  border-radius: 0.5rem;
  font-size: 1.8rem;
}
@media all and (max-width: 1000px) {
  .seller-findbiz-results table tbody tr td button {
    margin-top: 2rem;
  }
}

.seller-co-info {
  text-align: left;
  margin-top: 2rem;
}
.seller-co-info label {
  margin: 2rem 0 0.8rem 0;
  font-weight: 600;
  display: block;
}
.seller-co-info p span {
  color: #999;
  display: inline-block;
  width: 8rem;
}
.seller-co-info p.notes-info {
  font-size: 1.2rem;
  margin-top: 0.4rem;
  color: #999999;
  font-style: italic;
}
.seller-co-info button {
  width: 100%;
  margin-top: 2rem;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.6;
  font-size: 1.3rem;
  color: #444444;
}

h1,
h2,
h3 {
  font-weight: 300;
  line-height: 1.2;
}

h4 {
  font-weight: 600;
  line-height: 1.2;
  text-transform: uppercase;
}

h1 {
  font-size: 4.4rem;
}

h2 {
  font-size: 3.6rem;
}

h3 {
  font-size: 2.8rem;
}

h4 {
  font-size: 1.8rem;
}

@media all and (max-width: 600px) {
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.7rem;
  }
  h3 {
    font-size: 2.3rem;
  }
  h4 {
    font-size: 1.6rem;
  }
}

.italic {
  font-style: italic;
}

strong,
.strong {
  font-weight: 700;
}

.small-font {
  font-size: 1.2rem;
}

.accent-font {
  font-family: "Playfair Display";
}

.white-text {
  color: #ffffff;
}

.capitalize {
  text-transform: capitalize;
}

.strike {
  text-decoration: line-through;
  text-decoration-color: #999999;
}

.txtl {
  text-align: left;
}

.txtc {
  text-align: center;
}

.txtr {
  text-align: right;
}

a:link,
a:visited {
  color: #469ad4;
  text-decoration: none;
}

a:hover {
  color: #425caf;
}

.blog-post_content p:not(:last-child) {
  margin-bottom: 1.6rem;
}

.media-modal_desc_text p:not(:last-child) {
  margin-bottom: 0.4rem;
}

.left {
  float: left;
}

.right {
  float: right;
}

.clb {
  clear: both;
}

.blk-ctr-align {
  display: block;
  margin: 0 auto;
}

.dsp-blk {
  display: block !important;
}

.dsp-in-blk {
  display: inline-block !important;
}

@media all and (max-width: 750px) {
  .show-box {
    opacity: 1;
    height: 100%;
  }
}

@media all and (max-width: 750px) {
  .hide-box {
    opacity: 0;
    height: 0;
  }
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.half-width {
  width: 50% !important;
}

.web-divider {
  width: 2%;
  height: 2rem;
}

.mgt-none {
  margin-top: 0;
}

.mgt-sm {
  margin-top: 1rem !important;
}

.mgt-md {
  margin-top: 2rem !important;
}

.mgt-lg {
  margin-top: 4rem;
}

.mgb-none {
  margin-bottom: 0;
}

.mgb-sm {
  margin-bottom: 1rem;
}

.mgb-md {
  margin-bottom: 2rem;
}

.mgb-lg {
  margin-bottom: 4rem;
}

.mgl-none {
  margin-left: 0;
}

.mgl-sm {
  margin-left: 1rem;
}

.mgl-md {
  margin-left: 2rem;
}

.mgl-lg {
  margin-left: 4rem;
}

.mgr-none {
  margin-right: 0;
}

.mgr-sm {
  margin-right: 1rem;
}

.mgr-md {
  margin-right: 2rem;
}

.mgr-lg {
  margin-right: 4rem;
}

.pdt-none {
  margin-top: 0;
}

.pdt-sm {
  margin-top: 1rem;
}

.pdt-md {
  margin-top: 2rem;
}

.pdt-lg {
  margin-top: 4rem;
}

.pdb-none {
  margin-bottom: 0;
}

.pdb-sm {
  margin-bottom: 1rem;
}

.pdb-md {
  margin-bottom: 2rem;
}

.pdb-lg {
  margin-bottom: 4rem;
}

.pdl-none {
  margin-left: 0;
}

.pdl-sm {
  margin-left: 1rem;
}

.pdl-md {
  margin-left: 2rem;
}

.pdl-lg {
  margin-left: 4rem;
}

.pdr-none {
  margin-right: 0;
}

.pdr-sm {
  margin-right: 1rem;
}

.pdr-md {
  margin-right: 2rem;
}

.pdr-lg {
  margin-right: 4rem;
}

.reg-td {
  vertical-align: super;
  font-size: 0.7em;
}

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #fff;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled {
  cursor: inherit;
}

.ReactCrop__image {
  display: block;
  max-width: 100%;
}

.ReactCrop--crop-invisible .ReactCrop__image {
  opacity: 0.5;
}

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop__drag-handle {
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}

.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}

.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}

.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}

.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}

.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}

.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}

.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}

.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (max-width: 768px) {
  .ReactCrop__drag-handle {
    width: 17px;
    height: 17px;
  }
  .ReactCrop .ord-nw {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-n {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-ne {
    margin-top: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-e {
    margin-top: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-se {
    margin-bottom: -9px;
    margin-right: -9px;
  }
  .ReactCrop .ord-s {
    margin-bottom: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-sw {
    margin-bottom: -9px;
    margin-left: -9px;
  }
  .ReactCrop .ord-w {
    margin-top: -9px;
    margin-left: -9px;
  }
  .ReactCrop__drag-bar.ord-n {
    height: 14px;
    margin-top: -7px;
  }
  .ReactCrop__drag-bar.ord-e {
    width: 14px;
    margin-right: -7px;
  }
  .ReactCrop__drag-bar.ord-s {
    height: 14px;
    margin-bottom: -7px;
  }
  .ReactCrop__drag-bar.ord-w {
    width: 14px;
    margin-left: -7px;
  }
}

.LoadingSvg {
  text-align: center;
}
.LoadingSvg svg {
  width: 30px;
}

@media all and (max-width: 600px) {
  .wrapper-messages {
    margin-bottom: 0rem !important;
  }
  .wrapper-messages footer,
  .wrapper-messages .breadcrumb,
  .wrapper-messages .mobile-footer-menu,
  .wrapper-messages .header-search {
    display: none !important;
  }
}

.messenger {
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  height: calc(100vh - 18rem);
  background: #eeeef1;
  overflow: hidden;
  border-radius: 1rem;
}
@media all and (max-width: 600px) {
  .messenger {
    height: calc(100% - 5rem);
    max-width: 100%;
    border-radius: 0;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
  }
  .messenger .sidebar {
    margin-right: 0 !important;
    border-right: 1px solid #ccc;
  }
}
.messenger .container {
  padding: 1rem;
}
.messenger .scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.messenger .sidebar {
  background: white;
  width: 30rem;
  margin-right: 1rem;
  transition: all 0.3s ease-out;
}
@media all and (max-width: 600px) {
  .messenger .sidebar {
    width: 100%;
  }
  .messenger .sidebar.hasConversation {
    display: none;
  }
}
.messenger .sidebar .conversation-list {
  display: flex;
  flex-direction: column;
}
.messenger .sidebar .conversation-list .toolbar {
  border-bottom: 1px solid #e7e7e7 !important;
  padding-right: 1.4rem;
  order: -2;
}
.messenger .sidebar .conversation-list .toolbar-title {
  padding-right: 0;
  border-bottom: 0 !important;
}
.messenger .sidebar .conversation-list-new-chat-btn {
  padding: 2rem;
}
.messenger .sidebar .conversation-list-no-contacts {
  text-align: center;
  padding: 2rem;
}
.messenger .sidebar .conversation-list .conversation-list-item {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.messenger .sidebar .conversation-list .conversation-list-item i {
  color: #6ec568;
  font-size: 1rem !important;
}
.messenger .sidebar .conversation-list .conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}
.messenger .sidebar .conversation-list .conversation-info {
  flex: 1;
}
.messenger .sidebar .conversation-list .conversation-photo {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}
.messenger .sidebar .conversation-list .conversation-title {
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}
.messenger .sidebar .conversation-list .conversation-snippet {
  font-size: 1.2rem;
  color: #888;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 12rem;
  height: 2.1rem;
}
.messenger .content {
  background: white;
  width: 100%;
}
.messenger .footer {
  background: white;
}
.messenger .toolbar-title {
  padding: 2rem;
  display: block;
  width: 100%;
  border-bottom: 1px solid #e7e7e7 !important;
}
@media all and (max-width: 600px) {
  .messenger .toolbar-title {
    padding: 1.4rem;
  }
}
.messenger .message-list-container {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 12.1rem);
  top: 6rem;
  display: flex;
  flex-direction: column;
  padding-top: 7rem;
  padding-bottom: 0;
}
@media all and (max-width: 600px) {
  .messenger .message-list-container {
    top: 0;
    height: calc(100% - 6.1rem);
  }
}
.messenger .message-list-header {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}
.messenger .message-list-header .right-items {
  display: none;
}
@media all and (max-width: 600px) {
  .messenger .message-list-header {
    position: fixed;
    top: 5.1rem;
  }
}
@supports (backdrop-filter: blur(20px)) {
  .messenger .message-list-header {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}
.messenger .message-list-header .toolbar {
  width: 100%;
}
.messenger .message-list-header .toolbar-title {
  border-bottom: 0;
}
.messenger .message-list-header .toolbar-title:hover {
  cursor: pointer;
}
.messenger .message-list-header button {
  display: none;
  background: #469ad4;
  color: #fff;
  font-size: 2rem;
  padding: 0 2.8rem;
}
.messenger .message-list-header button:hover {
  background-color: #425caf;
}
.messenger .message-list-header button:last-child {
  background: #fff;
  color: #444;
  border-bottom: 1px solid #e7e7e7;
  border-left: 1px solid #e7e7e7;
}
@media all and (max-width: 600px) {
  .messenger .message-list-header button {
    display: block;
    padding: 0 1.8rem;
  }
}
.messenger .message-list {
  height: 100%;
  position: relative;
}
.messenger .compose {
  padding: 1rem;
  display: flex;
  align-items: center;
  background: white;
  position: absolute;
  bottom: 0 !important;
  left: 0;
  width: 100%;
  border-top: 1px solid #ccc !important;
}
.messenger .compose form {
  width: 100%;
  display: flex;
  align-items: center;
}
.messenger .compose form button {
  height: 100%;
  display: block;
  font-size: 2rem;
  margin: 0 2rem;
}
@supports (backdrop-filter: blur(20px)) {
  .messenger .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}
.messenger .compose-input {
  flex: 1;
  border: none;
  font-size: 1.4rem;
  height: 4rem;
  background: none;
  width: 100%;
}
.messenger .compose-input::placeholder {
  opacity: 0.3;
}
.messenger .compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 1.5rem;
}
.messenger .compose .toolbar-button:hover {
  color: #99999c;
}
.messenger .conversation-search {
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.messenger .conversation-search-input {
  background: #f4f4f8;
  padding: 0.8rem 1rem;
  border-radius: 1rem;
  border: none;
  font-size: 1.4rem;
}
.messenger .conversation-search-input::placeholder {
  text-align: center;
}
.messenger .conversation-search-input:focus::placeholder {
  text-align: left;
}
.messenger .message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 1.2rem;
  margin: 1rem 0;
  text-transform: uppercase;
}
.messenger .message .bubble-container {
  font-size: 1.4rem;
  display: flex;
}
.messenger .message .bubble-container a {
  font-style: italic;
  text-decoration: underline;
  text-transform: capitalize;
}
.messenger .chatImageContainer {
  height: 200px;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.messenger .chatImageContainer svg {
  background: #fff;
  border-radius: 100%;
}
.messenger .message.mine .bubble-container {
  justify-content: flex-end;
}
.messenger .message.start .bubble-container .bubble {
  margin-top: 1rem;
  border-top-left-radius: 2rem;
  max-width: 340px;
}
.messenger .message.end .bubble-container .bubble {
  border-bottom-left-radius: 2rem;
  margin-bottom: 1rem;
  max-width: 340px;
}
.messenger .message.mine.start .bubble-container .bubble {
  margin-top: 2rem;
  border-top-right-radius: 2rem;
}
.messenger .message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 2rem;
  margin-bottom: 1rem;
}
.messenger .message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 1rem 1.5rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 75%;
  border-top-left-radius: 2px;
  height: auto;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  overflow: hidden;
  word-break: break-word;
}
.messenger .message .bubble-container .bubble img {
  border-radius: 1rem;
}
@media all and (max-width: 700px) {
  .messenger .message .bubble-container .bubble img {
    width: 100%;
    height: auto;
  }
}
.messenger .message .bubble-container .bubble div {
  width: 100%;
}
.messenger .message.mine .bubble-container .bubble {
  background: #469ad4;
  color: white;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.messenger .message.mine .bubble-container .bubble a {
  color: #fff;
}
.messenger .message-list-container {
  padding: 1rem;
}
.messenger .toolbar {
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  position: sticky;
  top: 0;
}
@supports (backdrop-filter: blur(20px)) {
  .messenger .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}
.messenger .toolbar-title {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 800;
}
.messenger .left-items,
.messenger .right-items {
  flex: 1;
  padding: 1rem;
  display: flex;
}
.messenger .left-items .toolbar-button {
  margin-right: 2rem;
}
.messenger .right-items .toolbar-button {
  margin-left: 2rem;
}
.messenger .left-items .toolbar-button:last-child,
.messenger .right-items .toolbar-button:last-child {
  margin: 0;
}
.messenger .right-items i {
  font-size: 2rem;
}
@media all and (max-width: 600px) {
  .messenger .right-items i {
    font-size: 2.5rem;
  }
}
.messenger .right-items button.active {
  color: #444 !important;
}
.messenger .right-items button:not(:first-child) {
  margin-left: 1rem;
  border-left: 1px solid #ccc;
  padding-left: 1rem;
}
@media all and (max-width: 600px) {
  .messenger .right-items button {
    padding: 1rem;
    margin: 0.2rem !important;
    border-left: 0 !important;
    background: #f8f8f8;
    border-radius: 5px;
  }
}
.messenger .toolbar-button {
  color: #469ad4;
  font-size: 2.8rem;
  transition: all 0.1s;
}
.messenger .toolbar-button:hover {
  cursor: pointer;
  color: #425caf;
}
.messenger .toolbar-button:active {
  color: #469ad4;
  opacity: 0.25;
}

@keyframes move-in-left {
  0% {
    transform: translateX(0rem);
  }
  33% {
    transform: translateX(-0.5rem);
  }
  66% {
    transform: translateX(0.5rem);
  }
  100% {
    transform: translateX(0);
  }
}

header {
  border-bottom: 1px solid #ededed;
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0rem 2rem;
}
@media all and (max-width: 1150px) {
  header {
    padding-left: 0px !important;
  }
}
header .mobile-menu-icon,
header .logo-header,
header .header-tools,
header nav {
  height: 5rem !important;
}

.profile_header {
  margin-top: -1.3rem !important;
}

.logo-header {
  box-sizing: content-box;
  height: 5rem;
  position: relative;
  display: flex;
  align-items: center;
}
@media all and (max-width: 1150px) {
  .logo-header {
    flex: 1;
  }
}
@media all and (max-width: 570px) {
  .logo-header {
    width: auto;
    border-right: 0;
  }
}

.mobile-menu-icon {
  display: none;
  height: 5rem;
  padding: 0 3rem;
  position: relative;
}
@media all and (max-width: 1150px) {
  .mobile-menu-icon {
    display: block;
  }
}
@media all and (max-width: 570px) {
  .mobile-menu-icon {
    border-right: 0;
  }
}
@media all and (max-width: 340px) {
  .mobile-menu-icon {
    padding: 0 2.5rem;
  }
}
@media all and (max-width: 340px) {
  .mobile-menu-icon button {
    font-size: 1.8rem !important;
  }
}

nav {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 5rem;
  padding: 0 2rem;
}
@media all and (max-width: 1150px) {
  nav {
    display: none;
  }
}
nav div {
  margin-right: 0 !important;
}
nav div a {
  display: flex;
  height: 5.1rem;
  border-radius: initial !important;
  align-items: center;
  border: none !important;
}
nav div a:hover {
  border: none !important;
  padding: 0.6rem 1.2rem !important;
}

.website-location {
  margin-right: 2rem;
  padding-right: 2rem;
  border-right: 1px solid #e7e7e7;
  display: flex;
  height: 50px;
}
@media all and (max-width: 500px) {
  .website-location {
    margin-right: 1rem;
    padding-right: 1rem;
  }
}
@media all and (max-width: 410px) {
  .website-location {
    margin-right: 2rem;
  }
}
@media all and (max-width: 360px) {
  .website-location {
    margin-right: 1rem;
  }
}
.website-location button {
  display: flex;
  align-items: center;
  line-height: 1;
}
.website-location button i {
  font-size: 1.9rem;
  font-weight: 300;
  color: #777;
  margin-right: 0.8rem;
  line-height: 1;
}
@media all and (max-width: 360px) {
  .website-location button i {
    margin-right: 0rem;
  }
}
.website-location button span {
  color: #555;
  font-size: 1.1rem;
  line-height: 1;
  font-weight: 600;
}
@media all and (max-width: 410px) {
  .website-location button span {
    display: none;
  }
}

.header-tools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 6rem;
}
.header-tools div {
  line-height: 1;
}
@media all and (max-width: 550px) {
  .header-tools {
    padding-left: 0;
  }
}
.header-tools img {
  border: 1px solid #cccccc;
}

.header-search {
  max-width: 97%;
  margin: 0 auto;
  margin-bottom: 1rem;
  border: 1px solid #ededed;
  border-radius: 5rem;
  overflow: hidden;
  position: relative;
  z-index: 10;
  background: #fff;
  display: none;
}

.container {
  width: 100%;
  margin: 0 auto;
  background: #f4f4f4;
  position: relative;
}

main {
  margin: 0 auto;
}

.main-100 {
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  padding: 0 2rem;
}
@media all and (max-width: 600px) {
  .main-100 {
    padding: 0;
    margin-bottom: 4px;
  }
}
.main-100 ~ .main-sidebar {
  display: none;
}
.main-100-header {
  text-align: center;
}
@media all and (max-width: 600px) {
  .main-100-header {
    padding: 0 2rem;
  }
}
.main-100-header h1::after {
  content: "";
  display: block;
  margin: 0 auto;
  border-top: 1px solid #444444;
  width: 8rem;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
}
.main-100-header p {
  max-width: 40rem;
  margin: 0 auto;
  margin-bottom: 4rem;
}
.main-100-header p button:not(:last-child) {
  margin-right: 1rem;
}

.category-body {
  padding: 0 4rem;
  display: flex;
}
@media all and (max-width: 600px) {
  .category-body {
    padding: 0;
    display: flex;
  }
}
.category-body .main-container {
  flex-grow: 1;
  order: 2;
}
.category-body .main-sidebar {
  width: 32rem;
  margin-right: 4rem;
}
@media all and (max-width: 900px) {
  .category-body .main-sidebar {
    margin-left: 4rem;
    margin-right: 0;
  }
}

.listing-body {
  display: flex;
  max-width: 140rem;
  margin: 0 auto;
  width: 97%;
  justify-content: space-between;
}
@media all and (max-width: 600px) {
  .listing-body {
    width: 100%;
  }
}
.listing-body .main-container {
  width: 75%;
}
@media all and (max-width: 900px) {
  .listing-body .main-container {
    width: 100%;
  }
}
@media all and (max-width: 600px) {
  .listing-body .main-container {
    margin-bottom: 4px;
  }
}
.listing-body .main-sidebar {
  margin-left: 4rem;
}

.profile-header .logged-in-header {
  max-width: 140rem;
}

.profile-body {
  display: flex;
  max-width: 140rem;
  width: 97%;
  margin: 0 auto;
}
.profile-body .profile_content {
  flex-grow: 1;
}
.profile-body .main-sidebar {
  width: 32rem;
  margin-left: 2rem;
}

.group-body {
  display: flex;
  max-width: 140rem;
  width: 97%;
  margin: 0 auto;
}
.group-body .profile_content {
  flex-grow: 1;
}
.group-body .main-sidebar {
  width: 32rem;
  margin-left: 2rem;
}

.profile-posts-container {
  padding: 0 4rem;
  display: flex;
}
@media all and (max-width: 400px) {
  .profile-posts-container {
    padding: 0 2rem;
    display: flex;
  }
}
.profile-posts-container .profile-main-container {
  flex-grow: 1;
  order: 2;
}
.profile-posts-container .main-sidebar {
  width: 32rem;
  margin-right: 4rem !important;
}
@media all and (max-width: 900px) {
  .profile-posts-container .main-sidebar {
    margin-left: 4rem;
    margin-right: 0 !important;
  }
}

.main-featured-slider {
  width: 97%;
  margin: 0 auto;
}

footer {
  margin-top: 2rem;
  background: #fff;
  padding: 4rem;
}
@media all and (max-width: 600px) {
  footer {
    margin-top: 0;
    padding: 4rem 2rem;
  }
}

.footer-container {
  display: flex;
  justify-content: space-between;
  max-width: 140rem;
  margin: 0 auto;
}
@media all and (max-width: 850px) {
  .footer-container {
    display: block;
  }
}

.footer-branding {
  max-width: 19%;
}
@media all and (max-width: 1180px) {
  .footer-branding {
    max-width: 22%;
  }
}
@media all and (max-width: 1090px) {
  .footer-branding {
    width: 20rem;
    max-width: 20rem;
  }
}
@media all and (max-width: 850px) {
  .footer-branding {
    margin-bottom: 4rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
  }
}
.footer-branding-logo {
  width: 100%;
}
@media all and (max-width: 850px) {
  .footer-branding-logo {
    max-width: 20rem;
    display: inline-block;
    margin-right: 4rem;
    vertical-align: bottom;
  }
}
@media all and (max-width: 540px) {
  .footer-branding-logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 4rem;
  }
}
.footer-branding .footer-app-download {
  display: none;
}
@media all and (max-width: 1090px) {
  .footer-branding .footer-app-download {
    display: block !important;
    max-width: 100%;
    width: 100%;
    margin-top: 3rem;
  }
}
@media all and (max-width: 850px) {
  .footer-branding .footer-app-download {
    max-width: 20rem;
    margin-top: 0;
    display: inline-block !important;
  }
}
@media all and (max-width: 850px) {
  .footer-branding .footer-app-download h4 {
    display: none;
  }
}
@media all and (max-width: 540px) {
  .footer-branding .footer-app-download h4 {
    display: inline-block;
  }
}

.footer-app-download {
  width: 19%;
}
@media all and (max-width: 1180px) {
  .footer-app-download {
    max-width: 22%;
  }
}
@media all and (max-width: 1090px) {
  .footer-app-download {
    display: none;
  }
}
.footer-app-download h4 {
  margin-bottom: 1.6rem;
}
.footer-app-download div {
  display: flex;
  justify-content: space-between;
}
.footer-app-download div img {
  display: block;
  max-width: 7rem;
  max-height: 7rem;
}
@media all and (max-width: 1250px) {
  .footer-app-download div img {
    max-width: 6.5rem;
    max-height: 6.5rem;
  }
}
@media all and (max-width: 1180px) {
  .footer-app-download div img {
    max-width: 6rem;
    max-height: 6rem;
  }
}

.footer-links {
  width: 51%;
  display: flex;
  justify-content: space-around;
}
@media all and (max-width: 1090px) {
  .footer-links {
    width: 70%;
  }
}
@media all and (max-width: 850px) {
  .footer-links {
    width: 100%;
  }
}
@media all and (max-width: 700px) {
  .footer-links {
    display: none;
  }
}
.footer-links-row {
  text-align: left;
}
.footer-links-row h4 {
  margin-bottom: 0.8rem;
}
.footer-links-row a {
  color: #444444;
  padding-top: 0.8rem;
  display: block;
}
@media all and (max-width: 1180px) {
  .footer-links-row:last-child {
    display: none;
  }
}
@media all and (max-width: 1090px) {
  .footer-links-row:last-child {
    display: block;
  }
}
@media all and (max-width: 650px) {
  .footer-links-row:last-child {
    display: none;
  }
}
@media all and (max-width: 500px) {
  .footer-links-row:nth-child(3) {
    display: none;
  }
}

.footer-badges {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
}
.footer-badges #bbblink {
  display: inline-block;
}
.footer-badges table {
  display: none;
  height: 82px;
  margin-left: 2rem;
}

.footer-copyright {
  font-size: 1.2rem;
  text-align: center;
  margin-top: 3rem;
}
@media all and (max-width: 850px) {
  .footer-copyright {
    line-height: 4rem;
  }
}
@media all and (max-width: 650px) {
  .footer-copyright .form-input-select {
    display: block !important;
    max-width: 300px;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .footer-copyright span:nth-child(8) {
    display: none !important;
  }
}
@media all and (max-width: 400px) {
  .footer-copyright span {
    padding: 0 4px !important;
  }
}
.footer-copyright span {
  padding: 0 1rem;
}

.mobileNavLegal,
.mobileAppLanguage {
  display: none;
}

.featured {
  padding: 0 2rem;
}
@media all and (max-width: 950px) {
  .featured {
    text-align: center;
  }
}
.featured-item {
  background: #fff;
  padding-bottom: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 3rem;
  margin-top: 0;
}
@media all and (max-width: 950px) {
  .featured-item {
    width: 48%;
    margin: 1% !important;
    display: inline-block;
    float: none;
  }
}
@media all and (max-width: 650px) {
  .featured-item {
    width: 100%;
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }
}
@media all and (max-width: 950px) {
  .featured-item .featured-event_details_info span {
    text-align: left;
  }
}
@media all and (max-width: 950px) {
  .featured-item .featured-event_details_info:not(:last-child) {
    margin-right: 2rem;
  }
}
@media all and (max-width: 440px) {
  .featured-item .featured-event_details_info:not(:last-child) {
    margin-right: 1rem;
  }
}
@media all and (max-width: 1300px) {
  .featured-item h2 {
    font-size: 2.4rem;
    margin-top: 0.5rem;
  }
}
@media all and (max-width: 1000px) {
  .featured-item h2 {
    font-size: 2rem;
    margin-top: 0.5rem;
  }
}
.featured-item img {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}

.category-title h2 {
  margin-bottom: 3rem;
  margin-left: 4rem;
  line-height: 1.2;
  margin-top: 2rem;
}

.category-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-gap: 2rem;
}
@media all and (max-width: 600px) {
  .category-list {
    grid-gap: 1rem;
  }
}
@media all and (max-width: 340px) {
  .category-list {
    grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  }
}
.category-list_item {
  display: block;
  width: 100%;
  background: #fff;
  padding-bottom: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}
@media all and (max-width: 1040px) {
  .category-list_item .no-coupon {
    display: none;
  }
}
@media all and (max-width: 900px) {
  .category-list_item .no-coupon {
    display: block;
  }
}
@media all and (max-width: 700px) {
  .category-list_item .no-coupon {
    display: none;
  }
}
@media all and (max-width: 600px) {
  .category-list_item {
    border-radius: 0 !important;
    margin-bottom: 0 !important;
  }
}
.category-list_item img {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.category-list_item .engage_buttons_comments {
  cursor: default !important;
}

.Listing-Mobile-Map {
  display: none;
}
@media all and (max-width: 500px) {
  .Listing-Mobile-Map {
    display: block;
    margin-top: 3px;
  }
  .Listing-Mobile-Map .ProductDetailHeaderBox {
    margin-bottom: 0 !important;
    height: 36rem;
    border: 0 !important;
  }
}

@media all and (max-width: 500px) {
  .ServiceContent__Details-Container
    .ProductDetailHeader
    .ProductDetailHeaderBox:nth-child(2),
  .real-estate_property
    .ProductDetailHeader
    .ProductDetailHeaderBox:nth-child(2),
  .store-product .ProductDetailHeader .ProductDetailHeaderBox:nth-child(2) {
    display: none !important;
  }
  .ServiceContent__Details-Container .ProductDetailHeaderBox:first-child,
  .real-estate_property .ProductDetailHeaderBox:first-child,
  .store-product .ProductDetailHeaderBox:first-child {
    margin-bottom: 0 !important;
  }
}

.category-search-panel {
  margin-bottom: 3rem;
  margin-top: -5rem;
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
}
@media all and (max-width: 900px) {
  .category-search-panel {
    margin-bottom: 0 !important;
  }
}
@media all and (max-width: 700px) {
  .category-search-panel {
    margin-top: -5rem;
  }
}
@media all and (max-width: 650px) {
  .category-search-panel {
    margin-top: -3.5rem;
  }
}
.category-search-panel .home-search-box,
.category-search-panel .home-food-box {
  border-radius: 0 !important;
  background-image: none !important;
  background: transparent;
  height: 400px;
  width: 100%;
  margin-top: 0 !important;
}
@media all and (max-width: 900px) {
  .category-search-panel .home-search-box,
  .category-search-panel .home-food-box {
    margin-bottom: 0px !important;
  }
}
@media all and (max-width: 800px) {
  .category-search-panel .home-search-box,
  .category-search-panel .home-food-box {
    height: 450px !important;
  }
}
@media all and (max-width: 400px) {
  .category-search-panel .home-search-box,
  .category-search-panel .home-food-box {
    height: 500px;
  }
}
.category-search-panel .home-search-box .hsb-background,
.category-search-panel .home-food-box .hsb-background {
  border-radius: 0 !important;
}
.category-search-panel .home-search-box .hsb-container,
.category-search-panel .home-food-box .hsb-container {
  max-width: 700px;
}
@media all and (max-width: 960px) {
  .category-search-panel .home-search-box .hsb-container,
  .category-search-panel .home-food-box .hsb-container {
    width: 100%;
  }
}
.category-search-panel .home-search-box .hsb-container .foods-inputs,
.category-search-panel .home-food-box .hsb-container .foods-inputs {
  display: block;
}
.category-search-panel .home-search-box .hsb-container .foods-inputs .hsb-input,
.category-search-panel .home-food-box .hsb-container .foods-inputs .hsb-input {
  width: 100%;
}
.category-search-panel
  .home-search-box
  .hsb-container
  .foods-inputs
  .hsb-input:first-child,
.category-search-panel
  .home-food-box
  .hsb-container
  .foods-inputs
  .hsb-input:first-child {
  margin-bottom: 1rem;
}
.category-search-panel .home-search-box .hsb-container .hsb-filter-group,
.category-search-panel .home-food-box .hsb-container .hsb-filter-group {
  justify-content: flex-start !important;
}
@media all and (max-width: 420px) {
  .category-search-panel .home-search-box .hsb-container .hsb-filter-group,
  .category-search-panel .home-food-box .hsb-container .hsb-filter-group {
    justify-content: space-between !important;
  }
}
.category-search-panel
  .home-search-box
  .hsb-container
  .hsb-filter-group
  .hsb-filter:first-child,
.category-search-panel
  .home-food-box
  .hsb-container
  .hsb-filter-group
  .hsb-filter:first-child {
  margin-right: 2rem;
}
@media all and (max-width: 420px) {
  .category-search-panel
    .home-search-box
    .hsb-container
    .hsb-filter-group
    .hsb-filter:first-child,
  .category-search-panel
    .home-food-box
    .hsb-container
    .hsb-filter-group
    .hsb-filter:first-child {
    margin-right: 0;
  }
}
.category-search-panel .home-search-box .hsb-buttons,
.category-search-panel .home-food-box .hsb-buttons {
  justify-content: space-between;
}
@media all and (max-width: 960px) {
  .category-search-panel .home-search-box .hsb-buttons,
  .category-search-panel .home-food-box .hsb-buttons {
    justify-content: space-between;
  }
}
@media all and (max-width: 700px) {
  .category-search-panel .home-search-box .hsb-buttons,
  .category-search-panel .home-food-box .hsb-buttons {
    justify-content: center;
    flex-direction: column;
  }
  .category-search-panel .home-search-box .hsb-buttons button,
  .category-search-panel .home-food-box .hsb-buttons button {
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .category-search-panel .home-search-box .hsb-buttons button:first-child,
  .category-search-panel .home-food-box .hsb-buttons button:first-child {
    margin-right: 1rem;
  }
}
@media all and (max-width: 400px) {
  .category-search-panel .home-search-box .hsb-buttons div:first-child,
  .category-search-panel .home-food-box .hsb-buttons div:first-child {
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
  .category-search-panel .home-search-box .hsb-buttons div:first-child button,
  .category-search-panel .home-food-box .hsb-buttons div:first-child button {
    margin-right: 0;
  }
}
@media all and (max-width: 800px) {
  .category-search-panel .real-estate-search-box {
    height: 450px !important;
  }
}
@media all and (max-width: 400px) {
  .category-search-panel .real-estate-search-box {
    height: 550px !important;
  }
}

.row {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

.col {
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}
@media only screen and (max-width: 480px) {
  .col {
    width: 100% !important;
  }
}

.col:first-child {
  margin-left: 0;
}

@media only screen and (max-width: 750px) {
  .add-listing .col {
    margin: 0;
    margin-bottom: 1rem;
    width: 100% !important;
  }
}

@media only screen and (max-width: 480px) {
  .col {
    margin: 0;
    margin-bottom: 1rem;
  }
}

.span-2-of-2 {
  width: 100%;
}

.span-1-of-2 {
  width: 49.2%;
}

.span-3-of-3 {
  width: 100%;
}

.span-2-of-3 {
  width: 66.13%;
}

.span-1-of-3 {
  width: 32.26%;
}

.span-4-of-4 {
  width: 100%;
}

.span-3-of-4 {
  width: 74.6%;
}

.span-2-of-4 {
  width: 49.2%;
}

.span-1-of-4 {
  width: 23.8%;
}

.span-5-of-5 {
  width: 100%;
}

.span-4-of-5 {
  width: 79.68%;
}

.span-3-of-5 {
  width: 59.36%;
}

.span-2-of-5 {
  width: 39.04%;
}

.span-1-of-5 {
  width: 18.72%;
}

.span-6-of-6 {
  width: 100%;
}

.span-5-of-6 {
  width: 83.06%;
}

.span-4-of-6 {
  width: 66.13%;
}

.span-3-of-6 {
  width: 49.2%;
}

.span-2-of-6 {
  width: 32.26%;
}

.span-1-of-6 {
  width: 15.33%;
}

.span-7-of-7 {
  width: 100%;
}

.span-6-of-7 {
  width: 85.48%;
}

.span-5-of-7 {
  width: 70.97%;
}

.span-4-of-7 {
  width: 56.45%;
}

.span-3-of-7 {
  width: 41.94%;
}

.span-2-of-7 {
  width: 27.42%;
}

.span-1-of-7 {
  width: 12.91%;
}

.span-8-of-8 {
  width: 100%;
}

.span-7-of-8 {
  width: 87.3%;
}

.span-6-of-8 {
  width: 74.6%;
}

.span-5-of-8 {
  width: 61.9%;
}

.span-4-of-8 {
  width: 49.2%;
}

.span-3-of-8 {
  width: 36.5%;
}

.span-2-of-8 {
  width: 23.8%;
}

.span-1-of-8 {
  width: 11.1%;
}

.span-9-of-9 {
  width: 100%;
}

.span-8-of-9 {
  width: 88.71%;
}

.span-7-of-9 {
  width: 77.42%;
}

.span-6-of-9 {
  width: 66.13%;
}

.span-5-of-9 {
  width: 54.84%;
}

.span-4-of-9 {
  width: 43.55%;
}

.span-3-of-9 {
  width: 32.26%;
}

.span-2-of-9 {
  width: 20.97%;
}

.span-1-of-9 {
  width: 9.68%;
}

.span-10-of-10 {
  width: 100%;
}

.span-9-of-10 {
  width: 89.84%;
}

.span-8-of-10 {
  width: 79.68%;
}

.span-7-of-10 {
  width: 69.52%;
}

.span-6-of-10 {
  width: 59.36%;
}

.span-5-of-10 {
  width: 49.2%;
}

.span-4-of-10 {
  width: 39.04%;
}

.span-3-of-10 {
  width: 28.88%;
}

.span-2-of-10 {
  width: 18.72%;
}

.span-1-of-10 {
  width: 8.56%;
}

.span-11-of-11 {
  width: 100%;
}

.span-10-of-11 {
  width: 90.76%;
}

.span-9-of-11 {
  width: 81.52%;
}

.span-8-of-11 {
  width: 72.29%;
}

.span-7-of-11 {
  width: 63.05%;
}

.span-6-of-11 {
  width: 53.81%;
}

.span-5-of-11 {
  width: 44.58%;
}

.span-4-of-11 {
  width: 35.34%;
}

.span-3-of-11 {
  width: 26.1%;
}

.span-2-of-11 {
  width: 16.87%;
}

.span-1-of-11 {
  width: 7.63%;
}

.span-12-of-12 {
  width: 100%;
}

.span-11-of-12 {
  width: 91.53%;
}

.span-10-of-12 {
  width: 83.06%;
}

.span-9-of-12 {
  width: 74.6%;
}

.span-8-of-12 {
  width: 66.13%;
}

.span-7-of-12 {
  width: 57.66%;
}

.span-6-of-12 {
  width: 49.2%;
}

.span-5-of-12 {
  width: 40.73%;
}

.span-4-of-12 {
  width: 32.26%;
}

.span-3-of-12 {
  width: 23.8%;
}

.span-2-of-12 {
  width: 15.33%;
}

.span-1-of-12 {
  width: 6.86%;
}

.flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.flx-2 .flx-item {
  width: 49.5%;
}
@media all and (max-width: 800px) {
  .flx-2 .flx-item {
    width: 100%;
  }
  .flx-2 .flx-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.flx-2 .flx-item-lg {
  width: 74.8%;
}
@media all and (max-width: 800px) {
  .flx-2 .flx-item-lg {
    width: 100%;
  }
  .flx-2 .flx-item-lg:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.flx-2 .flx-item-sm {
  width: 24%;
}
@media all and (max-width: 800px) {
  .flx-2 .flx-item-sm {
    width: 100%;
  }
  .flx-2 .flx-item-sm:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.flx-3 .flx-item {
  width: 32.5%;
}
@media all and (max-width: 900px) {
  .flx-3 .flx-item {
    width: 100%;
  }
  .flx-3 .flx-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}
.flx-4 .flx-item {
  width: 24%;
}
@media all and (max-width: 1000px) {
  .flx-4 .flx-item {
    width: 49.5%;
  }
  .flx-4 .flx-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 800px) {
  .flx-4 .flx-item {
    width: 100%;
  }
  .flx-4 .flx-item:not(:last-child) {
    margin-bottom: 1rem;
  }
}

nav div {
  position: relative;
}
nav div:not(:last-child) {
  margin-right: 0.5rem;
}
nav div a,
nav div button {
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  color: #555 !important;
  border: 1px solid #ededed;
  padding: 0.6rem 1.2rem;
  border-radius: 100px;
}
nav div a:hover,
nav div button:hover {
  padding: 0.7rem 1.3rem;
  border: 0px solid #fff !important;
}
nav div a.specials-main-nav,
nav div button.specials-main-nav {
  border: 1px solid #ff888a;
  color: #ee6565 !important;
}
nav div a.specials-main-nav:hover,
nav div a.specials-main-nav.active,
nav div button.specials-main-nav:hover,
nav div button.specials-main-nav.active {
  border: 1px solid #ee6565;
  background: #ee6565;
  color: #fff !important;
}
nav div button {
  font-family: "Open Sans";
  padding: 0.7rem 1.2rem;
}
nav div button:hover {
  padding: 0.8rem 1.3rem;
}
nav div .sub-menu {
  position: absolute;
  background: #ffffff;
  padding: 1.7rem;
  padding-top: 1rem;
  top: 4rem;
  left: 0rem;
  min-width: 14rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  -webkit-box-shadow: 8px 12px 17px -4px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 8px 12px 17px -4px rgba(0, 0, 0, 0.22);
  box-shadow: 8px 12px 17px -4px rgba(0, 0, 0, 0.22);
}
nav div .sub-menu a {
  display: block;
  margin-bottom: 1rem;
  border: 0px solid #ededed;
  padding: 0rem;
  border-radius: 0px;
}
nav div .sub-menu a:hover {
  color: #469ad4 !important;
}

.mobile-menu-icon button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.4rem;
  color: #469ad4;
}
.mobile-menu-icon button:hover {
  color: #469ad4;
}

.mobile-menu-modal {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  height: 100vh;
  padding: 2rem 0;
  width: 28rem;
  box-shadow: 0 0 2.1rem -0.4rem rgba(0, 0, 0, 0.34);
  overflow-y: scroll;
}
.mobile-menu-modal h4 {
  padding: 0 2rem;
}
.mobile-menu-modal ul {
  margin-top: 2rem;
}
.mobile-menu-modal ul.mobileNavSocial li a i {
  color: #469ad4;
}
.mobile-menu-modal ul.mobileNavStore li a i {
  color: #6ec568;
}
.mobile-menu-modal ul.mobileNavRealEstate li a i {
  color: #ee6565;
}
.mobile-menu-modal ul.mobileNavServices li a i {
  color: #e67e22;
}
.mobile-menu-modal ul.mobileNavContainer li a i {
  color: #fff;
  border-radius: 7px;
  font-size: 1.8rem;
  margin-right: 1.2rem;
  vertical-align: middle;
  display: inline-block;
  width: 3.4rem;
  text-align: center;
  padding: 8px 0;
}
.mobile-menu-modal ul.mobileNavContainer li a i.fa-home {
  background: #444;
}
.mobile-menu-modal ul li {
  border-bottom: 1px solid #ededed;
  padding: 1.2rem 2rem;
}
.mobile-menu-modal ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.mobile-menu-modal ul li a {
  width: 100%;
  display: block;
  font-size: 1.4rem;
  color: #555;
}
.mobile-menu-modal ul li span {
  font-weight: 700;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #b5b5b5;
}
.mobile-menu-modal button {
  position: absolute;
  top: 1.6rem;
  right: 2rem;
  font-size: 3rem;
  color: #b5b5b5;
}
.mobile-menu-modal button:hover {
  color: #469ad4;
}

.MobileMenuBox {
  padding-bottom: 12rem;
}

@media all and (max-width: 600px) {
  .container {
    margin-bottom: 7rem;
  }
}

.mobile-footer-menu {
  display: flex;
  justify-content: space-between;
  width: 110px;
  margin-right: 0.6rem;
}
@media all and (max-width: 600px) {
  .mobile-footer-menu {
    justify-content: center;
    margin-right: 0;
    position: fixed;
    bottom: 10px;
    left: 5px;
    right: 5px;
    width: calc(100% - 10px);
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
  }
  .mobile-footer-menu .header-tools-notifications {
    padding: 1.5rem 2rem;
    width: calc(100% - 1px);
    text-align: center;
  }
  .mobile-footer-menu .header-tools-notifications i {
    font-size: 2.2rem;
    color: #222;
  }
  .mobile-footer-menu .header-tools-notifications:not(:last-child) {
    border-right: 1px solid #e7e7e7;
  }
}
@media all and (max-width: 500px) {
  .mobile-footer-menu {
    justify-content: space-evenly;
    height: 58px;
  }
  .mobile-footer-menu .header-tools-notifications {
    padding: 1.3rem;
  }
}
@media all and (max-width: 400px) {
  .mobile-footer-menu .header-tools-notifications {
    padding: 1.2rem 1rem;
  }
}
@media all and (max-width: 350px) {
  .mobile-footer-menu .header-tools-notifications {
    padding: 1.2rem 0;
  }
  .mobile-footer-menu .header-tools-notifications:not(:last-child) {
    border-right: 0px solid #e7e7e7;
  }
}

.pagination {
  padding: 2rem 0;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.pagination button.pagination_previous_first {
  margin-right: 0.8rem;
}
.pagination button.pagination_next_last {
  margin-left: 0.8rem;
}
.pagination button.pagination_previous,
.pagination button.pagination_next {
  font-size: 1.4rem;
  color: #fff;
  vertical-align: middle;
  background: #469ad4;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.pagination button.pagination_number {
  border: 0;
  background: transparent;
  color: #444;
  font-size: 1.5rem;
  display: flex;
  padding: 0 1rem;
  height: 30px;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 500px) {
  .pagination button.pagination_number {
    font-size: 1.4rem;
  }
}
.pagination button.pagination_number:hover {
  color: #469ad4;
}
.pagination button.pagination_current {
  color: #444;
  background: transparent !important;
  border: 1px solid #444;
  display: flex;
  padding: 0 1rem;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  font-size: 1.5rem;
}
@media all and (max-width: 500px) {
  .pagination button.pagination_current {
    font-size: 1.4rem;
  }
}
.pagination span {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
}
.pagination span.first {
  padding-left: 1rem;
}
.pagination span.last {
  padding-right: 1rem;
}
.pagination_current {
  cursor: text !important;
  border: 1px solid #444444 !important;
  background: #444444 !important;
}

.breadcrumb {
  margin-left: 2%;
  margin-bottom: 2rem;
  font-size: 1rem;
  display: block;
  overflow: hidden;
  height: 1.4rem;
}
@media all and (max-width: 650px) {
  .breadcrumb {
    margin-bottom: 1rem;
  }
}
.breadcrumb a {
  color: #444444;
  font-weight: 600;
}
.breadcrumb span {
  padding: 0 0.8rem;
}
.breadcrumb-return {
  padding: 0.7rem 0.8rem;
  border: 1px solid #469ad4;
  color: #469ad4 !important;
  margin-right: 1rem;
  border-radius: 100%;
  font-size: 1.3rem;
  display: inline-block;
}
.breadcrumb-return:hover {
  background-color: #469ad4;
  color: #fff !important;
}

.notifications-nav button {
  border: 1px solid #ededed;
  background: #fff;
  padding: 0.8rem 1.5rem;
  border-radius: 5rem;
  margin: 0.5rem !important;
  color: #444;
}
.notifications-nav button.active {
  background-color: #469ad4;
  color: #fff;
}

/* FIX PROFILE SWIPER */
.profile_header
  .logged-in-header_to-do_swiper
  .swiper-wrapper
  .profile-nav-box-title {
  width: 27px !important;
}

.logo-header-desktop {
  font-size: 2.6rem;
  font-weight: 600;
  color: #444444;
  line-height: 1;
}
@media all and (max-width: 480px) {
  .logo-header-desktop {
    font-size: 2rem;
  }
}
@media all and (max-width: 340px) {
  .logo-header-desktop {
    font-size: 1.8rem;
  }
}
.logo-header-desktop span:first-child {
  color: #469ad4;
}
.logo-header-desktop span.logo-com {
  font-size: 1.6rem;
}
@media all and (max-width: 480px) {
  .logo-header-desktop span.logo-com {
    font-size: 1.3rem;
  }
}
.logo-header-desktop span.real-estate-logo {
  font-size: 1.1rem;
  display: block;
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;
}
@media all and (max-width: 450px) {
  .logo-header-desktop span.real-estate-logo {
    font-size: 1rem;
  }
}

.logo-header-mobile {
  display: none;
}

.header-tools-user a {
  padding-left: 0 !important;
}

@media all and (max-width: 600px) {
  .header-tools-user {
    margin-right: 0.8rem;
  }
}

@media all and (max-width: 350px) {
  .header-tools-user {
    margin-right: 0rem;
  }
}

.header-tools div a {
  text-decoration: none;
  font-size: 1.9rem;
  font-weight: 300;
  padding: 0 0.8rem;
  color: #777;
}
@media all and (max-width: 550px) {
  .header-tools div a {
    padding: 0 0.7rem;
  }
}

.header-tools div button.tool-text {
  font-size: 1.1rem;
  font-weight: 600;
  border: 0;
}

.header-tools img {
  width: 40px !important;
  height: 40px !important;
  margin-top: 2px !important;
}

.header-tools-notifications a {
  position: relative;
  display: block;
}
.header-tools-notifications a span {
  position: absolute;
  top: -10px;
  left: 16px;
  font-size: 9px !important;
  background: #469ad4;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
}
@media all and (max-width: 600px) {
  .header-tools-notifications a span {
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateX(10px);
  }
}

.header-tools .sign-out-320 {
  display: none;
}
@media all and (max-width: 1200px) {
  .header-tools .sign-out-320 {
    display: inline-block;
    margin-left: 0.4rem !important;
  }
  .header-tools .sign-out-320 i {
    text-decoration: none;
    font-size: 2rem;
    font-weight: 300;
    color: #777;
    margin-top: 2px;
  }
}

@media all and (max-width: 1200px) {
  .header-tools .sign-out-desktop {
    display: none;
  }
}

.website-location {
  float: right;
}

.master-search {
  position: relative;
  width: 85.2%;
  border-right: 0;
  display: inline-block;
  border-left: 1px solid #ededed;
}
@media all and (max-width: 1200px) {
  .master-search {
    width: 82.78%;
  }
}
@media all and (max-width: 1060px) {
  .master-search {
    width: 75%;
  }
}
@media all and (max-width: 750px) {
  .master-search {
    width: 66%;
  }
}
@media all and (max-width: 500px) {
  .master-search {
    width: 60%;
  }
}
.master-search input {
  height: 4rem;
  border: 0;
  padding: 0 4.6rem 0 6rem !important;
  width: 100%;
  color: #444;
}
@media all and (max-width: 570px) {
  .master-search input {
    padding: 0 4.6rem 0 4.2rem !important;
  }
}
.master-search::before {
  content: "\f002";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 1.6rem;
  left: 2.2rem;
  position: absolute;
  top: 0.8rem;
  color: #777;
}
@media all and (max-width: 570px) {
  .master-search::before {
    left: 1.4rem;
  }
}
.master-search-button {
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  border: 1px solid #65a2e7;
  padding: 0.5rem 0.6rem;
  border-radius: 50%;
}
.master-search-button:hover {
  background: #469ad4;
  color: #fff;
}
.master-search-button i {
  font-size: 1.3rem;
}

.master-search-location {
  position: relative;
  width: 14.8%;
  border-right: 0;
  display: inline-block;
}
@media all and (max-width: 1200px) {
  .master-search-location {
    width: 17.22%;
  }
}
@media all and (max-width: 1060px) {
  .master-search-location {
    width: 25%;
  }
}
@media all and (max-width: 750px) {
  .master-search-location {
    width: 34%;
  }
}
@media all and (max-width: 500px) {
  .master-search-location {
    width: 40%;
  }
}
.master-search-location select {
  height: 4rem;
  border: 0;
  padding: 0 2rem 0 5rem !important;
  width: 100%;
  color: #444;
  border-radius: 0 !important;
  background: #fff;
}
@media all and (max-width: 570px) {
  .master-search-location select {
    padding: 0 2rem 0 4rem !important;
  }
}
.master-search-location::before {
  content: "\F3C5";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 1.6rem;
  left: 2.2rem;
  position: absolute;
  top: 0.8rem;
  color: #777;
  z-index: 1;
}
@media all and (max-width: 570px) {
  .master-search-location::before {
    left: 1.4rem;
  }
}

.modal-adv-body {
  text-align: left !important;
}

.adv-search {
  position: relative;
  width: 100%;
}
.adv-search-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.adv-search-buttons button:first-child {
  margin-right: 1rem;
}
.adv-search-content {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 20px auto;
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.adv-search-content-box p {
  margin-bottom: 1rem;
}
.adv-search-content-box .form-input-select:before {
  top: 1rem;
}
.adv-search-content-box .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.adv-search-content-box .flex input {
  width: 42%;
}
.adv-search-content-box .flex span {
  padding: 1rem;
}

.main-sidebar {
  min-width: 32rem;
  max-width: 32rem;
}
@media all and (max-width: 900px) {
  .main-sidebar {
    position: fixed;
    background: #fff;
    width: 32rem;
    top: 0rem;
    opacity: 0;
    right: -32rem;
    transition: all 0.3s;
    height: 100vh;
    padding-left: 1rem;
    border-left: 1px solid #ededed;
    padding-top: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
  }
}
@media all and (max-width: 900px) {
  .main-sidebar-active {
    right: 0rem;
    opacity: 1;
    padding-bottom: 4rem;
    background: #f4f4f4;
  }
  .main-sidebar-active .blog-item-preview_stats,
  .main-sidebar-active .media-gallery-category_listing_stats {
    display: block;
  }
  .main-sidebar-active .blog-item-preview_stats .engage_buttons,
  .main-sidebar-active .media-gallery-category_listing_stats .engage_buttons {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 350px) {
  .main-sidebar-active {
    max-width: 27rem !important;
    min-width: 27rem !important;
  }
}
.main-sidebar_box {
  border-radius: 1rem;
  background: #ffffff;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  width: 100%;
}
.main-sidebar_box h4 {
  margin-bottom: 2rem;
  line-height: 1;
}
.main-sidebar_box .show-more-link {
  display: block;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
}
.main-sidebar_box .no-coupon {
  display: none;
}
.main-sidebar_box input {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
}
.main-sidebar_box input:focus {
  border: 1px solid #999 !important;
}
.main-sidebar_box button {
  display: block;
}
.main-sidebar_box a.button-regular {
  margin: 0 auto;
  display: block;
  text-align: center;
  width: 16rem;
}
.main-sidebar_box ul li a,
.main-sidebar_box ul li button {
  color: #444444;
  padding-bottom: 1.4rem;
  line-height: 1;
  display: block;
  font-size: 1.6rem;
  width: 100%;
  text-align: left;
}
.main-sidebar_box table {
  width: 100%;
  table-layout: fixed;
}
.main-sidebar_box table td {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1.2rem 1rem 1.2rem 1rem;
  border-bottom: 1px solid #ededed;
  color: #444444;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: middle;
}
.main-sidebar_box table td:first-child {
  text-align: center;
  padding-left: 0 !important;
  width: 20%;
}
.main-sidebar_box table td:first-child i {
  margin-right: 0 !important;
  font-size: 2rem !important;
}
.main-sidebar_box table td:last-child {
  text-align: left;
  padding-left: 0 !important;
  width: 80%;
  overflow: hidden;
  line-height: 1.3;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.main-sidebar_box table td i {
  font-size: 2.4rem;
  color: #b5b5b5;
  margin-right: 1rem;
  vertical-align: middle;
}
.main-sidebar_box table tr:last-child td {
  padding-bottom: 0;
  border-bottom: 0;
}
.main-sidebar_box_admin {
  background: #469ad4;
  padding-bottom: 0;
}
.main-sidebar_box_admin * {
  color: #ffffff;
}
.main-sidebar_box_admin_tools {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
.main-sidebar_box_admin_tools button {
  background: none;
  border: 0;
  padding: 0;
  width: 30%;
  margin-bottom: 2rem;
  cursor: pointer;
  color: #ffffff;
}
.main-sidebar_box_admin_tools button i {
  font-size: 3rem;
  border-radius: 1rem;
  border: 1px solid #ffffff;
  width: 100%;
  padding: 3rem 0;
  transition: all 0.3s ease-in-out;
}
@media all and (max-width: 400px) {
  .main-sidebar_box_admin_tools button i {
    padding: 1.5rem;
  }
}
.main-sidebar_box_admin_tools button span {
  display: block;
  margin-top: 0.4rem;
  text-transform: uppercase;
}
.main-sidebar_box_admin_tools button:hover i {
  background: #ffffff;
  color: #469ad4;
}
.main-sidebar_box_users {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
}
.main-sidebar_box_users a {
  background: none;
  border: 0;
  padding: 0;
  margin: 0 2.5% 1.2rem 2.5%;
  width: 28%;
  cursor: pointer;
  color: #ffffff;
  margin-top: 0;
  text-align: center;
}
.main-sidebar_box_users a img {
  width: 100%;
  border-radius: 50%;
  display: block;
}
.main-sidebar_box_users a span {
  display: block;
  margin-top: 1rem;
  color: #444444;
  line-height: 1;
  font-size: 1.1rem;
}
.main-sidebar_box_add-follower:not(:last-child) {
  margin-bottom: 2rem;
}
.main-sidebar_box_add-follower img {
  width: 8.2rem;
  height: 8.2rem;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 1.6rem;
}
@media all and (max-width: 700px) {
  .main-sidebar_box_add-follower img {
    width: 6.4rem;
    height: 6.4rem;
  }
}
.main-sidebar_box_add-follower img img {
  width: 100%;
  display: block;
}
.main-sidebar_box_add-follower_info p {
  font-size: 1.8rem;
  padding-top: 1.6rem;
  padding-bottom: 0.2rem;
  line-height: 1.2;
}
@media all and (max-width: 700px) {
  .main-sidebar_box_add-follower_info p {
    padding-top: 0.6rem;
  }
}
.main-sidebar_box_add-follower_info button {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  font-size: 1.2rem;
  font-weight: 600;
  color: #444444;
}
.main-sidebar_box_add-follower_info button:hover,
.main-sidebar_box_add-follower_info button span {
  color: #469ad4;
}
.main-sidebar_box_sponsored {
  width: 100%;
}
.main-sidebar_box_sponsored .category-list_item {
  width: 100%;
  border: 1px solid #ededed;
}
.main-sidebar_box_sponsored .category-list_item:last-child {
  margin-bottom: 0 !important;
}
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
}
.main-sidebar_box_sponsored .category-list_item .video-item-preview {
  border: 0;
  padding-bottom: 0;
}
.main-sidebar_box_rate {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.main-sidebar_box_rate button {
  padding: 0;
  margin: 0;
  border: 0;
  display: inline-block;
  font-size: 2.4rem;
  margin-right: 0.4rem;
  color: #b5b5b5;
}
.main-sidebar_box_rate button:hover {
  color: #444444;
}
.main-sidebar_box_review {
  margin-top: 1rem;
}
.main-sidebar_box_review textarea {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
}
.main-sidebar_box_review textarea:focus {
  border: 1px solid #999 !important;
}
.main-sidebar_box .account-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.main-sidebar_box .account-stats_box {
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: relative;
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.main-sidebar_box .account-stats_box::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.main-sidebar_box .account-stats_box_content div {
  font-size: 2.4rem;
  line-height: 1;
  font-weight: 300;
}
@media all and (max-width: 350px) {
  .main-sidebar_box .account-stats_box_content div {
    font-size: 1.8rem;
  }
}
.main-sidebar_box .account-stats_box_content span {
  font-size: 1rem !important;
  line-height: 1.4;
  font-weight: bold;
  display: block;
}
@media all and (max-width: 350px) {
  .main-sidebar_box .account-stats_box_content span {
    font-weight: initial;
  }
}
.main-sidebar_box_brand-logo {
  text-align: center;
}
.main-sidebar_box_brand-logo img {
  width: 80%;
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
  border-radius: 1rem;
  margin-top: 1rem;
}
.main-sidebar_box_brand-logo span {
  font-size: 2rem;
  font-weight: 300;
  text-transform: capitalize;
}

.toggle-sidebar {
  display: block;
  position: fixed;
  right: 0;
  text-align: center;
  background: #425caf;
  padding: 1.4rem 1.8rem;
  top: 70%;
  transform: translateY(-50%);
  font-size: 2.6rem;
  color: #ffffff !important;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  display: none;
  transition: all 0.3s;
}
@media all and (max-width: 900px) {
  .toggle-sidebar {
    display: block;
  }
}
.toggle-sidebar i {
  animation-name: move-in-left;
  animation-duration: 0.7s;
  animation-timing-function: ease-in;
  animation-delay: 1s;
  animation-iteration-count: 1;
  display: block;
}

.main-sidebar-active + .toggle-sidebar {
  right: 32rem;
}
@media all and (max-width: 350px) {
  .main-sidebar-active + .toggle-sidebar {
    right: 26rem;
  }
}

.main-sidebar_box-social,
.main-sidebar_box-free {
  background: #469ad4;
  background: -webkit-linear-gradient(to right bottom, #469ad4, #00b4db);
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#469ad4),
    to(#00b4db)
  );
  background: -webkit-linear-gradient(left top, #469ad4, #00b4db);
  background: -o-linear-gradient(left top, #469ad4, #00b4db);
  background: linear-gradient(to right bottom, #469ad4, #00b4db);
  color: #fff;
  text-align: center;
  position: relative;
}
.main-sidebar_box-social h3,
.main-sidebar_box-free h3 {
  margin-bottom: 0rem;
  margin-top: -1rem;
}
.main-sidebar_box-social p,
.main-sidebar_box-free p {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.3rem;
}
.main-sidebar_box-social::after,
.main-sidebar_box-free::after {
  content: "Selected Plan";
  position: absolute;
  background: #fff;
  color: #999;
  top: 3.2rem;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 0.6rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  -webkit-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
}
.main-sidebar_box-social button,
.main-sidebar_box-free button {
  display: inline-block;
}

.main-sidebar_box-premium,
.main-sidebar_box-premium-one-year,
.main-sidebar_box-premium-two-years {
  background: #ffafbd;
  background: -webkit-linear-gradient(to right bottom, #ffafbd, #ffc3a0);
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ffafbd),
    to(#ffc3a0)
  );
  background: -webkit-linear-gradient(left top, #ffafbd, #ffc3a0);
  background: -o-linear-gradient(left top, #ffafbd, #ffc3a0);
  background: linear-gradient(to right bottom, #ffafbd, #ffc3a0);
  color: #fff;
  text-align: center;
  position: relative;
}
.main-sidebar_box-premium h3,
.main-sidebar_box-premium-one-year h3,
.main-sidebar_box-premium-two-years h3 {
  margin-bottom: 0rem;
  margin-top: -1rem;
}
.main-sidebar_box-premium p,
.main-sidebar_box-premium-one-year p,
.main-sidebar_box-premium-two-years p {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.3rem;
}
.main-sidebar_box-premium::after,
.main-sidebar_box-premium-one-year::after,
.main-sidebar_box-premium-two-years::after {
  content: "Selected Plan";
  position: absolute;
  background: #fff;
  color: #999;
  top: 3.2rem;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 0.6rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  -webkit-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  visibility: hidden;
}
.main-sidebar_box-premium button,
.main-sidebar_box-premium-one-year button,
.main-sidebar_box-premium-two-years button {
  display: inline-block;
}

.main-sidebar_box-basic {
  display: none;
  background: #06beb6;
  background: -webkit-linear-gradient(to right bottom, #48b1bf, #06beb6);
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#48b1bf),
    to(#06beb6)
  );
  background: -webkit-linear-gradient(left top, #48b1bf, #06beb6);
  background: -o-linear-gradient(left top, #48b1bf, #06beb6);
  background: linear-gradient(to right bottom, #48b1bf, #06beb6);
  color: #fff;
  text-align: center;
  position: relative;
}
.main-sidebar_box-basic h3 {
  margin-bottom: 0rem;
  margin-top: -1rem;
}
.main-sidebar_box-basic p {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.3rem;
}
.main-sidebar_box-basic::after {
  content: "Selected Plan";
  position: absolute;
  background: #fff;
  color: #999;
  top: 3.2rem;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 0.6rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  -webkit-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
}
.main-sidebar_box-basic button {
  display: inline-block;
}

.main-sidebar_box-enterprise {
  background: #f77062;
  background: -webkit-linear-gradient(to right bottom, #f77062, #fe5196);
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#f77062),
    to(#fe5196)
  );
  background: -webkit-linear-gradient(left top, #f77062, #fe5196);
  background: -o-linear-gradient(left top, #f77062, #fe5196);
  background: linear-gradient(to right bottom, #f77062, #fe5196);
  color: #fff;
  text-align: center;
  position: relative;
}
.main-sidebar_box-enterprise h3 {
  margin-bottom: -0.8rem;
  margin-top: -1rem;
}
.main-sidebar_box-enterprise p {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1.3rem;
}
.main-sidebar_box-enterprise::after {
  content: "Selected Plan";
  position: absolute;
  background: #fff;
  color: #999;
  top: 3.2rem;
  left: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  padding: 0.6rem 1rem;
  border-bottom-left-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  -webkit-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  -moz-box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
  box-shadow: -4px 2px 5px -1px rgba(0, 0, 0, 0.09);
}
.main-sidebar_box-enterprise button {
  display: inline-block;
}

.main-sidebar_box ul li button.main-sidebar_category-inactive {
  padding-left: 2rem;
  padding-top: 1rem;
  border-left: 2px solid #469ad4;
}

.main-sidebar_box ul li button.main-sidebar_category-active {
  padding-left: 2rem;
  border-left: 2px solid white;
  padding-top: 1rem;
  transition: all 0.3s ease-out;
}

.main-sidebar_box ul li button.main-sidebar_category-active:hover {
  border-left: 2px solid #469ad4;
}

.static-page-feature img {
  display: block;
  width: 100%;
  border-radius: 1rem;
}
@media all and (max-width: 650px) {
  .static-page-feature img {
    border-radius: 0;
  }
}

.static-page-feature p {
  background: #ffffff;
  padding: 4rem;
  border-radius: 1rem;
  text-align: center;
  max-width: 50rem;
  display: block;
  margin: 0 auto;
  margin-top: -6rem;
  margin-bottom: 4rem;
  position: relative;
  z-index: 9;
}
@media all and (max-width: 650px) {
  .static-page-feature p {
    margin-top: 0;
    max-width: 100%;
    border-radius: 0;
  }
}

@media all and (max-width: 650px) {
  .static_section {
    padding: 0 2rem;
  }
}

.about-page-new {
  display: flex;
}
@media (max-width: 800px) {
  .about-page-new {
    flex-direction: column;
  }
}
.about-page-new .sidebar {
  width: 200px;
  flex: none;
  position: sticky;
  top: 2rem;
}
@media (max-width: 800px) {
  .about-page-new .sidebar {
    width: 100%;
    position: relative;
    top: 0;
    margin-bottom: 2rem;
  }
  .about-page-new .sidebar ul {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .about-page-new .sidebar ul li {
    margin: 0 !important;
    padding: 0 !important;
  }
  .about-page-new .sidebar ul li a {
    padding: 0.6rem 0.8rem;
    font-size: 1.4rem;
    border: 1px solid #cccccc;
    border-radius: 8px;
    display: block;
    width: 100%;
  }
}
@media (max-width: 600px) {
  .about-page-new .sidebar {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.about-page-new .sidebar h4 {
  margin-bottom: 2rem;
}
.about-page-new .sidebar ul li {
  margin: 0.4rem 0;
}
.about-page-new .sidebar ul li a {
  display: block;
  color: #444;
  font-size: 1.5rem;
}
.about-page-new .sidebar ul li a:hover {
  color: #469ad4;
}
.about-page-new .content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  flex: 1;
}
.about-page-new .content p,
.about-page-new .content li {
  font-size: 1.6rem;
  line-height: 1.6;
}
.about-page-new .content h2 {
  text-transform: uppercase;
}
.about-page-new .content h3 {
  font-weight: 700;
}
.about-page-new .content .back-to-top {
  display: inline-block;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.about-page-new .content .back-to-top i {
  margin-right: 0.5rem;
  font-size: 2rem;
}
.about-page-new .content .section {
  background: #fff;
  border-radius: 0.5rem;
  overflow: hidden;
}
@media (max-width: 600px) {
  .about-page-new .content .section {
    border-radius: 0;
  }
}
.about-page-new .content .cover {
  padding: 4rem;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-size: cover;
  position: relative;
}
@media (max-width: 800px) {
  .about-page-new .content .cover {
    height: auto;
  }
}
.about-page-new .content .cover .cover-logo {
  width: 400px;
  z-index: 1;
}
@media (max-width: 800px) {
  .about-page-new .content .cover .cover-logo {
    max-width: 280px;
    margin-bottom: 4rem;
  }
}
@media (max-width: 600px) {
  .about-page-new .content .cover .cover-logo {
    max-width: 200px;
  }
}
.about-page-new .content .cover .cover-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 0;
}
.about-page-new .content .cover .cover-text {
  color: #fff;
  z-index: 1;
}
.about-page-new .content .cover .cover-text h1 {
  font-size: 4.8rem;
  font-weight: 700;
  line-height: 1.1;
}
@media (max-width: 800px) {
  .about-page-new .content .cover .cover-text h1 {
    font-size: 3.2rem;
  }
}
.about-page-new .content .cover .cover-text p {
  font-size: 2.4rem;
  line-height: 1.4;
  margin-top: 2rem;
}
@media (max-width: 800px) {
  .about-page-new .content .cover .cover-text p {
    font-size: 1.8rem;
    margin-bottom: 4rem;
  }
  .about-page-new .content .cover .cover-text p br {
    display: none;
  }
}
.about-page-new .content .cover .cover-addresses {
  display: flex;
  gap: 4rem;
  z-index: 1;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .cover .cover-addresses {
    justify-content: space-between;
  }
}
@media (max-width: 600px) {
  .about-page-new .content .cover .cover-addresses {
    flex-direction: column;
  }
}
.about-page-new .content .cover .cover-addresses h4 {
  margin-bottom: 1rem;
}
.about-page-new .content .cover .cover-addresses td {
  font-size: 1.6rem;
}
.about-page-new .content .cover .cover-addresses td:first-child {
  padding-right: 1rem;
  vertical-align: top;
  font-size: 1.8rem;
}
.about-page-new .content .valuation h2 {
  background: #333;
  color: #fff;
  padding: 6rem 4rem 2rem 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .valuation h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .valuation .valuation-content {
  padding: 4rem;
  columns: 2;
  column-gap: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .valuation .valuation-content {
    columns: 1;
    padding: 2rem;
  }
}
.about-page-new .content .valuation .valuation-content p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .valuation .valuation-content h3 {
  margin-bottom: 1rem;
}
.about-page-new .content .valuation .valuation-content .valuation-content-item {
  margin-top: 2rem;
}
.about-page-new .content .sponsor h2 {
  background: #333;
  color: #fff;
  padding: 6rem 4rem 2rem 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .sponsor h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .sponsor .our-sponsors-content {
  display: flex;
  padding: 4rem;
}
@media (max-width: 1000px) {
  .about-page-new .content .sponsor .our-sponsors-content {
    flex-direction: column;
    gap: 4rem;
  }
}
@media (max-width: 800px) {
  .about-page-new .content .sponsor .our-sponsors-content {
    padding: 2rem;
  }
}
.about-page-new .content .sponsor .our-sponsors-content-item h3 {
  font-size: 2.4rem;
  font-weight: 700;
  margin: 0.5rem 0;
}
.about-page-new .content .sponsor .our-sponsors-content-item p {
  line-height: 1.4;
}
.about-page-new .content .sponsor .our-sponsors-content-item.middle {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-left: 40px;
  margin-right: 40px;
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 1000px) {
  .about-page-new .content .sponsor .our-sponsors-content-item.middle {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    border-left: none;
    border-right: none;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.about-page-new .content .whoweare h2 {
  padding: 6rem 4rem 2rem 4rem;
  border-bottom: 1px solid #e7e7e7;
}
@media (max-width: 800px) {
  .about-page-new .content .whoweare h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .whoweare .who-we-are-content {
  display: flex;
  flex-direction: column;
}
.about-page-new
  .content
  .whoweare
  .who-we-are-content
  .who-we-are-content-item {
  display: flex;
}
@media (max-width: 1000px) {
  .about-page-new
    .content
    .whoweare
    .who-we-are-content
    .who-we-are-content-item {
    flex-direction: column;
  }
}
.about-page-new
  .content
  .whoweare
  .who-we-are-content
  .who-we-are-content-item
  .who-we-are-bkg {
  flex: none;
  width: 50%;
  background-size: cover;
}
@media (max-width: 1000px) {
  .about-page-new
    .content
    .whoweare
    .who-we-are-content
    .who-we-are-content-item
    .who-we-are-bkg {
    width: 100%;
    height: 300px;
  }
}
.about-page-new
  .content
  .whoweare
  .who-we-are-content
  .who-we-are-content-item
  p {
  padding: 6rem;
}
@media (max-width: 1000px) {
  .about-page-new
    .content
    .whoweare
    .who-we-are-content
    .who-we-are-content-item
    p {
    padding: 4rem;
  }
}
.about-page-new
  .content
  .whoweare
  .who-we-are-content
  .who-we-are-content-item:last-child
  .who-we-are-bkg {
  order: 1;
}
@media (max-width: 1000px) {
  .about-page-new
    .content
    .whoweare
    .who-we-are-content
    .who-we-are-content-item:last-child
    .who-we-are-bkg {
    order: 0;
  }
}
.about-page-new .content .pillars h2 {
  padding: 6rem 4rem 2rem 4rem;
  border-bottom: 1px solid #e7e7e7;
}
@media (max-width: 800px) {
  .about-page-new .content .pillars h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .pillars .core-pillars-content {
  padding: 4rem;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .about-page-new .content .pillars .core-pillars-content {
    padding: 2rem;
    flex-direction: column;
  }
}
.about-page-new
  .content
  .pillars
  .core-pillars-content
  .core-pillars-content-images {
  flex: none;
  width: 36%;
  order: 1;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .pillars
    .core-pillars-content
    .core-pillars-content-images {
    width: 100%;
    order: 0;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 4rem;
  }
}
.about-page-new
  .content
  .pillars
  .core-pillars-content
  .core-pillars-content-images
  img {
  width: 100%;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .pillars
    .core-pillars-content
    .core-pillars-content-images
    img {
    width: calc(33% - 1rem);
  }
}
.about-page-new
  .content
  .pillars
  .core-pillars-content
  .core-pillars-content-text {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  width: 56%;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .pillars
    .core-pillars-content
    .core-pillars-content-text {
    width: 100%;
  }
}
.about-page-new
  .content
  .pillars
  .core-pillars-content
  .core-pillars-content-text
  h3 {
  margin-bottom: 1rem;
}
.about-page-new .content .verticals h2 {
  padding: 6rem 4rem 2rem 4rem;
  border-bottom: 1px solid #e7e7e7;
}
@media (max-width: 800px) {
  .about-page-new .content .verticals h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .verticals .business-verticals-content {
  padding: 4rem;
  columns: 2;
  column-gap: 6rem;
}
@media (max-width: 800px) {
  .about-page-new .content .verticals .business-verticals-content {
    padding: 2rem;
    columns: 1;
  }
}
.about-page-new
  .content
  .verticals
  .business-verticals-content
  .business-verticals-content-item {
  break-inside: avoid;
}
.about-page-new
  .content
  .verticals
  .business-verticals-content
  .business-verticals-content-item:not(:last-child) {
  margin-bottom: 4rem;
}
.about-page-new
  .content
  .verticals
  .business-verticals-content
  .business-verticals-content-item
  h3 {
  margin-bottom: 1rem;
}
.about-page-new
  .content
  .verticals
  .business-verticals-content
  .business-verticals-content-item
  a {
  margin-top: 1rem;
  display: inline-block;
  font-weight: 700;
  font-size: 1.6rem;
}
.about-page-new .content .company-structure h2 {
  padding: 6rem 4rem 2rem 4rem;
  background: #3aa38f;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .company-structure h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .company-structure .company-structure-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .company-structure .company-structure-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .company-structure
  .company-structure-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .company-structure .company-structure-content ol {
  list-style-type: decimal;
  list-style-position: inside;
  margin-bottom: 1rem;
}
.about-page-new .content .company-structure .company-structure-content ul {
  margin-bottom: 1rem;
}
.about-page-new .content .company-structure .company-structure-content h3 {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
.about-page-new
  .content
  .company-structure
  .company-structure-content
  .company-structure-locations {
  display: flex;
  gap: 4rem;
  margin-top: 3rem;
  font-size: 1.6rem;
}
.about-page-new
  .content
  .company-structure
  .company-structure-content
  .company-structure-locations
  b {
  font-weight: 700;
  font-size: 1.6rem;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .company-structure
    .company-structure-content
    .company-structure-locations {
    flex-direction: column;
  }
}
.about-page-new .content .management h2 {
  padding: 6rem 4rem 2rem 4rem;
  background: #3aa38f;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .management h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .management .management-content-description {
  margin-bottom: 4rem;
}
.about-page-new .content .management .management-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .management .management-content {
    padding: 2rem;
  }
}
.about-page-new .content .management .management-content h3 {
  margin-bottom: 2rem;
}
.about-page-new .content .management .management-content .management {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
.about-page-new .content .management .management-content .team-member {
  display: flex;
  gap: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .management .management-content .team-member {
    flex-direction: column;
    gap: 2rem;
  }
}
.about-page-new .content .management .management-content .team-member img {
  flex: none;
  width: 150px;
  height: 150px;
  border-radius: 1rem;
}
.about-page-new .content .management .management-content .team-member h4 {
  font-size: 2.2rem;
}
.about-page-new .content .management .management-content .team-member .title {
  font-size: 1.6rem;
  font-weight: 700;
}
.about-page-new .content .management .management-content .team-member .history {
  font-size: 1.6rem;
  font-weight: 700;
}
.about-page-new .content .management .management-content .team-member ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-left: 2px solid #3aa38f;
  padding-left: 2rem;
}
.about-page-new
  .content
  .management
  .management-content
  .team-member
  ul
  li:not(:last-child) {
  margin-bottom: 0.4rem;
}
.about-page-new
  .content
  .management
  .management-content
  .team-member
  .learn-more
  button {
  font-size: 1.6rem;
  margin-top: 1rem;
  font-weight: 700;
}
.about-page-new
  .content
  .management
  .management-content
  .team-member
  .learn-more
  .learn-more-text {
  margin-top: 2rem;
  border-left: 2px solid #3aa38f;
  padding: 1rem 2rem;
}
.about-page-new .content .culture-employees h2 {
  padding: 6rem 4rem 2rem 4rem;
  background: #fff;
  color: #333;
}
@media (max-width: 800px) {
  .about-page-new .content .culture-employees h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .culture-employees img {
  width: 100%;
  display: block;
}
.about-page-new .content .culture-employees .culture-employees-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .culture-employees .culture-employees-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .culture-employees
  .culture-employees-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .culture-employees .culture-employees-content h3 {
  margin-bottom: 2rem;
  margin-top: 4rem;
}
.about-page-new .content .market-analysis h2 {
  padding: 6rem 4rem 2rem 4rem;
  background: #333;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .market-analysis img {
  width: 100%;
  display: block;
}
.about-page-new .content .market-analysis h3 {
  padding: 6rem 4rem 2rem 4rem;
  background: #333;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis h3 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .market-analysis h4 {
  margin-bottom: 2rem;
}
.about-page-new .content .market-analysis .target-market-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis .target-market-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .market-analysis
  .target-market-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new
  .content
  .market-analysis
  .target-market-content
  .market-analysis-segments {
  columns: 2;
  column-gap: 6rem;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .market-analysis
    .target-market-content
    .market-analysis-segments {
    columns: 1;
  }
}
.about-page-new
  .content
  .market-analysis
  .target-market-content
  .market-analysis-segments
  .market-analysis-segment {
  break-inside: avoid;
}
.about-page-new
  .content
  .market-analysis
  .target-market-content
  .market-analysis-segments
  .market-analysis-segment:not(:last-child) {
  margin-bottom: 4rem;
}
.about-page-new
  .content
  .market-analysis
  .target-market-content
  .market-analysis-segments
  .market-analysis-segment
  h5 {
  margin-bottom: 1rem;
  font-size: 1.8rem;
  font-weight: 700;
}
.about-page-new
  .content
  .market-analysis
  .target-market-content
  .market-analysis-segments
  .market-analysis-segment
  p:not(:last-child) {
  margin-bottom: 1rem;
}
.about-page-new .content .market-analysis .demographics-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis .demographics-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .market-analysis
  .demographics-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .market-analysis .competitive-landscape-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis .competitive-landscape-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .market-analysis
  .competitive-landscape-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .market-analysis .market-opportunity-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis .market-opportunity-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .market-analysis
  .market-opportunity-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .market-analysis .market-opportunity-content ul {
  margin-bottom: 2rem;
  list-style-type: disc;
  margin-left: 2rem;
  columns: 2;
  column-gap: 6rem;
}
@media (max-width: 800px) {
  .about-page-new .content .market-analysis .market-opportunity-content ul {
    columns: 1;
  }
}
.about-page-new .content .business-model h2 {
  padding: 6rem 4rem 2rem 4rem;
  background: #333;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .business-model h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .business-model img {
  width: 100%;
  display: block;
}
.about-page-new .content .business-model h3 {
  padding: 6rem 4rem 2rem 4rem;
  background: #333;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .business-model h3 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .business-model h4 {
  margin-bottom: 2rem;
}
.about-page-new .content .business-model .business-strategy-content {
  padding: 4rem;
  columns: 2;
  column-gap: 6rem;
}
@media (max-width: 800px) {
  .about-page-new .content .business-model .business-strategy-content {
    padding: 2rem;
    columns: 1;
  }
}
.about-page-new
  .content
  .business-model
  .business-strategy-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .business-model .business-strategy-content ul {
  margin-bottom: 2rem;
  list-style-type: disc;
  margin-left: 2rem;
}
.about-page-new .content .business-model .revenue-models-content {
  padding: 4rem;
}
.about-page-new
  .content
  .business-model
  .revenue-models-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .business-model .new-market-penetration-content {
  padding: 4rem;
}
.about-page-new
  .content
  .business-model
  .new-market-penetration-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .business-model .product-development-roadmap {
  padding: 4rem;
  margin-left: 4rem;
  border-left: 1px solid #cccccc;
}
.about-page-new
  .content
  .business-model
  .product-development-roadmap
  .product-development-roadmap-item {
  margin-bottom: 4rem;
}
.about-page-new
  .content
  .business-model
  .product-development-roadmap
  .product-development-roadmap-item
  .pdri-year {
  font-size: 1.6rem;
  font-weight: 700;
  background: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  display: inline-block;
  margin-left: -4rem;
}
.about-page-new
  .content
  .business-model
  .product-development-roadmap
  .product-development-roadmap-item
  h4 {
  margin-bottom: 0;
  margin-top: 2rem;
}
.about-page-new
  .content
  .business-model
  .product-development-roadmap
  .product-development-roadmap-item
  ul {
  list-style-type: disc;
  margin-left: 2rem;
}
.about-page-new .content .technology h2 {
  padding: 6rem 4rem 2rem 4rem;
  background: #333;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .technology h2 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .technology .technology-video {
  width: 100%;
}
.about-page-new .content .technology .technology-video video {
  width: 100%;
  display: block;
}
.about-page-new .content .technology .technology-icons {
  display: flex;
  gap: 4rem;
  padding: 4rem;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  background: #333;
  color: #fff;
}
@media (max-width: 1100px) {
  .about-page-new .content .technology .technology-icons {
    flex-wrap: wrap;
    padding: 2rem;
    gap: 2rem;
  }
}
.about-page-new .content .technology .technology-content {
  padding: 4rem;
  columns: 2;
  column-gap: 6rem;
}
@media (max-width: 800px) {
  .about-page-new .content .technology .technology-content {
    padding: 2rem;
    columns: 1;
  }
}
.about-page-new .content .technology .technology-content p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .technology h3 {
  padding: 6rem 4rem 2rem 4rem;
  background: #425caf;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .technology h3 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .technology .technology-ip {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .technology .technology-ip {
    padding: 2rem;
  }
}
.about-page-new .content .technology .technology-ip p {
  margin-bottom: 2rem;
}
.about-page-new .content .technology .technology-ip table {
  width: 100%;
}
@media (max-width: 1100px) {
  .about-page-new .content .technology .technology-ip table tr {
    display: flex;
    flex-direction: column;
  }
  .about-page-new
    .content
    .technology
    .technology-ip
    table
    tr:not(:last-child) {
    border-bottom: 1px solid #cccccc;
    padding-bottom: 2rem;
  }
}
.about-page-new
  .content
  .technology
  .technology-ip
  table
  tr:not(:last-child)
  td {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 2rem;
}
@media (max-width: 1100px) {
  .about-page-new
    .content
    .technology
    .technology-ip
    table
    tr:not(:last-child)
    td {
    border-bottom: none;
    padding-bottom: 0;
  }
}
.about-page-new
  .content
  .technology
  .technology-ip
  table
  tr:not(:first-child)
  td {
  padding-top: 2rem;
}
.about-page-new .content .technology .technology-ip table tr td:first-child {
  width: 50%;
}
@media (max-width: 1100px) {
  .about-page-new .content .technology .technology-ip table tr td:first-child {
    order: 1;
    width: 100%;
  }
}
.about-page-new .content .technology .technology-ip table tr td span {
  display: block;
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.4;
}
.about-page-new
  .content
  .technology
  .technology-ip
  table
  tr
  td
  span:first-child {
  font-size: 3rem;
}
.about-page-new .content .technology .technology-ip table tr td img {
  height: 60px;
}
@media (max-width: 500px) {
  .about-page-new .content .technology .technology-ip table tr td img {
    height: 40px;
  }
}
.about-page-new .content .followone-ai h2 {
  padding: 4rem;
  height: 50rem;
  background: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-page-new .content .followone-ai h2 img {
  width: 420px;
  display: block;
}
.about-page-new .content .followone-ai .followone-ai-img {
  width: 100%;
  display: block;
}
.about-page-new .content .followone-ai h3 {
  padding: 6rem 4rem 2rem 4rem;
  background: #425caf;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-ai h3 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new .content .followone-ai .followone-ai-overview {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-ai .followone-ai-overview {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .followone-ai
  .followone-ai-overview
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-ai .followone-ai-overview h4 {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-ai .followone-ai-section {
  padding: 4rem;
  columns: 2;
  column-gap: 6rem;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-ai .followone-ai-section {
    padding: 2rem;
    columns: 1;
  }
}
.about-page-new
  .content
  .followone-ai
  .followone-ai-section
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-ai .followone-ai-section h4 {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-com .followone-com-cover {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50rem;
}
.about-page-new .content .followone-com .followone-com-cover img {
  width: 60%;
  display: block;
  margin-bottom: 8rem;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-cover img {
    width: 80%;
  }
}
@media (max-width: 600px) {
  .about-page-new .content .followone-com .followone-com-cover img {
    width: 100%;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-cover
  .followone-apps-download {
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
@media (max-width: 400px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-cover
    .followone-apps-download {
    flex-direction: column;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-cover
  .app-download-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  background: #fff;
  color: #444;
  border-radius: 8px;
  text-decoration: none;
  transition: opacity 0.2s;
  border: 1px solid #cccccc;
  transition: all 0.2s;
}
.about-page-new
  .content
  .followone-com
  .followone-com-cover
  .app-download-button:hover {
  background: #f0f0f0;
}
.about-page-new
  .content
  .followone-com
  .followone-com-cover
  .app-download-button
  i {
  font-size: 2rem;
  margin-right: 0.5rem;
}
.about-page-new
  .content
  .followone-com
  .followone-com-cover
  .app-download-button
  span {
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 1.2;
}
.about-page-new
  .content
  .followone-com
  .followone-com-cover
  .app-download-button
  small {
  font-size: 1rem;
  opacity: 0.8;
}
.about-page-new .content .followone-com .followone-com-intro {
  padding: 4rem;
  background: #f8f8f8;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-intro {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-intro
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-com .followone-com-vertical-list {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-vertical-list {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item {
  display: flex;
  gap: 4rem;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-vertical-list
    .followone-com-vertical-list-item {
    flex-direction: column;
    gap: 2rem;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  i {
  font-size: 4rem;
  color: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 8rem;
  height: 8rem;
  flex-shrink: 0;
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  p {
  line-height: 1;
  max-width: 40rem;
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  p
  span {
  display: block;
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  p
  span:not(:last-child) {
  margin-bottom: 1rem;
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  p
  span.title {
  font-size: 3rem;
  font-weight: 300;
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  p
  span.focus {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;
}
.about-page-new
  .content
  .followone-com
  .followone-com-vertical-list
  .followone-com-vertical-list-item
  p
  span.description {
  line-height: 1.4;
}
.about-page-new .content .followone-com .followone-com-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30rem;
  background: #f8f8f8;
}
.about-page-new .content .followone-com .followone-com-logo img {
  height: 80px;
  display: block;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-logo img {
    height: 60px;
  }
}
.about-page-new .content .followone-com .followone-com-preview {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 62rem;
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #e7e7e7;
}
.about-page-new .content .followone-com .followone-com-content {
  padding: 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-content {
    padding: 2rem;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-content
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-com .followone-com-auto-benefits {
  padding: 0 4rem 4rem 4rem;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-auto-benefits {
    padding: 2rem 2rem 2rem 2rem;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-brands {
  display: flex;
  gap: 4rem;
  align-items: center;
  background: #f8f8f8;
  padding: 4rem;
  margin-top: 4rem;
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-brands
  img {
  display: block;
  height: 40px;
}
@media (max-width: 1000px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-auto-benefits
    .followone-com-auto-brands {
    gap: 2rem;
    padding: 2rem;
  }
  .about-page-new
    .content
    .followone-com
    .followone-com-auto-benefits
    .followone-com-auto-brands
    img {
    width: calc(33% - 1rem);
  }
}
@media (max-width: 600px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-auto-benefits
    .followone-com-auto-brands {
    flex-wrap: wrap;
    justify-content: center;
  }
  .about-page-new
    .content
    .followone-com
    .followone-com-auto-benefits
    .followone-com-auto-brands
    img {
    width: calc(50% - 1rem);
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list {
  background: #dff4ec;
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list
  .followone-com-auto-benefits-item {
  width: 32.33333333333333%;
  padding: 2rem;
}
@media (max-width: 1000px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-auto-benefits
    .followone-com-auto-benefits-list
    .followone-com-auto-benefits-item {
    width: calc(50% - 1rem);
  }
}
@media (max-width: 600px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-auto-benefits
    .followone-com-auto-benefits-list
    .followone-com-auto-benefits-item {
    width: calc(100% - 1rem);
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list
  .followone-com-auto-benefits-item
  i {
  font-size: 3rem;
  margin-bottom: 2rem;
  height: 3rem;
  display: flex;
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list
  .followone-com-auto-benefits-item
  .accredited-logo {
  margin-bottom: 1.4rem;
  height: 3rem;
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list
  .followone-com-auto-benefits-item
  span {
  display: block;
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list
  .followone-com-auto-benefits-item
  span.title {
  font-size: 1.6rem;
  font-weight: 700;
}
.about-page-new
  .content
  .followone-com
  .followone-com-auto-benefits
  .followone-com-auto-benefits-list
  .followone-com-auto-benefits-item
  span.description {
  font-size: 1.4rem;
}
.about-page-new .content .followone-com .followone-com-real-estate table {
  margin-bottom: 2rem;
}
.about-page-new
  .content
  .followone-com
  .followone-com-real-estate
  table
  tr:first-child
  td {
  font-weight: 700;
}
.about-page-new .content .followone-com .followone-com-real-estate table tr td {
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  border: 1px solid #cccccc;
}
.about-page-new .content .followone-com .img-full {
  width: 100%;
}
.about-page-new .content .followone-com .social-video {
  width: 100%;
}
.about-page-new .content .followone-com .social-video video {
  width: 100%;
  display: block;
}
.about-page-new .content .followone-com .followone-com-social-profile-page h3 {
  padding: 6rem 4rem 2rem 4rem;
  background: #425caf;
  color: #fff;
}
@media (max-width: 800px) {
  .about-page-new
    .content
    .followone-com
    .followone-com-social-profile-page
    h3 {
    padding: 4rem 2rem 2rem 2rem;
  }
}
.about-page-new
  .content
  .followone-com
  .followone-com-social-profile-page
  p:not(:last-child) {
  margin-bottom: 2rem;
}
.about-page-new .content .followone-com .followone-com-interface-design h2 {
  padding: 18rem 4rem 18rem 4rem;
  background: #333;
  color: #fff;
  text-align: center;
}
@media (max-width: 800px) {
  .about-page-new .content .followone-com .followone-com-interface-design h2 {
    padding: 12rem 2rem 12rem 2rem;
  }
}
.about-page-new .content .end-cover {
  padding: 8rem 16rem;
}
@media (max-width: 1100px) {
  .about-page-new .content .end-cover {
    padding: 4rem;
  }
}
.about-page-new .content .end-cover h4 {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}
.about-page-new .content .end-cover table {
  width: 100%;
  margin-bottom: 8rem;
}
@media (max-width: 800px) {
  .about-page-new .content .end-cover table tr {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }
  .about-page-new .content .end-cover table tr:not(:last-child) {
    border-bottom: 1px solid #cccccc;
  }
}
.about-page-new .content .end-cover table tr td {
  padding: 2rem 0;
  border-bottom: 1px solid #cccccc;
}
@media (max-width: 800px) {
  .about-page-new .content .end-cover table tr td {
    border-bottom: 0;
    padding: 0;
  }
}
.about-page-new .content .end-cover table tr td img {
  height: 40px;
}
.about-page-new .content .end-cover table tr td:last-child {
  text-align: right;
  font-weight: 300;
  font-size: 3rem;
}
@media (max-width: 800px) {
  .about-page-new .content .end-cover table tr td:last-child {
    text-align: left;
    font-size: 2rem;
  }
}
.about-page-new .content .end-cover .followone-ai {
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-page-new .content .end-cover .followone-ai img {
  height: 80px;
  display: block;
}
@media (max-width: 800px) {
  .about-page-new .content .end-cover .followone-ai img {
    height: 60px;
  }
}

.bkg-store {
  background: linear-gradient(to bottom right, #6967af, #bea0cc);
}

.bkg-auto {
  background: linear-gradient(to bottom right, #8955a2, #d0b1d4);
}

.bkg-realestate {
  background: linear-gradient(to bottom right, #1e6fa3, #8ad2d7);
}

.bkg-jobs {
  background: linear-gradient(to bottom right, #fab59f, #ffecd3);
}

.bkg-travel {
  background: linear-gradient(to bottom right, #3ebca4, #90d3d1);
}

.bkg-services {
  background: linear-gradient(to bottom right, #f37063, #ef5394);
}

.bkg-healthcare {
  background: linear-gradient(to bottom right, #6967af, #bea0cc);
}

.bkg-food {
  background: linear-gradient(to bottom right, #5069b2, #6ba0d6);
}

.benefit-box {
  background: #fff;
  display: flex;
  border-bottom: 1px solid #e7e7e7;
}
.benefit-box:first-child {
  border-top: 1px solid #e7e7e7;
}
@media all and (max-width: 1000px) {
  .benefit-box {
    border-radius: 0;
    margin-bottom: 4px;
    flex-wrap: wrap;
  }
}
.benefit-box-img {
  flex: 1;
  text-align: center;
  padding: 4rem;
}
@media all and (max-width: 1000px) {
  .benefit-box-img {
    flex: auto;
    width: 100%;
    padding: 2rem;
  }
}
.benefit-box-img img {
  width: 80%;
}
@media all and (max-width: 1000px) {
  .benefit-box-img img {
    width: 100%;
  }
}
.benefit-box-list {
  border-right: 1px solid #e7e7e7;
  width: 400px;
  padding: 4rem;
}
@media all and (max-width: 1000px) {
  .benefit-box-list {
    width: 100%;
    border-right: 0;
  }
}
.benefit-box-list_right {
  order: 2;
  border-right: 0;
  border-left: 1px solid #e7e7e7;
  justify-content: flex-end;
}
@media all and (max-width: 1000px) {
  .benefit-box-list_right {
    width: 100%;
    order: 0;
    border-left: 0;
  }
}
.benefit-box-list table tr td {
  vertical-align: top;
  padding-top: 2rem;
}
.benefit-box-list table tr td:first-child {
  font-size: 2.4rem;
  padding-right: 2rem;
  text-align: center;
}
.benefit-box-list .note {
  margin-top: 2rem;
  font-size: 1.2rem;
}

.affiliates {
  padding: 0 10rem;
  padding: 0 2rem;
}
@media all and (max-width: 1250px) {
  .affiliates {
    padding: 0 5rem;
  }
}
@media all and (max-width: 650px) {
  .affiliates {
    padding: 0rem;
  }
}
@media all and (max-width: 1250px) {
  .affiliates {
    padding: 0 2rem;
  }
}
.affiliates-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.affiliates-container-program {
  background: #fff;
  border-radius: 1rem;
  overflow: hidden;
  width: 49%;
  padding-bottom: 4rem;
}
@media all and (max-width: 1080px) {
  .affiliates-container-program {
    width: 100%;
  }
  .affiliates-container-program:first-child {
    margin-bottom: 2rem;
  }
}
.affiliates img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.affiliates .static-page-feature p {
  background-color: #f8f8f8;
}
@media all and (max-width: 1150px) {
  .affiliates .static-page-feature p {
    width: 100%;
    border-radius: 0;
    max-width: 100%;
  }
}
.affiliates_section-1 {
  text-align: center;
}
@media all and (max-width: 1100px) {
  .affiliates_section-1 {
    padding: 0 2rem;
  }
}
.affiliates_section-1 p {
  max-width: 50rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.affiliates_section-1 .affiliate-logo {
  margin-bottom: 2rem;
}
.affiliates_section-1 .affiliate-logo img {
  height: 8rem;
}
@media all and (max-width: 700px) {
  .affiliates_section-1 .affiliate-logo img {
    height: 6rem;
  }
}
.affiliates_section-1 ul {
  margin-bottom: 2rem;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  display: inline-block;
  padding: 4rem;
}
@media all and (max-width: 600px) {
  .affiliates_section-1 ul {
    padding: 2rem;
  }
}
.affiliates_section-1 ul li {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.2;
}
.affiliates_section-1 ul li:not(:last-child) {
  margin-bottom: 2rem;
}
@media all and (max-width: 1100px) {
  .affiliates_section-1 ul li {
    font-size: 2rem;
  }
}
@media all and (max-width: 760px) {
  .affiliates_section-1 ul li {
    font-size: 1.6rem;
  }
}
@media all and (max-width: 650px) {
  .affiliates_section-1 ul li {
    margin-bottom: 1rem;
    line-height: 1.4;
  }
}

.cash-in-application-box {
  background: #fff;
  border-radius: 1rem;
  padding: 4rem;
}
@media all and (max-width: 700px) {
  .cash-in-application-box {
    padding: 2rem;
  }
}
.cash-in-application-box:not(:last-child) {
  margin-bottom: 2rem;
}
.cash-in-application-box .row {
  max-width: 30rem;
}
.cash-in-application-box .row .col {
  width: 100% !important;
}
.cash-in-application-box .personal-id {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cash-in-application-box .personal-id-box {
  width: 48%;
  border: 1px solid #ededed;
  padding: 20px;
  border-radius: 1rem;
  margin-top: 2rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cash-in-application-box .personal-id-box img {
  border-radius: 1rem;
  width: 60%;
}
@media all and (max-width: 1200px) {
  .cash-in-application-box .personal-id-box {
    flex-flow: column;
  }
  .cash-in-application-box .personal-id-box button {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 800px) {
  .cash-in-application-box .personal-id-box img {
    width: 100%;
  }
}
@media all and (max-width: 600px) {
  .cash-in-application-box .personal-id-box {
    width: 100%;
  }
}
.cash-in-application-box .cash-in-terms p {
  font-size: 1.2rem;
  margin-top: 1.6rem;
}
.cash-in-application-box .cash-in-terms p:first-child {
  text-transform: uppercase;
  font-weight: bold;
}
.cash-in-application-box .cash-in-agreement {
  margin-bottom: 2rem;
}
.cash-in-application-box .cash-in-agreement label {
  display: flex;
}
.cash-in-application-box .cash-in-agreement label i {
  width: 2.6rem !important;
  padding: 0.5rem !important;
  box-sizing: border-box;
}
.cash-in-application-box .cash-in-agreement label span {
  line-height: 1.5;
}

.reseller-levels {
  margin-top: 2rem;
  display: inline-block;
  border-radius: 1.2rem;
  overflow: hidden;
}
@media all and (max-width: 800px) {
  .reseller-levels {
    display: block;
  }
}
.reseller-levels table {
  max-width: 600px;
  width: 100%;
}
@media all and (max-width: 800px) {
  .reseller-levels table {
    max-width: 100%;
  }
}
@media all and (max-width: 650px) {
  .reseller-levels table tr {
    border-bottom: 2px solid #fff;
  }
}
.reseller-levels table tr.tr-header {
  background: #f8f8f8;
  font-weight: 700;
  line-height: 1.3;
}
@media all and (max-width: 650px) {
  .reseller-levels table tr.tr-header {
    display: none;
  }
}
.reseller-levels table tr.tr-header td {
  font-size: 12px !important;
}
.reseller-levels table tr.tr-silver {
  background: #999999;
  background: -webkit-linear-gradient(to bottom, #cccccc, #999999);
  background: linear-gradient(to right, #cccccc, #999999);
  color: #fff;
}
.reseller-levels table tr.tr-gold {
  background: #fda085;
  background: -webkit-linear-gradient(to right bottom, #f6d365, #fda085);
  background: linear-gradient(to right, #f6d365, #fda085);
  color: #fff;
}
.reseller-levels table tr.tr-diamond {
  background: #04befe;
  background: -webkit-linear-gradient(to right bottom, #04befe, #4481eb);
  background: linear-gradient(to right, #04befe, #4481eb);
  color: #fff;
}
.reseller-levels table tr.tr-executive {
  background: #868f96;
  background: -webkit-linear-gradient(to right bottom, #868f96, #596164);
  background: linear-gradient(to right, #868f96, #596164);
  color: #fff;
}
.reseller-levels table tr td {
  border-bottom: 1px solid #fff;
  padding: 14px;
  font-size: 16px;
}
@media all and (max-width: 650px) {
  .reseller-levels table tr td {
    display: block;
    border-bottom: 0;
    text-align: left !important;
    padding: 6px;
    padding-left: 20px;
  }
  .reseller-levels table tr td:first-child {
    padding-top: 20px;
    padding-bottom: 6px;
    font-weight: 700;
    font-size: 2rem;
  }
  .reseller-levels table tr td:nth-child(1) {
    padding-bottom: 1.6rem;
    position: relative;
  }
  .reseller-levels table tr td:nth-child(1):after {
    content: " Comission";
    font-weight: normal;
  }
  .reseller-levels table tr td:nth-child(1):before {
    content: "";
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    position: absolute;
    top: 4.8rem;
  }
  .reseller-levels table tr td:nth-child(2):after {
    content: " Basic Membership";
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
  .reseller-levels table tr td:nth-child(2) strong {
    display: inline-block;
    border: 1px solid #fff;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;
    width: 4.7rem;
    text-align: right;
  }
  .reseller-levels table tr td:nth-child(3) {
    padding-bottom: 2.4rem;
  }
  .reseller-levels table tr td:nth-child(3):after {
    content: " Premium Membership";
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }
  .reseller-levels table tr td:nth-child(3) strong {
    display: inline-block;
    border: 1px solid #fff;
    padding: 0.4rem 0.8rem;
    border-radius: 0.5rem;
    width: 4.7rem;
    text-align: right;
  }
  .reseller-levels table tr td:nth-child(4) {
    border-top: 1px solid #fff;
    padding-top: 16px !important;
  }
  .reseller-levels table tr td:nth-child(4):after {
    content: " per Level";
  }
  .reseller-levels table tr td:last-child {
    padding-top: 8px;
    padding-bottom: 16px;
  }
}
.reseller-levels table tr td:not(:last-child) {
  border-right: 1px solid #fff;
}
@media all and (max-width: 650px) {
  .reseller-levels table tr td:not(:last-child) {
    border-right: 0;
  }
}

.ras-divider {
  height: 3rem;
  width: 3rem;
}

.ras-url {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.ras-url input {
  display: inline-block;
  width: 50%;
  font-weight: bold;
  border: 1px solid #ededed;
  border-radius: 1rem;
}
@media all and (max-width: 1000px) {
  .ras-url input {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.ras-url button {
  padding: 0;
  width: 5.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  border-radius: 1rem;
  height: 5.2rem;
}
@media all and (max-width: 1000px) {
  .ras-url button {
    height: 5.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 450px) {
  .ras-url button {
    height: 4rem;
    width: 4rem;
  }
}
@media all and (max-width: 360px) {
  .ras-url button {
    height: 3.4rem;
    width: 3.4rem;
  }
}
.ras-url button i {
  font-size: 2rem;
}
@media all and (max-width: 360px) {
  .ras-url button i {
    font-size: 1.8rem;
  }
}
.ras-url .button-copy {
  position: relative;
}
.ras-url .button-copy span {
  display: block;
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: bold;
  color: #444;
}

.ras-url-info {
  font-style: italic;
  color: #999;
  margin-bottom: 0 !important;
}

.ras-earning-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 1250px) {
  .ras-earning-flex > div {
    width: 100%;
    text-align: center;
  }
  .ras-earning-flex > div:first-child {
    margin-bottom: 4rem;
  }
}

.ras-earnings,
.ras-levels {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.ras-circle {
  border: 2px solid #ededed;
  width: 160px;
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 1rem;
  border-radius: 50%;
}
@media all and (max-width: 1350px) {
  .ras-circle {
    width: 140px;
    height: 140px;
    margin: 1rem;
  }
}
@media all and (max-width: 850px) {
  .ras-circle {
    width: 130px;
    height: 130px;
    margin: 0.5rem;
  }
}
.ras-circle p {
  margin-bottom: 0 !important;
  text-align: center;
}
.ras-circle p:first-child {
  font-size: 2.2rem;
}
@media all and (max-width: 1350px) {
  .ras-circle p:first-child {
    font-size: 2rem;
  }
}
.ras-circle p:last-child {
  font-size: 1.2rem;
  line-height: 1.2;
  color: #999;
}

.current-silver {
  background: #999999;
  background: -webkit-linear-gradient(to bottom, #cccccc, #999999);
  background: linear-gradient(to right, #cccccc, #999999);
  color: #fff;
}
.current-silver p:last-child {
  color: #fff;
}

.current-gold {
  background: #fda085;
  background: -webkit-linear-gradient(to right bottom, #f6d365, #fda085);
  background: linear-gradient(to right, #f6d365, #fda085);
  color: #fff;
}
.current-gold p:last-child {
  color: #fff;
}

.current-diamond {
  background: #04befe;
  background: -webkit-linear-gradient(to right bottom, #04befe, #4481eb);
  background: linear-gradient(to right, #04befe, #4481eb);
  color: #fff;
}
.current-diamond p:last-child {
  color: #fff;
}

.current-executive {
  background: #868f96;
  background: -webkit-linear-gradient(to right bottom, #868f96, #596164);
  background: linear-gradient(to right, #868f96, #596164);
  color: #fff;
}
.current-executive p:last-child {
  color: #fff;
}

.ras-circle-silver {
  border: 2px solid #cccccc;
}

.ras-circle-gold {
  border: 2px solid #f6d365;
}

.ras-circle-diamond {
  border: 2px solid #04befe;
}

.ras-circle-executive {
  border: 2px solid #868f96;
}

.ras-cash-ready {
  border: 2px solid #6ec568;
}

.ras-lists-flex {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1350px) {
  .ras-lists-flex {
    flex-wrap: wrap;
  }
}

.ras-actions {
  text-align: center;
  margin-top: 1.5rem;
}
.ras-actions button {
  margin: 0.5rem;
}

.ras-lists-flex table {
  margin: 0 auto;
  width: 100%;
}
.ras-lists-flex table tr.table-header {
  font-weight: 700;
  text-transform: uppercase;
  text-align: left !important;
  font-size: 1.2rem;
}
@media all and (max-width: 700px) {
  .ras-lists-flex table tr.table-header {
    display: none;
  }
}
.ras-lists-flex table tr:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
.ras-lists-flex table tr td {
  padding: 1rem 2rem;
}
@media all and (max-width: 700px) {
  .ras-lists-flex table tr td {
    display: block;
    text-align: left;
    padding: 6px;
    padding-left: 20px;
  }
  .ras-lists-flex table tr td:first-child {
    padding-top: 20px;
  }
  .ras-lists-flex table tr td:last-child {
    padding-bottom: 20px;
  }
  .ras-lists-flex table tr td::before {
    color: #999;
    display: inline-block;
  }
}
@media all and (max-width: 700px) {
  .ras-lists-flex table.ras-invoice-table tr td::before {
    width: 90px;
  }
  .ras-lists-flex table.ras-invoice-table tr td:first-child::before {
    content: "Invoice ID:";
  }
  .ras-lists-flex table.ras-invoice-table tr td:nth-child(2)::before {
    content: "Date:";
  }
  .ras-lists-flex table.ras-invoice-table tr td:nth-child(3)::before {
    content: "Amount:";
  }
  .ras-lists-flex table.ras-invoice-table tr td:last-child::before {
    content: "Method:";
  }
  .ras-lists-flex table.ras-accounts-table tr td::before {
    width: 100px;
  }
  .ras-lists-flex table.ras-accounts-table tr td:first-child::before {
    content: "Business:";
  }
  .ras-lists-flex table.ras-accounts-table tr td:nth-child(2)::before {
    content: "Sale Date:";
  }
  .ras-lists-flex table.ras-accounts-table tr td:nth-child(3)::before {
    content: "Account Type:";
  }
  .ras-lists-flex table.ras-accounts-table tr td:last-child::before {
    content: "Comission:";
  }
}

.select-affiliate-payment {
  margin-top: 4rem;
  display: flex;
  flex-wrap: wrap;
}
.select-affiliate-payment .affiliate-payment-box {
  border: 2px solid #ededed;
  padding: 3rem;
  border-radius: 1rem;
  min-width: 300px;
  position: relative;
}
.select-affiliate-payment .affiliate-payment-box:first-child {
  margin-right: 2rem;
}
@media all and (max-width: 800px) {
  .select-affiliate-payment .affiliate-payment-box:first-child {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 800px) {
  .select-affiliate-payment .affiliate-payment-box {
    width: 100%;
  }
}
.select-affiliate-payment .affiliate-payment-box h4 {
  border-bottom: 1px solid #444;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
.select-affiliate-payment .affiliate-payment-box .affiliate-payment-check {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  color: #6ec568;
  font-size: 1.7rem;
  border: 2px solid #ccc;
  border-radius: 0.4rem;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-affiliate-payment .affiliate-payment-box-selected {
  border: 2px solid #6ec568 !important;
}
.select-affiliate-payment .affiliate-payment-paypal label {
  margin-top: 1.2rem;
  display: block;
  color: #999;
}
.select-affiliate-payment .affiliate-payment-paypal input {
  border: 1px solid #ededed;
  border-radius: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1.2rem;
}
.select-affiliate-payment .affiliate-payment-paypal .fa-paypal {
  color: #3b7bbf;
}
.select-affiliate-payment .affiliate-payment-check .fa-money-check {
  color: #6ec568;
}
.select-affiliate-payment .affiliate-payment-check p {
  margin-bottom: 1rem;
}
.select-affiliate-payment .affiliate-payment-check table {
  margin-bottom: 1rem;
}
.select-affiliate-payment .affiliate-payment-check table tr:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
.select-affiliate-payment .affiliate-payment-check table tr:first-child td {
  padding-bottom: 1rem;
}
.select-affiliate-payment .affiliate-payment-check table tr:last-child td {
  padding-top: 1rem;
}
.select-affiliate-payment .affiliate-payment-check table tr td {
  vertical-align: top;
}
.select-affiliate-payment .affiliate-payment-check table tr td:first-child {
  padding-right: 1rem;
  color: #999999;
}

.affiliate-terms {
  border: 1px solid #ccc;
  margin-top: 2rem;
  padding: 3rem;
  height: 300px;
  overflow-y: scroll;
}
.affiliate-terms ol {
  list-style-type: decimal;
  margin: 1rem 0 1rem 2rem;
}
.affiliate-terms > ol > li {
  margin: 1rem 0;
}

.cash-in-disclaimer {
  text-align: center;
  font-size: 1.2rem;
  color: #777;
  font-style: italic;
  margin-top: 2rem;
}

.cashin-intro-flex {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1250px) {
  .cashin-intro-flex .ras-circle {
    margin: 0.5rem;
    width: 120px;
    height: 120px;
  }
}
@media all and (max-width: 1000px) {
  .cashin-intro-flex {
    flex-wrap: wrap;
  }
}

.cash-in-application-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cash-in-application-flex .cash-in-application-box {
  margin-bottom: 2rem;
  width: 49.5%;
}
@media all and (max-width: 1100px) {
  .cash-in-application-flex .cash-in-application-box {
    width: 100%;
  }
}
.cash-in-application-flex .cash-in-application-box .reseller-levels {
  width: 100%;
}
.cash-in-application-flex
  .cash-in-application-box
  .select-affiliate-payment
  .affiliate-payment-box {
  border: 2px solid #ededed;
  padding: 3rem;
  border-radius: 1rem;
  min-width: auto;
  position: relative;
  width: 49%;
  box-sizing: border-box;
}
@media all and (max-width: 800px) {
  .cash-in-application-flex
    .cash-in-application-box
    .select-affiliate-payment
    .affiliate-payment-box {
    width: 100% !important;
  }
}
.cash-in-application-flex
  .cash-in-application-box
  .select-affiliate-payment
  .affiliate-payment-box:first-child {
  margin-right: 2%;
}
@media all and (max-width: 800px) {
  .cash-in-application-flex
    .cash-in-application-box
    .select-affiliate-payment
    .affiliate-payment-box:first-child {
    margin-right: 0 !important;
    margin-bottom: 2rem;
  }
}

.contact-box {
  max-width: 50rem;
  width: 100%;
  padding: 2rem;
  border-radius: 1rem;
  margin: 0 auto;
  background: #ffffff;
}
@media all and (max-width: 600px) {
  .contact-box {
    border-radius: 0;
    max-width: 100%;
  }
}

.help {
  padding: 0 10rem;
}
@media all and (max-width: 1250px) {
  .help {
    padding: 0 5rem;
  }
}
@media all and (max-width: 650px) {
  .help {
    padding: 0rem;
  }
}
.help .faq-container {
  background: #fff;
  border-radius: 1rem;
  padding: 4rem;
  display: flex;
}
@media all and (max-width: 650px) {
  .help .faq-container {
    padding: 2rem;
    margin: 0 2rem;
  }
}
@media all and (max-width: 600px) {
  .help .faq-container {
    margin: 0;
    border-radius: 0;
  }
}
.help .faq-nav {
  width: 20%;
}
@media all and (max-width: 1000px) {
  .help .faq-nav {
    display: none;
  }
}
.help .faq-nav .faq-li-header {
  padding-bottom: 0.4rem;
}
.help .faq-nav .faq-li-item {
  padding: 0.4rem 0;
  font-size: 1.5rem;
}
.help .faq-nav .faq-li-item a {
  color: #555 !important;
}
.help .faq-nav .faq-li-item a:hover {
  color: #469ad4 !important;
}
.help .faq-content {
  width: 70%;
  padding-left: 10rem;
  border-left: 1px solid #ededed;
}
@media all and (max-width: 1000px) {
  .help .faq-content {
    width: 100%;
    padding-left: 0rem;
    border-left: 0px solid #ededed;
  }
}
.help .faq-content h2 {
  margin-bottom: 3rem;
  border-bottom: 1px solid #6ec568;
  padding-left: 2px;
}
.help .faq-content h2:not(:first-child) {
  margin-top: 8rem;
}
.help .faq-content .faq-content-section {
  margin-bottom: 6rem;
}
.help .faq-content .faq-content-section h3 {
  margin-bottom: 3rem;
  padding-left: 1.6rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-left: 0.5rem solid #6ec568;
}
.help .faq-content .faq-content-section h4 {
  margin-bottom: 0.5rem;
}
.help .faq-content .faq-content-section .the-faq:not(:last-child) {
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid #ededed;
}
.help .faq-content .faq-content-section p:not(:last-child) {
  margin-bottom: 1rem;
}
.help .faq-content .faq-content-section .faq-arrow-up i {
  font-size: 2rem;
}

.forgot-password .home-box {
  margin-bottom: 2rem;
  margin-top: 4rem;
}
.forgot-password .home-box .visitor-header_box {
  margin: 0 auto;
  height: auto;
  max-width: 50rem !important;
  display: block !important;
}
.forgot-password .home-box .visitor-header_box_footer {
  position: initial !important;
}
.forgot-password .home-box .visitor-header_box_footer button {
  width: 18rem;
}
.forgot-password .home-box .visitor-header_box__sign-in .form-field-error {
  color: #fff !important;
  margin-top: 0.5rem;
}

.home-divider {
  margin-top: 2rem;
}
@media all and (max-width: 600px) {
  .home-divider {
    margin-top: 4px;
  }
}

.home-box {
  margin-bottom: 0rem;
}
@media all and (max-width: 750px) {
  .home-box {
    margin-bottom: 3rem;
  }
}
@media all and (max-width: 600px) {
  .home-box {
    margin-bottom: 0 !important;
  }
}
.home-box_header {
  text-align: center;
  margin-bottom: 3rem;
}
.home-box_shop-store {
  margin-bottom: 2rem;
}

.visitor-header {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 720px) {
  .visitor-header {
    display: block;
  }
}
.visitor-header .form-field-error {
  color: #fff !important;
  margin-top: 1rem;
}
.visitor-header_box {
  width: 49.9%;
  height: 43rem;
  overflow: hidden;
  padding: 5rem;
  position: relative;
}
@media all and (max-width: 1110px) {
  .visitor-header_box {
    width: 49.9%;
  }
}
@media all and (max-width: 720px) {
  .visitor-header_box {
    width: 100%;
  }
}
.visitor-header_box:first-child {
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}
.visitor-header_box:last-child {
  border-bottom-right-radius: 1rem;
  border-top-right-radius: 1rem;
}
.visitor-header_box h1 {
  text-align: left;
  color: #ffffff;
  font-weight: 300;
}
.visitor-header_box h1:after {
  display: none;
}
.visitor-header_box_footer {
  position: absolute;
  bottom: 4rem;
  left: 5rem;
}
.visitor-header_box_footer button {
  width: 15rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.visitor-header_box_footer div {
  color: #ffffff;
  font-size: 1.2rem;
  margin-top: 1rem;
}
.visitor-header_box_footer a {
  color: #ffffff;
}
.visitor-header_box_tagline {
  margin-top: 1rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 2.6rem;
}
.visitor-header_box_tagline span.spacer {
  display: block;
  height: 1rem;
}
.visitor-header_box_tagline span.only {
  vertical-align: super;
  font-size: 1.4rem;
}
.visitor-header_box_sign-in {
  background: #469ad4;
  display: none;
}
@media all and (max-width: 1110px) {
  .visitor-header_box_sign-in {
    display: none;
  }
}
.visitor-header_box_sign-in img {
  width: 22.5rem;
  display: block;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}
.visitor-header_box_sign-in input {
  color: #fff;
  border-bottom: 1px solid #ffffff;
}
.visitor-header_box_sign-in input::placeholder {
  color: #ffffff;
}
.visitor-header_box_sign-in div div button {
  color: #ffffff;
  width: auto;
  padding: 0;
}
.visitor-header_box_social {
  position: relative;
  background-position: center -5rem;
  background-size: 72rem;
  border-bottom-left-radius: 1rem;
  border-top-left-radius: 1rem;
}
.visitor-header_box_social iframe {
  position: absolute;
  top: -2.8rem;
  left: -5rem;
  height: 50rem;
  width: 80rem;
  z-index: 2;
}
@media all and (max-width: 1035px) {
  .visitor-header_box_social iframe {
    left: -14rem;
  }
}
@media all and (max-width: 1035px) {
  .visitor-header_box_social iframe {
    left: -20rem;
  }
}
@media all and (max-width: 720px) {
  .visitor-header_box_social iframe {
    left: -8rem;
  }
}
@media all and (max-width: 460px) {
  .visitor-header_box_social iframe {
    left: -16rem;
  }
}
.visitor-header_box_social_cover {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.visitor-header_box_social h1,
.visitor-header_box_social p {
  position: relative;
  z-index: 3;
}
.visitor-header_box_social div {
  z-index: 3;
}
@media all and (max-width: 1250px) {
  .visitor-header_box_social {
    background-position: -3rem -5rem;
  }
}
@media all and (max-width: 720px) {
  .visitor-header_box_social {
    background-position: center -5rem;
    margin-bottom: 2rem;
    border-radius: 1rem;
  }
}
@media all and (max-width: 460px) {
  .visitor-header_box_social {
    background-position: center -5rem;
  }
}
.visitor-header_box_business {
  background-size: 68rem;
  background-position: bottom;
}
@media all and (max-width: 720px) {
  .visitor-header_box_business {
    background-position: bottom left;
    background-size: cover;
    border-radius: 1rem;
  }
}

.logged-in-header .home-welcome {
  margin-bottom: 2rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
}
@media all and (max-width: 600px) {
  .logged-in-header .home-welcome {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.logged-in-header .home-welcome a {
  margin: 0 !important;
  padding: 0 !important;
  width: 75px;
  height: 75px;
  margin-right: 20px !important;
  display: block;
}
@media all and (max-width: 500px) {
  .logged-in-header .home-welcome a {
    width: 50px;
    height: 50px;
    margin-right: 16px !important;
  }
}
.logged-in-header .home-welcome img {
  width: 100%;
  border-radius: 100%;
  border: 1px solid #e7e7e7;
}
.logged-in-header .home-welcome h1 {
  margin-left: -2px;
  display: block;
  font-size: 3.3rem;
}
@media all and (max-width: 500px) {
  .logged-in-header .home-welcome h1 {
    font-size: 2.6rem;
  }
}

.logged-in-header_to-do {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.logged-in-header_to-do_swiper {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 1rem;
  padding: 0.2rem 0.2rem 1rem 0rem !important;
}
@media all and (max-width: 600px) {
  .logged-in-header_to-do_swiper {
    border-radius: 0;
  }
}
.logged-in-header_to-do_swiper .swiper-wrapper {
  margin-bottom: 1.6rem;
}
.logged-in-header_to-do_swiper .home-box-divider {
  display: block;
  width: 10px;
  border-radius: 0;
  border-right: 1px solid #e7e7e7;
}
.logged-in-header_to-do_swiper .home-box-divider:before {
  position: absolute;
  bottom: 0;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  left: 32px;
  color: #444;
  transform: rotate(-90deg);
  transform-origin: left bottom;
  width: 100%;
  display: block;
}
@media all and (max-width: 750px) {
  .logged-in-header_to-do_swiper .home-box-divider:before {
    font-size: 1.4rem;
  }
}
.logged-in-header_to-do_swiper .home-box-divider-business:before {
  content: "Sell";
}
.logged-in-header_to-do_swiper .home-box-divider-service:before {
  content: "Post";
}
.logged-in-header_to-do_swiper .home-box-divider-social:before {
  content: "Social";
}
.logged-in-header_to-do_spacer {
  width: 2%;
  height: 4rem;
  float: left;
}
@media all and (max-width: 750px) {
  .logged-in-header_to-do_spacer {
    display: block;
    width: 100%;
    height: 3rem;
  }
}
.logged-in-header_to-do_title {
  display: inlin-block;
  width: 100%;
  text-align: center;
  font-size: 1.6rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 1rem;
  background: #f4f4f4;
  z-index: 12;
  position: relative;
}

.logged-in-header a {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  padding: 1rem;
  width: 11rem;
  height: 9rem;
  margin: 1rem 0.5rem 1rem 1rem !important;
  color: #777;
}
@media all and (max-width: 980px) {
  .logged-in-header a {
    margin: 1rem !important;
  }
}
@media all and (max-width: 750px) {
  .logged-in-header a {
    margin: 0.4rem !important;
    width: 8rem;
    height: 7rem;
    overflow: hidden;
  }
  .logged-in-header a i {
    font-size: 2.2rem !important;
    margin-bottom: 0rem !important;
  }
  .logged-in-header a span {
    font-size: 1rem;
  }
}
.logged-in-header a:not(:last-child) {
  margin-right: 4rem;
}
@media all and (max-width: 980px) {
  .logged-in-header a:not(:last-child) {
    margin-right: 0;
  }
}
.logged-in-header a i {
  display: block;
  font-size: 3rem;
  width: 100%;
  margin-bottom: -2rem;
}
@media all and (max-width: 550px) {
  .logged-in-header a i {
    font-size: 3rem;
    margin-bottom: -1rem;
  }
}
.logged-in-header a span {
  display: block;
  width: 100%;
  line-height: 1.2;
  font-size: 1.2rem;
}

.logged-in-header button {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  padding: 1rem;
  width: 11rem;
  height: 9rem;
  margin: 1rem 0.5rem 1rem 1rem !important;
  color: #777;
}
@media all and (max-width: 980px) {
  .logged-in-header button {
    margin: 1rem !important;
  }
}
@media all and (max-width: 750px) {
  .logged-in-header button {
    margin: 0.5rem !important;
    margin-top: 1rem !important;
    width: 10rem;
    height: 10rem;
    overflow: hidden;
  }
  .logged-in-header button i {
    font-size: 2.8rem;
    margin-bottom: 0rem;
  }
  .logged-in-header button span {
    font-size: 1rem;
  }
}
.logged-in-header button:not(:last-child) {
  margin-right: 4rem;
}
@media all and (max-width: 980px) {
  .logged-in-header button:not(:last-child) {
    margin-right: 0;
  }
}
.logged-in-header button i {
  display: block;
  font-size: 3rem;
  width: 100%;
  margin-bottom: 1rem;
}
@media all and (max-width: 550px) {
  .logged-in-header button i {
    font-size: 3rem;
  }
}
.logged-in-header button span {
  display: block;
  width: 100%;
  line-height: 1.2;
  font-size: 1.2rem;
}

.home-categories {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}
@media (max-width: 700px) {
  .home-categories {
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
  }
}
@media all and (max-width: 600px) {
  .home-categories {
    padding: 0 2rem;
    margin-bottom: 2rem;
  }
}
@media (max-width: 470px) {
  .home-categories {
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
  }
}
.home-categories_box {
  display: block;
  text-align: center;
  color: #444444 !important;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 600;
}
.home-categories_box img {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.home_products_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.home_products_row .home_products_box {
  width: 24%;
  padding: 0;
  margin: 0;
  margin-bottom: 2rem;
}
@media all and (max-width: 1150px) {
  .home_products_row .home_products_box {
    width: 32%;
  }
}
@media all and (max-width: 900px) {
  .home_products_row .home_products_box {
    width: 48%;
  }
}
@media all and (max-width: 600px) {
  .home_products_row .home_products_box {
    width: 100%;
  }
}
.home_products_row .home_products_box .home_products_img {
  width: 100%;
  margin-bottom: 1.6rem;
  display: block;
}
.home_products_row .home_products_box .home_products_title {
  padding: 0 1.6rem;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 600;
}
.home_products_row .home_products_box .home_products_brand {
  padding: 0 1.6rem;
}
.home_products_row .home_products_box .home_products_price {
  padding: 0 1.6rem;
  font-size: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.home_products_row .home_products_box div {
  display: flex;
  padding: 0 1.6rem;
  justify-content: space-between;
  margin-bottom: 2rem;
  align-items: center;
}
.home_products_row .home_products_box div img.short {
  height: 44px;
}
@media all and (max-width: 1300px) {
  .home_products_row .home_products_box div img.short {
    height: 24px;
  }
}
.home_products_row .home_products_box div img.long {
  height: 24px;
}
@media all and (max-width: 1300px) {
  .home_products_row .home_products_box div img.long {
    height: 18px;
  }
}

.home-brands {
  text-align: center;
}
.home-brands img {
  height: 3.4rem;
  margin: 0 2rem;
  display: inline-block;
  margin-bottom: 3rem;
  mix-blend-mode: multiply;
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  transition: all 0.3s ease-in;
}
.home-brands img:hover {
  -webkit-filter: grayscale(0%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(0%);
}

.home-social {
  text-align: center;
}
.home-social a {
  display: inline-block;
  font-size: 8rem;
  padding: 2rem;
  width: 15rem;
  border-radius: 1rem;
  margin: 0.5rem;
  color: #b5b5b5;
  box-sizing: content-box;
  background: #fff;
}
@media all and (max-width: 800px) {
  .home-social a {
    font-size: 5rem;
    padding: 2rem 0;
    width: 31%;
    margin: 1%;
    box-sizing: border-box;
  }
}
@media all and (max-width: 500px) {
  .home-social a {
    padding: 0;
    padding-bottom: 1rem;
  }
}
.home-social a span {
  display: block;
  font-size: 1.2rem;
  margin-top: 1rem;
}
@media all and (max-width: 500px) {
  .home-social a span {
    margin-top: 0;
  }
}

.home-ads {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 1000px) {
  .home-ads {
    justify-content: center;
  }
}
.home-ads_box {
  color: #444444 !important;
  display: block;
  background: #ffffff;
  border-radius: 1rem;
  padding: 6rem 4rem 6rem 5rem;
  width: 32.5%;
  position: relative;
  overflow: hidden;
}
@media all and (max-width: 1000px) {
  .home-ads_box:not(:last-child) {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 1250px) {
  .home-ads_box {
    padding: 6rem 4rem;
  }
}
@media all and (max-width: 1190px) {
  .home-ads_box {
    padding: 6rem 3rem;
  }
}
@media all and (max-width: 1000px) {
  .home-ads_box {
    width: 48%;
    margin-left: 1%;
    margin-right: 1%;
  }
}
@media all and (max-width: 700px) {
  .home-ads_box {
    width: 80%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media all and (max-width: 500px) {
  .home-ads_box {
    width: 100%;
  }
}
.home-ads_box span {
  position: relative;
  z-index: 10;
}
.home-ads_box span h3 {
  line-height: 1.2;
  letter-spacing: 1px;
  position: relative;
  z-index: 10;
}
.home-ads_box img {
  position: absolute;
  right: -1px;
  top: -1px;
  width: 220px;
  z-index: 1;
}
@media all and (max-width: 1120px) {
  .home-ads_box img {
    right: -4rem;
  }
}
@media all and (max-width: 1000px) {
  .home-ads_box img {
    right: -1rem;
  }
}
@media all and (max-width: 750px) {
  .home-ads_box img {
    right: -4rem;
  }
}
@media all and (max-width: 700px) {
  .home-ads_box img {
    right: -1rem;
  }
}

.home_products_box {
  background: #fff;
  padding-bottom: 2rem;
  border-radius: 1rem;
  padding-top: 1rem;
  overflow: hidden;
  margin-bottom: 1.2rem;
}

@media all and (max-width: 750px) {
  .logged-in-header .main-100-header p {
    margin-bottom: 2rem;
  }
}

.home-social_blogs {
  color: #00b4db !important;
}

.home-social_photos {
  color: #06beb6 !important;
}

.home-social_videos {
  color: #fc5c7d !important;
}

.home-social_groups {
  color: #00b09b !important;
}

.home-social_events {
  color: #ff9966 !important;
}

.logged-in-header_to-do_swiper a.home-swiper-button span,
.logged-in-header_to-do_swiper button.home-swiper-button span {
  color: #fff;
}

.logged-in-header_to-do_swiper a.home-swiper-button:hover,
.logged-in-header_to-do_swiper button.home-swiper-button:hover {
  background: #fff;
  color: #444 !important;
}
.logged-in-header_to-do_swiper a.home-swiper-button:hover span,
.logged-in-header_to-do_swiper button.home-swiper-button:hover span {
  color: #444;
}

.new-home {
  margin-top: 2rem;
}
@media all and (max-width: 600px) {
  .new-home {
    margin-top: 4px;
  }
}

.home-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}
@media all and (max-width: 1060px) {
  .home-grid {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}
@media all and (max-width: 960px) {
  .home-grid {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
}
@media all and (max-width: 600px) {
  .home-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 5px;
  }
}
@media all and (max-width: 360px) {
  .home-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.home-signup-box {
  margin: 2rem 0;
  padding: 6rem 4rem;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media all and (max-width: 950px) {
  .home-signup-box {
    flex-direction: column;
  }
}
@media all and (max-width: 650px) {
  .home-signup-box {
    padding: 4rem 2rem;
  }
}
@media all and (max-width: 950px) {
  .home-signup-box .hsub-text {
    text-align: center;
    margin-bottom: 2rem;
  }
}
.home-signup-box h1 {
  margin-bottom: 0.4rem;
}
.home-signup-box .hsub-buttons button:first-child {
  margin-right: 1rem;
}

.real-estate-brokerage {
  text-align: left;
  border-top: 1px solid #ededed;
  margin-top: 2rem;
  padding-top: 2rem;
  font-size: 1.5rem;
}
.real-estate-brokerage p {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}
.real-estate-brokerage table td {
  padding-right: 2rem;
}
.real-estate-brokerage table thead td {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.5rem;
}

@media all and (max-width: 380px) {
  .real-estate-search-box {
    height: 50rem !important;
  }
}

.home-search-box {
  background: #fff;
  border-radius: 10px;
  padding: 4rem;
  height: 37rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media all and (max-width: 600px) {
  .home-search-box {
    border-radius: 0;
  }
}
@media all and (max-width: 500px) {
  .home-search-box {
    padding: 2rem;
    height: 40rem;
    flex-direction: column;
  }
}
@media all and (max-width: 400px) {
  .home-search-box {
    padding: 2rem 1rem;
  }
}
@media all and (max-width: 370px) {
  .home-search-box {
    height: 40rem;
  }
}
.home-search-box .coming-soon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  font-size: 2.8rem;
  font-weight: 300;
  border-radius: 1rem;
}
@media all and (max-width: 600px) {
  .home-search-box .coming-soon {
    border-radius: 0;
  }
}
@media all and (max-width: 650px) {
  .home-search-box .mobile-hide {
    display: none !important;
  }
}
.home-search-box .hsb-copyright {
  position: absolute;
  bottom: 20px;
  right: 20px;
  font-weight: 300;
  font-size: 1.2rem;
  cursor: pointer;
}
.home-search-box .hsb-copyright:hover strong {
  border-bottom: 1px solid #fff;
}
.home-search-box .hsb-copyright strong {
  font-size: 1.5rem;
  font-weight: 600;
}
@media all and (max-width: 500px) {
  .home-search-box .hsb-copyright {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 1rem;
  }
  .home-search-box .hsb-copyright strong {
    font-size: 1.2rem;
  }
}
.home-search-box .hsb-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #111;
  opacity: 0.6;
  border-radius: 1rem;
}
@media all and (max-width: 600px) {
  .home-search-box .hsb-background {
    border-radius: 0;
  }
}
.home-search-box .hsb-container {
  position: relative;
  width: 70%;
}
@media all and (max-width: 1350px) {
  .home-search-box .hsb-container {
    width: 90%;
  }
}
@media all and (max-width: 1150px) {
  .home-search-box .hsb-container {
    width: 100%;
  }
}
@media all and (max-width: 960px) {
  .home-search-box .hsb-container {
    width: 70%;
  }
}
@media all and (max-width: 900px) {
  .home-search-box .hsb-container {
    width: 90%;
  }
}
@media all and (max-width: 670px) {
  .home-search-box .hsb-container {
    width: 100%;
  }
}
.home-search-box .hsb-container h2 {
  font-size: 3.8rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 3rem;
}
@media all and (max-width: 500px) {
  .home-search-box .hsb-container h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 3rem;
  }
}
.home-search-box .hsb-container .hsb-filter-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
@media all and (max-width: 370px) {
  .home-search-box .hsb-container .hsb-filter-group {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .home-search-box .hsb-container .hsb-filter-group .hsb-filter:first-child {
    margin-bottom: 1rem;
  }
}
.home-search-box .hsb-container .hsb-filter-group .hsb-filter {
  margin-bottom: 0;
}
.home-search-box .hsb-container .hsb-filter {
  margin-bottom: 1rem;
}
@media all and (max-width: 500px) {
  .home-search-box .hsb-container .hsb-filter {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 370px) {
  .home-search-box .hsb-container .hsb-filter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.home-search-box .hsb-container .hsb-filter input[type="radio"] {
  visibility: hidden;
  position: absolute;
}
.home-search-box .hsb-container .hsb-filter label {
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 1rem;
  cursor: pointer;
}
.home-search-box .hsb-container .hsb-filter label:hover {
  border: 1px solid #fff;
}
@media all and (max-width: 500px) {
  .home-search-box .hsb-container .hsb-filter label {
    font-size: 1.3rem;
  }
}
.home-search-box
  .hsb-container
  .hsb-filter
  input[type="radio"]:checked
  + label {
  border: 1px solid #fff;
}
.home-search-box .hsb-container .hsb-input {
  position: relative;
  pointer-events: none;
}
.home-search-box .hsb-container .hsb-input:before {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  left: 0.8rem;
  font-size: 2rem;
  color: #999;
  font-weight: 300;
  width: 40px;
  text-align: center;
}
.home-search-box .hsb-container .hsb-input-services {
  margin-bottom: 1rem;
}
.home-search-box .hsb-container .hsb-input-auto {
  margin-bottom: 1rem;
  width: 49%;
}
.home-search-box .hsb-container .hsb-input-realestate-select {
  margin-bottom: 1rem;
  width: 49%;
}
.home-search-box .hsb-container .hsb-input-jobs {
  margin-bottom: 1rem;
}
.home-search-box .hsb-container .hsb-input-realestate:before {
  content: "\f3c5";
}
.home-search-box .hsb-container .hsb-input-medical:before {
  content: "\f0f0";
}
.home-search-box .hsb-container .hsb-input-realestate-select:before {
  content: "\f64f";
}
.home-search-box .hsb-container .hsb-input-realestate-select:after,
.home-search-box .hsb-container .hsb-input-auto-select:after,
.home-search-box .hsb-container .hsb-input-store-condition:after {
  position: absolute;
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
  top: 50%;
  transform: translateY(-50%);
  right: 1.4rem;
  font-size: 1.4rem;
  color: #333;
  font-weight: 300;
}
.home-search-box .hsb-container .hsb-input-auto:before {
  content: "\f1b9";
}
.home-search-box .hsb-container .hsb-input-store:before {
  content: "\f847";
}
.home-search-box .hsb-container .hsb-input-store-location {
  width: 49%;
  margin-left: 2%;
}
.home-search-box .hsb-container .hsb-input-store-condition {
  flex: 1;
}
.home-search-box .hsb-container .hsb-input-store-condition:before {
  content: "\f49a";
}
.home-search-box .hsb-container .hsb-input-travel:before {
  content: "\f277";
}
.home-search-box .hsb-container .hsb-input-jobs:before {
  content: "\f277";
}
.home-search-box .hsb-container .hsb-input-services:before {
  content: "\f0ad";
}
.home-search-box .hsb-container .hsb-input-food:before {
  content: "\f858";
}
.home-search-box .hsb-container .hsb-input-location:before {
  content: "\f3c5";
}
.home-search-box .hsb-container .hsb-input-date:before {
  content: "\f073";
}
.home-search-box .hsb-container .date-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.home-search-box .hsb-container .date-flex .hsb-input-date {
  width: 49%;
}
.home-search-box .hsb-container .date-flex .hsb-input-date .date-label {
  position: absolute;
  color: #444;
  left: 58px;
  top: 6px;
  font-size: 12px;
}
.home-search-box .hsb-container .date-flex .hsb-input-date input[type="text"] {
  border: 0;
  padding: 2.6rem 1.8rem 1rem 5.8rem;
  width: 100%;
  border-radius: 1rem;
  font-size: 1.6rem;
  color: #444;
}
.home-search-box .hsb-container input[type="text"] {
  border: 0;
  padding: 1.8rem 1.8rem 1.8rem 4.8rem;
  width: 100%;
  border-radius: 1rem;
  font-size: 1.6rem;
  color: #444;
  background: #fff;
  pointer-events: auto;
}
.home-search-box .hsb-container select {
  border: 0;
  padding: 1.8rem 3.4rem 1.8rem 4.8rem;
  width: 100%;
  border-radius: 1rem;
  font-size: 1.5rem;
  color: #444;
  background: #fff;
  pointer-events: auto;
}
.home-search-box .hsb-buttons {
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media all and (max-width: 960px) {
  .home-search-box .hsb-buttons {
    justify-content: center;
  }
}
@media all and (max-width: 500px) {
  .home-search-box .hsb-buttons {
    flex-direction: column;
    align-items: center;
  }
}
.home-search-box .hsb-buttons .button-white {
  background: transparent;
  color: #fff;
  margin-right: 2rem;
}
@media all and (max-width: 500px) {
  .home-search-box .hsb-buttons .button-white {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.home-search-box .hsb-buttons .button-white:hover {
  background: #fff;
  color: #444;
}
.home-search-box .hsb-buttons a {
  color: #fff;
  font-size: 1.4rem;
}
.home-search-box .hsb-buttons a:first-child {
  margin-right: 1rem;
}
.home-search-box .hsb-buttons a:last-child {
  margin-left: 1rem;
}
.home-search-box .hsb-buttons a:hover {
  text-decoration: underline;
}

.home-news-box h2 {
  font-size: 3.8rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 1rem;
}
@media all and (max-width: 500px) {
  .home-news-box h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 3.4rem;
  }
}

.home-news-box .hnb-filter {
  margin-bottom: 1rem;
}
@media all and (max-width: 500px) {
  .home-news-box .hnb-filter {
    margin-bottom: 2rem;
  }
}
.home-news-box .hnb-filter input[type="radio"] {
  visibility: hidden;
  position: absolute;
}
.home-news-box .hnb-filter label {
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-radius: 1rem;
  cursor: pointer;
  display: inline-block;
}
@media all and (max-width: 500px) {
  .home-news-box .hnb-filter label {
    font-size: 1.3rem;
  }
}
.home-news-box .hnb-filter input[type="radio"]:checked + label {
  border: 1px solid #444;
}

.home-news-box .hnb-news-grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  margin-top: 4rem;
}
@media all and (max-width: 1220px) {
  .home-news-box .hnb-news-grid {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}
@media all and (max-width: 860px) {
  .home-news-box .hnb-news-grid {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

.home-news-box .hnb-news-buttons {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 960px) {
  .home-news-box .hnb-news-buttons {
    justify-content: center;
  }
}
@media all and (max-width: 500px) {
  .home-news-box .hnb-news-buttons {
    flex-direction: column;
  }
}
.home-news-box .hnb-news-buttons .button-white {
  background: transparent;
  color: #444;
  margin-right: 2rem;
}
@media all and (max-width: 500px) {
  .home-news-box .hnb-news-buttons .button-white {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.home-news-box .hnb-news-buttons .button-white:hover {
  background: #444;
  color: #fff;
}
.home-news-box .hnb-news-buttons a {
  color: #444;
  font-size: 1.4rem;
}
.home-news-box .hnb-news-buttons a:first-child {
  margin-right: 1rem;
}
.home-news-box .hnb-news-buttons a:last-child {
  margin-left: 1rem;
}
.home-news-box .hnb-news-buttons a:hover {
  text-decoration: underline;
}

.home-food-box {
  margin-bottom: 4rem;
}
@media all and (max-width: 600px) {
  .home-food-box {
    margin-top: 4px;
    margin-bottom: 0;
  }
}
.home-food-box .hsb-container {
  position: relative;
  width: 70%;
}
@media all and (max-width: 1350px) {
  .home-food-box .hsb-container {
    width: 90%;
  }
}
@media all and (max-width: 1150px) {
  .home-food-box .hsb-container {
    width: 100%;
  }
}
@media all and (max-width: 960px) {
  .home-food-box .hsb-container {
    width: 70%;
  }
}
@media all and (max-width: 900px) {
  .home-food-box .hsb-container {
    width: 90%;
  }
}
@media all and (max-width: 670px) {
  .home-food-box .hsb-container {
    width: 100%;
  }
}
.home-food-box .foods-inputs {
  display: flex;
  flex-wrap: wrap;
}
.home-food-box .foods-inputs .hsb-input-food {
  width: 100%;
  margin-right: 0;
  margin-bottom: 1rem;
}
.home-food-box .foods-inputs .hsb-input-location {
  flex: 1;
}

.news-preview {
  background: #fff;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
}
.news-preview-img {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.news-preview-content {
  padding: 2rem 2rem 0 2rem;
}
.news-preview-content .bolder {
  font-weight: 400;
}
.news-preview-content h2 {
  font-size: 2.4rem;
  margin: 1rem 0;
  line-height: 1.3;
  font-weight: 300;
  text-align: left;
  display: block;
  height: 66px;
  overflow: hidden;
}
.news-preview-content p {
  margin-bottom: 1rem;
}
.news-preview-content a {
  font-weight: 600;
  color: #444;
}
.news-preview-content a:hover {
  text-decoration: underline;
}
.news-preview-content strong {
  font-weight: 400;
}
.news-preview-author {
  margin-top: 1rem;
}
.news-preview-source {
  position: absolute;
  top: -1px;
  right: -1px;
  font-size: 12px;
  border-bottom-left-radius: 10px;
  background: #fff;
  padding: 0.4rem 1rem;
  border: 1px solid #eee;
}

.investors {
  padding: 0 10rem;
}
@media all and (max-width: 1250px) {
  .investors {
    padding: 0 5rem;
  }
}
@media all and (max-width: 650px) {
  .investors {
    padding: 0rem;
  }
}
.investors h2 {
  margin: 6rem 0 4rem 0;
  line-height: 1;
  text-align: center;
}
.investors_section-1,
.investors_section-2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.investors_section-1 div,
.investors_section-2 div {
  width: 32%;
  border-radius: 1rem;
  overflow: hidden;
  background: #ffffff;
}
@media all and (max-width: 800px) {
  .investors_section-1 div,
  .investors_section-2 div {
    width: 100%;
  }
  .investors_section-1 div:not(:last-child),
  .investors_section-2 div:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.investors_section-1 div img,
.investors_section-2 div img {
  width: 100%;
}
.investors_section-1 div p,
.investors_section-2 div p {
  padding: 16px;
  text-align: center;
}
.investors_section-3 {
  margin-top: 4rem;
  text-align: center;
}
.investors_section-3 p {
  margin-bottom: 2rem;
}

.meet-the-team h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.the-team {
  max-width: 100rem;
  margin: 0 auto;
}
.the-team_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;
}
@media all and (max-width: 600px) {
  .the-team_section {
    margin-bottom: 0;
  }
}
.the-team_member {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  background: #fff;
  padding: 2rem;
  border-radius: 100rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media all and (max-width: 700px) {
  .the-team_member {
    border-radius: 2rem;
    align-items: flex-start;
  }
}
@media all and (max-width: 600px) {
  .the-team_member {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 4px;
    border-radius: 0;
  }
  .the-team_member:last-child {
    margin-bottom: 0;
  }
}
.the-team_member img {
  max-width: 200px;
  border-radius: 50%;
  border: 1px solid #efefef;
  margin-right: 30px;
}
@media all and (max-width: 700px) {
  .the-team_member img {
    max-width: 150px;
  }
}
@media all and (max-width: 600px) {
  .the-team_member img {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}
.the-team_member_name {
  font-size: 2.8rem;
  line-height: 1.2;
  font-weight: 300;
  margin-top: 0.2rem;
}
.the-team_member_title {
  font-size: 1.2rem;
  font-weight: 700;
}
.the-team_member_history:before {
  content: "";
  display: block;
  width: 40px;
  height: 1px;
  background: #444;
  margin: 10px 0;
}
.the-team_member ul {
  border-left: 3px solid #6ec568;
  padding-left: 20px;
  margin-top: 20px;
}
.the-team_member ul li:not(:last-child) {
  padding-bottom: 4px;
}

.page-404 {
  position: relative;
  z-index: 20;
}
.page-404-header {
  text-align: center;
  padding: 6rem 2rem;
  color: #fff;
}
@media all and (max-width: 700px) {
  .page-404-header {
    padding: 2rem 2rem;
  }
}
.page-404-header img {
  max-width: 300px;
}
@media all and (max-width: 700px) {
  .page-404-header img {
    margin-bottom: 1rem;
    max-width: 200px;
  }
}
.page-404-header h1 {
  line-height: 1.2;
  margin-bottom: 0.8rem;
}

.layout-background-404 {
  background: linear-gradient(to bottom right, #dba514, #dba514);
}

.press {
  padding: 0 10rem;
}
@media all and (max-width: 1250px) {
  .press {
    padding: 0 5rem;
  }
}
@media all and (max-width: 650px) {
  .press {
    padding: 0rem;
  }
}
.press h2 {
  text-align: center;
  margin-bottom: 2rem;
}
.press_the-release {
  border-radius: 1rem;
  padding: 4rem;
  margin-bottom: 2rem;
  background: #ffffff;
  display: flex;
  align-items: flex-start;
}
@media all and (max-width: 1000px) {
  .press_the-release {
    align-items: flex-start;
  }
}
@media all and (max-width: 700px) {
  .press_the-release {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 600px) {
  .press_the-release {
    border-radius: 0;
    padding: 2rem;
    margin-bottom: 4px;
  }
}
.press_the-release .pr-img {
  width: 30%;
  border-radius: 1rem;
  display: block;
}
@media all and (max-width: 700px) {
  .press_the-release .pr-img {
    width: 70%;
  }
}
@media all and (max-width: 600px) {
  .press_the-release .pr-img {
    width: 50%;
  }
}
@media all and (max-width: 500px) {
  .press_the-release .pr-img {
    width: 100%;
  }
}
.press_the-release-box {
  width: 65%;
  margin-left: 5%;
}
@media all and (max-width: 700px) {
  .press_the-release-box {
    width: 100%;
    margin-left: 0;
    margin-top: 4rem;
  }
}
.press_the-release h4 {
  line-height: 1.2;
}
.press_the-release p {
  margin-bottom: 1rem;
}
.press_the-release_meta {
  margin-bottom: 2rem;
  font-size: 1.2rem;
}
.press_the-release_contact {
  margin-bottom: 3rem;
  margin-top: 2rem;
  display: block;
  width: 30rem;
  padding: 2rem 2rem;
  border: 1px solid #ededed;
  border-radius: 1rem;
  padding-left: 2rem;
}
.press_the-release_contact .strong {
  color: #444444;
}
.press_the-release_contact span {
  color: #b5b5b5;
}
.press_the-release_show-less {
  opacity: 0;
  height: 10px;
  overflow: hidden;
}
@media all and (max-width: 600px) {
  .press .static_section {
    padding: 0;
  }
}
@media all and (max-width: 600px) {
  .press .press_section-2 {
    margin-top: -2rem;
  }
}
.press .press_section-3 {
  margin-top: 6rem;
}
@media all and (max-width: 650px) {
  .press .press_section-3 {
    margin: 0;
    margin-top: 2rem;
  }
}
.press .brand-usage {
  display: block;
}

.small-businesses {
  padding: 0 10rem;
}
@media all and (max-width: 1250px) {
  .small-businesses {
    padding: 0 5rem;
  }
}
@media all and (max-width: 650px) {
  .small-businesses {
    padding: 0rem;
  }
}
.small-businesses_section-1 {
  text-align: center;
}
.small-businesses_section-1 p {
  max-width: 50rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.small-businesses_section-1 ul {
  margin-bottom: 2rem;
  border-top: 1px solid #444444;
  border-bottom: 1px solid #444444;
  display: inline-block;
  padding: 4rem;
}
@media all and (max-width: 600px) {
  .small-businesses_section-1 ul {
    padding: 2rem;
  }
}
.small-businesses_section-1 ul li {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.2;
}
.small-businesses_section-1 ul li:not(:last-child) {
  margin-bottom: 2rem;
}
@media all and (max-width: 900px) {
  .small-businesses_section-1 ul li {
    font-size: 2rem;
  }
}
@media all and (max-width: 760px) {
  .small-businesses_section-1 ul li {
    font-size: 1.6rem;
  }
}
@media all and (max-width: 650px) {
  .small-businesses_section-1 ul li {
    margin-bottom: 1rem;
    line-height: 1.4;
  }
}

.legal-text {
  padding: 4rem;
  text-align: left;
  background: #ffffff;
  border-radius: 1rem;
}
.legal-text .mgb-0 {
  margin-bottom: 0 !important;
}
.legal-text li {
  list-style-type: disc;
  margin-left: 20px;
}
.legal-text table {
  margin-bottom: 2rem;
}
.legal-text table tr td {
  padding: 10px;
  border: 1px solid #e7e7e7;
}
@media all and (max-width: 950px) {
  .legal-text {
    padding: 3rem;
  }
}
@media all and (max-width: 600px) {
  .legal-text {
    border-radius: 0;
  }
}
@media all and (max-width: 500px) {
  .legal-text {
    padding: 2rem;
  }
}
.legal-text p:not(:last-child) {
  margin-bottom: 2rem;
}

.super-admin-nav {
  margin-bottom: 3rem;
  display: flex;
}
@media (max-width: 1000px) {
  .super-admin-nav {
    width: 100%;
    flex-direction: column;
  }
}

.super-admin-filter {
  width: 60%;
  padding-right: 15px;
}
.super-admin-filter button {
  width: 25%;
  border-radius: 0;
  text-align: left;
  border-right: 1px solid #fff;
}
.super-admin-filter button:first-child {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.super-admin-filter button:last-child {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  border-right: none;
}
@media (max-width: 1000px) {
  .super-admin-filter button {
    width: 100%;
    text-align: center;
    margin: 3px 0;
    margin-bottom: 0;
  }
  .super-admin-filter button:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 0;
    border-top-right-radius: 20px;
  }
  .super-admin-filter button:last-child {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 0px;
  }
}
@media (max-width: 1000px) {
  .super-admin-filter {
    width: 100%;
    padding-right: 0;
  }
}

.super-admin-search {
  width: 40%;
}
.super-admin-search input {
  width: 85%;
  border: 1px solid #ededed;
  box-sizing: border-box;
  padding: 1rem 2rem 1rem 2rem;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.super-admin-search button {
  width: 15%;
  border-radius: 0;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}
@media (max-width: 500px) {
  .super-admin-search button {
    text-align: center;
    padding-left: 18px;
  }
}
@media (max-width: 1000px) {
  .super-admin-search {
    width: 100%;
    margin-top: 1rem;
    text-align: center;
  }
}

.super-admin-users-table tr td {
  transition: all 0.3s ease-out;
}

.super-admin-users-table tr:hover:not(:first-child) td {
  padding: 3rem 2rem;
}

.super-admin .admin-user-modal {
  min-width: unset !important;
  max-width: 90% !important;
}
.super-admin .admin-user-modal table {
  width: 100%;
  margin-top: 1rem;
}
.super-admin .admin-user-modal table tr:first-child td {
  font-weight: bold;
}
.super-admin .admin-user-modal table tr td {
  border: 1px solid #ededed;
  padding: 0.5rem;
}
.super-admin .admin-user-modal-header {
  margin-top: 2rem;
  border-bottom: 1px solid #ededed;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.super-admin .admin-user-modal-header h4 {
  text-transform: initial;
}
.super-admin .admin-user-modal-header h4 span {
  font-size: 1.4rem;
}
.super-admin .admin-user-modal-af-payment-requests {
  margin-bottom: 2rem;
}
.super-admin .admin-user-modal-footer {
  border-top: 1px solid #ededed;
  padding-top: 2rem;
  margin-top: 2rem;
}
.super-admin .admin-user-modal-footer button {
  margin: 1rem;
}

.advertising-page {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
}
@media all and (max-width: 600px) {
  .advertising-page {
    grid-gap: 4px;
  }
}
@media all and (max-width: 500px) {
  .advertising-page {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

.advertise-section {
  background: #fff;
  padding-bottom: 4rem;
  border-radius: 1rem;
  overflow: hidden;
}
@media all and (max-width: 600px) {
  .advertise-section {
    border-radius: 0;
    margin-bottom: 4px;
  }
}
.advertise-section-header {
  clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 0 100%);
  padding: 4rem 4rem 4rem 4rem;
}
.advertise-section-header h2 {
  margin-bottom: 2rem;
}
.advertise-section-header p {
  margin-bottom: 2rem;
  font-size: 1.5rem;
}
.advertise-section-header p.note {
  font-size: 1.3rem;
}
.advertise-section-header table {
  margin-bottom: 2rem;
}
.advertise-section-header table tr td {
  font-size: 1.5rem;
  border: 1px solid #ffffff;
  padding: 1rem;
}
.advertise-section-header table tr td:first-child {
  width: 8rem;
}
.advertise-section img {
  max-width: 918px;
  width: 90%;
  margin: 0 auto;
  display: block;
  border-radius: 1rem;
  border: 1px solid #e7e7e7;
  margin-top: 4rem;
}
@media all and (max-width: 1000px) {
  .advertise-section img {
    width: 90%;
  }
}
.advertise-section_featured .advertise-section-header {
  background: #45b1bf;
  background: linear-gradient(to right bottom, #45b1bf, #1ebbb5);
  color: #fff !important;
}
.advertise-section_sponsored .advertise-section-header {
  background: #45b1bf;
  background: linear-gradient(to right bottom, #45b1bf, #3b97d3);
  color: #fff !important;
}
.advertise-section_gppv .advertise-section-header {
  background: #45b1bf;
  background: linear-gradient(to right bottom, #45b1bf, #894b9d);
  color: #fff !important;
}

.advertise-pricing {
  background: #fff;
  border-radius: 1rem;
  padding: 4rem;
}
@media all and (max-width: 650px) {
  .advertise-pricing h2 {
    margin-bottom: 2rem !important;
  }
}
.advertise-pricing table {
  margin-bottom: 4rem;
  width: 100%;
}
@media all and (max-width: 650px) {
  .advertise-pricing table {
    margin-bottom: 2rem;
  }
}
.advertise-pricing table td {
  border: 1px solid #e7e7e7;
  padding: 1rem;
  font-size: 1.5rem;
}
@media all and (max-width: 370px) {
  .advertise-pricing table td {
    font-size: 1.3rem;
    line-height: 1;
    padding: 1.6rem 0.8rem;
  }
}
.advertise-pricing table td span {
  font-size: 1.1rem;
  color: #777;
}
.advertise-pricing table thead td {
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1.2;
  font-size: 1.2rem;
  padding-top: 3rem;
}
.advertise-pricing p {
  width: 100%;
}
.advertise-pricing p:not(:last-child) {
  margin-bottom: 1rem;
}
@media all and (max-width: 600px) {
  .advertise-pricing {
    border-radius: 0;
    padding: 2rem;
  }
}

.th-featured-bkg {
  background: #45b1bf;
  background: linear-gradient(to right bottom, #45b1bf, #1ebbb5);
  color: #fff !important;
}

.th-sponsored-bkg {
  background: #45b1bf;
  background: linear-gradient(to right bottom, #45b1bf, #3b97d3);
  color: #fff !important;
}

.category-list_item,
.media-gallery-category_listing {
  box-shadow: none;
  transition: all 0.3s ease-in-out;
  position: relative;
}
@media all and (max-width: 750px) {
  .category-list_item,
  .media-gallery-category_listing {
    box-shadow: none;
  }
}

.media-gallery-category_listing {
  width: 100% !important;
  margin-bottom: 0 !important;
}
.media-gallery-category_listing .photo-preview-img-header {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.photo-preview-img-header {
  display: block;
  height: 240px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.deals-was-price {
  font-size: 1.6rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
  text-decoration: line-through;
  color: #888;
}

.store-listing-preview_price {
  font-size: 2.4rem;
}

.coupon-code-box {
  text-align: center;
  display: inline-block;
}

.coupon-code-text-box {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-right: 2rem;
  color: #555;
}
@media all and (max-width: 350px) {
  .coupon-code-text-box {
    margin-right: 1rem;
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}
.coupon-code-text-box span {
  display: inline-block;
  font-weight: 600;
  font-size: 1.8rem;
}
@media all and (max-width: 350px) {
  .coupon-code-text-box span {
    font-size: 1.6rem;
  }
}

.deal-listing-page-box {
  display: block;
  margin-top: -3rem;
  margin-bottom: 4rem;
}
.deal-listing-page-box p {
  border: 1px dashed #e04c4c;
  padding: 1rem 1.4rem;
  display: inline-block;
  border-radius: 1rem;
}

.listing-coupon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #ee6565;
  position: relative;
  margin-top: 0rem;
  border-radius: 1rem;
  padding: 1rem 1rem 1rem 2rem;
  text-align: left;
  vertical-align: middle;
  margin-bottom: 4rem;
}
@media all and (max-width: 350px) {
  .listing-coupon {
    padding-left: 1rem;
  }
}
.listing-coupon:before {
  content: "\F0C4";
  font-family: Font Awesome\5 Pro;
  position: absolute;
  right: 2rem;
  top: -1.6rem;
  font-size: 2rem;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.listing-coupon-code {
  font-size: 2rem;
  line-height: 2rem;
}
.listing-coupon-code span {
  font-weight: 600;
}
.listing-coupon-terms {
  font-size: 1.1rem;
  margin-top: 0rem;
  color: #555;
}

.listing-coupon-divider {
  display: block;
  width: 100%;
}

.listing-main-code {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 0.6rem 1.5rem 0.4rem;
  border-radius: 0.5rem;
  text-transform: uppercase;
  color: #ee6565;
}

.cat-code {
  position: relative;
  overflow: hidden;
  display: inline-block;
  margin-top: 0.6rem;
  transform: rotate(0deg);
  margin-left: -1rem;
}
.cat-code .partial-coupon {
  padding: 1rem 2rem;
  min-width: 130px;
  text-align: right;
}
.cat-code .partial-coupon::after {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#f2f2f2),
    to(#f8f8f8)
  );
  background-image: -webkit-linear-gradient(bottom, #f2f2f2 0%, #f8f8f8 100%);
  background-image: -o-linear-gradient(bottom, #f2f2f2 0%, #f8f8f8 100%);
  background-image: linear-gradient(to top, #f2f2f2 0%, #f8f8f8 100%);
  top: -30px;
  left: -25px;
  transform: rotate(75deg);
  -webkit-box-shadow: 0px 0px 50px -14px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 0px 0px 50px -14px rgba(0, 0, 0, 0.49);
  box-shadow: 0px 0px 30px -14px rgba(0, 0, 0, 0.7);
  z-index: 5;
}
.cat-code .partial-coupon::before {
  content: "Coupon";
  position: absolute;
  z-index: 10;
  left: 12px;
  font-size: 12px;
  font-style: italic;
  color: #777;
}
.cat-code .partial-coupon img {
  position: absolute;
  right: -8px;
  top: -13px;
  z-index: 0;
  opacity: 0.5;
}
.cat-code .partial-coupon span {
  position: relative;
  z-index: 1;
}
.cat-code .partial-coupon span::before {
  content: "";
  display: block;
  width: 400px;
  height: 1px;
  position: absolute;
  border-top: 1px dashed #ee6565;
  z-index: 4;
  top: -6px;
  right: -16px;
}
.cat-code .partial-coupon span::after {
  content: "";
  display: block;
  width: 400px;
  height: 1px;
  position: absolute;
  border-top: 1px dashed #ee6565;
  z-index: 10;
  bottom: -6px;
  right: -15px;
}
.cat-code .coupon-whole {
  position: absolute;
  width: 7px;
  height: 7px;
  background: #fff;
  top: 4px;
  left: 4px;
  z-index: 99;
  border-radius: 50%;
  -webkit-box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.5);
}

.property-list-preview .cat-code,
.travel-item-preview .cat-code {
  margin-top: 1.6rem;
}

.ServiceContent__Details {
  position: relative;
}

.service-cost {
  font-size: 2.8rem;
  font-weight: 300;
}
.service-cost-lt {
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: line-through;
  margin-right: 0.6rem;
  margin-left: 0.6rem;
  display: inline-block;
  color: #999;
}

.main-sidebar_box_sponsored .blog-item-preview_title h3 {
  height: auto;
}

.featured-photo-gallery {
  padding-bottom: 0;
}
@media all and (max-width: 950px) {
  .featured-photo-gallery {
    margin: 0 !important;
  }
}
.featured-photo-gallery_image {
  display: block;
  height: 26rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.featured-photo-gallery_image img {
  display: block;
  margin-bottom: 0;
}
.featured-photo-gallery_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 26rem;
  position: absolute;
  overflow: hidden;
  border-radius: 1rem;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff !important;
  text-align: center;
  transition: all 0.3s ease-out;
}
.featured-photo-gallery_details span {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-photo-gallery_details h3 {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-photo-gallery_details:hover {
  background: rgba(0, 0, 0, 0);
}
.featured-photo-gallery_details:hover span {
  transform: translateX(-300px);
  opacity: 0;
}
.featured-photo-gallery_details:hover h3 {
  transform: translateX(300px);
  opacity: 0;
}

.featured-video-gallery {
  padding-bottom: 0;
}
@media all and (max-width: 950px) {
  .featured-video-gallery {
    margin: 0 !important;
  }
}
.featured-video-gallery_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 24.3rem;
  position: absolute;
  overflow: hidden;
  border-radius: 1rem;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff !important;
  text-align: center;
  transition: all 0.3s ease-out;
}
@media all and (max-width: 530px) {
  .featured-video-gallery_details {
    height: 88%;
  }
}
@media all and (max-width: 410px) {
  .featured-video-gallery_details {
    height: 88%;
  }
}
@media all and (max-width: 330px) {
  .featured-video-gallery_details {
    height: 86%;
  }
}
.featured-video-gallery_details span {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-video-gallery_details h3 {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-video-gallery_details:hover {
  background: rgba(0, 0, 0, 0);
}
.featured-video-gallery_details:hover span {
  transform: translateX(-300px);
  opacity: 0;
}
.featured-video-gallery_details:hover h3 {
  transform: translateX(300px);
  opacity: 0;
}

.featured-groups {
  padding-bottom: 0;
}
@media all and (max-width: 950px) {
  .featured-groups {
    margin: 0 !important;
  }
}
.featured-groups_image {
  display: block;
  height: 26rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.featured-groups_image img {
  display: block;
  margin-bottom: 0;
}
.featured-groups_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 26rem;
  position: absolute;
  overflow: hidden;
  border-radius: 1rem;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff !important;
  text-align: center;
  transition: all 0.3s ease-out;
}
.featured-groups_details span {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-groups_details h3 {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-groups_details:hover {
  background: rgba(0, 0, 0, 0);
}
.featured-groups_details:hover span {
  transform: translateX(-300px);
  opacity: 0;
}
.featured-groups_details:hover h3 {
  transform: translateX(300px);
  opacity: 0;
}

.featured-event {
  padding-bottom: 0;
}
@media all and (max-width: 950px) {
  .featured-event {
    margin: 0 !important;
  }
}
.featured-event_image img {
  margin-bottom: 0px;
}
.featured-event_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 27.6rem;
  position: absolute;
  overflow: hidden;
  border-radius: 1rem;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff !important;
  text-align: center;
  transition: all 0.3s ease-out;
}
@media all and (max-width: 530px) {
  .featured-event_details {
    height: 90%;
  }
}
@media all and (max-width: 410px) {
  .featured-event_details {
    height: 88%;
  }
}
@media all and (max-width: 330px) {
  .featured-event_details {
    height: 87%;
  }
}
.featured-event_details a {
  color: #fff !important;
}
.featured-event_details_box {
  display: flex;
}
.featured-event_details_title {
  color: #ffffff;
  display: block;
  overflow: hidden;
  height: 3.6rem;
  margin-bottom: 2rem;
  transform: translateY(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-event_details_info {
  margin-bottom: 1rem;
  text-align: left;
  display: block;
}
.featured-event_details_info:not(:last-child) {
  margin-right: 3rem;
}
.featured-event_details_info i {
  font-size: 2rem;
  margin-right: 1rem;
  display: inline-block;
  vertical-align: top;
}
.featured-event_details_info span {
  display: inline-block;
  text-transform: uppercase;
  line-height: 1.3;
}
.featured-event_details .featured-event_details_info:first-child {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-event_details .featured-event_details_info:last-child {
  transform: translateX(0px);
  transition: all 0.3s ease-out;
  opacity: 1;
}
.featured-event_details:hover {
  background: rgba(0, 0, 0, 0);
}
.featured-event_details:hover .featured-event_details_title {
  transform: translateY(-200px);
  transition: all 0.3s ease-out;
  opacity: 0;
}
.featured-event_details:hover .featured-event_details_info:first-child {
  transform: translateX(-200px);
  transition: all 0.3s ease-out;
  opacity: 0;
}
.featured-event_details:hover .featured-event_details_info:last-child {
  transform: translateX(200px);
  transition: all 0.3s ease-out;
  opacity: 0;
}

.featured-store-listing {
  padding-bottom: 0;
  overflow: hidden;
}
@media all and (max-width: 950px) {
  .featured-store-listing {
    margin: 0 !important;
  }
}
.featured-store-listing_image {
  height: 27.6rem;
  overflow: hidden;
  display: block;
}
.featured-store-listing_image img {
  display: block;
  margin-bottom: 0;
  transform: scale(1);
  transition: all 0.3s ease;
}
.featured-store-listing:hover img {
  transform: scale(1.1);
}
.featured-store-listing_details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 27.6rem;
  position: absolute;
  overflow: hidden;
  border-radius: 1rem;
  opacity: 1;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #fff !important;
  text-align: center;
  transition: all 0.3s ease-out;
}
@media all and (max-width: 530px) {
  .featured-store-listing_details {
    height: 90%;
  }
}
@media all and (max-width: 410px) {
  .featured-store-listing_details {
    height: 88%;
  }
}
@media all and (max-width: 330px) {
  .featured-store-listing_details {
    height: 87%;
  }
}
.featured-store-listing_details a {
  color: #fff !important;
}
.featured-store-listing_details h3 {
  display: block;
  overflow: hidden;
  height: 34px;
  width: 90%;
  margin: 0 auto;
}
.featured-store-listing_details_price {
  margin-top: 0.6rem;
  font-size: 2.5rem;
  font-weight: 300;
}
.featured-store-listing_details_category {
  text-transform: uppercase;
  font-weight: bold;
}
.featured-store-listing_details_specs {
  margin-top: 0.8rem;
}
.featured-store-listing_details .engage_buttons_rating .fa-star {
  color: #ffffff !important;
  font-size: 2rem !important;
  margin-top: 1rem;
}
.featured-store-listing_details .engage_buttons_rating_number {
  display: none !important;
}
.featured-store-listing_details:hover {
  background: rgba(0, 0, 0, 0);
  opacity: 0;
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
@media all and (max-width: 700px) {
  .Backdrop {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.engageButton {
  padding: 0;
  border: 0;
  margin: 0 0.8rem;
  font-size: 1.2rem;
  color: #777;
}
.engageButton i {
  font-size: 2.5rem;
  vertical-align: middle;
  margin-left: 0.3rem;
}

.Comments i {
  font-size: 3.2rem;
}

.Views i {
  font-size: 3.7rem;
}

.StarsRating i {
  color: #444444;
}

.StarsRating .fas {
  display: inline-block;
}

.StarsRating:hover .fal {
  display: inline-block;
}

.StarsRating .fal,
.StarsRating .fas {
  color: #469ad4;
}

.fa-heart.light {
  color: #ccc;
}

.fa-bookmark.light {
  color: #ccc;
}

.fa-heart.red {
  color: #ee6565 !important;
}

.fa-bookmark.green {
  color: #6ec568 !important;
}

.shareBoxWrapper {
  display: inline-block;
}

.engage_comments {
  width: 100%;
  margin: auto;
  margin-top: 4rem;
}
.engage_comments h3 {
  text-align: center;
}

.engage_the-comment {
  margin-top: 2rem;
}
@media all and (max-width: 580px) {
  .engage_the-comment {
    margin-top: 4rem;
  }
}
.engage_the-comment_content {
  display: inline-block;
  width: 100%;
  position: relative;
  background: #fff;
  overflow: hidden;
  border: 1px solid #ededed;
  border-radius: 1rem;
  overflow: hidden;
}
.engage_the-comment_content_user {
  border-bottom: 1px solid #ededed;
  padding: 1rem;
  position: relative;
}
.engage_the-comment_content_user img {
  width: 4rem;
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 1rem;
}
.engage_the-comment_content_user span {
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 700;
}
.engage_the-comment_content_user button {
  position: absolute;
  font-size: 2rem !important;
  right: 1.8rem;
  top: 1.6rem;
}
.engage_the-comment_content_the-comment {
  padding: 1rem;
}
.engage_the-comment_content_the-comment textarea {
  border: 0;
  width: 100%;
  height: 5rem;
}
.engage_the-comment_content_engage {
  border-top: 1px solid #ededed;
  padding: 0.8rem 2rem 1rem 2rem;
}
.engage_the-comment_content_engage .left button {
  border: 0;
  padding: 0;
  font-size: 1.1rem;
  margin-right: 1.6rem;
}
.engage_the-comment_content_engage .left button i {
  font-size: 1.6rem;
  vertical-align: middle;
  color: #777;
  margin-right: 0.4rem;
}
.engage_the-comment_content_engage .right {
  font-size: 1.1rem;
  color: #777;
  padding-top: 0.3rem;
}
.engage_the-comment_content_engage .right i {
  font-size: 1.6rem;
  vertical-align: middle;
  color: #777;
  margin-right: 0.4rem;
}
.engage_the-comment_content_engage-send {
  background: #469ad4;
}
.engage_the-comment_content_engage-send * {
  color: #fff;
}
.engage_the-comment_content_engage-send .left button i,
.engage_the-comment_content_engage-send .right button i {
  color: #fff;
}
.engage_the-comment_content_arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0.8rem 1.4rem 0.8rem 0;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  left: -1.4rem;
  top: 2.3rem;
}
.engage_the-comment_reply {
  margin-left: 2rem;
}
@media all and (max-width: 400px) {
  .engage_the-comment_reply {
    margin-left: 0rem;
  }
}
.engage_the-comment_reply .engage_the-comment_content_user img {
  width: 3rem;
}

.write-review-box {
  margin-bottom: 4rem;
}
.write-review-box .form-field-error {
  text-align: left;
}

.theReview {
  margin-bottom: 2rem;
  border: 1px solid #ededed;
  border-radius: 1rem;
}
.theReviewRating {
  border-bottom: 1px solid #ededed;
  padding: 1rem;
}
.theReviewText {
  padding: 1rem;
}
.theReviewMeta {
  border-top: 1px solid #ededed;
  padding: 1rem;
}

.engage_button_numbers {
  display: inline-block;
  margin-left: 0rem;
  margin-right: 0rem;
  width: 2.2rem;
  font-size: 1.1rem !important;
  text-align: left !important;
}

.engage_buttons {
  display: flex;
  justify-content: flex-start;
  height: 3rem;
}

.engage_buttons_love,
.engage_buttons_comments,
.engage_buttons_views,
.engage_buttons_share,
.engage_buttons_share_button,
.engage_buttons_favorites {
  display: flex;
  align-items: center;
}
.engage_buttons_love i,
.engage_buttons_comments i,
.engage_buttons_views i,
.engage_buttons_share i,
.engage_buttons_share_button i,
.engage_buttons_favorites i {
  margin-right: 0.5rem;
}

.engage_buttons_rating {
  display: flex;
  align-items: center;
}
.engage_buttons_rating .engage_buttons_rating_number {
  font-size: 1.1rem !important;
  margin-left: 0.6rem !important;
  width: 3rem;
  margin-right: 0.6rem;
  display: inline-block;
  text-align: left !important;
  margin-top: 0 !important;
}
.engage_buttons_rating i.fa-star {
  font-size: 1.6rem !important;
}

.engage_buttons span {
  color: #777;
}

.engage_the-comment_content_user button {
  font-size: 1.6rem;
}

.blog-post .blog-post_feature .engage_buttons i,
.MediaGalleryMeta .engage_buttons i,
.video-listing_details_comments .engage_buttons i {
  font-size: 2.1rem !important;
  margin-right: 0.8rem;
}

.blog-post .blog-post_feature .engage_buttons span.engage_button_numbers,
.MediaGalleryMeta .engage_buttons span.engage_button_numbers,
.video-listing_details_comments .engage_buttons span.engage_button_numbers {
  font-size: 1.3rem !important;
  width: 2.2rem !important;
  margin-right: 0 !important;
}

@media all and (max-width: 650px) {
  .blog-post_feature .engage_buttons {
    padding-left: 2rem !important;
  }
}

@media all and (max-width: 400px) {
  .blog-post .blog-post_feature .engage_buttons span.engage_button_numbers,
  .MediaGalleryMeta .engage_buttons span.engage_button_numbers,
  .video-listing_details_comments .engage_buttons span.engage_button_numbers,
  .engage_button_numbers {
    width: auto !important;
  }
}

.error-message {
  border: 1px solid #ededed;
  padding: 1.2rem;
  display: block;
  font-size: 1.2rem;
  padding-left: 6rem;
  max-width: 32rem;
  border-radius: 1rem;
  position: relative;
  margin: 2rem auto;
  background: #fff;
}
.error-message-strong {
  display: block;
}
.error-message-info::after,
.error-message-alert::after {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 2.6rem;
  left: 1.6rem;
  position: absolute;
  top: 1.6rem;
}
.error-message-info::after {
  content: "\F05a";
  color: #469ad4;
}
.error-message-alert::after {
  content: "\f071";
  color: #ee6565;
}

.form-field-error {
  text-align: center;
  font-size: 1.2rem;
  padding: 0.4rem;
  color: #ee6565;
}

.optionExbandableBox {
  max-width: 40rem;
  width: 100%;
  overflow: hidden;
  border: 1px solid #ededed;
  border-bottom: 0;
}
.optionExbandableBox:first-child {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.optionExbandableBox:last-child {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border: 1px solid #ededed;
}
.optionExbandableBox button {
  width: 100%;
  border: 0;
  background: #469ad4;
  color: #ffffff;
  display: block;
  padding: 0.8rem 1.2rem;
  text-transform: uppercase;
  text-align: left;
  margin-top: 0 !important;
}
.optionExbandableBox button i {
  font-size: 1.8rem;
  margin-right: 0.8rem;
  vertical-align: bottom;
  transform: scaleY(-1);
}
.optionExbandableBoxContent {
  padding: 2rem 2.4rem;
  position: relative;
  opacity: 1;
  transition: all 0.7s ease-in-out;
  height: 20rem;
}
.optionExbandableBoxContent .arrowdown {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 1rem 0.7rem 0 0.7rem;
  border-color: #469ad4 transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.closedOptionBox button {
  background: #f8f8f8;
  color: #444444;
}
.closedOptionBox button:hover {
  background: #469ad4;
  color: #fff;
}
.closedOptionBox button i {
  transform: scaleY(1);
}
.closedOptionBox button:hover i {
  transform: scaleY(-1);
}

.closedOptionBox .optionExbandableBoxContent {
  opacity: 0;
  transition: all 0.7s ease-in-out;
  height: 0;
  margin-bottom: -4rem;
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  z-index: 500;
  background-color: #ffffff;
  border-radius: 1rem;
  transition: all 0.5s ease-in-out;
  -webkit-font-smoothing: subpixel-antialiased;
  max-width: 40rem;
  min-width: 34rem;
  overflow: hidden;
  position: relative;
}
@media all and (max-width: 350px) {
  .modal {
    min-width: 31rem;
    max-height: 80vh;
    overflow-y: scroll;
  }
}
.modal-medium {
  width: 100%;
  max-height: 80vh;
  max-width: 100rem !important;
  min-width: 31rem !important;
}
@media all and (max-height: 600px) {
  .modal-medium {
    overflow-y: scroll;
  }
}
@media all and (max-width: 1000px) {
  .modal-medium {
    max-width: 100%;
    width: 90%;
    overflow-y: scroll;
  }
}
.modal_wide {
  width: 90%;
  max-width: 90%;
  min-width: 32rem;
  height: 90vh;
  background: #f8f8f8;
}
@media all and (max-width: 980px) {
  .modal_wide {
    overflow-y: scroll;
    height: 80vh;
  }
}
@media all and (max-width: 700px) {
  .modal_wide {
    overflow-y: scroll;
    height: 75vh;
  }
}
.modal_wide .modal_close {
  top: 1rem;
  right: 2.4rem;
  background: #fff;
  border-radius: 5rem;
  padding: 0.5rem 0.9rem;
  color: #444;
}
.modal_breadcrumb {
  font-size: 1.1rem;
  text-align: left;
  color: #b5b5b5;
  padding: 0.9rem 1.6rem;
  border-bottom: 1px solid #ededed;
}
.modal_breadcrumb span {
  color: #469ad4;
}
.modal_body {
  text-align: center;
  padding: 2rem;
  position: relative;
}
.modal_body h4 {
  margin-bottom: 0rem;
}
.modal_body_total-cost {
  margin-top: 2rem;
  display: inline-block;
  margin-bottom: 2rem;
  font-size: 1.6rem;
  border: 1px solid #ededed;
  padding: 1rem 2rem;
}
.modal_body_time-frame {
  display: flex;
  justify-content: space-between;
}
.modal_body_time-frame label {
  font-size: 1.1rem;
  text-align: left !important;
  margin-top: 1rem;
  display: block;
}
.modal_body_time-frame span {
  display: block;
  width: 46%;
}
.modal_body_functions {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: block;
  width: 100%;
}
.modal_body_functions input[type="email"],
.modal_body_functions input[type="password"],
.modal_body_functions input[type="date"],
.modal_body_functions input[type="text"] {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
}
.modal_body_functions input[type="email"]:focus,
.modal_body_functions input[type="password"]:focus,
.modal_body_functions input[type="date"]:focus,
.modal_body_functions input[type="text"]:focus {
  border: 1px solid #999 !important;
}
.modal_body_functions_agree-terms {
  margin: 1.6rem 0;
}
.modal_body_functions_agree-terms label {
  font-size: 1.2rem;
}
.modal_body_functions button:not(.button-regular) {
  color: #469ad4;
  font-size: 1.2rem;
}
.modal_body_functions_account-type {
  width: 14rem;
  text-align: left;
  margin-top: 1.6rem;
}
.modal_body_functions_advertise-step-1 {
  width: 14rem;
  text-align: left;
  margin-top: 1.6rem;
}
.modal_body_functions_welcome-modal {
  margin-top: 1.6rem;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 22rem;
}
.modal_body_functions_welcome-modal_icon {
  border: 1px solid #469ad4;
  border-radius: 1rem;
  width: 100px;
  height: 100px;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1rem;
}
.modal_body_functions_welcome-modal_icon:hover {
  background-color: #469ad4;
  color: #ffffff;
}
.modal_body_functions_welcome-modal_icon i {
  font-size: 3rem;
}
.modal_body_functions_welcome-modal_icon span {
  font-size: 1.2rem;
  display: block;
  margin-top: 0.8rem;
}
.modal_body_functions_log-in {
  margin-bottom: 0;
}
.modal_body_functions .birthdate-label,
.modal_body_functions .gender-label {
  text-align: left;
  display: inline-block !important;
  color: #b5b5b5;
  font-size: 1.1rem;
  padding-left: 1.6rem;
  padding-bottom: 1.6rem;
  padding-top: 1.6rem;
  border-bottom: 1px solid #ededed;
  width: 50%;
}
.modal_body_functions .birthdate-input {
  width: 50% !important;
  display: inline-block !important;
}
.modal_body_functions .gender-label-female,
.modal_body_functions .gender-label-male {
  width: 25% !important;
  display: inline-block !important;
  border-bottom: 1px solid #ededed;
  padding-bottom: 1.5rem;
  margin: 0;
}
.modal_body_functions .gender-label-female label,
.modal_body_functions .gender-label-male label {
  font-size: 1.2rem;
}
.modal_body_buttons_next,
.modal_body_buttons_back {
  position: absolute;
  display: block;
  bottom: 2rem;
  color: #469ad4;
  font-size: 1.2rem;
  cursor: pointer;
  line-height: 1.2rem;
}
.modal_body_buttons_next:hover,
.modal_body_buttons_back:hover {
  color: #425caf;
}
.modal_body_buttons_next {
  right: 2rem;
}
.modal_body_buttons_back {
  left: 2rem;
}
.modal_close {
  position: absolute;
  display: block;
  top: 0rem;
  right: 0rem;
  padding: 0.2rem 1rem;
  font-size: 2.2rem;
  line-height: 3rem;
  cursor: pointer;
  color: #469ad4;
}
.modal_close:hover {
  color: #425caf;
}

.media-modal {
  display: flex;
  align-items: stretch;
  height: 100%;
}
@media all and (max-width: 980px) {
  .media-modal {
    display: block;
    height: auto;
  }
}
.media-modal_box {
  width: 75%;
  height: 100%;
  background: #000000;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1600px) {
  .media-modal_box {
    width: 70%;
  }
}
@media all and (max-width: 1250px) {
  .media-modal_box {
    width: 60%;
  }
}
@media all and (max-width: 980px) {
  .media-modal_box {
    width: 100%;
    background: #fff !important;
    display: block;
  }
}
.media-modal_box_video {
  width: 100%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  display: block;
}
.media-modal_box_video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.media-modal_box_image {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 980px) {
  .media-modal_box_image {
    width: 100%;
    height: auto;
    display: block;
  }
}
.media-modal_box_image img {
  max-height: 100%;
  max-width: 100%;
}
@media all and (max-width: 980px) {
  .media-modal_box_image img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.media-modal_box_image button {
  position: absolute;
  top: 0;
  z-index: 999999999;
  color: #fff;
  font-size: 7rem;
  height: 100%;
  display: none;
}
.media-modal_box_image button:hover {
  color: #469ad4;
}
@media all and (max-width: 800px) {
  .media-modal_box_image button {
    display: block;
  }
}
.media-modal_box_image:hover button {
  display: block;
}
.media-modal_box_image_left-arrow {
  left: 0rem;
  padding-left: 2rem;
  padding-right: 4rem;
}
.media-modal_box_image_right-arrow {
  right: 0rem;
  padding-left: 4rem;
  padding-right: 2rem;
}
.media-modal_desc {
  width: 25%;
  background: #f8f8f8;
  padding: 0;
  overflow-y: scroll;
  position: relative;
}
@media all and (max-width: 1600px) {
  .media-modal_desc {
    width: 30%;
  }
}
@media all and (max-width: 1250px) {
  .media-modal_desc {
    width: 40%;
  }
}
@media all and (max-width: 980px) {
  .media-modal_desc {
    width: 100%;
  }
}
.media-modal_desc_author {
  background: #fff;
  padding: 20px 20px 0 20px;
  display: flex;
  align-items: center;
}
.media-modal_desc_author img {
  display: block;
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 50%;
  margin-right: 1.6rem;
}
.media-modal_desc_author h3 {
  display: block;
  line-height: 1.2;
}
.media-modal_desc_title {
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0 !important;
}
.media-modal_desc_text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 0 !important;
  overflow: hidden;
}
.media-modal_desc_text:before {
  content: "Description";
  display: block;
  margin-bottom: 8px;
  font-size: 12px;
  text-transform: uppercase;
}
.media-modal_desc .engage_buttons {
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
}
.media-modal_desc .engage_buttons .engage_buttons_favorites {
  margin-right: 0;
}
.media-modal_desc .engage_comments {
  width: 100%;
  margin-top: 0;
}
.media-modal_desc .engage_comments div:last-child {
  margin-bottom: 10px;
}
.media-modal_desc .engage_comments .engage_the-comment {
  margin-top: 0rem;
}
.media-modal_desc .engage_comments .engage_the-comment_user {
  width: 15%;
  margin-right: 3%;
}
.media-modal_desc .engage_comments .engage_the-comment_content {
  width: 100%;
  max-width: 100%;
  border-radius: 0;
}
.media-modal_desc
  .engage_comments
  .engage_the-comment_content
  .left
  button:last-child {
  margin-right: 0 !important;
}
@media all and (max-width: 980px) {
  .media-modal_desc .engage_comments .engage_the-comment_content {
    max-width: 95% !important;
    margin-left: 2.5% !important;
  }
}
.media-modal_desc .xButton {
  position: absolute;
  display: block;
  top: 1.5rem;
  right: 2rem;
  font-size: 3rem;
  line-height: 3rem;
  cursor: pointer;
  color: #469ad4;
}
.media-modal_desc .xButton:hover {
  color: #425caf;
}
.media-modal_crop {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: stretch;
}
.media-modal_crop .media-modal_box {
  width: 100%;
  height: 90%;
  overflow: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 1600px) {
  .media-modal_crop .media-modal_box {
    width: 100%;
  }
}
@media all and (max-width: 1250px) {
  .media-modal_crop .media-modal_box {
    width: 100%;
  }
}
@media all and (max-width: 980px) {
  .media-modal_crop .media-modal_box {
    width: 100%;
  }
}
.media-modal_crop .media-modal_desc {
  width: 100%;
  height: 15%;
  background: #ffffff;
  padding: 2rem;
  overflow-y: initial;
  display: flex;
  align-items: center;
}
@media all and (max-width: 980px) {
  .media-modal_crop .media-modal_desc {
    position: fixed !important;
    bottom: 0px !important;
    height: 110px !important;
  }
}
@media all and (max-width: 920px) {
  .media-modal_crop .media-modal_desc {
    display: block;
    padding: 0;
  }
}
.media-modal_crop .media-modal_desc_crop-image {
  margin-right: 2rem;
}
.media-modal_crop .media-modal_desc_crop-image input {
  width: auto;
  border: 1px solid #469ad4;
  text-align: center;
  padding: 1rem;
  border: 1px solid #469ad4;
  text-transform: uppercase;
  border-radius: 5rem;
  cursor: pointer;
  padding: 1.2rem 3.2rem;
  font-size: 1.3rem;
}
.media-modal_crop .media-modal_desc_crop-image input:hover {
  background: #469ad4;
  color: #ffffff;
}
@media all and (max-width: 700px) {
  .media-modal_crop .media-modal_desc_crop-image input {
    width: 20rem;
  }
}
@media all and (max-width: 470px) {
  .media-modal_crop .media-modal_desc_crop-image input {
    width: 14rem;
  }
}
@media all and (max-width: 920px) {
  .media-modal_crop .media-modal_desc_crop-image {
    width: 50%;
    border-bottom: 1px solid #ededed;
    padding: 2rem;
    display: inline-block;
    margin-right: 0;
  }
}
.media-modal_crop .media-modal_desc .crop-modal-mobile-button {
  margin-right: 2rem;
}
.media-modal_crop .media-modal_desc .crop-modal-mobile-button button {
  padding: 1.5rem 2rem;
}
@media all and (max-width: 920px) {
  .media-modal_crop .media-modal_desc .crop-modal-mobile-button {
    width: 50%;
    border-bottom: 1px solid #ededed;
    padding: 0rem;
    display: inline-block;
    margin-right: 0;
  }
  .media-modal_crop .media-modal_desc .crop-modal-mobile-button button {
    padding: 1.5rem 2rem;
  }
}
.media-modal_crop .media-modal_desc_crop-desc {
  margin-right: 2.2rem;
}
@media all and (max-width: 920px) {
  .media-modal_crop .media-modal_desc_crop-desc {
    display: inline-block;
    border-bottom: 1px solid #ededed;
    width: 50%;
    text-align: center;
    padding: 1rem;
    margin: 0;
  }
}
@media all and (max-width: 550px) {
  .media-modal_crop .media-modal_desc_crop-desc {
    width: 100%;
    text-align: left;
    padding: 1rem 2rem 1rem 2.7rem;
  }
}
@media all and (max-width: 920px) {
  .media-modal_crop .media-modal_desc button {
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
}

.modal-crop .modal_close {
  top: 1rem;
  right: 1rem;
}

@media all and (max-width: 1200px) {
  .modal-crop .media-modal_desc {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media all and (max-width: 920px) {
  .modal-crop .media-modal_desc {
    display: flex;
    padding: 2rem;
  }
}

@media all and (max-width: 980px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image,
  .modal-crop .media-modal_desc .crop-modal-mobile-button {
    margin-bottom: 0.5rem !important;
  }
}

.modal-crop .media-modal_desc .media-modal_desc_crop-image button,
.modal-crop .media-modal_desc .crop-modal-mobile-button button {
  padding: 1.4rem 2rem;
  display: flex;
  align-items: center;
}
@media all and (max-width: 850px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image button,
  .modal-crop .media-modal_desc .crop-modal-mobile-button button {
    border-radius: 100%;
    padding: 1.4rem 1.5rem;
  }
}
.modal-crop .media-modal_desc .media-modal_desc_crop-image button i,
.modal-crop .media-modal_desc .crop-modal-mobile-button button i {
  padding-right: 1rem;
  font-size: 1.8rem;
}
@media all and (max-width: 850px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image button i,
  .modal-crop .media-modal_desc .crop-modal-mobile-button button i {
    padding-right: 0rem;
  }
}
@media all and (max-width: 850px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image button span,
  .modal-crop .media-modal_desc .crop-modal-mobile-button button span {
    display: none;
  }
}

@media all and (max-width: 980px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image,
  .modal-crop .media-modal_desc .crop-modal-mobile-button {
    margin-bottom: 1.6rem;
  }
}

@media all and (max-width: 920px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image,
  .modal-crop .media-modal_desc .crop-modal-mobile-button {
    width: auto !important;
    border-bottom: 0px solid #ededed;
    padding: 0rem;
  }
  .modal-crop .media-modal_desc .media-modal_desc_crop-image button,
  .modal-crop .media-modal_desc .crop-modal-mobile-button button {
    margin: 0 !important;
    margin-left: 1rem !important;
  }
}

@media all and (max-width: 1200px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-desc {
    width: 100%;
    text-align: center;
  }
}

@media all and (max-width: 920px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-desc {
    border-bottom: 0px solid #ededed;
    padding: 0rem;
  }
}

.modal-crop .media-modal_desc .media-modal_desc_crop-desc span {
  display: inline-block;
}
.modal-crop .media-modal_desc .media-modal_desc_crop-desc span:first-child {
  padding-right: 2rem;
}

.ModalPreview {
  padding: 4rem 12rem;
}
.ModalPreview h4 {
  margin-bottom: 1rem;
}
.ModalPreview h4:not(:first-child) {
  margin-top: 4rem;
}
.ModalPreview .ModalPreviewBox {
  max-width: 40rem;
  border: 1px solid #ededed;
  border-radius: 1rem;
}

.shareBoxWrapper {
  position: relative;
}

.ShareModal {
  border: 1px solid #ededed;
  display: block;
  padding: 1rem 2rem;
  border-radius: 1rem;
  position: absolute;
  top: -80px;
  width: 250px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.19);
}
.ShareModal button {
  font-size: 3rem;
  color: #b5b5b5;
}
.ShareModal button:hover {
  color: #469ad4;
}
.ShareModal button:not(:last-child) {
  margin-right: 2rem;
}
.ShareModal button .fo-followone {
  font-weight: 700;
}
.ShareModal:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 14px 10px 0 10px;
  border-color: #ededed transparent transparent transparent;
  position: absolute;
  left: 2rem;
  bottom: -1.4rem;
}

.payment-field-box label {
  text-align: left;
  display: block;
  margin-bottom: 1rem;
}

.payment-field {
  border-bottom: 1px solid #ededed;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
}

.step-four_thank-you h4 {
  margin-bottom: 1rem;
}

.step-four_thank-you p span {
  font-size: 2.4rem;
}

.step-four_thank-you button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal_body_functions_agree-terms label span i {
  border: 1px solid #ededed;
  color: #fff;
  padding: 0.5rem !important;
  border-radius: 0.3rem !important;
  width: auto !important;
  height: auto !important;
}

.listing-media-modal {
  display: block;
}
.listing-media-modal .media-modal_box {
  width: 100%;
}

@media all and (max-width: 980px) {
  .listing-modal_wide {
    height: auto;
    max-height: 100vh !important;
  }
}

@media all and (max-width: 700px) {
  .listing-modal_wide {
    width: 100%;
    max-width: 100%;
    border-radius: 0 !important;
  }
  .listing-modal_wide .modal_close {
    top: 2.4rem !important;
    padding: 0.8rem 1.2rem !important;
  }
}

.cookie-modal {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem 2rem;
  background: #fff;
  text-align: center;
  left: 0;
  display: block;
  -webkit-box-shadow: 0px 0px 33px -2px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 0px 33px -2px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 0px 33px -2px rgba(0, 0, 0, 0.39);
}
.cookie-modal span {
  padding: 0 2rem;
}
@media all and (max-width: 600px) {
  .cookie-modal span {
    display: block;
    margin-bottom: 1rem;
  }
}
.cookie-modal i {
  font-size: 2.4rem;
  vertical-align: middle;
}
@media all and (max-width: 700px) {
  .cookie-modal i {
    display: none;
  }
}
.cookie-modal .button-text {
  padding-left: 1rem;
  text-transform: capitalize;
}

.account-info-row {
  display: flex;
  justify-content: space-start;
  border-bottom: 1px solid #ededed;
  align-items: center;
  padding: 0.5rem 1.5rem;
}
.account-info-row label {
  margin-right: 1rem;
  color: #b5b5b5;
  font-size: 1.3rem;
}
.account-info-row input[type="radio"] + label i {
  border: 1px solid #ccc;
  border-radius: 100%;
  width: 2.6rem;
  height: 2.6rem;
  padding: 0.5rem 0 0 0.1rem;
  vertical-align: middle;
}
.account-info-row #enterBirthDate {
  padding: 0.8rem 0;
  border-bottom: 0px solid #fff;
}

.site-sign-in {
  padding: 0;
  width: 100%;
}
.site-sign-in h4 {
  margin-bottom: 1rem;
}
.site-sign-in button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.site-sign-in .button-regular {
  margin-right: 1rem;
}
.site-sign-in .forgot-password {
  font-size: 1.1rem !important;
  margin-top: 0.5rem;
  display: inline-block;
  color: #555;
}
.site-sign-in .txtr {
  text-align: right;
}
.site-sign-in .modal_body_functions {
  padding: 2rem;
  padding-top: 1rem;
}
.site-sign-in-header {
  background: #469ad4;
  background: -webkit-linear-gradient(to right bottom, #ed6ea0, #ec8c69);
  background: -webkit-gradient(
    linear,
    right top,
    right bottom,
    from(#ed6ea0),
    to(#ec8c69)
  );
  background: -webkit-linear-gradient(to right bottom, #ed6ea0, #ec8c69);
  background: -o-linear-gradient(to right bottom, #ed6ea0, #ec8c69);
  background: linear-gradient(to right bottom, #ed6ea0, #ec8c69);
  color: #f8f8f8;
  padding: 2rem 0 0 0;
  text-align: left;
  position: relative;
}
.site-sign-in-header-iconlist {
  text-align: center;
  margin-top: 2rem;
  position: relative;
  z-index: 1;
}
.site-sign-in-header-iconlist i {
  font-size: 1.6rem;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  color: #f8f8f8;
}
.site-sign-in-header-title {
  font-size: 2.4rem;
  font-weight: 300;
  line-height: 1.3;
  padding: 0 3rem;
  position: relative;
  z-index: 1;
  color: #f8f8f8;
}
.site-sign-in-header-cta {
  padding: 0 3rem;
  line-height: 1.4;
  font-size: 1.3rem;
  margin-top: 1rem;
  position: relative;
  z-index: 1;
  color: #f8f8f8;
}
.site-sign-in-2ndcta {
  color: #999;
  text-align: left;
  padding-top: 2rem;
  padding-left: 3.5rem;
}

.PostSomethingNew {
  width: 100%;
  padding: 2rem 2rem 1.2rem 2rem;
  background: #f8f8f8;
  border-radius: 1rem;
  margin-bottom: 2rem;
  background: #fff;
}
@media all and (max-width: 900px) {
  .PostSomethingNew {
    margin-left: 0rem;
    min-width: 100vw;
    margin-top: -4rem;
    border-radius: 0;
  }
}
.PostSomethingNewTools {
  margin-top: 1rem;
  padding: 0 1.3rem;
}
.PostSomethingNewTools .left {
  padding-top: 0.8rem;
}
@media all and (max-width: 550px) {
  .PostSomethingNewTools .left {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
  }
}
.PostSomethingNewTools .left button {
  margin-right: 1rem;
  border: 0;
}
@media all and (max-width: 550px) {
  .PostSomethingNewTools .left button {
    margin: 0 1rem;
  }
}
.PostSomethingNewTools .left button:hover i {
  color: #469ad4;
}
@media all and (max-width: 550px) {
  .PostSomethingNewTools .right {
    width: 100%;
    text-align: center;
  }
}
.PostSomethingNewTools .right i {
  margin-right: 0.8rem;
  vertical-align: middle;
}
.PostSomethingNewTools .right .selectDesign {
  position: relative;
  display: inline-block;
  margin-right: 1rem;
}
.PostSomethingNewTools .right .selectDesign select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0 solid #ededed;
  cursor: pointer;
  background: #ffffff;
  width: 9rem;
  font-size: 1.2rem;
}
.PostSomethingNewTools .right .selectDesign::before {
  content: "\F107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 1.8rem;
  right: 1.6rem;
  position: absolute;
  top: -0.3rem;
  color: #b5b5b5;
  pointer-events: none;
}
.PostSomethingNewTools i {
  font-size: 2.4rem;
  color: #444444;
}
.PostSomethingNew textarea {
  width: 100%;
  border: 0;
  border-radius: 1rem;
  padding: 1.6rem;
  color: #444444;
  height: 6rem;
  border: 1px solid #ededed;
}

.add-listing {
  background: #fff;
}
.add-listing_full-width {
  padding: 1.6rem;
  border: 1px solid #ededed;
  border-radius: 1rem;
  max-width: 100rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.add-listing label {
  font-size: 1.2rem;
  color: #444444;
  padding-left: 1.6rem;
  padding-bottom: 0.4rem;
  display: block;
}
.add-listing input {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #ededed;
  border-radius: 1rem;
}
.add-listing input:focus {
  border: 1px solid #999 !important;
}
.add-listing input:focus {
  border: 1px solid #999 !important;
}
.add-listing input[type="date"] {
  padding: 1.4rem;
}
.add-listing select {
  padding: 1.5rem;
  border: 1px solid #ededed;
  cursor: pointer;
  width: 100%;
  background: #ffffff;
  font-size: 1.2rem;
  border-radius: 1rem;
}
.add-listing select:focus {
  border: 1px solid #999;
}
.add-listing textarea {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #ededed;
  border-radius: 1rem;
  height: 20rem;
}
.add-listing textarea:focus {
  border: 1px solid #999 !important;
}
.add-listing textarea.textarea_keywords {
  height: 20rem;
}
.add-listing textarea.textarea_content {
  height: 30rem;
}
.add-listing textarea:focus {
  border: 1px solid #999;
}
.add-listing .show-featured-image img {
  display: block;
  border-radius: 1rem;
  margin: 1rem 0 2rem 0;
  width: 50%;
}
.add-listing .row-border-divider {
  border-bottom: 1px solid #ededed;
  margin: 2rem 0;
}

.submit-listing {
  max-width: 80rem;
  margin: 0 auto;
  text-align: center;
}
.submit-listing-button {
  text-align: center;
}
@media all and (max-width: 650px) {
  .submit-listing-button button {
    font-size: 1.2rem;
    padding: 1rem 2.6rem;
  }
}
@media all and (max-width: 400px) {
  .submit-listing-button button {
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
  }
}
.submit-listing .button-white {
  margin-right: 1.6rem;
}
@media all and (max-width: 650px) {
  .submit-listing .button-white {
    margin-right: 1rem;
  }
}
@media all and (max-width: 400px) {
  .submit-listing .button-white {
    margin-right: 0.5rem;
  }
}
.submit-listing .button-white i {
  margin-right: 1rem;
}

.embedded-video-preview {
  overflow: hidden;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border: 1px solid #ededed;
  border-radius: 1rem;
}
.embedded-video-preview:after {
  content: "Your video will preview here.";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.embedded-video-preview iframe {
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
}

.show-gallery-photos {
  padding: 1.6rem;
  border: 1px solid #ededed;
  border-radius: 1rem;
  max-width: 80rem;
  margin: 0 auto;
  margin-top: 3rem;
}

.newPhotosAdded div div div div {
  position: relative;
}

.newPhotosAdded img {
  width: 100%;
  display: block;
  padding: 0.2rem;
  border-radius: 1rem;
}

.newPhotosAdded button {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;
  background: #fff;
  border-radius: 2px;
  padding: 0.5rem 0.8rem;
  line-height: 1;
  font-size: 1.4rem;
  opacity: 0;
  transition: all 0.5s;
}

.newPhotosAdded div div div div:hover button {
  opacity: 1;
}

.newPhotosAdded .featured-image-text {
  position: initial;
}

.newPhotosAdded .selectedPhoto + .featured-image-text::after {
  content: "Main Image";
  position: absolute;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  background: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  border-radius: 1rem;
}

.addSpecTable {
  vertical-align: middle;
}
.addSpecTable .mgb-none {
  margin-bottom: 0 !important;
}
.addSpecTable .addSpecLabel {
  width: 40%;
}
@media all and (max-width: 340px) {
  .addSpecTable .addSpecLabel {
    width: 30%;
  }
}
.addSpecTable .addSpecLabel input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: 13px;
  line-height: 1.3;
  height: 52px;
  -webkit-text-fill-color: #444444;
  opacity: 1;
  /* required on iOS */
}
.addSpecTable .addSpecLabel .form-input-select::before {
  top: 1rem;
}
.addSpecTable .addSpecLabel select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 1.6rem;
  font-size: 13px;
  line-height: 1.3;
  height: 52px;
}
.addSpecTable .addSpecValue {
  width: 40%;
  text-align: right;
}
@media all and (max-width: 340px) {
  .addSpecTable .addSpecValue {
    width: 30%;
  }
}
.addSpecTable .addSpecValue input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
  font-size: 13px;
  line-height: 1.3;
  height: 52px;
}
.addSpecTable .addSpecValue select {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
  font-size: 13px;
  line-height: 1.3;
  height: 52px;
}
.addSpecTable .addRemoveValue button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  font-size: 2.4rem;
  display: block;
  color: #b5b5b5;
  margin: 0 auto;
}
@media all and (max-width: 340px) {
  .addSpecTable .addRemoveValue button {
    margin-left: 2rem;
  }
}
.addSpecTable .addRemoveValue button:hover {
  color: #469ad4;
}
.addSpecTable .addRemoveValue button:disabled {
  color: #ddd;
}
.addSpecTable .addRemoveValue button:disabled:hover {
  color: #ddd;
}
.addSpecTable .addRemoveValue button span {
  font-size: 1.2rem !important;
  display: inline-block;
  padding-right: 0.5rem;
  margin-top: 0.3rem;
}
.addSpecTable .addRemoveValue button i {
  display: inline-block;
}

.SubmitedSpecTable {
  vertical-align: middle;
  width: 100%;
  margin-top: 2rem;
  max-width: 30rem;
}
.SubmitedSpecTable td {
  border-bottom: 1px solid #ededed;
  padding: 0.8rem;
}
.SubmitedSpecTable td.addSpecLabel,
.SubmitedSpecTable td.addSpecValue {
  padding: 0 1.6rem;
}
.SubmitedSpecTable td.addRemoveValue {
  text-align: right;
}
.SubmitedSpecTable tr:last-child td {
  border-bottom: 0;
}
.SubmitedSpecTable button {
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  font-size: 2.4rem;
  color: #b5b5b5;
  margin: 0 auto;
}

.createNew {
  max-width: 100rem;
  margin: 0 auto;
}
.createNewPrimary {
  width: 68%;
  float: left;
}
@media all and (max-width: 970px) {
  .createNewPrimary {
    width: 60%;
  }
}
@media all and (max-width: 970px) {
  .createNewPrimary {
    width: 100%;
    float: none;
  }
}
.createNewPrimary textarea {
  height: 40rem;
}
.createNewSecondary {
  width: 31%;
  float: right;
}
@media all and (max-width: 970px) {
  .createNewSecondary {
    width: 38%;
  }
}
@media all and (max-width: 970px) {
  .createNewSecondary {
    width: 100%;
    float: none;
  }
}
.createNewSecondary label {
  margin-bottom: 1.6rem;
}
.createNewSecondary span.label {
  font-size: 1.1rem;
  color: #b5b5b5;
  padding: 0 3.2rem;
  margin-bottom: -0.8rem;
  display: block;
}
.createNewSecondary input {
  margin-bottom: 0;
}
.createNewBox {
  border: 1px solid #ededed;
  margin-bottom: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}
.createNewBoxContent {
  padding: 1.6rem;
  border-bottom: 1px solid #ededed;
  margin-bottom: 1.6rem;
}
.createNewBoxContent:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.createNew label {
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-bottom: 1px solid #ededed;
  background: #f8f8f8;
  color: #444444;
  padding: 1.2rem;
}
.createNew input {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #ededed;
  margin-bottom: 1.6rem;
  border-radius: 1rem;
}
.createNew input:focus {
  border: 1px solid #999 !important;
}
.createNew textarea {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444444;
  border-bottom: 1px solid #ededed;
  font-family: "Open Sans", sans-serif;
  border: 1px solid #ededed;
  margin-bottom: 1.6rem;
  border-radius: 1rem;
}
.createNew textarea:focus {
  border: 1px solid #999 !important;
}
.createNew select {
  padding: 1.6rem;
  border: 1px solid #ededed;
  cursor: pointer;
  width: 100%;
  border-radius: 0;
  background: #ffffff;
  font-size: 1.2rem;
  border-radius: 1rem;
}

.productCostBox {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 600px) {
  .productCostBox {
    display: block;
  }
}
.productCostBox input,
.productCostBox span {
  width: 32%;
}
@media all and (max-width: 600px) {
  .productCostBox span {
    width: 48%;
    display: block;
    float: left;
    margin-bottom: 1.8rem;
  }
  .productCostBox span:last-child {
    float: right;
  }
}
@media all and (max-width: 450px) {
  .productCostBox span {
    width: 100%;
  }
}
@media all and (max-width: 600px) {
  .productCostBox input {
    width: 100%;
  }
}
.productCostBox span::before {
  top: 1.6rem;
}
.productCostBox .createNewPrimary {
  width: 60%;
}
.productCostBox .createNewSecondary {
  width: 39%;
}

.productCostBoxFix input {
  width: 24%;
}

.productCostBoxFix span {
  width: 24%;
}

.productCostBoxFix .selectDesign:before {
  top: 2.5rem !important;
  line-height: 0 !important;
}

.yourProductPhotos div div div div div {
  position: relative;
}

.yourProductPhotos img {
  width: 100%;
  display: block;
  padding: 0.2rem;
  border-radius: 1rem;
}

.yourProductPhotos button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: #fff;
  border-radius: 2px;
  padding: 5px;
  line-height: 1;
  font-size: 14px;
  opacity: 0;
  transition: all 1s;
}

.yourProductPhotos div div div div div:hover button {
  opacity: 1;
}

.propertyAddressFields {
  display: flex;
  justify-content: space-between;
}
.propertyAddressFields input:first-child {
  width: 40%;
}
.propertyAddressFields input:nth-child(2) {
  width: 12%;
}
.propertyAddressFields input:nth-child(3) {
  width: 20%;
}
.propertyAddressFields input:last-child {
  width: 20%;
}

.show-coupon-image img {
  width: 50%;
  border-radius: 1rem;
  display: block;
  margin-bottom: 2rem;
  margin-top: 1.2rem;
}
@media all and (max-width: 600px) {
  .show-coupon-image img {
    width: 100%;
  }
}

.add-new-main-image {
  position: relative;
  overflow: hidden;
}
.add-new-main-image img {
  width: 50%;
  border-radius: 1rem;
}
@media all and (max-width: 600px) {
  .add-new-main-image img {
    width: 100%;
  }
}
.add-new-main-image::after {
  content: "Main Image";
  position: absolute;
  top: 30px;
  left: -60px;
  text-align: center;
  width: 200px;
  display: block;
  background: #fff;
  padding: 0.5rem;
  text-transform: uppercase;
  color: #999;
  font-weight: 700;
  font-size: 1.1rem;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.addDefaultSpecTable input,
.addDefaultSpecTable select {
  margin-bottom: 0.8rem;
}

.addDefaultSpecTable .addSpecValue {
  width: 50% !important;
}

.group-members-list {
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
}
.group-members-list .group_member_profile-image {
  width: 18%;
  margin: 1%;
  text-align: center;
}
.group-members-list .group_member_profile-image img {
  width: 100%;
}
.group-members-list .group_member_profile-image .delete-member-button {
  display: inline-block;
  margin-left: 0.5rem;
  cursor: pointer;
}
.group-members-list .group_member_profile-image .delete-member-button:hover {
  color: #469ad4;
}

.add-new-page {
  z-index: 10;
  position: relative;
}
.add-new-page .main-100-header {
  color: #fff;
}
.add-new-page .main-100-header h1::after {
  border-top: 1px solid #fff;
}

.layout-background-new {
  display: block;
  height: 420px;
  width: 100%;
  position: absolute;
  top: 50px;
  z-index: 1;
}
.layout-background-new-newstorelisting,
.layout-background-new-addstorelisting,
.layout-background-new-editstorelisting {
  background: #7367f0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#7367f0),
    to(#ce9ffc)
  );
  background: linear-gradient(to right bottom, #7367f0, #ce9ffc);
  color: #fff !important;
}
.layout-background-new-newautolisting,
.layout-background-new-addautolisting,
.layout-background-new-editautolisting {
  background: #9f44d3;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#9f44d3),
    to(#e2b0ff)
  );
  background: linear-gradient(to right bottom, #9f44d3, #e2b0ff);
  color: #fff !important;
}
.layout-background-new-newfoodlisting,
.layout-background-new-addfoodlisting,
.layout-background-new-editfoodlisting {
  background: #65a2e7;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#496ad4),
    to(#65a2e7)
  );
  background: linear-gradient(to right bottom, #496ad4, #65a2e7);
  color: #fff !important;
}
.layout-background-new-newpropertylisting,
.layout-background-new-addpropertylisting,
.layout-background-new-editpropertylisting {
  background: #1d6fa3;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#1d6fa3),
    to(#65fdf0)
  );
  background: linear-gradient(to right bottom, #1d6fa3, #65fdf0);
  color: #fff !important;
}
.layout-background-new-newjoblisting,
.layout-background-new-addjoblisting,
.layout-background-new-editjoblisting {
  background: #fcb69f;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#fcb69f),
    to(#ffecd2)
  );
  background: linear-gradient(to right bottom, #fcb69f, #ffecd2);
  color: #fff !important;
}
.layout-background-new-newservicelisting,
.layout-background-new-addservicelisting,
.layout-background-new-editservicelisting {
  background: #fe5196;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#f77062),
    to(#fe5196)
  );
  background: linear-gradient(to right bottom, #f77062, #fe5196);
  color: #fff !important;
}
.layout-background-new-newtravelpackage,
.layout-background-new-addtravelpackage,
.layout-background-new-edittravelpackage {
  background: #8ddad5;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#00cdac),
    to(#8ddad5)
  );
  background: linear-gradient(to right bottom, #00cdac, #8ddad5);
  color: #fff !important;
}
.layout-background-new-neweventlisting,
.layout-background-new-addeventlisting,
.layout-background-new-editeventlisting {
  background: #ff9966;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#ff5e62),
    to(#ff9966)
  );
  background: linear-gradient(to right bottom, #ff5e62, #ff9966);
  color: #fff !important;
}
.layout-background-new-createablog,
.layout-background-new-editblog {
  background: #00b4db;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#0083b0),
    to(#00b4db)
  );
  background: linear-gradient(to right bottom, #0083b0, #00b4db);
  color: #fff !important;
}
.layout-background-new-editnews,
.layout-background-new-postnews {
  background: #00b4db;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#0083b0),
    to(#00b4db)
  );
  background: linear-gradient(to right bottom, #555, #999);
  color: #fff !important;
}
.layout-background-new-addphotos,
.layout-background-new-editphotos {
  background: #06beb6;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#48b1bf),
    to(#06beb6)
  );
  background: linear-gradient(to right bottom, #48b1bf, #06beb6);
  color: #fff !important;
}
.layout-background-new-addvideo,
.layout-background-new-editvideo {
  background: #fc5c7d;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#6a82fb),
    to(#fc5c7d)
  );
  background: linear-gradient(to right bottom, #6a82fb, #fc5c7d);
  color: #fff !important;
}
.layout-background-new-createagroup,
.layout-background-new-editgroup {
  background: #00b09b;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#96c93d),
    to(#00b09b)
  );
  background: linear-gradient(to right bottom, #96c93d, #00b09b);
  color: #fff !important;
}

.new-edit-page {
  max-width: 100rem;
  margin: 0 auto;
  overflow-x: hidden;
}
.new-edit-page_header {
  margin-bottom: 2rem;
  padding-top: 1rem;
  color: #fff;
}
@media all and (max-width: 600px) {
  .new-edit-page_header {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.new-edit-page_section {
  border: 1px solid #ededed;
  border-radius: 1rem;
  max-width: 100rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  background: #fff;
}
@media all and (max-width: 600px) {
  .new-edit-page_section {
    border: 0;
    border-radius: 0;
    margin-bottom: 4px;
  }
}
.new-edit-page_section-title {
  padding: 1rem 1.6rem;
  border-bottom: 1px solid #ededed;
  font-weight: 600;
  text-transform: uppercase;
}
.new-edit-page_section-specs {
  padding: 1.6rem;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.new-edit-page_section-specs label {
  margin-bottom: 0.8rem;
  display: inline-block;
}
.new-edit-page_section-content {
  padding: 1.6rem;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}
.new-edit-page_section-content-x4 {
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
}
.new-edit-page_section-content-list {
  grid-gap: 8px;
}
.new-edit-page_section-content-list .form-input-lists {
  margin: 0 !important;
}
.new-edit-page_section-content .edit-page-field-full {
  grid-column: 1 / -1;
}
.new-edit-page_section-content .edit-page-field-flex {
  display: flex;
  align-items: center;
}
.new-edit-page_section-content .edit-page-field .upload-more-photos {
  opacity: 0;
  position: absolute;
  z-index: -1;
}
.new-edit-page_section-content .edit-page-field label {
  display: inline-block;
}
.new-edit-page_section-content .edit-page-field label.button {
  line-height: 1;
}
.new-edit-page_section-content .edit-page-field label.info {
  font-size: 1.2rem;
  display: block;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
}
.new-edit-page_section-content .edit-page-field label.info i {
  font-size: 1.4rem;
  margin-right: 0.6rem;
}
.new-edit-page_section-image {
  border-top: 1px solid #ededed;
  padding: 1.6rem;
}
.new-edit-page_section-image img {
  max-width: 320px;
  display: block;
  border-radius: 1rem;
}
.new-edit-page_section-images {
  border-top: 1px solid #ededed;
}
@media all and (max-width: 600px) {
  .new-edit-page .submit-listing {
    margin-bottom: 2rem;
  }
}

.media-gallery-category_listing_desc_title,
.store-item-preview_title,
.featured-store-item_title,
.property-list-preview_title,
.property-list-preview_title,
.featured-event_details_title,
.event-item-preview_details_title {
  font-weight: 600;
  text-transform: capitalize;
}

.coupon-edit {
  padding: 0.8rem 1.2rem;
  font-size: 1.4rem;
  margin: 0 !important;
}

.JobsPostPreviewBox__Details h4 {
  font-size: 1.6rem;
}

.event-item-preview_details_title span:nth-child(2) {
  font-weight: 400;
}

.featured_realestate .button {
  margin-left: 2rem;
}

.store-listing-preview-img-bkg {
  display: block;
  height: 22rem;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid #e7e7e7;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  position: relative;
  z-index: 2;
}
@media all and (max-width: 600px) {
  .store-listing-preview-img-bkg {
    border-radius: 0;
    height: 28rem;
  }
}
.store-listing-preview-img-bkg span.auto {
  display: block;
  width: 100%;
  height: 22rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  position: relative;
  z-index: 0;
}

.store-item-preview {
  padding-bottom: 0 !important;
}

.slp-sub-price-icons-preview {
  display: flex;
  margin-top: 1.5rem;
  padding: 0 2rem;
  height: 20px;
}
.slp-sub-price-icons-preview div {
  display: flex;
  align-items: center;
}
.slp-sub-price-icons-preview div:not(:last-child) {
  margin-right: 1.6rem;
}
.slp-sub-price-icons-preview div img {
  width: 20px;
  display: inline-block;
  min-width: auto !important;
  margin-right: 0.6rem;
  margin-bottom: 0 !important;
  border-radius: 0 !important;
}

.slp-pricing-header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2rem;
  min-height: 10rem;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 2rem;
}
.slp-pricing-header-price {
  line-height: 1;
}
.slp-pricing-header-price .store-listing-preview_price {
  font-weight: 600;
}
.slp-pricing-header-price .store-listing-preview_price-call {
  width: 8.4rem;
  font-size: 2rem;
  line-height: 1.1;
}
.slp-pricing-header-price .store-listing-preview_price-attr {
  font-size: 1.3rem;
  display: inline-block;
  margin-left: 0.4rem;
  margin-top: 0.4rem;
}
.slp-pricing-header-price .fsp-cost {
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  color: #60ae5b;
}
@media all and (max-width: 1300px) {
  .slp-pricing-header-price .fsp-cost {
    font-size: 1.3rem;
  }
}
.slp-pricing-header-price .fsp-cost span {
  color: #444;
  font-weight: 600;
  margin-left: 0.5rem;
}
.slp-pricing-header-price .fsp-cost i {
  font-size: 1.4rem;
  cursor: pointer;
  margin-left: 0.8rem;
  color: #60ae5b;
}
@media all and (max-width: 1300px) {
  .slp-pricing-header-price .fsp-cost i {
    font-size: 1.6rem;
  }
}
.slp-pricing-header-price .deals-was-price {
  display: block;
  margin: 0 !important;
  margin-top: 0.4rem !important;
  font-weight: 400;
}
.slp-pricing-header-option {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.slp-pricing-header-option i {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: 0.5rem;
}
.slp-pricing-header-option img {
  width: 34px;
  height: 34px;
  margin-bottom: 0.5rem !important;
  border-radius: 0 !important;
  border-top-right-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}
.slp-pricing-header-option p {
  line-height: 1.3;
  text-align: center;
  font-size: 1.2rem;
  color: #555;
}
.slp-pricing-header-option p strong {
  font-size: 1.3rem;
  color: #444;
}

.slp-divider {
  width: 1px;
  height: 56px;
  background: #e7e7e7;
}

.slp-title {
  padding: 0 2rem;
  font-size: 2rem;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0.4rem;
  display: block;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slp-brand {
  padding: 0 2rem;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  height: 18px;
}
.slp-brand span {
  color: #999;
}

.slp-desc {
  padding: 0 2rem;
  font-size: 1.4rem;
  display: block;
  margin-top: 2rem;
}

.slp-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  margin-top: 2rem;
}
.slp-buttons .button {
  margin-left: 0 !important;
}

.slp-rating {
  padding: 0 2rem;
  margin-top: 0.4rem;
}
.slp-rating .engage_buttons {
  align-items: center;
  font-size: 1.4rem !important;
}
.slp-rating .engage_buttons_rating {
  display: flex;
  align-items: center;
}
.slp-rating .engage_buttons_rating .fa-star {
  color: #444 !important;
}
.slp-rating .engage_buttons_rating span {
  font-size: 1.4rem !important;
  width: auto;
  margin-top: 0 !important;
  font-weight: 600;
}

.slp-social {
  padding: 1rem 2rem 0 2rem;
  border-top: 1px solid #e7e7e7;
  margin-top: 3rem;
}
.slp-social .engage_buttons {
  justify-content: space-between;
}
.slp-social .engage_buttons .engage_button_numbers {
  width: auto;
}
.slp-social .engage_buttons button,
.slp-social .engage_buttons span {
  margin-right: 0 !important;
}

.store-listing-preview {
  padding: 0 2rem;
  margin-top: 2rem;
}
.store-listing-preview .engage_buttons {
  padding: 0 !important;
  margin: 0.4rem 0 !important;
  margin-top: 0.8rem !important;
}
.store-listing-preview .button {
  margin-left: 0 !important;
}
.store-listing-preview_category {
  font-size: 1.2rem;
  text-transform: uppercase;
  line-height: 1.2;
}
.store-listing-preview_title {
  color: #444;
  font-size: 1.8rem;
  display: block;
  overflow: hidden;
  height: 2.8rem;
  font-weight: 600;
}
.store-listing-preview_condition span {
  color: #777;
}
.store-listing-preview_brand {
  font-size: 1.3rem;
  line-height: 1.4;
}
.store-listing-preview_brand span {
  color: #777;
}
.store-listing-preview_address {
  font-size: 1.2rem;
  line-height: 1.3;
}
.store-listing-preview_meta {
  font-size: 1.2rem;
  line-height: 1.2;
  margin-top: 1rem;
}
.store-listing-preview_duration {
  font-size: 1.2rem;
  line-height: 1.4;
  padding-top: 0.8rem;
}
.store-listing-preview_duration span {
  color: #999;
}
.store-listing-preview_starting-date {
  font-size: 1.2rem;
  line-height: 1.4;
}
.store-listing-preview_starting-date span {
  color: #999;
}
.store-listing-preview_price {
  font-size: 2.2rem;
  font-weight: 300;
  margin: 0.4rem 0;
  margin-bottom: 0.6rem;
}

.event-item-preview_details_title .engage_buttons {
  margin-top: 0.2rem;
}

.event-item-preview_details_title .store-listing-preview_address {
  font-weight: 400;
  margin-top: 1rem;
}

.job-description-preview-list {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.job-description-preview-list p {
  font-size: 1.2rem;
  line-height: 2rem;
}
.job-description-preview-list p span {
  color: #999;
  width: 7rem;
  display: inline-block;
}

.job-description-actions .engage_buttons {
  margin-bottom: 1rem !important;
}

.featured-slider {
  height: 43rem;
  margin-bottom: 3rem;
}
@media all and (max-width: 920px) {
  .featured-slider {
    border: 0;
    height: auto;
  }
}
.featured-slider_slide_details {
  float: left;
  width: 40%;
  padding: 3rem;
  text-align: left;
  box-sizing: border-box;
}
@media all and (max-width: 770px) {
  .featured-slider_slide_details {
    width: 100%;
    text-align: center;
  }
}
.featured-slider_slide_details h1 {
  font-family: "Playfair Display";
  font-size: 2.6rem;
  line-height: 1.1;
}
.featured-slider_slide_details_meta {
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.featured-slider_slide_details_desc {
  font-size: 1.2rem;
}
@media all and (max-width: 860px) {
  .featured-slider_slide_details_desc {
    display: none;
  }
}
.featured-slider_slide_details_engage {
  margin-top: 2rem;
}
@media all and (max-width: 770px) {
  .featured-slider_slide_details_engage {
    margin-bottom: 2rem;
  }
}
.featured-slider_slide_details .button-regular {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
  color: #ffffff;
}
@media all and (max-width: 770px) {
  .featured-slider_slide_details .button-regular {
    position: relative;
    bottom: 0;
    left: 0;
    display: inline-block;
    margin-top: 0.6rem;
  }
}
.featured-slider_slide_image {
  float: right;
  max-height: 46rem;
  max-width: 46rem;
}
@media all and (max-width: 730px) {
  .featured-slider_slide_image {
    width: 100%;
  }
}
.featured-slider_slide_image img {
  width: 100%;
  display: block;
}
@media all and (max-width: 730px) {
  .featured-slider_slide_image img {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
}

.blog-item-preview_title h3 {
  font-family: "Playfair Display";
  text-transform: initial;
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #444444;
  margin-bottom: 0.8rem;
  overflow: hidden;
  display: block;
  height: 5.6rem;
  padding: 0 2rem;
}

.blog-item-preview_meta {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 0 2rem;
}

.blog-item-preview_desc,
.blog-item-preview .slp-desc {
  font-size: 1.3rem;
  margin-bottom: 2rem;
  padding: 0 2rem;
  height: 6rem;
  overflow: hidden;
}
@media all and (max-width: 700px) {
  .blog-item-preview_desc,
  .blog-item-preview .slp-desc {
    height: auto;
  }
}

.blog-item-preview_stats {
  padding: 0 2rem;
}
.blog-item-preview_stats span a {
  color: #444;
}
.blog-item-preview_stats .button {
  margin-top: 2rem;
  display: inline-block;
}

.Coupon {
  border: 1px dashed #ccc;
  margin-top: 2rem;
  border-radius: 1rem;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  padding-bottom: 1.6rem;
  background: #fff;
  box-sizing: border-box;
}
.Coupon .couponFeaturedImage {
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
}
.Coupon .CouponSale {
  font-size: 4rem;
  line-height: 3.8rem;
  font-weight: 300;
  padding: 0 1rem;
  padding-top: 1rem;
}
.Coupon .CouponSale span {
  color: #ee6565;
}
.Coupon .CouponProduct {
  padding: 0 1rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 0.4rem;
  margin-bottom: -0.4rem;
}
.Coupon .CouponBrand {
  font-weight: bold;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.Coupon .CouponCode {
  text-transform: uppercase;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.Coupon .CouponCode span {
  font-size: 2rem;
}
.Coupon .CouponDate {
  padding: 0 1rem;
}
.Coupon .CouponBrandLogo {
  width: 5rem;
  height: 5rem;
  position: absolute;
  border: 1px dashed #ededed;
  border-radius: 1rem;
  bottom: 1.6rem;
  right: 1.6rem;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  padding: 0.2rem;
}
.Coupon .CouponBrandLogo img {
  width: 100%;
  align-self: center;
  border-radius: 50%;
}
.Coupon .button-clear {
  margin: 0 1rem;
  margin-top: 1.6rem;
}

.featured .Coupon {
  width: 32.26%;
  display: block;
  float: left;
  margin: 1% 0 1% 1.6%;
}
.featured .Coupon:first-child {
  margin-left: 0;
}
@media all and (max-width: 950px) {
  .featured .Coupon {
    display: inline-block;
    float: none;
    text-align: left;
  }
}
@media all and (max-width: 650px) {
  .featured .Coupon {
    width: 100%;
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }
}

.Coupon:hover {
  position: relative;
  overflow: initial;
}
.Coupon:hover::before {
  content: "\F0c4";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  right: 2rem;
  top: -1.6rem;
  font-size: 2rem;
  transform: rotate(180deg);
}

.event-item-preview .store-listing-preview_title {
  margin-top: 4rem !important;
  display: block;
  height: 2.6rem;
  overflow: hidden;
}

.event-item-preview .flex a {
  color: #444;
}

.event-item-preview .engage_buttons {
  margin-top: 0 !important;
}

.event-item-preview .button {
  display: inline-block !important;
  flex-grow: 0 !important;
}

.event-item-preview-image {
  display: block;
  position: relative;
}
.event-item-preview-image img {
  display: block;
}
.event-item-preview-image-date {
  position: absolute;
  bottom: -3.2rem;
  right: 2rem;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #dddddd;
  padding: 1rem;
  color: #444;
  border-radius: 1rem;
  width: 10rem;
}
.event-item-preview-image-date-month {
  display: block;
  font-size: 1.1rem;
  text-align: center;
  line-height: 1;
  font-weight: 600;
}
.event-item-preview-image-date-day {
  display: block;
  text-align: center;
  font-size: 3.6rem;
  line-height: 1.2;
}
.event-item-preview-image-date-time {
  display: block;
  text-align: center;
  font-size: 1.3rem;
  line-height: 1;
  font-weight: 600;
}

.event-item-preview-box {
  display: flex;
  align-items: center;
  border: 1px solid #ededed;
  width: 100%;
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.event-item-preview-box:hover {
  -webkit-box-shadow: 0px 7px 21px -9px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 7px 21px -9px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 7px 21px -9px rgba(0, 0, 0, 0.3);
}
.event-item-preview-box-icon i {
  font-size: 2rem;
  color: #555;
  margin-right: 2rem;
  margin-left: 1rem;
}

.event-item-preview .flex {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.event-item-preview .flex a {
  flex-grow: 2;
  display: block;
  text-align: right;
}

.events-listing .property-address-box {
  display: none;
}

.events-listing .ProductDetailReviews h4 {
  display: none;
}

.events-listing .ProductDetailReviews .engage_buttons {
  display: none;
}

.events-listing .ProductDetailReviews .engage_comments {
  margin-top: 0;
}
.events-listing .ProductDetailReviews .engage_comments h3 {
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.8rem;
}

.group-item-preview_details {
  padding: 0 2rem;
  padding-top: 1rem;
}
.group-item-preview_details_title {
  margin-bottom: 1rem;
  color: #444444;
  font-size: 1.6rem;
  display: block;
  overflow: hidden;
  height: 2.3rem;
  font-weight: 600;
}
.group-item-preview_details_action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}
.group-item-preview_details_action a.button {
  color: #444;
  display: inline-block;
}
@media all and (max-width: 550px) {
  .group-item-preview_details_action a.button {
    margin-top: 0 !important;
  }
}
@media all and (max-width: 450px) {
  .group-item-preview_details_action a.button {
    margin-top: 0 !important;
  }
}
.group-item-preview_details_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.group-item-preview_details_content .group-stats {
  display: flex;
  align-items: center;
}
.group-item-preview_details_content .group-stats span.group-stats-members {
  font-size: 1.1rem;
  color: #777777;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.group-item-preview_details_content .group-stats span.group-stats-members i {
  font-size: 1.8rem;
  display: inline-block;
  margin-left: 0.2rem;
}
.group-item-preview_details_content .group-stats span.group-stats-members span {
  display: inline-block;
  margin-left: 0.5rem;
  margin-right: 0.6rem;
  width: 3rem;
  font-size: 1.1rem !important;
}

.job-description-actions a {
  color: #444;
}

.job-description-actions .button {
  display: inline-block;
}

.featured-gallery {
  text-align: center;
  position: relative;
}
.featured-gallery_details {
  padding: 1rem 0;
}
.featured-gallery_details span {
  font-size: 1.2rem;
}
.featured-gallery_details h2 {
  line-height: 1.2;
  font-size: 3rem;
}
.featured-gallery_video {
  position: absolute;
  background: transparent;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.featured-gallery .resp-video-cat {
  margin-bottom: 1rem;
}

.media-gallery-category_listing {
  width: 94%;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  border-radius: 1rem;
}
@media all and (max-width: 400px) {
  .media-gallery-category_listing {
    width: 100% !important;
  }
}
.media-gallery-category_listing img {
  width: 100%;
  display: block;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.media-gallery-category_listing_desc {
  display: block;
  padding: 2rem;
  border-top: 0;
  text-align: left;
}
.media-gallery-category_listing_desc_title {
  color: #444444;
  font-size: 1.6rem;
  display: block;
  overflow: hidden;
  height: 2.3rem;
}
.media-gallery-category_listing_desc_author {
  color: #444444;
  font-size: 1.1rem;
}
.media-gallery-category_listing_stats {
  padding: 0 2rem;
}
.media-gallery-category_listing_stats span a {
  color: #444;
}
.media-gallery-category_listing_stats .button {
  margin-top: 2rem;
  display: inline-block;
}
.media-gallery-category_listing .engage_buttons_comments {
  cursor: default !important;
}

.video-item-preview {
  position: relative;
}

span.resp-video-cat {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  display: block;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

span.resp-video-cat iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.main-featured-slider .swiper-slide {
  width: 43.2rem;
  margin-bottom: 1rem;
}
@media all and (max-width: 530px) {
  .main-featured-slider .swiper-slide {
    width: 40rem;
    height: auto;
  }
}
@media all and (max-width: 460px) {
  .main-featured-slider .swiper-slide {
    width: 94%;
    height: auto;
  }
}
@media all and (max-width: 950px) {
  .main-featured-slider .swiper-slide .featured-item {
    width: 100%;
    margin-bottom: 3rem !important;
    display: block;
  }
}

.search-page-header .main-100-header p {
  max-width: 100%;
}
.search-page-header .main-100-header p button.current {
  color: #444 !important;
}
@media all and (max-width: 700px) {
  .search-page-header .main-100-header p button {
    padding: 1rem 1.2rem !important;
    border-radius: 10rem;
    border: 1px solid #eee;
    margin: 0.5rem;
    font-size: 1.1rem;
    text-transform: capitalize;
    cursor: pointer;
    color: #469ad4;
    background-color: #fff;
  }
  .search-page-header .main-100-header p button.current {
    color: #fff !important;
    background: #469ad4;
    border: 1px solid #469ad4;
  }
}

.search-page-content .ServicesTile {
  background: #fff;
  display: block;
  width: 32%;
  margin-bottom: 2rem;
  margin-right: 2%;
  background: #fff;
  padding-bottom: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}
.search-page-content .ServicesTile:nth-child(3n + 3) {
  margin-right: 0;
}
@media all and (max-width: 1399px) {
  .search-page-content .ServicesTile {
    width: 49%;
  }
  .search-page-content .ServicesTile:nth-child(odd) {
    margin-right: 2%;
  }
  .search-page-content .ServicesTile:nth-child(even) {
    margin-right: 0 !important;
  }
}
@media all and (max-width: 1040px) {
  .search-page-content .ServicesTile {
    width: 100%;
  }
  .search-page-content .ServicesTile:nth-child(odd) {
    margin-right: 0 !important;
  }
}
@media all and (max-width: 900px) {
  .search-page-content .ServicesTile {
    width: 49%;
  }
  .search-page-content .ServicesTile:nth-child(odd) {
    margin-right: 2%;
  }
  .search-page-content .ServicesTile:nth-child(even) {
    margin-right: 0 !important;
  }
  .search-page-content .ServicesTile:nth-child(odd) {
    margin-right: auto !important;
  }
}
@media all and (max-width: 700px) {
  .search-page-content .ServicesTile {
    width: 100%;
  }
  .search-page-content .ServicesTile:nth-child(odd) {
    margin-right: 0 !important;
  }
}
.search-page-content .ServicesTile img {
  display: block;
  width: 100%;
  margin-bottom: 1rem;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
}
.search-page-content .ServicesTile .main-sidebar_box {
  display: none;
}
.search-page-content .ServicesTile .engage_buttons {
  display: none;
}

.ServicesCategories {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.ServicesCategories button {
  display: block;
  font-size: 1rem;
  text-align: center;
  color: #444444;
  margin-bottom: 1rem;
  width: 11.5%;
}
.ServicesCategories button span {
  display: flex;
  width: 100%;
  height: 10rem;
  border: 1px solid #ededed;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
}
.ServicesCategories button span i {
  font-size: 3rem;
  color: #b5b5b5;
}

.ServicesTile {
  width: 100%;
  border: 1px solid #ededed;
  border-radius: 1rem;
  text-align: center;
  overflow: hidden;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.ServicesTile h4 {
  font-size: 1.4rem;
}
.ServicesTile > div:not(:last-child) {
  margin-bottom: 1.6rem;
}
.ServicesTile__FeaturedImage {
  margin-bottom: 0 !important;
}
.ServicesTile__FeaturedImage img {
  display: block;
  width: 100%;
}
.ServicesTile__BrandLogo {
  width: 7.5rem;
  height: 7.5rem;
  margin: 0 auto;
  border: 1px solid #ededed;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  margin-top: -45px;
  position: relative;
  z-index: 9;
}
.ServicesTile__BrandLogo img {
  width: 100%;
}
.ServicesTile__BusinessRating i {
  font-size: 1.8rem !important;
}
.ServicesTile__BusinessDescription {
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.4;
  height: 66px;
  overflow: hidden;
  padding: 0 1.6rem;
}
.ServicesTile__BusinessDetails {
  margin-bottom: 2rem !important;
}
.ServicesTile__BusinessDetails h4 {
  display: none;
}
.ServicesTile__BusinessDetails table {
  margin: 0 auto;
}
.ServicesTile__BusinessDetails table.profileInfoTable td:last-child {
  text-align: left;
}

.featured-service .service-feature-content,
.featured-joblisting .service-feature-content {
  padding: 1rem 2rem;
}
.featured-service .service-feature-content .button-clear,
.featured-joblisting .service-feature-content .button-clear {
  margin-top: 2rem;
}
.featured-service .service-feature-content h4,
.featured-joblisting .service-feature-content h4 {
  color: #444444;
  font-size: 1.8rem;
  display: block;
  overflow: hidden;
  height: 2.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.featured-service
  .service-feature-content
  .service-feature-content_category::after,
.featured-joblisting
  .service-feature-content
  .service-feature-content_category::after {
  content: " | ";
}

.JobPostingContent__Engage {
  display: flex;
  align-items: center;
}
@media all and (max-width: 550px) {
  .JobPostingContent__Engage {
    display: block;
  }
}
.JobPostingContent__Engage button.button-regular {
  margin-right: 2rem;
}
@media all and (max-width: 550px) {
  .JobPostingContent__Engage button.button-regular {
    margin-bottom: 1.6rem;
  }
}

.CategoryFeaturedBrands {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}
@media all and (max-width: 800px) {
  .CategoryFeaturedBrands {
    flex-wrap: wrap;
  }
}

.FeaturedBrandAd {
  width: 24.5%;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background: #000000;
}
@media all and (max-width: 800px) {
  .FeaturedBrandAd {
    width: 49%;
    margin-bottom: 2%;
  }
}
.FeaturedBrandAd img.featuredBrandProduct {
  width: 100%;
  display: block;
  opacity: 0.6;
  transition: opacity 0.5s ease-in-out;
}
.FeaturedBrandAd img.featuredBrandLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 8rem;
  display: block;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
@media all and (max-width: 1100px) {
  .FeaturedBrandAd img.featuredBrandLogo {
    height: 6rem;
  }
}
.FeaturedBrandAd:hover img.featuredBrandProduct {
  opacity: 1;
}
.FeaturedBrandAd:hover img.featuredBrandLogo {
  opacity: 0;
  height: 0;
  top: 16rem;
}

.store-item-preview {
  text-align: left;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  border-radius: 1rem;
}
.store-item-preview img {
  margin-bottom: 2rem;
}
.store-item-preview a {
  color: #444444 !important;
}
.store-item-preview_brand {
  margin-bottom: 0.5rem;
  padding: 0 2rem;
}
.store-item-preview_title {
  font-size: 1.5rem;
  color: #444444;
  padding: 0 2rem;
}
.store-item-preview_price {
  font-size: 2.2rem;
  line-height: 1;
  color: #444444;
  font-weight: 300;
  margin-bottom: 1.6rem;
  padding: 0 2rem;
}
.store-item-preview .engage_buttons {
  margin-bottom: 1.2rem;
  padding: 0 2rem;
}
.store-item-preview .button {
  margin-left: 2rem;
  display: inline-block;
}

.travel-item-preview {
  padding-bottom: 2rem;
  margin-bottom: 3.6rem;
  background: #ffffff;
  border-radius: 1rem;
}

.property-list-preview {
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  background: #ffffff;
  border-radius: 1rem;
}
.property-list-preview_title {
  color: #444444;
  font-size: 1.8rem;
  display: block;
  overflow: hidden;
  height: 2.6rem;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 2rem;
  margin-bottom: 0 !important;
}
.property-list-preview_address {
  display: block;
  font-size: 1.5rem;
  color: #444444;
  margin-bottom: 0.4rem;
  padding: 0 2rem;
}
.property-list-preview_price {
  display: block;
  font-size: 2.2rem;
  line-height: 1;
  color: #444444;
  font-weight: 300;
  margin-bottom: 0.6rem;
  padding: 0 2rem;
}
.property-list-preview_meta {
  padding: 0 2rem;
}
.property-list-preview_rating {
  display: block;
  font-size: 1.8rem;
  color: #444444;
  margin-bottom: 1.2rem;
  margin-top: 0.8rem;
  padding: 0 2rem;
}
.property-list-preview_rating button i {
  font-size: 2rem;
  margin: 0 1px;
}
.property-list-preview .button {
  margin-left: 2rem;
}

.featured-store-item_details {
  padding: 1rem 2rem;
  text-align: center;
  text-align: left;
}

.featured-store-item_title {
  color: #444444;
  font-size: 1.8rem;
  display: block;
  overflow: hidden;
  height: 2.6rem;
  font-weight: 600;
  text-transform: uppercase;
}

.featured-store-item_brand {
  margin-bottom: 0.5rem;
  display: block;
}

.featured-store-item .engage_buttons {
  margin-bottom: 1.2rem;
}

.featured-store-item_price {
  font-size: 2.2rem;
  line-height: 1;
  color: #444444;
  font-weight: 300;
  margin-bottom: 1.6rem;
  display: block;
}

.featured_realestate a {
  display: inline-block;
}

.featured_realestate .property-list-preview_title {
  font-size: 1.6rem;
  margin-bottom: 0.6rem;
  color: #444;
  display: block;
}

.no-coupon {
  height: 5rem;
}

.property-list-preview .no-coupon,
.travel-item-preview .no-coupon {
  margin-top: 1.2rem;
}

.travel-item-preview_details {
  padding: 0 2rem;
  padding-top: 1rem;
}
.travel-item-preview_details button {
  margin-top: 2rem;
}
.travel-item-preview_details span {
  color: #b5b5b5;
  font-weight: 300;
}
.travel-item-preview_details_type {
  text-transform: uppercase;
}
.travel-item-preview_details_title {
  color: #444444;
  font-size: 1.8rem;
  display: block;
  overflow: hidden;
  height: 2.6rem;
  font-weight: 600;
  text-transform: uppercase;
}
.travel-item-preview_details_location {
  margin-bottom: 1.5rem;
}
.travel-item-preview_details_cost {
  font-size: 3rem;
  margin-top: 1rem;
  line-height: 1.6;
  font-weight: 300;
}

.featured-travel_details {
  padding: 1rem 0;
  text-align: center;
}
.featured-travel_details span {
  font-size: 1.2rem;
}
.featured-travel_details h2 {
  line-height: 1;
}

.featured .travel-item-preview {
  width: 32.26%;
}
@media all and (max-width: 950px) {
  .featured .travel-item-preview {
    width: 48%;
    margin: 1% !important;
    display: inline-block;
    float: none;
  }
}
@media all and (max-width: 650px) {
  .featured .travel-item-preview {
    width: 100%;
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }
}

.blog-post_header {
  margin-bottom: 2rem;
}
.blog-post_header_title h1 {
  font-family: "Playfair Display";
}
@media all and (max-width: 1110px) {
  .blog-post_header_title h1 {
    font-size: 3.4rem;
  }
}
.blog-post_header_meta {
  font-size: 1.2rem;
}
.blog-post_header_meta::before {
  content: "";
  display: block;
  width: 5rem;
  height: 1px;
  background: #444444;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.blog-post_feature {
  background: #ffffff;
  text-align: center;
  border-radius: 1rem;
  overflow: hidden;
  padding: 2rem;
  margin-bottom: 2rem;
}
@media all and (max-width: 650px) {
  .blog-post_feature {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
.blog-post_feature img {
  width: 100%;
  border-radius: 1rem;
  display: block;
}
@media all and (max-width: 650px) {
  .blog-post_feature img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
.blog-post_feature .engage_buttons {
  margin-top: 2rem;
}

.blog-post_content {
  background: #ffffff;
  border-radius: 1rem;
  padding: 4rem;
  margin-bottom: 2rem;
}
@media all and (max-width: 650px) {
  .blog-post_content {
    padding: 2rem;
  }
}

.blog-post_comments {
  background: #ffffff;
  border-radius: 1rem;
  padding: 4rem;
}
@media all and (max-width: 650px) {
  .blog-post_comments {
    padding: 2rem;
    padding-bottom: 5rem;
  }
}
.blog-post_comments .engage_buttons {
  text-align: center;
}
.blog-post_comments .engage_buttons > button i {
  font-size: 2.4rem;
  margin-right: 1.2rem;
  vertical-align: inherit;
  margin-left: 0.5rem;
}
.blog-post_comments .engage_buttons_share_modal {
  left: -16.5rem;
}
.blog-post_comments .engage_comments {
  width: 100%;
  margin-top: 0;
}
.blog-post_comments .engage_comments .engage_the-comment:first-child {
  margin-top: 0;
}

.BlogPostAddComment {
  max-width: 50%;
  margin: 0 auto;
  text-align: center;
  margin-top: 4rem;
}
.BlogPostAddComment h3 {
  margin-bottom: 2rem;
}
.BlogPostAddComment textarea {
  border: 1px solid #ededed;
  width: 100%;
  padding: 1.6rem;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  height: 10rem;
  margin-bottom: 2rem;
  display: block;
}

.blog-post_comments .engage_buttons {
  height: 0;
}

.blog-post_comments .engage_comments h3 {
  text-align: left;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.8rem;
}

.group-listing-header .profile_header {
  margin-top: 0 !important;
}

.JobPostingContent {
  padding: 0rem;
  overflow: hidden;
}
.JobPostingContent__Details-Container {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 600px) {
  .JobPostingContent__Details-Container {
    margin-bottom: 4px;
  }
}
@media all and (max-width: 500px) {
  .JobPostingContent__Details-Container .ProductDetailHeader {
    max-height: inherit;
  }
  .JobPostingContent__Details-Container
    .ProductDetailHeader
    .ProductDetailHeaderBox:last-child {
    margin-bottom: 0rem;
  }
}
.JobPostingContent__Details {
  padding: 4rem;
  background: #fff;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
@media all and (max-width: 600px) {
  .JobPostingContent__Details {
    margin-bottom: 4px;
    border-radius: 0 !important;
  }
}
.JobPostingContent__Details__Header {
  margin-bottom: 3rem;
}
.JobPostingContent__Details__Sidebar {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  margin-right: 2%;
}
@media all and (max-width: 1250px) {
  .JobPostingContent__Details__Sidebar {
    width: 34%;
    margin-right: 6%;
  }
}
@media all and (max-width: 1050px) {
  .JobPostingContent__Details__Sidebar {
    width: 100%;
    margin-right: 0%;
  }
}
@media all and (max-width: 900px) {
  .JobPostingContent__Details__Sidebar {
    width: 34%;
    margin-right: 6%;
  }
}
@media all and (max-width: 750px) {
  .JobPostingContent__Details__Sidebar {
    width: 100%;
    margin-right: 0%;
  }
}
.JobPostingContent__Details__Main {
  width: 68%;
  display: inline-block;
}
@media all and (max-width: 1250px) {
  .JobPostingContent__Details__Main {
    width: 60%;
  }
}
@media all and (max-width: 1050px) {
  .JobPostingContent__Details__Main {
    width: 100%;
  }
}
@media all and (max-width: 900px) {
  .JobPostingContent__Details__Main {
    width: 60%;
  }
}
@media all and (max-width: 750px) {
  .JobPostingContent__Details__Main {
    width: 100%;
  }
}
.JobPostingContent__Details__Main h4 {
  margin-top: 2rem;
}
.JobPostingContent__Details__Main h4:first-child {
  margin-top: 0;
}
.JobPostingContent__Details h1 {
  margin-bottom: 2rem;
  line-height: 1;
}
.JobPostingContent__Details table {
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.JobPostingContent__Details table td {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 2rem 1rem 0;
  border-bottom: 1px solid #ededed;
  color: #444444;
}
.JobPostingContent__Details table td:first-child {
  color: #b5b5b5;
}
.JobPostingContent__Details table tr:last-child td {
  border-bottom: 0;
}
.JobPostingContent__Map {
  width: 49%;
  background: #ffffff;
  border-radius: 1rem;
  overflow: hidden;
}
.JobPostingContent__Map h4 {
  padding: 0 4rem;
  margin-bottom: 2rem !important;
  display: block;
}
@media all and (max-width: 600px) {
  .JobPostingContent__Map h4 {
    padding: 0 2rem;
  }
}
.JobPostingContent__Map iframe {
  width: 100%;
  display: block;
  height: 100%;
}
.JobPostingContent__Gallery {
  padding: 4rem;
  background: #fff;
  border-radius: 1rem;
}
.JobPostingContent__Gallery .ProductDetailBox {
  padding: 0;
}
.JobPostingContent ul {
  list-style-type: disc;
}
.JobPostingContent li {
  margin-left: 3rem;
}
.JobPostingContent li:not(:last-child) {
  margin-bottom: 0.4rem;
}
.JobPostingContent h4 {
  margin-bottom: 1rem;
}

.MediaGalleryPhotos h1 {
  text-align: left;
}

.MediaGalleryMeta {
  margin-bottom: 2rem;
}
.MediaGalleryMeta .engage_buttons .engage_buttons_share_modal {
  transform: translateX(0) !important;
}
.MediaGalleryMeta::before {
  content: "";
  display: block;
  margin-top: 0.6rem;
}
.MediaGalleryMeta .mgmLeft {
  text-align: left;
  width: 80%;
  display: inline-block;
  font-size: 1.2rem;
}
@media all and (max-width: 1000px) {
  .MediaGalleryMeta .mgmLeft {
    width: 100%;
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 900px) {
  .MediaGalleryMeta .mgmLeft {
    width: 80%;
    text-align: left;
    margin-bottom: 0;
  }
}
@media all and (max-width: 680px) {
  .MediaGalleryMeta .mgmLeft {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.MediaGalleryMeta .mgmRight {
  text-align: right;
  width: 20%;
  display: inline-block;
  font-size: 1.2rem;
}
@media all and (max-width: 1000px) {
  .MediaGalleryMeta .mgmRight {
    width: 100%;
    text-align: center;
  }
}
@media all and (max-width: 900px) {
  .MediaGalleryMeta .mgmRight {
    text-align: right;
    width: 20%;
    text-align: right;
  }
}
@media all and (max-width: 680px) {
  .MediaGalleryMeta .mgmRight {
    width: 100%;
    text-align: center;
  }
}
.MediaGalleryMeta .row {
  background: #fff;
  margin: 2rem 0.7rem 0 0.7rem;
  padding: 1.6rem;
  border-radius: 1rem;
}
.MediaGalleryMeta .row .col {
  margin: 0;
}
.MediaGalleryMeta .row .col label {
  margin-right: 20px;
}

.MediaGalleryContainer button {
  display: block;
  border: 0;
  padding: 0;
  margin: 2%;
}
.MediaGalleryContainer button img {
  width: 100%;
  display: block;
  border-radius: 1rem;
  border: 1px solid #e7e7e7;
}

.video-listing_details {
  background: #ffffff;
  border-radius: 1rem;
}
.video-listing_details .engage_buttons .engage_buttons_share_modal {
  transform: translateX(0) !important;
}
.video-listing_details_comments {
  padding: 2rem;
}
.video-listing_details_comments .engage_comments {
  margin-top: 0;
}

.MediaGalleryContainer div div div:first-child div:first-child button {
  position: relative;
  overflow: hidden;
}
.MediaGalleryContainer div div div:first-child div:first-child button::after {
  content: "Main Image";
  position: absolute;
  top: 30px;
  left: -60px;
  text-align: center;
  width: 200px;
  display: block;
  background: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  text-transform: uppercase;
  color: #444;
  font-weight: 700;
  font-size: 1.1rem;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.MediaGalleryMeta .media-gallery-meta::after {
  content: "";
  display: block;
  width: 5rem;
  height: 1px;
  background: #999;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
}

@media all and (max-width: 600px) {
  .MediaGalleryContainer div div div div button {
    margin: 0 !important;
  }
  .MediaGalleryContainer div div div div button img {
    border-radius: 0 !important;
  }
}

@media all and (max-width: 600px) {
  .ProductDetailPhotos {
    padding: 0 !important;
  }
  .ProductDetailPhotos h4 {
    padding: 4rem 4rem 0 4rem;
  }
}

@media all and (max-width: 550px) {
  .ProductDetailPhotos h4 {
    padding: 2rem 2rem 0 2rem;
  }
}

.ServiceContent {
  padding: 0rem;
  overflow: hidden;
}
.ServiceContent__Details-Container {
  display: flex;
  margin-bottom: 2rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media all and (max-width: 600px) {
  .ServiceContent__Details-Container {
    margin-bottom: 12px;
  }
}
@media all and (max-width: 500px) {
  .ServiceContent__Details-Container .ProductDetailHeader {
    max-height: inherit;
  }
  .ServiceContent__Details-Container
    .ProductDetailHeader
    .ProductDetailHeaderBox:last-child {
    margin-bottom: 0rem;
  }
}
.ServiceContent__Details {
  padding: 4rem;
  background: #fff;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
@media all and (max-width: 600px) {
  .ServiceContent__Details {
    border-radius: 0;
    margin-bottom: 4px;
  }
}
.ServiceContent__Details__Header {
  margin-bottom: 3rem;
}
@media all and (max-width: 500px) {
  .ServiceContent__Details__Header .engage_buttons {
    flex-wrap: wrap;
  }
  .ServiceContent__Details__Header .engage_buttons .engage_buttons_rating {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.ServiceContent__Details__Sidebar {
  width: 30%;
  display: inline-block;
  vertical-align: top;
  margin-right: 2%;
}
@media all and (max-width: 1250px) {
  .ServiceContent__Details__Sidebar {
    width: 34%;
    margin-right: 6%;
  }
}
@media all and (max-width: 1050px) {
  .ServiceContent__Details__Sidebar {
    width: 100%;
    margin-right: 0%;
  }
}
@media all and (max-width: 900px) {
  .ServiceContent__Details__Sidebar {
    width: 34%;
    margin-right: 6%;
  }
}
@media all and (max-width: 750px) {
  .ServiceContent__Details__Sidebar {
    width: 100%;
    margin-right: 0%;
  }
}
.ServiceContent__Details__Main {
  width: 68%;
  display: inline-block;
}
@media all and (max-width: 1250px) {
  .ServiceContent__Details__Main {
    width: 60%;
  }
}
@media all and (max-width: 1050px) {
  .ServiceContent__Details__Main {
    width: 100%;
  }
}
@media all and (max-width: 900px) {
  .ServiceContent__Details__Main {
    width: 60%;
  }
}
@media all and (max-width: 750px) {
  .ServiceContent__Details__Main {
    width: 100%;
  }
}
.ServiceContent__Details h1 {
  margin-bottom: 2rem;
  line-height: 1;
  font-size: 3.4rem;
}
.ServiceContent__Details table {
  border-radius: 1rem;
  margin-bottom: 2rem;
}
.ServiceContent__Details table td {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 2rem 1rem 0;
  border-bottom: 1px solid #ededed;
  color: #444444;
}
.ServiceContent__Details table td:first-child {
  color: #b5b5b5;
}
.ServiceContent__Details table tr:last-child td {
  border-bottom: 0;
}
.ServiceContent__Map {
  width: 49%;
  background: #ffffff;
  border-radius: 1rem;
  overflow: hidden;
}
@media all and (max-width: 600px) {
  .ServiceContent__Map {
    border-radius: 0;
  }
}
.ServiceContent__Map h4 {
  padding: 0 4rem;
  margin-bottom: 2rem !important;
  display: block;
}
@media all and (max-width: 600px) {
  .ServiceContent__Map h4 {
    padding: 0 2rem;
  }
}
.ServiceContent__Map iframe {
  width: 100%;
  display: block;
  height: 100%;
}
.ServiceContent__Gallery {
  padding: 4rem;
  background: #fff;
  border-radius: 1rem;
  margin-bottom: 2rem;
}
@media all and (max-width: 600px) {
  .ServiceContent__Gallery {
    border-radius: 0;
    margin-bottom: 4px;
  }
}
.ServiceContent__Gallery .ProductDetailBox {
  padding: 0;
}
.ServiceContent__Reviews {
  padding: 4rem;
  background: #fff;
  border-radius: 1rem;
}
@media all and (max-width: 600px) {
  .ServiceContent__Reviews {
    border-radius: 0;
  }
}
.ServiceContent__Reviews .ProductDetailBox {
  padding: 0;
}
.ServiceContent ul {
  list-style-type: disc;
}
.ServiceContent li {
  margin-left: 3rem;
}
.ServiceContent li:not(:last-child) {
  margin-bottom: 0.4rem;
}
.ServiceContent h4 {
  margin-bottom: 1rem;
}
.ServiceContent .service-cost {
  margin-top: 2rem;
}

.ProductDetailMeta {
  margin-top: 2rem;
  text-align: center;
  background: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  padding: 4rem 0;
  margin-bottom: 0;
}
.ProductDetailMeta h1 {
  margin-bottom: 0.4rem;
  text-transform: capitalize;
}

.ProductDetailInfo {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
@media all and (max-width: 400px) {
  .ProductDetailInfo {
    font-size: 1.3rem;
  }
}

@media all and (max-width: 400px) {
  .print-button {
    margin-left: 1.4rem;
  }
}

.ProductDetailHeaderBox {
  position: relative;
}
.ProductDetailHeaderBox .swiper-pagination {
  width: 60% !important;
  bottom: 3rem !important;
  height: 1.4rem;
  padding-top: 2px;
}
.ProductDetailHeaderBox .swiper-pagination-bullet {
  background-color: #fff !important;
  opacity: 1 !important;
  width: 1rem;
  height: 1rem;
  border: 1px solid #444444;
}
.ProductDetailHeaderBox .swiper-pagination-bullet,
.ProductDetailHeaderBox .swiper-pagination-bullet-active-prev-prev,
.ProductDetailHeaderBox .swiper-pagination-bullet-active-prev,
.ProductDetailHeaderBox .swiper-pagination-bullet-active-next-next,
.ProductDetailHeaderBox .swiper-pagination-bullet-active-next {
  transform: scale(0.8) !important;
}
.ProductDetailHeaderBox .swiper-pagination-bullet-active {
  transform: scale(1.4) !important;
}
.ProductDetailHeaderBox .swiper-button-next {
  opacity: 1 !important;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3rem;
  border-radius: 1rem;
}
.ProductDetailHeaderBox .swiper-button-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 3rem;
}
.ProductDetailHeaderBox .swiper-button-prev {
  opacity: 1 !important;
  background-image: none;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 3rem;
  border-radius: 1rem;
}
.ProductDetailHeaderBox .swiper-button-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 3rem;
}

.ProductDetailStars {
  margin-bottom: 2rem;
  display: inline-block;
}
@media all and (max-width: 650px) {
  .ProductDetailStars .engage_buttons {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .ProductDetailStars .engage_buttons_rating {
    width: 100%;
    margin-bottom: 1.6rem;
    display: flex;
    justify-content: center;
    margin-right: 0 !important;
  }
  .ProductDetailStars .engage_buttons_share_modal {
    left: -16.8rem;
  }
}

.property-address-box {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
.property-address-box::before {
  content: "";
  height: 1px;
  background: #ededed;
  width: 40rem;
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.property-address-box::after {
  content: "";
  height: 1px;
  background: #ededed;
  width: 40rem;
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
}
.property-address-box i {
  font-size: 2.5rem;
  color: #b5b5b5;
  padding-right: 0.5rem;
  vertical-align: sub;
}

.store-seller-contact {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}
.store-seller-contact i {
  font-size: 1.8rem;
  vertical-align: sub;
  margin-right: 0.5rem;
  color: #b5b5b5;
}
.store-seller-contact i:last-child {
  margin-left: 2rem;
}

@media all and (max-width: 1050px) {
  .ProductDetailHeader {
    flex-direction: column;
  }
}

@media all and (max-width: 900px) {
  .ProductDetailHeader {
    flex-direction: row;
  }
}

@media all and (max-width: 700px) {
  .ProductDetailHeader {
    flex-direction: column;
  }
}

@media all and (max-width: 1200px) {
  .ProductDetailHeader-SellOnline {
    flex-direction: column !important;
  }
}

.ProductDetailHeader .ProductDetailHeaderBox {
  background: transparent;
  border: 0;
  overflow: visible;
}
@media all and (max-width: 1200px) {
  .ProductDetailHeader .ProductDetailHeaderBox:first-child {
    width: 65%;
  }
}
@media all and (max-width: 1050px) {
  .ProductDetailHeader .ProductDetailHeaderBox:first-child {
    width: 100%;
    margin-bottom: -1rem;
  }
}
@media all and (max-width: 900px) {
  .ProductDetailHeader .ProductDetailHeaderBox:first-child {
    width: 55%;
    margin-bottom: 0rem;
  }
}
@media all and (max-width: 700px) {
  .ProductDetailHeader .ProductDetailHeaderBox:first-child {
    width: 100%;
    margin-bottom: -1rem;
  }
}
@media all and (max-width: 500px) {
  .ProductDetailHeader .ProductDetailHeaderBox:first-child {
    margin-bottom: -1rem !important;
  }
}
@media all and (max-width: 1200px) {
  .ProductDetailHeader .ProductDetailHeaderBox:nth-child(2) {
    width: 33%;
  }
}
@media all and (max-width: 1050px) {
  .ProductDetailHeader .ProductDetailHeaderBox:nth-child(2) {
    width: 100%;
    display: none;
  }
}
@media all and (max-width: 900px) {
  .ProductDetailHeader .ProductDetailHeaderBox:nth-child(2) {
    width: 43%;
    display: block;
  }
}
@media all and (max-width: 700px) {
  .ProductDetailHeader .ProductDetailHeaderBox:nth-child(2) {
    width: 100%;
    display: none;
  }
}
@media all and (max-width: 1200px) {
  .ProductDetailHeader .ProductDetailHeaderBox-Photo {
    width: 100% !important;
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 1050px) {
  .ProductDetailHeader .ProductDetailHeaderBox-Photo {
    display: block !important;
    margin-bottom: 1rem !important;
  }
}
.ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
  padding: 4rem !important;
  justify-content: flex-start;
}
@media all and (max-width: 1200px) {
  .ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
    width: 100% !important;
  }
}
@media all and (max-width: 1050px) {
  .ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
    display: block !important;
  }
}
@media all and (max-width: 700px) {
  .ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
    display: block !important;
  }
}
@media all and (max-width: 600px) {
  .ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
    border: 0px !important;
    margin-bottom: -7px;
    margin-top: -10px;
  }
}
@media all and (max-width: 550px) {
  .ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
    padding: 2rem !important;
    display: block !important;
  }
}
@media all and (max-width: 500px) {
  .ProductDetailHeader .ProductDetailHeaderBox-ListingMeta {
    display: block !important;
    margin-bottom: -7px !important;
    margin-top: 10px !important;
  }
}
.ProductDetailHeader
  .ProductDetailHeaderBox-ListingMeta
  .auto-listing-title-map {
  padding: 0 !important;
}

.ProductDetailHeader .engage_buttons {
  justify-content: space-between !important;
  border-radius: 10px;
  padding: 1.4rem !important;
  border: 1px solid #e7e7e7;
  position: relative;
}
@media all and (max-width: 600px) {
  .ProductDetailHeader .engage_buttons {
    border-radius: 0;
    border: 0;
    margin-bottom: 4px;
    margin-top: 4px;
  }
}
.ProductDetailHeader .engage_buttons i {
  font-size: 2rem !important;
}

.ProductDetailHeader .engage_buttons_share_modal {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translatex(-50%);
  z-index: 9;
}

@media all and (max-width: 400px) {
  .ProductDetailHeader .listing-engage-box {
    width: 100%;
  }
  .ProductDetailHeader .listing-engage-box .engage_buttons {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .listing-body
    .store-product
    .ProductDetailHeader
    .ProductDetailHeaderBox-ListingMeta {
    display: block !important;
  }
}

.auto-listing-body .deal-listing-page-box {
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
}

.auto-listing-body .ProductDetailMeta {
  margin-bottom: 0 !important;
}

.auto-listing-body .product-main-contact {
  margin-top: 0 !important;
}

.ProductDetailHeaderBox:last-child {
  background: #fff;
  padding: 10px;
  border: 1px solid #e7e7e7;
}

.ProductDetailHeaderBox iframe {
  border-radius: 0.8rem !important;
  overflow: hidden !important;
}

.auto-listing-title-map {
  width: 100%;
  padding-bottom: 4rem;
}
.auto-listing-title-map .product-main-contact {
  display: block;
  margin-top: 1.8rem;
  border-left: 1px solid #6ec568;
  padding-left: 2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
@media all and (max-width: 650px) {
  .auto-listing-title-map .product-main-contact {
    margin-top: 4rem;
  }
}
.auto-listing-title-map .product-main-contact .property-address-box {
  margin-bottom: 0;
  font-size: 1.3rem;
}
.auto-listing-title-map .product-main-contact .property-address-box::before,
.auto-listing-title-map .product-main-contact .property-address-box::after {
  display: none;
}
.auto-listing-title-map .product-main-contact .property-address-box i {
  font-size: 1.8rem;
  vertical-align: sub;
  margin-right: 0rem;
  color: #b5b5b5;
}

.car-meta-info {
  flex: 1;
}
.car-meta-info h1 {
  display: block;
  margin-bottom: 1.6rem;
  font-size: 3rem;
  font-weight: 400;
}
.car-meta-info .engage_buttons_rating i {
  color: #444 !important;
  font-size: 2rem !important;
  margin-right: 0.4rem;
}
.car-meta-info .engage_buttons_rating_number {
  font-size: 1.3rem !important;
  width: auto !important;
  margin-top: 0 !important;
}
.car-meta-info .engage_buttons_rating_number:after {
  content: " Reviews";
}

.touch-address {
  margin-bottom: 1rem;
  margin-top: -0.4rem;
}

.touch-contact {
  display: flex;
  margin-top: 1rem;
}
.touch-contact button,
.touch-contact a {
  display: block;
  font-size: 2rem;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  border: 1px solid #e7e7e7;
  color: #425caf;
}
.touch-contact button i,
.touch-contact a i {
  margin: 0 !important;
}
.touch-contact button:not(:last-child),
.touch-contact a:not(:last-child) {
  margin-right: 1rem;
}
.touch-contact button:hover,
.touch-contact a:hover {
  background: #425caf;
  color: #fff;
  border: 1px solid #425caf;
}

.product-listing-header-image {
  max-height: 300px;
  height: 100%;
  background: #fff;
  display: flex;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  position: relative;
}
@media all and (max-width: 600px) {
  .product-listing-header-image {
    border-radius: 0;
  }
}
.product-listing-header-image .cba-logo {
  position: absolute;
  height: 26px !important;
  opacity: 0.6;
  width: auto !important;
  bottom: 10px;
  right: 10px;
  min-width: auto !important;
  display: none !important;
}
.product-listing-header-image-jobs {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  margin-bottom: 1rem;
}
@media all and (max-width: 600px) {
  .product-listing-header-image-jobs {
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    margin-bottom: 2px;
  }
}
@media all and (max-width: 500px) {
  .product-listing-header-image {
    height: 250px;
  }
}
.product-listing-header-image .swiper-slide {
  height: auto !important;
}
.product-listing-header-image .swiper-container {
  height: 300px !important;
}
@media all and (max-width: 500px) {
  .product-listing-header-image .swiper-container {
    height: 250px !important;
  }
}
.product-listing-header-image img {
  object-fit: cover;
  height: 300px;
  width: 100% !important;
}
@media all and (max-width: 500px) {
  .product-listing-header-image img {
    height: 250px !important;
  }
}

.cost-map {
  padding: 2rem;
  border: 1px solid #e7e7e7;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  margin-bottom: 1rem;
}
@media all and (max-width: 600px) {
  .cost-map {
    border-radius: 0;
    margin-bottom: 0;
  }
}
@media all and (max-width: 400px) {
  .cost-map {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
@media all and (max-width: 350px) {
  .cost-map {
    padding: 1.6rem 0;
    justify-content: space-evenly;
  }
}
.cost-map-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 2rem;
  padding-left: 2rem;
  border-left: 1px solid #e7e7e7;
}
@media all and (max-width: 400px) {
  .cost-map-icon {
    margin-left: 0rem;
    padding-left: 0rem;
    border-left: 0;
  }
}
.cost-map-icon img {
  width: 34px !important;
  min-width: 34px !important;
  display: block;
  margin-bottom: 0.8rem;
}
@media all and (max-width: 350px) {
  .cost-map-icon img {
    width: 30px !important;
    min-width: 30px !important;
  }
}
.cost-map-icon i {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.8rem;
  font-size: 3rem;
}
@media all and (max-width: 350px) {
  .cost-map-icon i {
    width: 30px !important;
    min-width: 30px !important;
    font-size: 2.8rem;
  }
}
.cost-map-icon p {
  text-align: center;
  line-height: 1.3;
  font-size: 1.1rem;
}
.cost-map-icon p strong {
  font-size: 1.3rem;
}

.listing-header-engage-box {
  width: 100%;
}
.listing-header-engage-box .engage_buttons {
  width: 100%;
  justify-content: space-evenly !important;
  background: #fff;
}

.map-iframe {
  display: none;
}
@media all and (max-width: 500px) {
  .map-iframe {
    height: 150px;
    width: 150px;
    overflow: hidden;
    border-radius: 1rem;
  }
}
@media all and (max-width: 400px) {
  .map-iframe {
    height: 120px;
    width: 120px;
  }
}
@media all and (max-width: 350px) {
  .map-iframe {
    height: 100px;
    width: 100px;
  }
}
.map-iframe iframe {
  width: 100%;
  height: 100%;
}

@media all and (max-width: 500px) {
  .auto-details-cost {
    margin-top: 0rem;
  }
}

.auto-details-cost .original-price {
  display: flex;
  align-items: center;
}
.auto-details-cost .original-price .listing-cost-type {
  color: #999;
  margin-left: 0.4rem;
}

.auto-details-cost .sub-price-icons {
  display: flex;
  margin-top: 1rem;
}
.auto-details-cost .sub-price-icons div {
  display: flex;
  align-items: center;
}
.auto-details-cost .sub-price-icons div:not(:last-child) {
  margin-right: 1.6rem;
}
.auto-details-cost .sub-price-icons div img {
  width: 20px;
  display: inline-block;
  min-width: auto !important;
  margin-right: 0.6rem;
}

.auto-details-cost .product-cost-discount {
  font-size: 1.8rem;
  text-decoration: line-through;
  color: #999999;
  display: flex;
  align-items: center;
}
.auto-details-cost .product-cost-discount span {
  padding-left: 0.4rem;
  display: inline-block;
  text-decoration: none !important;
}

.auto-details-cost .dealer-cost {
  font-size: 2.6rem;
  display: flex;
  align-items: center;
  line-height: 1.2;
}
@media all and (max-width: 1300px) {
  .auto-details-cost .dealer-cost {
    font-size: 2.4rem;
  }
}
.auto-details-cost .dealer-cost .call-for-pricing {
  font-size: 1.8rem;
}
.auto-details-cost .dealer-cost .listing-cost-type {
  font-size: 1.4rem;
  margin-left: 0.8rem;
}

.auto-details-cost .fsp-cost {
  color: #999999;
  font-weight: 600;
  display: inline-block;
  font-size: 2.1rem;
  display: flex;
  align-items: center;
  color: #60ae5b;
}
@media all and (max-width: 1300px) {
  .auto-details-cost .fsp-cost {
    font-size: 1.6rem;
  }
}
.auto-details-cost .fsp-cost span {
  color: #444;
  font-weight: 600;
  margin-left: 1rem;
}
.auto-details-cost .fsp-cost i {
  font-size: 2.1rem;
  cursor: pointer;
  margin-left: 1rem;
  color: #60ae5b;
}
@media all and (max-width: 1300px) {
  .auto-details-cost .fsp-cost i {
    font-size: 1.6rem;
  }
}

.auto-description {
  margin-top: 4rem;
}
.auto-description h4 {
  margin-bottom: 2rem;
}

.auto-info {
  display: flex;
  align-items: flex-start;
}
@media all and (max-width: 1050px) {
  .auto-info {
    flex-direction: column;
  }
}
@media all and (max-width: 900px) {
  .auto-info {
    flex-direction: row;
  }
}
@media all and (max-width: 650px) {
  .auto-info {
    flex-direction: column;
  }
}
.auto-info .auto-primary-info {
  width: 260px;
  margin-right: 2rem;
}
@media all and (max-width: 1050px) {
  .auto-info .auto-primary-info {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4rem;
  }
}
@media all and (max-width: 900px) {
  .auto-info .auto-primary-info {
    width: 260px;
    margin-right: 2rem;
  }
}
@media all and (max-width: 650px) {
  .auto-info .auto-primary-info {
    width: 100%;
    margin-right: 0;
    margin-bottom: 4rem;
  }
}
.auto-info .auto-secondary-info {
  flex: 1;
  width: 100%;
}

.auto-primary-info table {
  width: 100%;
}
.auto-primary-info table tr td {
  font-weight: 600;
  padding: 1rem 2rem 1rem 0;
  border-bottom: 1px solid #ededed;
  color: #444;
  text-transform: capitalize;
}
.auto-primary-info table tr td:first-child {
  color: #b5b5b5;
  width: 100px;
}
.auto-primary-info table tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0;
}
.auto-primary-info table tr:last-child td a {
  display: inline-block;
  margin-top: 1rem;
}

.auto-secondary-info {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}
.auto-secondary-info-box {
  border: 1px solid #e7e7e7;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
}
.auto-secondary-info-box img {
  width: 34px;
  display: block;
  margin-bottom: 1.2rem;
}
.auto-secondary-info-box strong {
  font-size: 1.4rem;
}
.auto-secondary-info-box p {
  text-align: center;
}
.auto-secondary-info-box i {
  font-size: 3.2rem;
  height: 3.4rem;
  width: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;
}

.auto-options {
  margin-top: 4rem;
}
.auto-options ul {
  margin-top: 2rem;
  column-count: 4;
  column-gap: 40px;
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 20px;
}
@media all and (max-width: 1200px) {
  .auto-options ul {
    column-count: 3;
  }
}
@media all and (max-width: 1100px) {
  .auto-options ul {
    column-count: 2;
  }
}
@media all and (max-width: 900px) {
  .auto-options ul {
    column-count: 3;
  }
}
@media all and (max-width: 750px) {
  .auto-options ul {
    column-count: 2;
  }
}
@media all and (max-width: 400px) {
  .auto-options ul {
    column-count: 1;
    column-gap: 0px;
  }
}

.auto-features {
  margin-top: 4rem;
}
.auto-features ul {
  margin-top: 2rem;
  column-count: 4;
  column-gap: 40px;
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 20px;
}
@media all and (max-width: 1200px) {
  .auto-features ul {
    column-count: 3;
  }
}
@media all and (max-width: 1100px) {
  .auto-features ul {
    column-count: 2;
  }
}
@media all and (max-width: 900px) {
  .auto-features ul {
    column-count: 3;
  }
}
@media all and (max-width: 750px) {
  .auto-features ul {
    column-count: 2;
  }
}
@media all and (max-width: 400px) {
  .auto-features ul {
    column-count: 1;
    column-gap: 0px;
  }
}

.ProductDetailCost {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.8rem;
  font-weight: 300;
}
@media all and (max-width: 550px) {
  .ProductDetailCost {
    font-size: 2.2rem;
    font-weight: 400;
  }
  .ProductDetailCost span {
    margin: 0 0rem 0 0.2rem !important;
  }
}
@media all and (max-width: 450px) {
  .ProductDetailCost {
    display: block;
  }
  .ProductDetailCost a.button {
    display: block;
    width: 80%;
    margin: 0 auto;
    margin-top: 2rem;
  }
}
.ProductDetailCost span {
  font-size: 1.4rem;
  font-weight: 400;
  margin: 0 1.5rem 0 0.5rem;
  display: inline-block;
  margin-top: 12px;
}

.ProductDetailNav {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  text-align: center;
  background: #fff;
}
@media all and (max-width: 700px) {
  .ProductDetailNav {
    border: 0;
  }
}
.ProductDetailNav button {
  border: 0;
  padding: 1.6rem 2rem;
  margin-top: -1px;
  margin-bottom: -1px;
  text-transform: uppercase;
}
.ProductDetailNav button strong {
  font-weight: 400;
}
@media all and (max-width: 1100px) {
  .ProductDetailNav button {
    padding: 1.6rem 1.6rem;
  }
}
@media all and (max-width: 980px) {
  .ProductDetailNav button {
    padding: 1.6rem 0;
    margin-right: 1.6rem;
  }
  .ProductDetailNav button:last-child {
    margin-right: 0;
  }
}
@media all and (max-width: 900px) {
  .ProductDetailNav button {
    padding: 1.6rem 1.6rem;
    margin-right: 0;
  }
}
@media all and (max-width: 700px) {
  .ProductDetailNav button {
    padding: 1rem 1.2rem !important;
    border-radius: 100px;
    border: 1px solid #eee;
    margin: 0.5rem;
    font-size: 1.1rem;
    text-transform: capitalize;
  }
}
.ProductDetailNav button.current,
.ProductDetailNav button:hover {
  color: #469ad4;
  border-top: 1px solid #469ad4;
  border-bottom: 1px solid #469ad4;
}
@media all and (max-width: 700px) {
  .ProductDetailNav button.current,
  .ProductDetailNav button:hover {
    color: #fff;
    background: #469ad4;
    border: 1px solid #469ad4;
  }
}

.ProductDetailHeader {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  max-width: 104rem;
}
@media all and (max-width: 1350px) {
  .ProductDetailHeader {
    height: auto;
  }
}
@media all and (max-width: 500px) {
  .ProductDetailHeader {
    display: block;
    height: auto;
  }
}

.ProductDetailHeaderBox {
  width: 49%;
  border: 1px solid #ededed;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background: #fff;
  flex-direction: column;
}
@media all and (max-width: 600px) {
  .ProductDetailHeaderBox {
    border-radius: 0 !important;
  }
}
@media all and (max-width: 500px) {
  .ProductDetailHeaderBox {
    width: 100%;
  }
  .ProductDetailHeaderBox:first-child {
    margin-bottom: 1rem;
  }
  .ProductDetailHeaderBox:last-child {
    margin-bottom: 18rem;
  }
}
.ProductDetailHeaderBox .ProductDetailHeaderBoxinBox {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden img;
  overflow-flex-shrink: 0;
  overflow-min-width: 100%;
  overflow-min-height: 100%;
}
.ProductDetailHeaderBox img {
  flex-shrink: 0;
  min-width: 100%;
}
.ProductDetailHeaderBox iframe {
  width: 100%;
  height: 100%;
  display: block;
}
.ProductDetailHeaderBox .swiper-container {
  position: relative;
  height: 100%;
}
@media all and (max-width: 500px) {
  .ProductDetailHeaderBox .swiper-container {
    height: auto;
    max-height: 46rem;
  }
}
.ProductDetailHeaderBox .swiper-container .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media all and (max-width: 500px) {
  .ProductDetailHeaderBox .swiper-container .swiper-slide {
    height: auto;
    max-height: 46rem;
  }
}
.ProductDetailHeaderBox .swiper-container .swiper-pagination {
  bottom: 4rem;
}

.ProductDetailBox {
  padding: 4rem;
  background: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
@media all and (max-width: 540px) {
  .ProductDetailBox {
    padding: 2rem;
  }
}
.ProductDetailBox h4 {
  margin-bottom: 2rem;
}
.ProductDetailBox p {
  margin-bottom: 1.6rem;
}
.ProductDetailBox p:last-child {
  margin-bottom: 0;
}
.ProductDetailBox table td {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 2rem 1rem 0;
  border-bottom: 1px solid #ededed;
  color: #444444;
}
.ProductDetailBox table td:first-child {
  color: #777;
}
.ProductDetailBox table tr:last-child td {
  border-bottom: 0;
  padding-bottom: 0;
}

.ProductDetailDescription p {
  word-wrap: break-word;
}

.PropertyListingBox {
  width: 32%;
  margin-bottom: 2rem;
}
@media all and (max-width: 1100px) {
  .PropertyListingBox {
    width: 49%;
  }
}
@media all and (max-width: 600px) {
  .PropertyListingBox {
    width: 100%;
  }
}

.PropertyListing {
  border: 1px solid #ededed;
  padding-bottom: 2rem;
  padding-top: 0;
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
.PropertyListing .ProductListingTitle {
  font-size: 1.5rem;
  line-height: 1.4;
  height: 40px;
  overflow: hidden;
  font-weight: 400;
  text-transform: capitalize;
  padding: 0 1rem;
}
.PropertyListing .PropertyListingAddress {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 400;
  text-transform: capitalize;
  border-top: 1px solid #ededed;
  padding-top: 1rem;
}
.PropertyListing .ProductListingPrice {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 600;
  margin: 1rem 0;
  padding: 1rem 1rem;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
}
.PropertyListing .PropertyDetailsMeta {
  font-size: 1.2rem;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 0 1rem 1rem 1rem;
  border-bottom: 1px solid #ededed;
  text-transform: capitalize;
}

.event-address-date {
  display: flex;
  margin-bottom: 0rem;
  flex-direction: column;
}
@media all and (max-width: 400px) {
  .event-address-date {
    flex-wrap: wrap;
  }
}
.event-address-date .featured-event_details_info {
  text-align: left;
  float: none;
  border: 1px solid #ededed;
  padding: 0;
  border-radius: 1rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 0 !important;
  width: auto !important;
  max-width: 500px;
}
.event-address-date .featured-event_details_info:not(:last-child) {
  margin-bottom: 1rem !important;
}
@media all and (max-width: 550px) {
  .event-address-date .featured-event_details_info {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
  .event-address-date .featured-event_details_info:last-child {
    margin-right: 0;
  }
}
.event-address-date .featured-event_details_info i {
  font-size: 2.6rem;
  background: #06beb6;
  background: -webkit-linear-gradient(to right bottom, #48b1bf, #06beb6);
  background: linear-gradient(to right bottom, #48b1bf, #06beb6);
  color: #fff;
  width: 60px !important;
  text-align: center;
  height: 100%;
  padding: 1.6rem !important;
  margin-right: 0;
}
.event-address-date .featured-event_details_info span {
  padding: 0 1.6rem;
}

.ProductBrandContact {
  display: none;
}

.ProductDetailPolicies,
.ProductDetailReviews,
.ProductDetailPhotos {
  margin-top: 2rem;
  border-radius: 1rem;
  max-width: 104rem;
  overflow: hidden;
}
@media all and (max-width: 600px) {
  .ProductDetailPolicies,
  .ProductDetailReviews,
  .ProductDetailPhotos {
    border-radius: 0 !important;
    margin-top: 4px;
  }
}

.product-main-details {
  padding: 4rem;
  background: #fff;
  border-radius: 1rem;
  margin-top: 2rem;
  position: relative;
  max-width: 104rem;
}
@media all and (max-width: 600px) {
  .product-main-details {
    border-radius: 0;
    margin-top: 11px;
  }
}
@media all and (max-width: 550px) {
  .product-main-details {
    padding: 2rem;
  }
}
.product-main-details .ProductDetailMeta {
  border-radius: 0rem;
  padding: 0;
  margin-top: 0;
  margin-bottom: 4rem;
  text-align: left;
}
.product-main-details .ProductDetailMeta .store-seller-contact,
.product-main-details .ProductDetailMeta .ProductDetailStars {
  margin-bottom: 0;
}
@media all and (max-width: 650px) {
  .product-main-details .ProductDetailMeta .ProductDetailStars .engage_buttons,
  .product-main-details
    .ProductDetailMeta
    .ProductDetailStars
    .engage_buttons_rating {
    justify-content: flex-start;
  }
}
.product-main-details .ProductDetailMeta .ProductDetailCost {
  justify-content: flex-start;
  margin-top: 2rem;
}
.product-main-details
  .ProductDetailMeta
  .ProductDetailCost
  .product-cost-discount {
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: line-through;
  margin: 0;
  margin-right: 1.6rem;
  margin-left: -0.6rem;
  display: inline-block;
  color: #999;
  margin-top: 0.6rem;
}
.product-main-details .ProductDetailMeta .ProductDetailCost span:first-child {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
  margin-right: 1.5rem;
}
@media all and (max-width: 550px) {
  .product-main-details .ProductDetailMeta .ProductDetailCost span:first-child {
    margin-right: 1.5rem !important;
  }
}
.product-main-details .ProductDetailMeta .ProductDetailCost a.button-clear {
  margin-left: 1.5rem;
}
@media all and (max-width: 450px) {
  .product-main-details .ProductDetailMeta .ProductDetailCost a.button {
    display: inline-block;
    width: inherit;
    margin: auto;
    margin-top: auto;
  }
  .product-main-details .ProductDetailMeta .ProductDetailCost a.button-clear {
    margin-left: 1rem !important;
  }
}
@media all and (max-width: 430px) {
  .product-main-details .ProductDetailMeta .ProductDetailCost span:first-child {
    display: block;
    margin-bottom: 1rem !important;
  }
}
.product-main-details .ProductDetailMeta .product-main-contact {
  display: block;
  margin-top: 1.8rem;
  border-left: 1px solid #6ec568;
  padding-left: 2rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}
@media all and (max-width: 650px) {
  .product-main-details .ProductDetailMeta .product-main-contact {
    margin-top: 4rem;
  }
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .property-address-box {
  margin-bottom: 0;
  font-size: 1.3rem;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .property-address-box::before,
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .property-address-box::after {
  display: none;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .property-address-box
  i {
  font-size: 1.8rem;
  vertical-align: sub;
  margin-right: 0rem;
  color: #b5b5b5;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .store-seller-contact
  a:first-child {
  margin-right: 1rem;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .store-seller-contact
  i:last-child {
  margin-left: 0.1rem;
  margin-top: 1rem;
}
@media all and (max-width: 360px) {
  .product-main-details
    .ProductDetailMeta
    .product-main-contact
    .store-seller-contact
    a {
    display: block;
  }
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .event-address-date {
  display: block;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .event-address-date
  .featured-event_details_info {
  border: 0;
  display: block;
  border-radius: 0;
  margin-bottom: 0;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .event-address-date
  .featured-event_details_info
  span {
  padding-right: 0;
  padding-top: 0;
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .event-address-date
  .featured-event_details_info
  span
  br:not(:first-child) {
  display: none;
}
@media all and (max-width: 450px) {
  .product-main-details
    .ProductDetailMeta
    .product-main-contact
    .event-address-date
    .featured-event_details_info
    span {
    width: 80%;
  }
}
.product-main-details
  .ProductDetailMeta
  .product-main-contact
  .event-address-date
  .featured-event_details_info
  i {
  background: #fff;
  color: #b5b5b5;
  padding-left: 0;
  padding-top: 0;
}
.product-main-details .mls-biz-ops-det {
  align-items: flex-start !important;
}
.product-main-details .mls-meta {
  margin-bottom: 2rem;
  margin-top: -1rem;
}
.product-main-details .mls-sellers {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin-bottom: 4rem;
}
@media all and (max-width: 500px) {
  .product-main-details .mls-sellers {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}
.product-main-details .mls-sellers .mls-seller {
  border: 1px solid #efefef;
  padding: 2rem;
  border-radius: 1rem;
}
.product-main-details .mls-sellers .mls-seller-header {
  display: flex;
  align-items: center;
}
.product-main-details .mls-sellers .mls-seller-contact {
  border-top: 1px solid #efefef;
  margin-top: 1.6rem;
  padding-top: 1rem;
}
.product-main-details .mls-sellers .mls-seller:not(:last-child) {
  margin-right: 2rem;
}
.product-main-details .mls-sellers .mls-seller p strong {
  font-size: 1.2rem;
}
.product-main-details .mls-sellers .mls-seller-image {
  width: 50px;
  height: 50px;
  border-radius: 150px;
  border: 1px solid #efefef;
  margin-right: 1rem;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.product-main-details .mls-sellers .mls-seller-name {
  font-size: 1.6rem;
}
.product-main-details .mls-sellers .mls-seller .mls-address i {
  margin-right: 1rem;
  display: inline-block;
  font-size: 2rem;
  width: 30px;
  text-align: center;
}
.product-main-details .mls-sellers .mls-seller-phone,
.product-main-details .mls-sellers .mls-seller-email {
  font-size: 1.3rem;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.product-main-details .mls-sellers .mls-seller-phone i,
.product-main-details .mls-sellers .mls-seller-email i {
  margin-right: 1rem;
  display: inline-block;
  font-size: 2rem;
  width: 30px;
  text-align: center;
}
.product-main-details .mls-address {
  display: flex;
  margin-bottom: 2rem;
}
.product-main-details .mls-address .mls-address-box {
  display: flex;
}
.product-main-details .mls-address .mls-address-box i {
  font-size: 2rem;
  display: block;
  margin-right: 1rem;
}
.product-main-details .mls-address .mls-address-box:first-child {
  margin-right: 4rem;
}
.product-main-details .listing-icon-specs {
  display: grid;
  margin-bottom: 4rem;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
}
.product-main-details .listing-icon-specs .listing-icon-spec {
  border: 1px solid #e7e7e7;
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 1rem;
  color: #555;
}
.product-main-details .listing-icon-specs .listing-icon-spec img {
  width: 34px;
  display: block;
  margin-bottom: 1.2rem;
}
.product-main-details .listing-icon-specs .listing-icon-spec i {
  font-size: 3.2rem;
  height: 3.4rem;
  width: 3.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;
}
.product-main-details .listing-icon-specs .listing-icon-spec p {
  text-align: center;
}
.product-main-details .listing-icon-specs .listing-icon-spec p strong {
  font-size: 1.4rem;
}
.product-main-details .mls-company-logo {
  margin-bottom: 2rem;
}
.product-main-details .mls-company-logo img {
  max-height: 50px;
  width: auto;
  display: block;
}
@media all and (max-width: 350px) {
  .product-main-details .mls-company-logo img {
    max-width: 200px;
  }
}
.product-main-details .ProductDetailSpecs {
  border-radius: 0rem;
  padding: 0;
  width: 34%;
  display: inline-block;
  vertical-align: top;
  margin-right: 2%;
}
@media all and (max-width: 1250px) {
  .product-main-details .ProductDetailSpecs {
    width: 34%;
    margin-right: 6%;
  }
}
@media all and (max-width: 1050px) {
  .product-main-details .ProductDetailSpecs {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 4rem;
  }
}
@media all and (max-width: 900px) {
  .product-main-details .ProductDetailSpecs {
    width: 34%;
    margin-right: 6%;
  }
}
@media all and (max-width: 750px) {
  .product-main-details .ProductDetailSpecs {
    width: 100%;
    margin-right: 0%;
  }
}
.product-main-details .ProductDetailDescription {
  border-radius: 0rem;
  padding: 0;
  width: 64%;
  display: inline-block;
}
@media all and (max-width: 1250px) {
  .product-main-details .ProductDetailDescription {
    width: 60%;
  }
}
@media all and (max-width: 1050px) {
  .product-main-details .ProductDetailDescription {
    width: 100%;
  }
}
@media all and (max-width: 900px) {
  .product-main-details .ProductDetailDescription {
    width: 60%;
  }
}
@media all and (max-width: 750px) {
  .product-main-details .ProductDetailDescription {
    width: 100%;
  }
}

.ProductDetailCost {
  font-size: 1.7rem;
  font-weight: 400;
}
.ProductDetailCost span:first-child {
  font-size: 2.8rem !important;
  font-weight: 300;
}

@media all and (max-width: 550px) {
  .ProductDetailCost {
    display: block;
    font-size: 1.3rem;
    font-weight: 600;
  }
  .ProductDetailCost span:first-child {
    font-size: 2.2rem !important;
  }
  .product-cost-discount {
    display: block !important;
  }
  a.button {
    display: inline-block;
    width: auto !important;
    text-align: center;
    margin-top: 1rem;
  }
}

@media all and (max-width: 450px) {
  .product-main-details .ProductDetailMeta .ProductDetailCost span:first-child {
    display: inline-block;
    margin-bottom: 0rem !important;
  }
  a.button {
    display: inline-block !important;
    width: auto !important;
    text-align: center !important;
    margin-top: 1rem !important;
    margin-left: 0 !important;
  }
}

@media all and (max-width: 550px) {
  .product-main-details
    .ProductDetailMeta
    .product-main-contact
    .store-seller-contact
    a {
    display: block;
  }
  .product-main-contact i {
    display: inline-block !important;
    width: 20px !important;
    text-align: center !important;
    padding-right: 0 !important;
    margin-right: 0.5rem !important;
  }
  .product-main-contact .property-address-box {
    display: table-row;
  }
  .product-main-contact .property-address-box i {
    display: table-cell;
  }
  .product-main-contact .property-address-box span {
    display: table-cell;
    padding-left: 4px;
  }
}

.property-address-box {
  text-transform: uppercase;
}

.subscription-plan {
  max-width: 100%;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  justify-content: space-evenly;
}
@media all and (max-width: 1150px) {
  .subscription-plan {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
.subscription-plan_box {
  background: #ffffff;
  border-radius: 1rem;
  padding-bottom: 3rem;
  text-align: center;
  align-self: center;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subscription-plan_box_title {
  font-size: 3rem;
  padding-top: 3rem;
  padding-bottom: 2rem;
}
.subscription-plan_box_title span {
  font-size: 1.2rem;
  font-weight: 400;
  display: none;
  margin-bottom: 1rem;
}
.subscription-plan_box .plan-benefits {
  background: #fff;
  width: 90%;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  box-shadow: 10px 10px 31px -15px rgba(0, 0, 0, 0.4);
  text-align: center;
}
.subscription-plan_box .plan-benefits h2 {
  color: #444;
  padding: 2rem 2rem 0 2rem;
}
.subscription-plan_box .plan-benefits h2 span {
  font-size: 2rem;
}
.subscription-plan_box .plan-benefits .icon-box {
  display: inline-flex;
  padding: 1rem 2rem 2rem 2rem;
  flex-wrap: wrap;
  color: #777;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border-top: 1px solid #ededed;
}
.subscription-plan_box .plan-benefits .icon-box-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ededed;
  margin: 2px;
  border-radius: 1rem;
}
.subscription-plan_box .plan-benefits .icon-box-item span {
  font-size: 1.1rem;
  display: none;
}
.subscription-plan_box .plan-benefits .icon-box-item i {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}
.subscription-plan_box .plans-ultimate-pricing-container {
  display: flex;
  justify-content: flex-end;
}
.subscription-plan_box .plans-ultimate-pricing {
  background: #fff;
  text-align: left;
  padding: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  width: 240px;
}
.subscription-plan_box .plans-ultimate-pricing .form-input-lists label {
  color: #444444;
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  line-height: 1.5rem;
}
.subscription-plan_box .plans-ultimate-pricing .form-input-lists label span {
  font-size: 1.3rem;
  font-weight: 400;
}
.subscription-plan_box
  .plans-ultimate-pricing
  .form-input-lists
  label
  span:last-child {
  color: #777;
  display: inline-block;
  margin-left: 0.4rem;
  font-size: 1.3rem;
}
.subscription-plan_box_price {
  margin-bottom: 0rem;
  padding-bottom: 0rem;
  border-bottom: 0px solid #e7e7e7;
  margin-top: 3rem;
  font-size: 1.8rem;
  font-weight: 400;
}
.subscription-plan_box_price span {
  font-size: 1.3rem !important;
  font-weight: 400;
  display: inline-block;
  padding-left: 0.2rem;
  margin-top: -1rem;
}
.subscription-plan_box_benefit {
  line-height: 1.7rem;
  text-align: left;
  font-size: 1.2rem;
  opacity: 0.8;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  color: #444;
  width: 80%;
  margin: 0 auto;
}
.subscription-plan_box_benefit:not(:last-child) {
  margin-bottom: 1rem;
  border-bottom: 1px solid #999;
  padding-bottom: 0.4rem;
}
.subscription-plan_box_benefit span {
  font-size: 2.2rem;
  font-weight: 600;
  display: inline-block;
  margin-right: 0.4rem;
}
.subscription-plan_box_benefit i {
  font-size: 1.4rem;
  width: 4rem;
  text-align: center;
  margin-right: 0rem;
  vertical-align: middle;
  display: none;
}
.subscription-plan_box_benefit i.fa-check {
  color: #6ec568;
}
.subscription-plan_box .PlanExcludes {
  color: rgba(68, 68, 68, 0.3);
}
.subscription-plan_box .GetPlan {
  margin-top: 1rem;
}
.subscription-plan_box .enterprise-contact {
  margin-top: 3rem;
}
.subscription-plan .premium-plan::after {
  content: "\f00c";
  font-family: "Font Awesome 5 Pro";
  position: absolute;
  border-radius: 10rem;
  top: -18px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  background: #fff;
  color: #444;
  width: 40px;
  font-size: 1.9rem;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscription-plan .premium-plan button,
.subscription-plan .premium-plan button:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #444 !important;
  cursor: context-menu;
}

.subscription-plan_box_free_header {
  background: #469ad4;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right bottom, #469ad4, #00b4db);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right bottom, #469ad4, #00b4db);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
}

.subscription-plan_box_premium_header {
  background: #ffafbd;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right bottom, #ffafbd, #ffc3a0);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right bottom, #ffafbd, #ffc3a0);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
}

.subscription-plan_box_basic_header {
  background: #06beb6;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right bottom, #48b1bf, #06beb6);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right bottom, #48b1bf, #06beb6);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
}

.subscription-plan_box_enterprise_header {
  background: #f77062;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right bottom, #f77062, #fe5196);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right bottom, #f77062, #fe5196);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #fff;
}

.enterprise-plan {
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 1rem;
  width: 100%;
  background: linear-gradient(to right bottom, #f77062, #fe5196);
  color: #fff;
}
.enterprise-plan h2 {
  margin-bottom: 2rem;
}
.enterprise-plan .button {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.enterprise-plan_icons i {
  font-size: 3rem;
  margin: 1rem;
  color: #fff;
}

.enterprise-static {
  border-radius: 1rem;
  padding-bottom: 3rem;
}
@media (max-width: 900px) {
  .enterprise-static {
    width: 100%;
    max-width: 50rem;
    margin: 0 auto;
  }
}
.enterprise-static .subscription-plan_box_benefit {
  border-bottom: 1px solid #f77062;
}
.enterprise-static p {
  padding: 0 2rem;
}
.enterprise-static .GetPlan {
  text-align: center;
}

.profile-settings_nav {
  width: 100%;
  margin-top: -2rem;
  margin-bottom: 0rem;
  position: relative;
  z-index: 10;
  margin-bottom: 2rem;
}
.profile-settings_nav .swiper-wrapper {
  padding-bottom: 1.6rem;
}
.profile-settings_nav a {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  padding: 1rem;
  width: 11rem;
  height: 9rem;
  margin: 1rem 0.5rem 1rem 1rem !important;
  color: #777;
  border: 1px solid #ccc;
}
.profile-settings_nav a:last-child {
  margin-right: 20rem !important;
}
.profile-settings_nav a i {
  display: block;
  font-size: 3rem;
  width: 100%;
  margin-bottom: -2rem;
}
.profile-settings_nav a span {
  display: block;
  width: 100%;
  line-height: 1.2;
  font-size: 1.2rem;
}
.profile-settings_nav a img {
  height: 24px;
  margin-bottom: -20px;
  margin-top: 6px;
}
@media all and (max-width: 800px) {
  .profile-settings_nav a {
    width: 9rem;
    height: 7rem;
    padding: 0.4rem;
    margin: 1rem 0.5rem 1rem 0.5rem !important;
  }
  .profile-settings_nav a i {
    font-size: 2.2rem;
  }
  .profile-settings_nav a img {
    height: 22px;
    margin-bottom: -20px;
    margin-top: 6px;
  }
  .profile-settings_nav a span {
    font-size: 1.1rem;
  }
}
.profile-settings_nav a:hover {
  border: 1px solid #999;
}
.profile-settings_nav a.ProfileLiActive,
.profile-settings_nav a.InviteLiActive,
.profile-settings_nav a.AccountLiActive,
.profile-settings_nav a.PostHistoryLiActive,
.profile-settings_nav a.PrivacyLiActive,
.profile-settings_nav a.SubscriptionLiActive,
.profile-settings_nav a.ManangeCouponsLiActive,
.profile-settings_nav a.PurchaseHistoryLiActive,
.profile-settings_nav a.DeleteLiActive,
.profile-settings_nav a.ManagePromotionsLiActive,
.profile-settings_nav a.ResellerAffiliateDashboardLiActive,
.profile-settings_nav
  a.SellerDashboardLiActive
  .profile-settings_nav
  a.PaidNotificationsLiActive {
  border: 1px solid #777;
  background: #fff;
}

.profile-settings_box {
  background: #ffffff;
  border-radius: 1rem;
  padding: 4rem;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  z-index: 20;
  text-align: left;
}
@media all and (max-width: 700px) {
  .profile-settings_box {
    float: none;
    width: 100%;
    border-left: 0;
    margin-left: 0;
    padding: 2rem;
  }
}
.profile-settings_box h4 {
  margin-bottom: 2rem;
  line-height: 1.2;
}
.profile-settings_box img {
  border-radius: 1rem;
}
.profile-settings_box p {
  margin-bottom: 1.6rem;
}
.profile-settings_box .spacer {
  height: 4rem;
}

.profile-update-settings {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.profile-update-settings .profile-settings_box {
  width: 32%;
  margin: 0;
}
.profile-update-settings .profile-settings_box:nth-child(2) {
  margin-left: 2%;
  margin-right: 2%;
}
@media all and (max-width: 1350px) {
  .profile-update-settings .profile-settings_box {
    width: 49%;
  }
  .profile-update-settings .profile-settings_box:nth-child(2) {
    margin-left: 2%;
    margin-right: 0;
  }
  .profile-update-settings .profile-settings_box:last-child {
    margin-top: 2rem;
    width: 100%;
  }
}
@media all and (max-width: 950px) {
  .profile-update-settings .profile-settings_box {
    width: 100%;
  }
  .profile-update-settings .profile-settings_box:nth-child(2) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 2rem;
  }
}
.profile-update-settings .profile-settings_box .mgb-sm {
  margin-bottom: 1rem !important;
}
.profile-update-settings .profile-settings_box p {
  display: inline-block;
  width: 8rem;
  margin-right: 1.6rem;
}
.profile-update-settings .profile-settings_box p.payment-method-label {
  width: 100%;
}
.profile-update-settings .profile-settings_box button {
  width: 21rem;
}
.profile-update-settings .profile-settings_box .photo-placeholder {
  margin-bottom: 2rem;
}
.profile-update-settings .profile-settings_box .photo-placeholder img {
  width: 100%;
  margin-bottom: 1rem;
  display: block;
}
.profile-update-settings .profile-settings_box .photo-placeholder_profile img {
  max-width: 50%;
  border-radius: 50%;
}
.profile-update-settings_footer {
  padding: 1rem 4rem 0 4rem;
  text-align: center;
  width: 100%;
  border-top: 1px solid #ccc;
  margin-top: 4rem;
}
@media all and (max-width: 700px) {
  .profile-update-settings_footer {
    padding: 1rem 2rem 0 2rem;
  }
}
.profile-update-settings_footer button {
  width: 18rem;
}

.business-options-box {
  margin: 1rem 0;
}
.business-options-box p {
  margin-bottom: 0 !important;
}
.business-options-box p.payment-method-label {
  margin-bottom: 1.6rem !important;
}
@media all and (max-width: 450px) {
  .business-options-box p {
    display: block !important;
    width: 100%;
  }
}

.invite-friends-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.invite-friends-options_box {
  width: 48%;
}
@media all and (max-width: 950px) {
  .invite-friends-options_box {
    width: 100%;
  }
  .invite-friends-options_box:not(:last-child) {
    margin-bottom: 6rem;
  }
}
.invite-friends-options button.invite-friends-btn {
  width: 10rem;
  padding: 3rem 0;
  border-radius: 1rem;
  margin-right: 0.8rem;
  margin-bottom: 0.8rem;
  margin-top: 0;
  color: #444444;
  border: 1px solid #444444;
  transition: all 0.2s ease-in-out;
}
.invite-friends-options button.invite-friends-btn:hover {
  color: #469ad4;
  border-color: #469ad4;
}
.invite-friends-options i {
  font-size: 4rem;
}
@media all and (max-width: 750px) {
  .invite-friends-options i {
    font-size: 3rem;
  }
}

.account-settings .modal_body h4 {
  margin-bottom: rem;
}

.account-settings-options {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.account-settings-options .profile-settings_box {
  width: 32%;
  margin: 0;
}
.account-settings-options .profile-settings_box:nth-child(2) {
  margin-right: 2%;
  margin-left: 2%;
}
.account-settings-options .profile-settings_box:nth-child(2),
.account-settings-options .profile-settings_box:nth-child(5) {
  margin-right: 2%;
  margin-left: 2%;
}
.account-settings-options .profile-settings_box:first-child,
.account-settings-options .profile-settings_box:nth-child(2),
.account-settings-options .profile-settings_box:nth-child(3) {
  margin-bottom: 2rem;
}
@media all and (max-width: 1200px) {
  .account-settings-options .profile-settings_box {
    width: 49%;
  }
  .account-settings-options .profile-settings_box:nth-child(even) {
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }
  .account-settings-options .profile-settings_box:nth-child(odd) {
    margin: 0 !important;
    margin-right: 2% !important;
    margin-bottom: 2rem !important;
  }
  .account-settings-options .profile-settings_box:last-child {
    margin-bottom: 0 !important;
  }
}
@media all and (max-width: 750px) {
  .account-settings-options .profile-settings_box {
    width: 100%;
  }
  .account-settings-options .profile-settings_box:nth-child(even) {
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }
  .account-settings-options .profile-settings_box:nth-child(odd) {
    margin: 0 !important;
    margin-bottom: 2rem !important;
  }
  .account-settings-options .profile-settings_box:last-child {
    margin-bottom: 0 !important;
  }
}
.account-settings-options .profile-settings_box .payment-cta {
  padding: 0;
  margin-top: -1rem;
  margin-bottom: 1rem;
  margin-left: 0rem;
}
.account-settings-options .profile-settings_box .payment-table {
  width: 100%;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr:not(:last-child)
  td {
  border-bottom: 1px solid #ededed;
}
.account-settings-options .profile-settings_box .payment-table tr td {
  padding: 1.2rem !important;
}
@media all and (max-width: 450px) {
  .account-settings-options .profile-settings_box .payment-table tr td {
    padding: 1.2rem 0.5rem !important;
  }
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td
  .make-primary {
  color: #469ad4;
  font-size: 1.2rem;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td
  .primary-card {
  color: #444;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td:first-child {
  width: 3rem;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td:first-child
  i {
  font-size: 2.6rem;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td:first-child
  i.fa-circle {
  color: #ccc !important;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td:first-child
  i.fa-check-circle {
  color: #6ec568;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td:nth-child(4) {
  width: 3rem;
}
.account-settings-options
  .profile-settings_box
  .payment-table
  tr
  td:nth-child(4)
  i {
  font-size: 1.6rem;
}

.step-three_payment .form-input-lists {
  text-align: left !important;
}

.privacy-settings-options {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.privacy-settings-options .profile-settings_box {
  width: 49%;
}
@media all and (max-width: 900px) {
  .privacy-settings-options .profile-settings_box {
    width: 100%;
  }
}

.DeleteAccount {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
@media all and (max-width: 1230px) {
  .DeleteAccount {
    width: 70%;
  }
}
@media all and (max-width: 1230px) {
  .DeleteAccount {
    width: 100%;
  }
}
.DeleteAccount img {
  width: 100%;
  display: block;
  border-radius: 1rem;
  margin-bottom: 3rem;
}
.DeleteAccount .goodByeText {
  font-family: "Playfair Display";
  font-size: 1.8rem;
  line-height: 2.6rem;
  padding: 0 2rem;
}
.DeleteAccount p {
  margin-bottom: 3rem;
}
.DeleteAccount button {
  width: 22rem;
  margin: 1rem;
}

.posts-history .main-100-header p button {
  margin: 0 0.8rem;
  font-size: 1.3rem;
}

.posts-history-section_manage-listings {
  width: 100%;
}
@media all and (max-width: 1100px) {
  .posts-history-section_manage-listings {
    width: 100%;
  }
}
.posts-history-section_manage-listings
  table
  tr.inactive-listing
  td:not(:last-child) {
  color: #ccc !important;
}
.posts-history-section_manage-listings
  table
  tr.inactive-listing
  td:not(:last-child)
  a {
  color: #ccc !important;
}

.posts-history-section table {
  margin: 0 auto;
  width: 100%;
}
.posts-history-section table tr {
  border-bottom: 1px solid #ededed;
}
.posts-history-section table tr:last-child {
  border-bottom: 0px solid #ededed;
}
.posts-history-section table tr.tableHeader td {
  font-weight: 700;
  text-transform: uppercase;
  text-align: left !important;
}
@media all and (max-width: 900px) {
  .posts-history-section table tr.tableHeader td {
    display: none;
  }
}
.posts-history-section table tr td {
  padding: 1rem 0;
}
.posts-history-section table tr td:not(:first-child) {
  padding-left: 1rem;
}
.posts-history-section table tr td:not(:last-child) {
  padding-right: 1rem;
  border-right: 1px solid #ededed;
}
.posts-history-section table tr td button {
  font-size: 1.3rem;
  line-height: 1.6;
  color: #469ad4;
}
.posts-history-section table tr td button:last-child {
  color: red;
}
.posts-history-section table tr td span {
  color: #ededed;
  margin: 0 0.4rem;
}
@media all and (max-width: 900px) {
  .posts-history-section table tr td {
    display: block;
    width: 100%;
    padding-top: 0;
    padding-left: 0 !important;
    padding-bottom: 0;
    border-right: 0 !important;
    text-align: left !important;
  }
  .posts-history-section table tr td:first-child {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .posts-history-section table tr td:nth-child(2) {
    font-size: 2rem;
    line-height: 1;
  }
  .posts-history-section table tr td:last-child {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .posts-history-section table tr td span {
    color: #ededed;
    margin: 0 1rem;
  }
}

@media all and (max-width: 900px) {
  .purchase-history-section_manage-listings table tr td:nth-child(1):before {
    content: "Order ID: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
  }
}

@media all and (max-width: 900px) {
  .purchase-history-section_manage-listings table tr td:nth-child(2) {
    font-size: 1.3rem;
    line-height: 1;
  }
  .purchase-history-section_manage-listings table tr td:nth-child(2):before {
    content: "Start Date: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
  }
}

@media all and (max-width: 900px) {
  .purchase-history-section_manage-listings table tr td:nth-child(3):before {
    content: "End Date: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
  }
}

@media all and (max-width: 900px) {
  .purchase-history-section_manage-listings table tr td:nth-child(5):before {
    content: "Order Date: ";
    width: 8rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
  }
}

@media all and (max-width: 900px) {
  .purchase-history-section_manage-listings table tr td:nth-child(6):before {
    content: "Amount: ";
    width: 6rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
  }
}

.manage-listings_nav {
  width: 100%;
  margin-bottom: 2rem;
  padding-bottom: 0.5rem;
}
.manage-listings_nav_header {
  display: block;
  padding: 1rem 0;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
  background: #fff;
  margin-bottom: 0.5rem;
}
.manage-listings_nav_items {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  background: #f8f8f8;
  padding: 0.5rem;
  border-radius: 2rem;
}
.manage-listings_nav button {
  padding: 1rem 1.2rem !important;
  border-radius: 100px;
  border: 1px solid #eee;
  margin: 0.5rem;
  font-size: 1.2rem;
  text-align: center;
  background: #fff;
}
.manage-listings_nav button.active {
  background: #469ad4;
  border: 1px solid #469ad4;
  display: inline-block;
  color: #ffffff;
}
.manage-listings_nav button.active:before {
  content: "" !important;
  padding-right: 0;
}

.notifications-page .main-100-header p span:not(:last-child) {
  margin-right: 1rem;
}

.notifications-page_content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.notifications-page_content_box {
  background: #ffffff;
  border-radius: 1rem;
  overflow: hidden;
}
.notifications-page_content_box_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  border-bottom: 1px solid #e7e7e7;
}
.notifications-page_content_box_header a,
.notifications-page_content_box_header button {
  color: #444;
}
@media all and (max-width: 400px) {
  .notifications-page_content_box_header {
    flex-direction: column;
  }
  .notifications-page_content_box_header h4 {
    margin-bottom: 1rem;
  }
}
.notifications-page_content_box .paid-notifications-list {
  padding: 2rem;
}
.notifications-page_content_box .notification-comments-item {
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
}
.notifications-page_content_box .notification-comments-item:not(:last-child) {
  border-bottom: 1px solid #e7e7e7;
}
.notifications-page_content_box .notification-comments-item:nth-child(even) {
  background: #f8f8f8;
}
.notifications-page_content_box .notification-comments-item img {
  display: block;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 1.4rem;
}
.notifications-page_content_box .notification-comments-item-content {
  flex: 1;
}
.notifications-page_content_box .notification-comments-item-content a {
  color: #444;
}
.notifications-page_content_box .notification-comments-item-content a:hover {
  text-decoration: underline;
}
.notifications-page_content_box .notification-comments-item-content-date {
  color: #999;
  font-size: 12px;
  font-style: italic;
}
.notifications-page_content_box .notification-comments-item-content-text {
  font-size: 1.4rem;
}
@media all and (max-width: 1000px) {
  .notifications-page_content_box {
    width: 100%;
  }
  .notifications-page_content_box:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.notifications-page_content_box_listing {
  border: 1px solid #ededed;
  border-radius: 1rem;
}
.notifications-page_content_box_listing_meta {
  border-bottom: 1px solid #ededed;
  padding: 1rem 2rem;
}
.notifications-page_content_box_listing_comment {
  padding: 1rem 2rem;
}
.notifications-page_content_box_listing_buttons {
  padding: 1rem 2rem;
}
.notifications-page_content_box_listing_buttons button:first-child {
  margin-right: 2rem;
}
.notifications-page_content_box_listitem {
  margin-bottom: 1rem;
}

.follow-requests_content_box {
  padding: 2rem;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}
.follow-requests_content_box_listing {
  background: #ffffff;
  border-radius: 1rem;
  text-align: center;
  border: 1px solid #e7e7e7;
}
.follow-requests_content_box_listing_meta img {
  width: 80%;
  display: block;
  border-radius: 50%;
  margin: 10%;
}
.follow-requests_content_box_listing_meta a {
  color: #444444;
}
.follow-requests_content_box_listing_buttons {
  display: flex;
  border-top: 1px solid #ededed;
  margin-top: 2rem;
}
.follow-requests_content_box_listing_buttons button {
  width: 50%;
  text-align: center;
  padding: 1rem 0;
}
.follow-requests_content_box_listing_buttons button:first-child {
  border-right: 1px solid #ededed;
}

.notification-comments-box {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-bottom: 1rem;
}

.messages {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media all and (max-width: 750px) {
  .messages {
    flex-wrap: wrap;
  }
}
.messages-box {
  width: 80%;
  background: #ffffff;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  overflow: hidden;
  border-right: 8px solid #f4f4f4;
}
@media all and (max-width: 1024px) {
  .messages-box {
    width: 70%;
  }
}
@media all and (max-width: 750px) {
  .messages-box {
    width: 100%;
    border-right: 0px solid #f4f4f4;
    border-radius: 1rem;
  }
}
.messages-box-info {
  background: #ffffff;
  height: 4.6rem;
  width: 100%;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 750px) {
  .messages-box-info {
    justify-content: flex-start;
  }
}
.messages-box-info-username {
  font-weight: 600;
  width: 30%;
  text-align: center;
}
@media all and (max-width: 750px) {
  .messages-box-info-username {
    text-align: left;
    padding-left: 2rem;
    width: auto;
    padding-right: 2rem;
  }
}
.messages-box-info-nav {
  width: 35%;
  padding-left: 2rem;
}
@media all and (max-width: 750px) {
  .messages-box-info-nav {
    width: auto;
  }
}
.messages-box-info-nav button {
  font-size: 1.2rem;
  color: #469ad4;
  display: none;
  border-right: 1px solid #ededed;
  padding-right: 2rem;
  height: 4.6rem;
}
.messages-box-info-nav button i {
  font-size: 3rem;
}
@media all and (max-width: 750px) {
  .messages-box-info-nav button {
    display: inline-block;
  }
}
.messages-box-info-msg {
  width: 35%;
  text-align: right;
  padding-right: 2rem;
  font-size: 0.8rem;
  line-height: 1.4;
  color: #999;
}
@media all and (max-width: 750px) {
  .messages-box-info-msg {
    flex-grow: 1;
  }
}
.messages-box-holder {
  height: 500px;
  overflow-y: auto;
  padding: 2rem;
  display: block;
}
@media all and (max-width: 550px) {
  .messages-box-holder {
    height: 320px;
  }
}
.messages-box-holder .message-sent {
  width: 60%;
  margin-left: 40%;
  margin-top: 2rem;
}
@media all and (max-width: 900px) {
  .messages-box-holder .message-sent {
    width: 70%;
    margin-left: 30%;
  }
}
@media all and (max-width: 600px) {
  .messages-box-holder .message-sent {
    width: 90%;
    margin-left: 10%;
  }
}
.messages-box-holder .message-sent-body {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #469ad4;
  width: 90%;
  float: left;
  overflow: hidden;
}
@media all and (max-width: 1100px) {
  .messages-box-holder .message-sent-body {
    width: 88%;
  }
}
@media all and (max-width: 1024px) {
  .messages-box-holder .message-sent-body {
    width: 86%;
  }
}
@media all and (max-width: 900px) {
  .messages-box-holder .message-sent-body {
    width: 84%;
  }
}
@media all and (max-width: 550px) {
  .messages-box-holder .message-sent-body {
    width: 75%;
  }
}
.messages-box-holder .message-sent-author {
  width: 4rem;
  text-align: center;
  float: right;
}
.messages-box-holder .message-sent-author img {
  width: 100%;
  display: block;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}
.messages-box-holder .message-sent-author span {
  display: none;
}
.messages-box-holder .message-received {
  width: 60%;
  margin-top: 2rem;
}
@media all and (max-width: 900px) {
  .messages-box-holder .message-received {
    width: 70%;
  }
}
@media all and (max-width: 600px) {
  .messages-box-holder .message-received {
    width: 90%;
  }
}
.messages-box-holder .message-received-body {
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid #6ec568;
  width: 90%;
  float: right;
}
@media all and (max-width: 1100px) {
  .messages-box-holder .message-received-body {
    width: 88%;
  }
}
@media all and (max-width: 1024px) {
  .messages-box-holder .message-received-body {
    width: 86%;
  }
}
@media all and (max-width: 900px) {
  .messages-box-holder .message-received-body {
    width: 84%;
  }
}
@media all and (max-width: 550px) {
  .messages-box-holder .message-received-body {
    width: 75%;
  }
}
.messages-box-holder .message-received-author {
  width: 4rem;
  text-align: center;
  float: left;
}
.messages-box-holder .message-received-author img {
  width: 100%;
  display: block;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}
.messages-box-holder .message-received-author span {
  display: none;
}
.messages-box-input {
  position: relative;
  overflow: hidden;
}
.messages-box-input input {
  width: 100%;
  border: 0;
  padding: 2rem;
  border-top: 1px solid #ededed;
  padding-right: 9rem;
  height: 6rem;
}
.messages-box-input button {
  position: absolute;
  color: #fff;
  background: #469ad4;
  right: 0rem;
  top: 1px;
  border: 0.6rem solid #fff;
  border-radius: 1rem;
  height: 100%;
  width: 7.2rem;
  text-align: center;
}
.messages-box-input button:hover {
  background: #425caf;
}
.messages-box-input button i {
  font-size: 2rem;
}
.messages-users {
  width: 20%;
  background: #ffffff;
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 1024px) {
  .messages-users {
    width: 30%;
  }
}
@media all and (max-width: 750px) {
  .messages-users {
    width: 100%;
    border-radius: 1rem;
  }
}
.messages-search {
  position: relative;
}
.messages-search input {
  padding: 16px 16px 16px 44px;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 1px solid #ededed;
}
.messages-search::before {
  content: "\F002";
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  font-size: 1.4rem;
  left: 1.6rem;
  position: absolute;
  top: 1.2rem;
  color: #b5b5b5;
}
.messages-active-chats {
  background: #f9f9f9;
  height: 100%;
  overflow-y: auto;
}
.messages-active-chat button {
  background: #fff;
  width: 100%;
  padding: 1.6rem;
  text-align: left;
  position: relative;
}
.messages-active-chat:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
.messages-new {
  position: relative;
}
.messages-new input {
  padding: 16px;
  width: 100%;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  border-top: 1px solid #ededed;
  padding-right: 6rem;
}
.messages-new button {
  text-align: center;
  display: inline-block;
  background: #469ad4;
  color: #ffffff;
  height: 100%;
  position: absolute;
  width: 5rem;
  top: 0;
  right: 0;
}
.messages-new button i {
  font-size: 2rem;
}
.messages-new button:hover {
  background: #425caf;
}

.user-online {
  color: #b5b5b5;
}
.user-online::after {
  content: "";
  height: 0.8rem;
  width: 0.8rem;
  background: #6ec568;
  position: absolute;
  right: 2rem;
  border-radius: 50%;
  top: 1.8rem;
}

.user-offline {
  color: #b5b5b5;
}

.user-unread-message {
  font-weight: bold;
  color: #444444;
}

.subscription-plan_box_price span {
  font-size: 2rem;
}

.manage-coupons-box table {
  width: 100%;
  margin-bottom: 2rem;
}
.manage-coupons-box table tr {
  border-bottom: 1px solid #ededed;
}
@media all and (max-width: 1000px) {
  .manage-coupons-box table tr.table-header {
    display: none;
  }
}
.manage-coupons-box table tr.table-header td {
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
}
.manage-coupons-box table tr:last-child {
  border-bottom: 0;
}
.manage-coupons-box table td {
  padding: 1rem 2rem;
}
@media all and (max-width: 1000px) {
  .manage-coupons-box table td {
    display: block;
    width: 100%;
  }
  .manage-coupons-box table td:first-child {
    font-weight: bold;
    padding-bottom: 0;
  }
  .manage-coupons-box table td:nth-child(2) {
    padding-bottom: 0;
  }
  .manage-coupons-box table td:nth-child(2):before {
    content: "Code Value:";
    padding-right: 1rem;
  }
  .manage-coupons-box table td:nth-child(3) {
    display: inline-block;
    width: auto;
    padding-right: 0.5rem;
  }
  .manage-coupons-box table td:nth-child(4) {
    display: inline-block;
    width: auto;
    padding-left: 0;
  }
  .manage-coupons-box table td:nth-child(4):before {
    content: " - ";
  }
}
.manage-coupons-box table td span {
  color: #ededed;
  margin: 0 0.4rem;
}

.manage-coupons-box button i {
  margin-right: 1rem;
}

.create-coupon-modal .form-input-select {
  min-width: auto !important;
}

.settings-menu {
  max-width: 1200px;
  margin: 0 auto;
}
@media all and (max-width: 1240px) {
  .settings-menu {
    padding: 0 2rem;
  }
}
@media all and (max-width: 600px) {
  .settings-menu {
    padding: 0;
  }
}
.settings-menu > h2 {
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.settings-menu .settings-layout {
  background: #ccc;
  margin-top: 2rem;
  margin-bottom: 4rem;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
}
@media all and (max-width: 700px) {
  .settings-menu .settings-layout {
    flex-wrap: wrap;
  }
}
@media all and (max-width: 600px) {
  .settings-menu .settings-layout {
    border-radius: 0;
  }
}
.settings-menu .settings-menu-divider {
  border-top: 1px solid #e7e7e7;
  margin: 0.8rem 0;
}
.settings-menu .settings-content {
  width: 80%;
  padding: 2rem;
  min-height: 300px;
}
@media all and (max-width: 1200px) {
  .settings-menu .settings-content {
    width: 75%;
  }
}
@media all and (max-width: 950px) {
  .settings-menu .settings-content {
    width: 70%;
  }
}
@media all and (max-width: 850px) {
  .settings-menu .settings-content {
    width: 65%;
  }
}
@media all and (max-width: 700px) {
  .settings-menu .settings-content {
    width: 100%;
  }
}
.settings-menu .settings-content .profile-settings_box {
  width: 100%;
  margin: 0 !important;
}
.settings-menu .settings-content .profile-settings_box:not(:last-child) {
  margin-bottom: 2rem !important;
}
.settings-menu .settings-content .update-profile-photo-settings {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1170px) {
  .settings-menu .settings-content .update-profile-photo-settings {
    flex-direction: column;
  }
  .settings-menu
    .settings-content
    .update-profile-photo-settings
    > div:first-child {
    margin-bottom: 4rem;
  }
}
.settings-menu .settings-content .update-profile-photo-settings img {
  height: 100px;
  width: auto;
}
@media all and (max-width: 920px) {
  .settings-menu
    .settings-content
    .update-profile-photo-settings
    .photo-placeholder_cover
    img {
    width: 100%;
    height: auto;
  }
}
.settings-menu .settings-content .invite-friends-options {
  display: block !important;
}
.settings-menu .settings-content .invite-friends-options .ras-url button {
  width: 5.2rem !important;
}
.settings-menu-list {
  background: #fff;
  width: 20%;
  padding: 20px;
  overflow-y: scroll;
}
.settings-menu-list span {
  display: flex !important;
  align-items: center;
}
.settings-menu-list span .LoadingSvg {
  margin-right: 5px;
}
@media all and (max-width: 1200px) {
  .settings-menu-list {
    width: 25%;
  }
}
@media all and (max-width: 950px) {
  .settings-menu-list {
    width: 30%;
  }
}
@media all and (max-width: 850px) {
  .settings-menu-list {
    width: 35%;
  }
}
@media all and (max-width: 700px) {
  .settings-menu-list {
    width: 100%;
    display: flex;
  }
}
.settings-menu-list .empty-space-mobile {
  display: none;
}
@media all and (max-width: 700px) {
  .settings-menu-list .empty-space-mobile {
    display: flex;
    border: 4px solid #fff;
  }
}
.settings-menu-list a,
.settings-menu-list button {
  display: flex;
  align-items: center;
  padding: 1.4rem;
  border-radius: 1rem;
  color: #777;
  transition: all 0.3s ease-in-out;
  width: 100%;
  text-align: left;
}
@media all and (max-width: 700px) {
  .settings-menu-list a,
  .settings-menu-list button {
    align-items: center;
    justify-content: center;
    padding: 0rem;
    border: 1px solid #ccc;
    margin-right: 1rem;
    white-space: nowrap;
  }
  .settings-menu-list a.active,
  .settings-menu-list button.active {
    order: -1;
  }
}
.settings-menu-list a:not(:last-child),
.settings-menu-list button:not(:last-child) {
  margin-bottom: 0.1rem;
}
.settings-menu-list a:hover,
.settings-menu-list a.active,
.settings-menu-list button:hover,
.settings-menu-list button.active {
  background: #496ad4;
  color: #fff !important;
}
.settings-menu-list a:hover i,
.settings-menu-list a.active i,
.settings-menu-list button:hover i,
.settings-menu-list button.active i {
  color: #fff !important;
}
.settings-menu-list a:active,
.settings-menu-list button:active {
  transform: scale(1);
}
.settings-menu-list a i,
.settings-menu-list button i {
  display: flex;
  font-size: 2rem;
  width: 40px;
  margin-right: 0.8rem;
  align-items: center;
  justify-content: center;
}
@media all and (max-width: 700px) {
  .settings-menu-list a i,
  .settings-menu-list button i {
    border-right: 1px solid #fccc;
    padding: 1.4rem 2rem;
    height: 100%;
  }
}
.settings-menu-list a .affiliate-logo,
.settings-menu-list button .affiliate-logo {
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
}
.settings-menu-list a .affiliate-logo img,
.settings-menu-list button .affiliate-logo img {
  display: block;
  width: 90%;
}
.settings-menu-list a span,
.settings-menu-list button span {
  display: block;
  width: 100%;
  line-height: 1.2;
  font-size: 1.4rem;
}
@media all and (max-width: 700px) {
  .settings-menu-list a span,
  .settings-menu-list button span {
    font-size: 1.3rem;
    padding-right: 2rem;
    padding-left: 1rem;
  }
}

.return-to-settings {
  width: 100%;
  margin-bottom: 40px;
  justify-content: center;
  display: flex;
  margin-top: -8px;
}
.return-to-settings a {
  padding: 0px 10px;
  color: #777;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  transition: all 0.3s ease-in-out;
}
.return-to-settings a:hover i {
  transform: scale(1.1);
}
.return-to-settings a i {
  font-size: 24px;
  transition: all 0.3s ease-in-out;
}
.return-to-settings a span {
  padding: 0 10px;
  font-weight: 600;
}

header {
  z-index: 99;
}

.settings-menu {
  z-index: 1;
  position: relative;
}
.settings-menu h2 {
  color: #fff;
}

.layout-background {
  display: block;
  height: 354px;
  width: 100%;
  position: absolute;
  top: 50px;
  z-index: 1;
}

.layout-background-settings-menu {
  background: linear-gradient(to bottom right, #114357, #f29492);
}

.layout-background-notifications-menu {
  background: linear-gradient(to bottom right, #eecda3, #ef629f);
}

.button-promote {
  background-color: #60ae5b;
  border: 1px solid #60ae5b;
  color: #ffffff;
  padding: 0.8rem 1.5rem !important;
  display: flex !important;
  align-items: center;
}
.button-promote i {
  font-size: 1.7rem;
  margin-right: 0.8rem;
  display: block;
}
.button-promote:hover {
  background-color: #6ec568;
  border: 1px solid #6ec568;
  color: #ffffff;
}

.blog-item-preview .button-promote,
.media-gallery-category_listing .button-promote,
.video-item-preview .button-promote {
  float: right;
}
@media all and (max-width: 450px) {
  .blog-item-preview .button-promote,
  .media-gallery-category_listing .button-promote,
  .video-item-preview .button-promote {
    margin-top: 1rem !important;
  }
}

.event-item-preview .button-promote {
  margin-left: 1rem !important;
}

.store-item-preview .button-promote,
.job-description-actions .button-promote,
.service-category .button-promote,
.property-list-preview .button-promote,
.travel-item-preview .button-promote {
  float: right;
}

.main-sidebar .button-promote {
  display: none !important;
}

.appv {
  display: block;
  width: 96%;
  margin: 0 auto;
}
.appv-preview {
  width: 28%;
  float: left;
  min-height: 30rem;
  border-radius: 1rem;
  padding: 3rem;
  background: #f8f8f8;
  background: -webkit-linear-gradient(left top, #e2ebf0, #cfd9df);
  background: linear-gradient(to right bottom, #e2ebf0, #cfd9df);
}
@media all and (max-width: 1250px) {
  .appv-preview {
    width: 35%;
  }
}
@media all and (max-width: 1000px) {
  .appv-preview {
    padding: 2rem;
  }
}
@media all and (max-width: 980px) {
  .appv-preview {
    width: 40%;
  }
}
@media all and (max-width: 850px) {
  .appv-preview {
    width: 49%;
  }
}
@media all and (max-width: 700px) {
  .appv-preview {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.appv-preview img {
  width: 100%;
}
.appv-preview .no-coupon,
.appv-preview .button-promote {
  display: none !important;
}
.appv-preview h4 {
  margin-bottom: 2rem;
  color: #444;
}
.appv-preview-ad {
  border-radius: 1rem;
  overflow: hidden;
  padding-bottom: 2rem;
  background: #fff;
  position: relative;
  -webkit-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 22px 0px rgba(0, 0, 0, 0.2);
}
.appv-preview-ad .category-list_item {
  width: 100% !important;
}
.appv-preview-ad::after {
  content: "AD PREVIEW";
  position: absolute;
  top: 50px;
  left: 20px;
  display: block;
  color: #fff;
  font-family: "Lato";
  font-weight: 900;
  opacity: 0.6;
  font-size: 40px;
  transform: rotate(-20deg);
}
.appv-preview-ad .store-item-preview,
.appv-preview-ad .travel-item-preview,
.appv-preview-ad .category-list_item,
.appv-preview-ad .media-gallery-category_listing {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  width: 100%;
}
.appv-settings {
  width: 70%;
  float: right;
  min-height: 300px;
  background: #fff;
  border-radius: 1rem;
  padding: 3rem;
  margin-bottom: 4rem;
}
@media all and (max-width: 1250px) {
  .appv-settings {
    width: 63%;
  }
}
@media all and (max-width: 1000px) {
  .appv-settings {
    padding: 2rem;
  }
}
@media all and (max-width: 980px) {
  .appv-settings {
    width: 58%;
  }
}
@media all and (max-width: 850px) {
  .appv-settings {
    width: 49%;
  }
}
@media all and (max-width: 700px) {
  .appv-settings {
    width: 100%;
  }
}
.appv-settings input {
  display: block;
  width: 100%;
  padding: 1.6rem;
  border: 0;
  background: none;
  color: #444;
  border-bottom: 1px solid #ededed;
  font-family: Open Sans, sans-serif;
  border: 1px solid #ededed;
  border-radius: 1rem;
}
.appv-settings label {
  margin-left: 1.6rem;
  margin-bottom: 0.6rem;
  display: block;
}
.appv-settings .form-input-lists {
  display: inline-block;
  margin-top: -1rem;
  margin-bottom: 0;
}
.appv-settings .row:not(:last-child) {
  margin-bottom: 2rem;
}
@media all and (max-width: 1000px) {
  .appv-settings .row-campaign-info div:first-child {
    width: 100%;
  }
  .appv-settings .row-campaign-info div:nth-child(2) {
    width: 49%;
    margin: 0 !important;
    margin-right: 2% !important;
  }
  .appv-settings .row-campaign-info div:last-child {
    width: 49%;
    margin: 0 !important;
  }
}
@media all and (max-width: 1000px) {
  .appv-settings .row-campaign-location .col {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 1rem !important;
  }
}
.appv-settings .input-range {
  max-width: 30rem;
  margin-left: 0rem;
}
@media all and (max-width: 800px) {
  .appv-settings .input-range {
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
  }
}
.appv-settings .appv-price-disclaimer {
  margin-left: 1.6rem;
  color: #999;
  margin-top: 1rem;
  display: block;
  font-style: italic;
}
.appv-settings .total-cost {
  display: flex;
}
.appv-settings .total-cost div:not(:last-child) {
  margin-right: 2rem;
}
.appv-settings .total-cost span {
  font-size: 2rem;
}
.appv-settings .ppv-actions {
  margin-top: 3rem;
}

.mp-navgation {
  display: flex;
}
@media all and (max-width: 1050px) {
  .mp-navgation {
    display: block;
  }
}
@media all and (max-width: 1050px) {
  .mp-navgation div {
    background: #f4f4f4;
    border-radius: 1rem;
    width: 100%;
    padding: 0.5rem 2rem;
    overflow: hidden;
    padding-bottom: 1rem;
  }
  .mp-navgation div button {
    background: #fff;
    margin-top: 1rem;
  }
}
@media all and (max-width: 600px) {
  .mp-navgation div {
    padding: 0;
    padding-bottom: 1rem;
  }
  .mp-navgation div button {
    background: #fff;
    margin-top: 0.5rem;
  }
}
.mp-navgation div:first-child {
  margin-right: 4rem;
  margin-bottom: 4rem;
}
@media all and (max-width: 1050px) {
  .mp-navgation div:first-child {
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 1050px) {
  .mp-navgation div:last-child {
    margin-bottom: 4rem !important;
  }
}
@media all and (max-width: 600px) {
  .mp-navgation div {
    text-align: center;
  }
  .mp-navgation div span {
    display: block;
    margin-bottom: 1rem;
    background: #e7e7e7;
    color: #444444;
    font-size: 1.1rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    text-align: left;
  }
}
.mp-navgation button {
  border-radius: 100px;
  border: 1px solid #ddd;
  margin: 0 0.2rem;
  margin-bottom: 0.6rem;
  padding: 0.8rem 1rem;
  font-size: 1.1rem !important;
}
.mp-navgation button.active {
  border-radius: 100px;
  background: #469ad4;
  color: #fff;
  border: 1px solid #469ad4;
  font-size: 1.1rem !important;
}

.mp-container {
  display: flex;
  flex-wrap: wrap;
}
.mp-container-box {
  border: 1px solid #ededed;
  border-radius: 1rem;
  overflow: hidden;
  width: 31.333%;
  margin-right: 2%;
  margin-bottom: 2rem;
}
@media all and (max-width: 1110px) {
  .mp-container-box {
    width: 49%;
    margin-right: 2%;
  }
  .mp-container-box:nth-child(even) {
    margin-right: 0;
  }
}
@media all and (max-width: 750px) {
  .mp-container-box {
    width: 100%;
    margin-right: 0 !important;
  }
}
.mp-container-box img {
  display: block;
  width: 100%;
  border-radius: 0 !important;
}
.mp-container-box-title {
  padding: 2rem 2rem 0 2rem;
  margin-bottom: 0 !important;
  font-size: 1.6rem;
  font-weight: 700;
}
.mp-container-box-info-title {
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0 !important;
}
.mp-container-box-showmore {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-weight: 600;
}
.mp-container-box-showmore i {
  font-size: 2.4rem;
  margin-left: 1rem;
  display: block;
}
.mp-container-box-id {
  margin-bottom: 0 !important;
  padding-left: 2rem;
}
.mp-container-box-id span {
  display: inline-block;
  color: #ccc;
  margin: 0 1rem;
}
.mp-container-box-date {
  padding: 0 2rem;
  margin-bottom: 1rem !important;
}
.mp-container-box-audience {
  padding: 1rem 2rem;
}
.mp-container-box-audience table tr td {
  padding: 0.5rem 0;
}
.mp-container-box-audience table tr td:first-child {
  padding-right: 2rem;
}
.mp-container-box-audience table tr:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
.mp-container-box-stats p {
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}
.mp-container-box-stats table {
  width: 100%;
}
.mp-container-box-stats table tr td {
  width: 33.333%;
  background: #e7e7e7;
  padding: 1rem;
}
.mp-container-box-stats table tr:first-child td {
  font-size: 1.2rem;
  padding-bottom: 0;
}
.mp-container-box-stats table tr:last-child td {
  font-size: 1.6rem;
  padding-top: 0;
}
.mp-container-box-buttons {
  display: flex;
  border-top: 1px solid #fff;
}
.mp-container-box-buttons button {
  display: block;
  width: 25%;
  padding: 1.4rem 0;
  background: #469ad4;
  color: #fff;
}
.mp-container-box-buttons button:not(:last-child) {
  border-right: 1px solid #fff;
}
.mp-container-box-buttons button:hover {
  background: #425caf;
}
.mp-container-box-buttons button.mp-button-active {
  background: #6ec568 !important;
  color: #fff;
}
.mp-container-box-buttons button.mp-button-inactive {
  background: #e7e7e7 !important;
  color: #999;
}

.mp-container-box-featured button,
.mp-container-box-sponsored button {
  width: 50%;
}

.notifications-page_content_box {
  width: 100% !important;
}

.notifications-page .main-100-header p {
  max-width: 100% !important;
}

.pns-container {
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 1100px) {
  .pns-container {
    display: block;
  }
}
.pns-container-column {
  width: 48%;
}
@media all and (max-width: 1100px) {
  .pns-container-column {
    width: 100%;
  }
  .pns-container-column:first-child {
    margin-bottom: 6rem;
  }
}
.pns-container-active-info {
  border: 2px solid #ededed;
}
@media all and (max-width: 600px) {
  .pns-container-active-info {
    width: 100%;
  }
}
.pns-container-active-info td {
  padding: 1rem 2rem;
}
.pns-container-active-info td:not(:last-child) {
  border-right: 2px solid #ededed;
  font-weight: 600;
}
@media all and (max-width: 600px) {
  .pns-container-active-info td {
    text-align: center;
    padding: 1rem !important;
  }
}
@media all and (max-width: 550px) {
  .pns-container-active-info td {
    display: block;
    border-right: 0 !important;
  }
  .pns-container-active-info td:not(:last-child) {
    padding-bottom: 0 !important;
  }
}
.pns-container-info {
  font-size: 1.2rem;
  color: #777;
  font-style: italic;
}
@media all and (max-width: 550px) {
  .pns-container-info {
    text-align: center;
    margin-top: 0.5rem;
  }
}
.pns-container-box {
  width: 100%;
}
.pns-container-box:not(:last-child) {
  margin-bottom: 6rem;
}
.pns-container-invite button {
  margin-top: 2rem;
}
@media all and (max-width: 600px) {
  .pns-container-history-table {
    width: 100%;
  }
}
.pns-container-history-table tr:first-child {
  font-weight: 600;
  text-transform: uppercase;
}
.pns-container-history-table tr:first-child td {
  text-align: left !important;
}
@media all and (max-width: 550px) {
  .pns-container-history-table tr:first-child {
    display: none;
  }
}
.pns-container-history-table tr:not(:last-child) {
  border-bottom: 1px solid #ededed;
}
.pns-container-history-table tr td {
  padding: 0.5rem 2rem 0.5rem 0;
}
.pns-container-history-table tr td:not(:first-child) {
  padding-left: 2rem;
}
.pns-container-history-table tr td:not(:last-child) {
  border-right: 1px solid #ededed;
}
.pns-container-history-table tr td:last-child {
  padding-right: 0;
}
.pns-container-history-table tr td:nth-child(2),
.pns-container-history-table tr td:nth-child(3) {
  text-align: right;
}
@media all and (max-width: 550px) {
  .pns-container-history-table tr td {
    display: block;
    text-align: left !important;
    padding: 0rem 0 0.5rem 0 !important;
    border-right: 0 !important;
  }
  .pns-container-history-table tr td:first-child {
    padding-top: 1rem !important;
  }
  .pns-container-history-table tr td:first-child::before {
    content: "Payment Date: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
    width: 10rem;
  }
  .pns-container-history-table tr td:nth-child(2)::before {
    content: "(Fo) Coins: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
    width: 10rem;
  }
  .pns-container-history-table tr td:nth-child(3)::before {
    content: "Amount: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
    width: 10rem;
  }
  .pns-container-history-table tr td:last-child {
    padding-bottom: 1rem !important;
  }
  .pns-container-history-table tr td:last-child::before {
    content: "Credited To: ";
    width: 7rem;
    display: inline-block;
    color: #b5b5b5;
    font-weight: 300;
    width: 10rem;
  }
}

.paid-notifications-list .button-promote {
  display: none !important;
}

.paid-notifications-list .category-list_item {
  display: block;
  width: 95%;
  margin-bottom: 2rem;
  margin-right: 2%;
  background: #fff;
  padding-bottom: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid #ededed;
}

.paid-notification-navigation {
  margin-bottom: 2rem;
}
.paid-notification-navigation button {
  border-radius: 100px;
  border: 1px solid #ddd;
  margin: 0 0.2rem;
  margin-bottom: 0.6rem;
  padding: 0.8rem 1rem;
  font-size: 1.1rem !important;
}
.paid-notification-navigation button.active {
  border-radius: 100px;
  background: #469ad4;
  color: #fff;
  border: 1px solid #469ad4;
  font-size: 1.1rem !important;
}

.paid-notifications-pagination {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.UserProfileIntroPhoto .UserProfileImage {
  float: left;
  margin-right: 2rem;
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  overflow: hidden;
}
.UserProfileIntroPhoto .UserProfileImage img {
  width: 100%;
}

.UserProfileIntroPhoto h4 {
  padding-top: 0.6rem;
}

.UserProfileIntroToDo button,
.UserProfileIntroToDo a {
  border: 0;
  padding: 0;
  font-size: 1.2rem;
  margin: 0;
  color: #444444;
}
.UserProfileIntroToDo button:hover,
.UserProfileIntroToDo a:hover {
  color: #469ad4;
}

.UserProfileIntroToDo ul {
  border-left: 1px solid #ededed;
  padding: 1.6rem 0;
  display: inline-block;
  margin-top: 2rem;
  margin-left: 3.2rem;
}
.UserProfileIntroToDo ul li {
  padding: 0.4rem 1.6rem;
}

.WallListing {
  border: 0;
  border-radius: 1rem;
  margin-bottom: 2rem;
  background: #fff;
  overflow: hidden;
}
@media all and (max-width: 900px) {
  .WallListing {
    margin: 0 2rem;
  }
  .WallListing:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.WallListing header {
  padding: 1.6rem;
  margin: 0;
}
.WallListing header .WallListingUserProfileImage {
  width: 6.4rem;
  height: 6.4rem;
  overflow: hidden;
  float: left;
  margin-right: 1.6rem;
  border-radius: 50%;
  background: #f8f8f8;
}
.WallListing header .WallListingUserProfileImage img {
  width: 100%;
  display: block;
}
@media all and (max-width: 450px) {
  .WallListing header .left {
    max-width: 20rem;
  }
}
.WallListing header .WallListingUserInfo {
  font-size: 1.5rem;
  margin-top: 0.7rem;
}
@media all and (max-width: 450px) {
  .WallListing header .WallListingUserInfo {
    line-height: 1.2;
    margin-bottom: 0.6rem;
  }
}
.WallListing header .WallListingMeta {
  font-size: 1.2rem;
}
.WallListing footer {
  margin-top: 0;
  background: #ffffff;
  padding: 1.6rem;
  border-top: 1px solid #ededed;
}
.WallListing .WallPhoto button {
  border: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  display: block;
}
.WallListing .WallPhoto button.WallVideo {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}
.WallListing .WallPhoto button.WallVideo iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.WallListing .WallPhoto img {
  width: 100%;
  display: block;
}
.WallListing .WallText {
  padding: 3.2rem;
  width: 100%;
}
.WallListing .WallBlogPreview {
  padding: 3.2rem;
}
.WallListing .WallBlogPreview h4 {
  font-size: 1.8rem;
  text-transform: capitalize;
}
.WallListing .WallBlogPreview p {
  margin: 1.6rem 0;
}
.WallListing .WallBlogPreview .button-regular a {
  color: #ffffff;
}
.WallListing .WallBlog img {
  width: 100%;
}
.WallListing .WallProduct img {
  display: block;
  width: 100%;
}
.WallListing .WallProductDetails {
  padding: 3.2rem;
  border-top: 1px solid #ededed;
}
.WallListing .WallProductDetails .left {
  width: 100%;
}
.WallListing .WallProductDetails .left .WallpropertyRating .engageButton,
.WallListing .WallProductDetails .left .WallproductRating .engageButton {
  margin: 0;
}
.WallListing .WallProductDetails .left .WallpropertyRating .engageButton i,
.WallListing .WallProductDetails .left .WallproductRating .engageButton i {
  margin-left: 0;
  margin-right: 0.3rem;
  font-size: 2rem;
}
.WallListing .WallProductDetails .right {
  width: 100%;
}
.WallListing .WallProductDetails .right a {
  background-color: #469ad4;
  border: 1px solid #469ad4;
  color: #ffffff;
  padding: 1.2rem 3.2rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 3rem;
  display: inline-block;
}
.WallListing .WallProductDetails .WallProductPrice {
  font-size: 2.6rem;
  font-weight: 300;
}

.WallSortMenu {
  text-align: center;
  margin-bottom: 2rem;
}
.WallSortMenu button {
  border: none;
  padding: 0 0.8rem;
  color: #444444;
}
.WallSortMenu button:hover {
  color: #469ad4;
}
.WallSortMenu .WallSortItemSelected {
  color: #469ad4;
}

.profile_header {
  padding: 0rem;
  margin-bottom: 4rem;
  text-align: center;
  position: relative;
}
.profile_header .update-profile-image-icon {
  position: absolute;
  top: 1rem;
  right: 2rem;
  font-size: 4rem;
  color: #fff;
  z-index: 11;
  transition: all 0.2s ease-out;
}
.profile_header .update-profile-image-icon:hover {
  transform: scale(1.2);
}
.profile_header_cover-image {
  height: 30rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 0;
  position: relative;
}
@media all and (max-width: 500px) {
  .profile_header_cover-image {
    height: 25rem;
  }
}
.profile_header_cover-image:before {
  content: "";
  width: 100%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
  pointer-events: none;
}
.profile_header_user-image {
  margin-top: -26rem !important;
  height: 30rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  color: #fff !important;
  position: relative;
}
.profile_header_user-image img {
  width: 14rem;
  height: 14rem;
  border-radius: 50rem;
  display: inline-block;
  margin-top: 3rem;
  padding: 0px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
@media all and (max-width: 500px) {
  .profile_header_user-image img {
    width: 11rem;
    height: 11rem;
    margin-top: 2rem;
  }
}
.profile_header_user-image h1 {
  display: inline-block;
  font-size: 2.8rem;
  font-weight: 400;
  text-transform: capitalize;
}
.profile_header_user-image h1 span i {
  font-size: 2rem;
}
.profile_header_user-image h1 span.gppv-credit-info {
  background: #6ec568;
  color: #fff;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-top: 0rem;
  display: inline-block;
}
.profile_header_user-image h1 span.gppv-credit-info span {
  font-weight: 400;
  display: inline-block;
  margin-right: 0.4rem;
}
@media all and (max-width: 700px) {
  .profile_header_user-image h1 {
    font-size: 2.4rem;
  }
}
@media all and (max-width: 500px) {
  .profile_header_user-image h1 {
    font-size: 2rem;
  }
}
.profile_header_user-image .engage_buttons {
  position: relative;
}
.profile_header_user-image .engage_button_numbers,
.profile_header_user-image .engage_buttons_views,
.profile_header_user-image .engage_buttons_rating {
  margin-top: 0;
}
.profile_header_user-image .engage_buttons_rating {
  margin-right: 0 !important;
}
.profile_header_user-image .engage_buttons_share_modal {
  position: absolute;
  top: 2rem;
  width: 300px !important;
  max-width: initial;
}
.profile_header_user-image .engage_buttons_share_modal div,
.profile_header_user-image .engage_buttons_share_modal button,
.profile_header_user-image .engage_buttons_share_modal a,
.profile_header_user-image .engage_buttons_share_modal i {
  line-height: 1 !important;
}
.profile_header_user-image .engage_buttons_share_button {
  margin-right: 0;
}
.profile_header_user-image .engage_buttons_share_button .engage_button_numbers {
  display: none;
}

@media all and (max-width: 600px) {
  .profile-content {
    width: 100% !important;
    padding: 0;
  }
}

.profile-content-limited {
  padding: 0rem;
  max-width: 120rem;
  margin: 0 auto;
  display: flex;
}
@media all and (max-width: 1260px) {
  .profile-content-limited {
    width: 97%;
  }
}
.profile-content-limited .profile-tab-limited {
  flex: 1;
  margin-right: 2rem;
}
@media all and (max-width: 900px) {
  .profile-content-limited .profile-tab-limited {
    margin-right: 0;
  }
}
.profile-content-limited .profile-tab-limited .ProductDetailReviews {
  margin-top: 0;
}
@media all and (max-width: 900px) {
  .profile-content-limited .profile-tab-limited .new-profile-description {
    margin-bottom: 0 !important;
  }
}
@media all and (max-width: 600px) {
  .profile-content-limited .profile-tab-limited .new-profile-description {
    border-radius: 0 !important;
  }
}

.profile-content .__react_component_tooltip {
  margin-top: 0 !important;
  background: #fff;
  border: 0;
}

.profile_content {
  width: 50%;
  display: inline-block;
  margin-left: 0;
  margin-right: 2rem;
  text-align: left;
}
.profile_content .ProductDetailReviews {
  border-radius: 1rem;
}
@media all and (max-width: 1250px) {
  .profile_content {
    width: 64%;
  }
}
@media all and (max-width: 900px) {
  .profile_content {
    width: 100%;
    margin-right: 0;
    margin-top: 3rem;
  }
}

.profile_content + .main-sidebar {
  display: inline-block;
  float: none;
  vertical-align: top;
  margin-right: 0;
  text-align: left;
}

.profile-tab {
  border: 1px solid #ededed;
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  background: #fff;
}
.profile-tab_content {
  margin-top: 2rem;
}

.profile-review {
  margin-bottom: 4rem;
  text-align: left;
  border-bottom: 1px solid #ededed;
  padding-bottom: 4rem;
}
.profile-review:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}
.profile-review_rating {
  margin-bottom: 2rem;
  text-align: center;
  border-bottom: 0;
}
.profile-review_rating i {
  font-size: 2rem;
}
.profile-review_text {
  padding: 0;
  margin-bottom: 1rem;
}
.profile-review_meta {
  font-size: 1.1rem;
  text-align: right;
  color: #b5b5b5;
  padding-right: 2rem;
}

.view-all-followers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.view-all-followers .followers-list-box {
  width: 17%;
  margin: 1.5%;
}
@media all and (max-width: 1200px) {
  .view-all-followers .followers-list-box {
    width: 21%;
    margin: 2%;
  }
}
@media all and (max-width: 700px) {
  .view-all-followers .followers-list-box {
    width: 28%;
    margin: 2%;
  }
}
@media all and (max-width: 500px) {
  .view-all-followers .followers-list-box {
    width: 42%;
    margin: 2%;
  }
}
.view-all-followers .followers-list-box img {
  width: 100%;
  display: block;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.WallListing .engage_buttons {
  text-align: center;
}

.WallListing .engage_the-comment_content {
  max-width: 56rem;
}

@media all and (max-width: 750px) {
  .WallListing .engage_comments {
    width: 98%;
    margin-left: 2%;
  }
}

@media all and (max-width: 580px) {
  .WallListing footer {
    padding-bottom: 4rem !important;
  }
}

.WallListingContent .category-list_item {
  width: 100%;
  border-radius: 0;
}
.WallListingContent .category-list_item img {
  border-radius: 0;
}
.WallListingContent .category-list_item .job-description-actions {
  justify-content: flex-start;
}
.WallListingContent .category-list_item .job-description-actions button {
  margin-left: 2rem !important;
}

.WallListingContent .event-item-preview_details {
  padding: 2rem 2rem 0 2rem;
}

.WallListing .engage_buttons {
  text-align: left;
}

.WallListing .engage_comments {
  margin-top: 0 !important;
}

.profile_header .logged-in-header {
  background: #fff;
  width: 76.5%;
  margin: 0 auto;
  padding-bottom: 0rem;
  border-radius: 1rem;
}
@media all and (max-width: 1250px) {
  .profile_header .logged-in-header {
    width: 90.5%;
  }
}
@media all and (max-width: 1100px) {
  .profile_header .logged-in-header {
    width: 95.5%;
  }
}
@media all and (max-width: 900px) {
  .profile_header .logged-in-header {
    width: 100%;
    border-radius: 0;
  }
}
.profile_header .logged-in-header .profile-nav-box {
  width: 150px !important;
}
@media all and (max-width: 700px) {
  .profile_header .logged-in-header .profile-nav-box {
    width: 104px !important;
  }
  .profile_header .logged-in-header .profile-nav-box-title {
    height: 8rem !important;
    width: 3rem !important;
    margin: 1rem 0rem !important;
  }
  .profile_header .logged-in-header .profile-nav-box-title span {
    font-size: 1.2rem !important;
  }
  .profile_header .logged-in-header .profile-nav-box button {
    height: 7rem !important;
    width: 7rem !important;
  }
  .profile_header .logged-in-header .profile-nav-box button.square-menu-item {
    height: 9rem !important;
    width: 9rem !important;
  }
  .profile_header
    .logged-in-header
    .profile-nav-box
    button.square-menu-item
    .square-menu-item-text {
    width: 7rem !important;
  }
  .profile_header .logged-in-header .profile-nav-box a {
    height: 7rem !important;
    width: 7rem !important;
  }
  .profile_header .logged-in-header .profile-nav-box i {
    font-size: 2rem !important;
    margin-bottom: 1rem !important;
  }
}

.profile_header .logged-in-header_to-do_swiper {
  width: 100%;
}

.profile_header .logged-in-header button {
  height: 10rem;
  border: 1px solid #ededed;
}
.profile_header .logged-in-header button:hover {
  border: 1px solid #ededed;
}

.profile_header .profile-nav-box-title {
  height: 12rem;
  border-right: 1px solid #ededed;
  width: 3rem;
  margin: 1rem !important;
  text-align: left;
  position: relative;
}
.profile_header .profile-nav-box-title span {
  display: block;
  transform: rotate(-90deg);
  text-align: left;
  position: absolute;
  bottom: 0rem;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  right: -0.5rem;
}

.profile-nav-box {
  width: 15.4rem;
  text-align: center;
  margin-right: -0.6rem !important;
}
.profile-nav-box a {
  height: 10rem !important;
  flex-direction: column;
  border: 1px solid #fff;
}
.profile-nav-box a i {
  margin-bottom: 1rem;
}
.profile-nav-box a:hover {
  border: 1px solid #ededed;
}
@media all and (max-width: 750px) {
  .profile-nav-box a {
    margin: 1rem !important;
    width: 11rem;
  }
}

.square-menu-item {
  border: 1px solid #ededed;
  padding: 0 !important;
  height: 12rem !important;
  width: 13rem !important;
  border-radius: 1rem !important;
  overflow: hidden;
  position: relative;
  display: block !important;
  flex-direction: unset !important;
}
.square-menu-item-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 11rem !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 1rem;
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
  z-index: 999999;
}
.square-menu-item-icon span {
  font-weight: 600;
}
.square-menu-item-text {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 11rem !important;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 1rem;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  z-index: 9999999;
}
.square-menu-item:hover .square-menu-item-icon {
  opacity: 0.2;
}
.square-menu-item_box {
  background: #fff;
  display: block;
  float: left;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  width: 50%;
  height: 50%;
  line-height: 1 !important;
}
.square-menu-item_box i {
  font-size: 2rem !important;
  margin-top: 45%;
  transform: translateY(-50%);
  display: block;
}
.square-menu-item_box:first-child {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.square-menu-item_box:nth-child(2) {
  border-bottom: 1px solid #fff;
}
.square-menu-item_box:nth-child(3) {
  border-right: 1px solid #fff;
}
.square-menu-item_box img {
  object-fit: cover;
  width: 100%;
  display: block;
  height: 100%;
}

.profile-posts-container {
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
}
@media all and (max-width: 600px) {
  .profile-posts-container {
    padding: 0 !important;
  }
}
.profile-posts-container .main-container {
  width: 73% !important;
  margin-left: 0 !important;
}
@media all and (max-width: 1100px) {
  .profile-posts-container .main-container {
    width: 68% !important;
  }
}
@media all and (max-width: 900px) {
  .profile-posts-container .main-container {
    width: 100% !important;
  }
}
.profile-posts-container .main-container h4 {
  margin-bottom: 2rem;
  margin-top: 0rem;
}
@media all and (max-width: 600px) {
  .profile-posts-container .main-container h4 {
    padding: 0 2rem !important;
  }
}
.profile-posts-container .main-sidebar {
  margin-top: 4rem;
  margin-right: 0;
}

.h4-followers-number-title {
  font-size: 1.5rem !important;
}
.h4-followers-number-title span:last-child {
  display: inline-block;
  border-left: 1px solid #ededed;
  padding-left: 1rem;
  margin-left: 0.8rem;
}

.followers-number-title {
  font-weight: 400;
  color: #555;
  display: inline-block;
  margin-right: 0;
  text-transform: none;
}

.profile-main-container {
  width: 90% !important;
  float: none !important;
  margin-left: 5% !important;
}
@media all and (max-width: 1200px) {
  .profile-main-container {
    width: 90% !important;
  }
}
@media all and (max-width: 1100px) {
  .profile-main-container {
    width: 95% !important;
    margin-left: 2.5% !important;
  }
}
@media all and (max-width: 1000px) {
  .profile-main-container {
    width: 95% !important;
  }
}

.profile_header {
  margin-bottom: 2rem;
}

.profile_content {
  width: 70.5%;
}
@media all and (max-width: 1100px) {
  .profile_content {
    width: 65.5%;
  }
}
@media all and (max-width: 900px) {
  .profile_content {
    width: 97%;
  }
}

.profile_header .logged-in-header {
  width: 97%;
}
@media all and (max-width: 900px) {
  .profile_header .logged-in-header {
    width: 100%;
  }
}

.about-profile-tab {
  border: 0px solid #ededed;
  padding: 0rem;
  margin-bottom: 0rem;
  border-radius: 0rem;
  background: none;
}
.about-profile-tab > h4 {
  display: none;
}
.about-profile-tab .profile-tab_content {
  margin-top: 0;
}
.about-profile-tab .new-profile-description {
  padding: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
  background: #fff;
  padding: 4rem;
}
.about-profile-tab .main-sidebar_box {
  padding: 0;
  width: 30%;
  display: inline-block;
  vertical-align: top;
  margin-right: 6%;
}
@media all and (max-width: 1250px) {
  .about-profile-tab .main-sidebar_box {
    width: 34%;
  }
}
@media all and (max-width: 1050px) {
  .about-profile-tab .main-sidebar_box {
    width: 100%;
    margin-bottom: 4rem;
  }
}
.about-profile-tab .main-sidebar_box h4 span {
  font-weight: 400;
  display: inline-block;
  margin-top: 2.5rem;
  font-size: 2.4rem;
  margin-bottom: -1rem;
  line-height: 2.8rem;
  text-transform: uppercase;
  margin-left: -2px;
}
.about-profile-tab .main-sidebar_box table {
  width: 100%;
  table-layout: fixed;
}
.about-profile-tab .main-sidebar_box table td:first-child {
  width: 40px;
}
.about-profile-tab .main-sidebar_box table td:last-child {
  width: 100%;
}
.about-profile-tab .BusinessAboutTab {
  width: 64%;
  display: inline-block;
}
@media all and (max-width: 1250px) {
  .about-profile-tab .BusinessAboutTab {
    width: 60%;
  }
}
@media all and (max-width: 1050px) {
  .about-profile-tab .BusinessAboutTab {
    width: 100%;
  }
}
.about-profile-tab .BusinessAboutTab h4 {
  margin-bottom: 2rem;
}
.about-profile-tab .BusinessAboutTab .ProductDetailBox {
  padding: 0px;
}
.about-profile-tab .BusinessAboutTab .ProductDetailBox p strong {
  display: block;
  margin-bottom: -1rem;
}

.accredited-business {
  vertical-align: middle;
}
.accredited-business img {
  max-width: 120px;
  display: inline-block;
  margin-top: -1.2rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
}

@media all and (max-width: 500px) {
  .profile-update .profile-update-settings_box p {
    display: block;
  }
  .profile-update .profile-update-settings_box .form-input-lists_row {
    margin-bottom: 1.6rem !important;
  }
}

@media all and (max-width: 330px) {
  .profile-update .profile-update-settings_box .form-input-lists_row label {
    font-size: 1.3rem;
  }
}

.profile_header
  .logged-in-header_to-do_swiper
  .swiper-wrapper
  .profile-nav-box-last {
  width: 40px !important;
}

.profile-social-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.4rem;
  width: 100%;
  flex-wrap: wrap;
}
.profile-social-container-buttons {
  margin-top: 0 !important;
}
.profile-social-container-buttons .engage_buttons i,
.profile-social-container-buttons .engage_buttons span {
  color: #fff !important;
}
.profile-social-container-buttons .engage_buttons span {
  width: auto !important;
}
.profile-social-container-buttons .engage_buttons_rating {
  margin-right: 1rem !important;
}
@media all and (max-width: 450px) {
  .profile-social-container-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0rem;
  }
  .profile-social-container-buttons .engage_buttons {
    justify-content: center;
  }
}
.profile-social-container .profile-follow-button {
  font-size: 1.4rem;
}
.profile-social-container .profile-follow-button button {
  margin-left: 2rem;
  width: 15rem;
}
@media all and (max-width: 700px) {
  .profile-social-container .profile-follow-button button {
    margin-left: 0rem;
    margin-top: 1.4rem;
  }
}
@media all and (max-width: 700px) {
  .profile-social-container .profile-follow-button {
    width: 100%;
  }
}

.profile-nav-box {
  width: 14.4rem;
}

@media (max-width: 900px) {
  .profile_content {
    margin-top: 0;
  }
}

@media (max-width: 750px) {
  .profile-nav-box {
    width: 14rem;
  }
  .profile-nav-box a,
  .profile-nav-box button {
    margin: 1rem 0.5rem !important;
    width: 11rem;
  }
}

.profile-favorites {
  display: block;
  background: #ffffff;
  border-radius: 1rem;
  padding: 4rem;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  z-index: 20;
  text-align: left;
}
@media all and (max-width: 600px) {
  .profile-favorites {
    width: 100%;
    border-radius: 0;
    padding: 2rem 0;
    margin-top: -36px;
  }
}
.profile-favorites h4 {
  margin-bottom: 2rem;
}
@media all and (max-width: 600px) {
  .profile-favorites h4 {
    padding: 0 2rem;
  }
}
.profile-favorites-main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.profile-favorites-main-nav {
  width: 100%;
}
.profile-favorites-main-nav .manage-listings_nav {
  width: 100%;
}
@media all and (max-width: 600px) {
  .profile-favorites-main-nav .manage-listings_nav {
    margin-bottom: 4px;
  }
  .profile-favorites-main-nav .manage-listings_nav_header {
    padding: 0 2rem;
  }
  .profile-favorites-main-nav .manage-listings_nav_items {
    border-radius: 0;
  }
}
.profile-favorites-main-content {
  width: 100%;
}
.profile-favorites-main-content .media-gallery-category_listing {
  border: 1px solid #e7e7e7;
}
.profile-favorites-main-content .category-list_item {
  border: 1px solid #e7e7e7;
}
.profile-favorites-main-content .profile-main-container {
  width: 100% !important;
  margin-left: 0 !important;
}
.profile-favorites-main-content .profile-main-container h4 {
  font-size: 1.4rem;
  display: block;
  padding: 1.2rem 2rem;
  border-bottom: 1px solid #ededed;
  width: 100%;
  text-align: left;
  text-transform: uppercase;
  font-weight: 600;
}
@media all and (max-width: 1100px) {
  .profile-favorites-main-content {
    width: 100%;
  }
}

.featured-slider .swiper-container {
  width: 100%;
  height: 100%;
  border: 1 solid #ededed;
  padding-bottom: 2rem;
  overflow: hidden;
}
@media all and (max-width: 920px) {
  .featured-slider .swiper-container {
    padding: 0;
    padding-bottom: 2rem;
    border-radius: 0;
  }
}
@media all and (max-width: 770px) {
  .featured-slider .swiper-container {
    border-radius: 1rem;
  }
}

.featured-slider .swiper-pagination-fraction,
.featured-slider .swiper-pagination-custom,
.featured-slider .swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 1.4rem;
}
@media all and (max-width: 920px) {
  .featured-slider .swiper-pagination-fraction,
  .featured-slider .swiper-pagination-custom,
  .featured-slider .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 0;
  }
}

.featured-slider .swiper-pagination-bullet-active {
  opacity: 1;
  background: #469ad4;
}

.featured-slider
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next,
.featured-slider
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-next-next,
.featured-slider
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev,
.featured-slider
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.featured-slider .swiper-slide {
  text-align: center;
  font-size: 1.8rem;
  background: #ffffff;
  width: 80rem;
  border: 1px solid #ededed;
  height: 38rem;
  border-radius: 1rem;
  overflow: hidden;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
@media all and (max-width: 920px) {
  .featured-slider .swiper-slide {
    width: 100%;
    box-sizing: border-box;
    margin-left: 0.2rem;
    height: auto;
    border: 0;
    border-radius: 0;
  }
}
@media all and (max-width: 770px) {
  .featured-slider .swiper-slide {
    width: 40rem;
    box-sizing: border-box;
    margin-left: 0.2rem;
    height: auto;
    border: 0;
    border-radius: 1rem;
  }
}
@media all and (max-width: 400px) {
  .featured-slider .swiper-slide {
    width: 30rem;
    box-sizing: border-box;
    margin-left: 0.2rem;
    height: auto;
    border: 0;
    border-radius: 1rem;
  }
}

.featured-slider .swiper-slide-active {
  opacity: 1;
}

.featured-slider .swiper-button-next,
.swiper-button-prev {
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.featured-slider .swiper-button-next {
  right: 3rem;
}

.featured-slider .swiper-button-prev {
  left: 3rem;
}

.featured-slider .swiper-container:hover .swiper-button-next,
.featured-slider .swiper-container:hover .swiper-button-prev {
  opacity: 1;
}

@media print {
  button.toggle-sidebar {
    display: none !important;
  }
}

.engage_buttons_share_modal {
  display: flex;
  max-width: 26rem;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-shadow: 0px 0px 2.1rem -4px rgba(0, 0, 0, 0.34);
  border: 0;
  z-index: 99;
  left: 50% !important;
  transform: translatex(-50%) !important;
}
.engage_buttons_share_modal .link-copied {
  color: #fff;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.paid-notifications-list .category-list_item {
  width: 96% !important;
  margin: 2% !important;
}
@media all and (max-width: 780px) {
  .paid-notifications-list .category-list_item {
    width: 100% !important;
    margin: 0 0 2rem 0 !important;
  }
}

@media all and (max-width: 500px) {
  .paid-notifications-pagination .pagination button.pagination_number,
  .paid-notifications-list .pagination button.pagination_number {
    font-size: 1.1rem;
    padding: 0.2rem;
  }
  .paid-notifications-pagination .pagination span,
  .paid-notifications-list .pagination span {
    margin: 0;
  }
}

@media all and (max-width: 360px) {
  .paid-notifications-pagination .pagination button.pagination_number,
  .paid-notifications-list .pagination button.pagination_number {
    display: none;
  }
  .paid-notifications-pagination .pagination span,
  .paid-notifications-list .pagination span {
    margin: 0.5rem;
  }
}

.gppv-end-text {
  color: #ee6565;
  font-style: italic;
  font-weight: 900;
}
.gppv-end-text:before {
  content: " - ";
  margin-left: 0.5rem;
  margin-right: 1rem;
  display: inline-block;
}

.modal-crop {
  width: 90%;
  min-width: 32rem;
  max-height: 90%;
  background: #f8f8f8;
  border-radius: 1rem;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
}
.modal-crop .media-modal_box {
  height: 85%;
  overflow: hide;
  display: block;
  margin: 0 auto;
  background: #fff;
  text-align: center;
}
@media all and (max-width: 800px) {
  .modal-crop .media-modal_box {
    height: auto;
  }
}
.modal-crop .media-modal_box .ReactCrop {
  height: 100%;
  text-align: center;
  max-height: 100%;
  max-width: 100%;
}
.modal-crop .media-modal_box .ReactCrop img {
  max-height: 84vh;
  max-width: 100%;
  display: block;
  margin: 0 auto;
}
.modal-crop .media-modal_desc {
  width: 100%;
  height: 15%;
  background: #fff;
  padding: 2rem;
  overflow-y: initial;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.modal-crop .media-modal_desc .media-modal_desc_crop-image {
  margin-right: 1rem;
}
@media all and (max-width: 980px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image {
    margin-right: 0;
  }
}
.modal-crop .media-modal_desc .media-modal_desc_crop-image input {
  width: auto;
  text-align: center;
  padding: 1rem;
  border: 1px solid #469ad4;
  text-transform: uppercase;
  border-radius: 5rem;
  cursor: pointer;
  padding: 1.2rem 3.2rem;
  font-size: 1.3rem;
  margin-bottom: 0 !important;
}
.modal-crop .media-modal_desc .media-modal_desc_crop-image input:hover {
  background: #469ad4;
  color: #ffffff;
}
@media all and (max-width: 1200px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image input {
    width: 14rem;
    padding: 1.2rem 2.4rem;
    margin-bottom: 1rem !important;
  }
}
@media all and (max-width: 980px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image input {
    margin-bottom: 0rem !important;
  }
}
@media all and (max-width: 920px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-image input {
    margin: 0.5rem !important;
  }
}
.modal-crop .media-modal_desc .crop-modal-mobile-button {
  margin-right: 1rem;
}
.modal-crop .media-modal_desc .crop-modal-mobile-button button {
  padding: 1.4rem 2.4rem;
  margin-bottom: 0 !important;
}
@media all and (max-width: 920px) {
  .modal-crop .media-modal_desc .crop-modal-mobile-button button {
    margin: 0.5rem !important;
  }
}
@media all and (max-width: 850px) {
  .modal-crop .media-modal_desc .crop-modal-mobile-button button {
    padding: 1.4rem 1.4rem;
  }
}
@media all and (max-width: 1200px) {
  .modal-crop .media-modal_desc .crop-modal-mobile-button {
    margin-bottom: 1rem;
  }
}
@media all and (max-width: 980px) {
  .modal-crop .media-modal_desc .crop-modal-mobile-button {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }
}
.modal-crop .media-modal_desc .media-modal_desc_crop-desc {
  margin-right: 2.2rem;
}
@media all and (max-width: 920px) {
  .modal-crop .media-modal_desc .media-modal_desc_crop-desc {
    margin-right: 0;
  }
}

.media-gallery-category_listing_stats
  div.engage_buttons
  div.engage_buttons_share {
  width: auto !important;
}

.media-gallery-category_listing_stats .button {
  margin-top: 2rem !important;
}

.spin-enter-web {
  display: block;
  width: 10rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.blog-post_feature {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.blog-post_feature_image {
  width: 48%;
}
@media all and (max-width: 1150px) {
  .blog-post_feature_image {
    width: 40%;
  }
}
@media all and (max-width: 750px) {
  .blog-post_feature_image {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.blog-post_feature .blog-post_header {
  width: 48%;
  text-align: left;
}
.blog-post_feature
  .blog-post_header
  .engage_buttons
  .engage_buttons_share_modal {
  transform: translateX(0) !important;
}
@media all and (max-width: 1150px) {
  .blog-post_feature .blog-post_header {
    width: 56%;
  }
}
@media all and (max-width: 750px) {
  .blog-post_feature .blog-post_header {
    width: 100%;
  }
}
@media all and (max-width: 650px) {
  .blog-post_feature .blog-post_header {
    text-align: center;
    padding: 0 2rem;
  }
  .blog-post_feature .blog-post_header .blog-post_header_meta::before {
    margin: 1.6rem auto;
  }
  .blog-post_feature .blog-post_header .engage_buttons {
    justify-content: center;
  }
}

.main-sidebar_box {
  padding: 2rem;
}

.main-sidebar_box_1rempad {
  padding: 2rem 1rem;
}
.main-sidebar_box_1rempad h4 {
  padding: 0 1rem;
}

.main-sidebar_box_sponsored .category-list_item,
.main-sidebar_box_sponsored .media-gallery-category_listing {
  border: 1px solid #e7e7e7;
}
.main-sidebar_box_sponsored .category-list_item:first-child,
.main-sidebar_box_sponsored .media-gallery-category_listing:first-child {
  margin-bottom: 1rem !important;
}
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_love,
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_views,
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_comments,
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_share,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_love,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_views,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_comments,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_share,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_love,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_views,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_comments,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_share,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_love,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_views,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_comments,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_share,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_love,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_views,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_comments,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_share,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_love,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_views,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_comments,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_share {
  margin-left: 0px !important;
  margin-right: 1.6rem !important;
  display: flex !important;
}
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_love
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_views
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_comments
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_love
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_views
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_comments
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_love
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_views
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_comments
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_love
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_views
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_comments
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_love
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_views
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_comments
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_love
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_views
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_comments
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers {
  width: auto !important;
}
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_share
  .engage_button_numbers {
  display: none;
}
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_favorites,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_favorites,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_favorites,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_favorites,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_favorites,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_favorites {
  margin: 0 !important;
  display: flex !important;
}
.main-sidebar_box_sponsored
  .category-list_item
  .store-listing-preview
  .engage_buttons
  .engage_buttons_favorites
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_favorites
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .category-list_item
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_favorites
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .store-listing-preview
  .engage_buttons
  .engage_buttons_favorites
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .media-gallery-category_listing_stats
  .engage_buttons
  .engage_buttons_favorites
  .engage_button_numbers,
.main-sidebar_box_sponsored
  .media-gallery-category_listing
  .blog-item-preview_stats
  .engage_buttons
  .engage_buttons_favorites
  .engage_button_numbers {
  display: none;
}

.product-main-details {
  overflow: hidden;
}

.Backdrop {
  z-index: 1000;
}

.modal,
.modal_wide,
.modal-crop {
  z-index: 1100;
}

.modal_close {
  z-index: 9999999999 !important;
}

.cookie-modal {
  z-index: 9999;
}

.account-info-modal,
.account-type-modal,
.account-login-modal {
  z-index: 999999999;
}

.toggle-sidebar {
  z-index: 1000;
}

@media all and (max-width: 900px) {
  .main-sidebar {
    z-index: 1000;
  }
}

.mobile-menu-modal {
  z-index: 1050;
}

nav div .sub-menu {
  z-index: 999;
}

.video-cat-modal-link {
  position: absolute;
  background: transparent;
  display: block;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 100;
}

@media all and (max-width: 375px) {
  .site-sign-in-header-cta,
  .site-sign-in-header-iconlist,
  .modal_breadcrumb {
    display: none;
  }
  .site-sign-in-header-title {
    padding-bottom: 2rem;
  }
  .account-info-modal h4 {
    display: none;
  }
  .account-info-modal .modal_body_buttons_back {
    bottom: 3.3rem;
    left: 2.6rem;
  }
  .account-info-modal .modal_body_functions {
    margin-bottom: 0;
  }
  .account-info-modal .modal_body_functions_agree-terms {
    line-height: 1;
    margin-top: 1.6rem !important;
    margin-bottom: 1.6rem !important;
  }
  .account-info-modal .form-input-lists {
    margin: 0.4rem 0;
  }
}

@media all and (max-width: 780px) {
  .newPhotosAdded .selectedPhoto + .featured-image-text::after {
    width: 80%;
    text-align: center;
    transform: translate(0, 0);
    left: 10%;
    bottom: 5rem;
    margin: 0 auto;
    top: auto;
    line-height: 1;
  }
}

@media all and (max-width: 700px) {
  .newPhotosAdded button {
    position: initial;
    top: auto;
    right: auto;
    background: #fff;
    border-radius: 0.7rem;
    padding: 0.8rem 1.1rem;
    line-height: 1;
    font-size: 1.4rem;
    opacity: 1;
    color: #444;
    margin: 0.2rem;
    display: flex;
    align-items: center;
  }
  .newPhotosAdded button:after {
    content: "Remove";
    font-size: 10px;
    margin-left: 8px;
  }
}

@media all and (max-width: 550px) {
  .newPhotosAdded div div {
    width: 100%;
  }
  .newPhotosAdded div div div {
    width: 100%;
    display: block !important;
  }
}

.store-listing-preview {
  margin-top: -1rem;
}

.category-list_item {
  overflow: visible;
}
.category-list_item img {
  margin-bottom: 2rem;
}

.event-item-preview-image-date {
  bottom: -4.4rem;
}

.media-modal_desc_title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.media-modal_desc_text {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.modal {
  zoom: 0.9;
}

.small-businesses h3 {
  text-align: center;
  margin-bottom: 4rem;
}

.small-businesses .getinfo-wrapper {
  border-radius: 1rem;
  padding: 6rem 0;
}
.small-businesses .getinfo-wrapper .contact-box {
  background: rgba(255, 255, 255, 0.9);
}

.startup-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.startup-list-box {
  width: 48.5%;
  background: #fff;
  padding-bottom: 2rem;
  margin-bottom: 4rem;
  border-radius: 1rem;
  overflow: hidden;
}
@media all and (max-width: 1050px) {
  .startup-list-box {
    width: 48.5%;
  }
}
@media all and (max-width: 750px) {
  .startup-list-box {
    width: 100%;
    margin: 1rem 2rem;
  }
  .startup-list-box:first-child {
    margin-top: 0;
  }
}
.startup-list-box h4,
.startup-list-box ul {
  margin: 0 2rem;
}
.startup-list-box h4 {
  margin-bottom: 2rem;
}
.startup-list-box h4::before {
  font-family: "Font Awesome 5 Free";
  content: "\f054";
  font-weight: 900;
  margin-right: 0.5rem;
  font-size: 0.8em;
}
.startup-list-box img {
  width: 100%;
  margin-bottom: 3rem;
}
.startup-list-box ul li {
  padding: 1.4rem 0;
  padding-left: 1.2rem;
  font-size: 1.4rem;
  border-bottom: 1px solid #ededed;
}
.startup-list-box ul li:last-child {
  border-bottom: 0;
}
.startup-list-box ul li i {
  color: #6ec568;
  margin-right: 1rem;
}
.startup-list-box p {
  padding: 1rem 3.2rem;
  color: #777;
}

.WallListing footer .engage_buttons .engage_buttons_share,
.WallListing footer .engage_buttons .engage_buttons_favorites {
  display: none;
}

.store-listing-preview .engage_buttons {
  padding: 4px 0 !important;
}

.engage_buttons .engage_buttons_favorites {
  margin-right: 0 !important;
}
.engage_buttons .engage_buttons_favorites i {
  margin-right: 0;
}
.engage_buttons .engage_buttons_favorites .engage_button_numbers {
  display: none;
}

.ProductDetailMeta .engage_buttons_share_modal,
.blog-post_header_engage .engage_buttons_share_modal,
.MediaGalleryMeta .engage_buttons_share_modal,
.video-listing_details_comments .engage_buttons_share_modal {
  max-width: 240px;
}

.MediaGalleryMeta .engage_buttons,
.video-listing_details_comments .engage_buttons {
  max-width: 280px;
}

.media-modal_desc .engage_buttons {
  padding: 20px !important;
  position: relative;
}
.media-modal_desc .engage_buttons .engage_buttons_share_modal {
  position: absolute;
  top: 40px;
}

.view-more-comments {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  text-align: center;
}

.view-more-replies {
  width: 100%;
  padding: 20px;
  padding-top: 0;
  text-align: left;
}

.account-info-modal .button-small,
.account-type-modal .button-small,
.site-sign-in .button-small {
  font-size: 1.2rem !important;
}

.account-info-modal .forgot-password,
.account-type-modal .forgot-password,
.site-sign-in .forgot-password {
  font-size: 13px !important;
  color: #469ad4;
}

.account-info-modal .form-field-error br,
.account-type-modal .form-field-error br,
.site-sign-in .form-field-error br {
  display: none;
}

.account-info-modal .site-sign-in-header,
.account-type-modal .site-sign-in-header,
.site-sign-in .site-sign-in-header {
  margin-bottom: 16px;
}
.account-info-modal .site-sign-in-header .site-sign-in-header-title,
.account-type-modal .site-sign-in-header .site-sign-in-header-title,
.site-sign-in .site-sign-in-header .site-sign-in-header-title {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 1rem !important;
  font-size: 35px !important;
  line-height: 1 !important;
}
.account-info-modal .site-sign-in-header .site-sign-in-2ndcta,
.account-type-modal .site-sign-in-header .site-sign-in-2ndcta,
.site-sign-in .site-sign-in-header .site-sign-in-2ndcta {
  color: #fff;
  text-align: center;
  margin: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 5rem;
  font-size: 14px !important;
}

.account-info-modal .modal_body_functions_account-type,
.account-type-modal .modal_body_functions_account-type,
.site-sign-in .modal_body_functions_account-type {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.account-info-modal .modal_body_functions_account-type .have-account-button,
.account-type-modal .modal_body_functions_account-type .have-account-button,
.site-sign-in .modal_body_functions_account-type .have-account-button {
  font-size: 13px;
  display: block;
  width: 200px;
  margin: 20px auto 0 auto;
  text-align: center;
}
.account-info-modal .modal_body_functions_account-type .account-type-list,
.account-type-modal .modal_body_functions_account-type .account-type-list,
.site-sign-in .modal_body_functions_account-type .account-type-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(84px, 1fr));
  grid-gap: 10px;
  margin-bottom: 20px;
}
.account-info-modal .modal_body_functions_account-type .form-input-lists,
.account-type-modal .modal_body_functions_account-type .form-input-lists,
.site-sign-in .modal_body_functions_account-type .form-input-lists {
  border: 1px solid #e7e7e7;
  text-align: center;
  margin: 0;
  border-radius: 10px;
  padding: 18px 0;
  transition: all 0.3s ease-in-out;
}
.account-info-modal .modal_body_functions_account-type .form-input-lists input,
.account-type-modal .modal_body_functions_account-type .form-input-lists input,
.site-sign-in .modal_body_functions_account-type .form-input-lists input {
  display: none;
}
.account-info-modal .modal_body_functions_account-type .form-input-lists:hover,
.account-type-modal .modal_body_functions_account-type .form-input-lists:hover,
.site-sign-in .modal_body_functions_account-type .form-input-lists:hover {
  border: 1px solid #6ec568;
}
.account-info-modal
  .modal_body_functions_account-type
  .form-input-lists
  input[type="radio"]:checked
  + label
  i,
.account-type-modal
  .modal_body_functions_account-type
  .form-input-lists
  input[type="radio"]:checked
  + label
  i,
.site-sign-in
  .modal_body_functions_account-type
  .form-input-lists
  input[type="radio"]:checked
  + label
  i {
  color: #6ec568 !important;
}
.account-info-modal
  .modal_body_functions_account-type
  .form-input-lists:hover
  label
  span
  i,
.account-type-modal
  .modal_body_functions_account-type
  .form-input-lists:hover
  label
  span
  i,
.site-sign-in
  .modal_body_functions_account-type
  .form-input-lists:hover
  label
  span
  i {
  color: #6ec568;
  transition: all 0.3s ease-in-out;
}
.account-info-modal .modal_body_functions_account-type .form-input-lists div,
.account-type-modal .modal_body_functions_account-type .form-input-lists div,
.site-sign-in .modal_body_functions_account-type .form-input-lists div {
  font-size: 12px;
}
.account-info-modal
  .modal_body_functions_account-type
  .form-input-lists
  label
  span,
.account-type-modal
  .modal_body_functions_account-type
  .form-input-lists
  label
  span,
.site-sign-in .modal_body_functions_account-type .form-input-lists label span {
  display: block;
  width: 100%;
  margin-bottom: 4px;
}
.account-info-modal
  .modal_body_functions_account-type
  .form-input-lists
  label
  span
  i,
.account-type-modal
  .modal_body_functions_account-type
  .form-input-lists
  label
  span
  i,
.site-sign-in
  .modal_body_functions_account-type
  .form-input-lists
  label
  span
  i {
  margin-right: 0;
  padding: 0;
  border: 0;
  color: #ccc;
  font-size: 28px;
  width: auto;
  height: auto;
  font-weight: 300;
}

.account-info-modal .modal_body_functions h3,
.account-type-modal .modal_body_functions h3,
.site-sign-in .modal_body_functions h3 {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 16px;
}

.account-info-modal .modal_body_functions input[type="email"],
.account-info-modal .modal_body_functions input[type="password"],
.account-info-modal .modal_body_functions input[type="date"],
.account-info-modal .modal_body_functions input[type="text"],
.account-type-modal .modal_body_functions input[type="email"],
.account-type-modal .modal_body_functions input[type="password"],
.account-type-modal .modal_body_functions input[type="date"],
.account-type-modal .modal_body_functions input[type="text"],
.site-sign-in .modal_body_functions input[type="email"],
.site-sign-in .modal_body_functions input[type="password"],
.site-sign-in .modal_body_functions input[type="date"],
.site-sign-in .modal_body_functions input[type="text"] {
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  font-size: 14px;
  margin-bottom: 8px;
  height: 56px;
}

.account-info-modal .modal_body_functions .profile-url,
.account-type-modal .modal_body_functions .profile-url,
.site-sign-in .modal_body_functions .profile-url {
  margin-bottom: 8px;
}
.account-info-modal .modal_body_functions .profile-url input,
.account-type-modal .modal_body_functions .profile-url input,
.site-sign-in .modal_body_functions .profile-url input {
  margin-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.account-info-modal .modal_body_functions .profile-url .profile-url-ex,
.account-type-modal .modal_body_functions .profile-url .profile-url-ex,
.site-sign-in .modal_body_functions .profile-url .profile-url-ex {
  padding: 8px;
  text-align: left;
  padding-left: 19px;
  color: #555;
  background: #e7e7e7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.account-info-modal .modal_body_functions .flex,
.account-type-modal .modal_body_functions .flex,
.site-sign-in .modal_body_functions .flex {
  display: flex;
  justify-content: space-between;
}
.account-info-modal .modal_body_functions .flex input,
.account-type-modal .modal_body_functions .flex input,
.site-sign-in .modal_body_functions .flex input {
  width: 49%;
}
.account-info-modal .modal_body_functions .flex .gender,
.account-info-modal .modal_body_functions .flex .birthday,
.account-type-modal .modal_body_functions .flex .gender,
.account-type-modal .modal_body_functions .flex .birthday,
.site-sign-in .modal_body_functions .flex .gender,
.site-sign-in .modal_body_functions .flex .birthday {
  width: 49%;
}
.account-info-modal .modal_body_functions .flex .gender .main-label,
.account-info-modal .modal_body_functions .flex .birthday .main-label,
.account-type-modal .modal_body_functions .flex .gender .main-label,
.account-type-modal .modal_body_functions .flex .birthday .main-label,
.site-sign-in .modal_body_functions .flex .gender .main-label,
.site-sign-in .modal_body_functions .flex .birthday .main-label {
  width: 100%;
  text-align: left;
  display: block;
  margin-bottom: 4px;
}
.account-info-modal .modal_body_functions .flex .birthday input,
.account-type-modal .modal_body_functions .flex .birthday input,
.site-sign-in .modal_body_functions .flex .birthday input {
  width: 100% !important;
}
.account-info-modal .modal_body_functions .flex .gender .gender-group,
.account-type-modal .modal_body_functions .flex .gender .gender-group,
.site-sign-in .modal_body_functions .flex .gender .gender-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.account-info-modal .modal_body_functions .flex .gender .gender-group .female,
.account-info-modal .modal_body_functions .flex .gender .gender-group .male,
.account-type-modal .modal_body_functions .flex .gender .gender-group .female,
.account-type-modal .modal_body_functions .flex .gender .gender-group .male,
.site-sign-in .modal_body_functions .flex .gender .gender-group .female,
.site-sign-in .modal_body_functions .flex .gender .gender-group .male {
  width: 49%;
  margin: 0;
  border: 1px solid #e7e7e7;
  padding: 12px 6px;
  border-radius: 10px;
  height: 56px;
}
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  label,
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  label,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  label,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  label,
.site-sign-in .modal_body_functions .flex .gender .gender-group .female label,
.site-sign-in .modal_body_functions .flex .gender .gender-group .male label {
  display: flex;
  align-items: center;
  justify-content: center;
}
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  input,
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  input,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  input,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  input,
.site-sign-in .modal_body_functions .flex .gender .gender-group .female input,
.site-sign-in .modal_body_functions .flex .gender .gender-group .male input {
  display: none;
}
.account-info-modal .modal_body_functions .flex .gender .gender-group .female i,
.account-info-modal .modal_body_functions .flex .gender .gender-group .male i,
.account-type-modal .modal_body_functions .flex .gender .gender-group .female i,
.account-type-modal .modal_body_functions .flex .gender .gender-group .male i,
.site-sign-in .modal_body_functions .flex .gender .gender-group .female i,
.site-sign-in .modal_body_functions .flex .gender .gender-group .male i {
  margin-right: 4px;
}
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-check,
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-check,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-check,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-check,
.site-sign-in
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-check,
.site-sign-in
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-check {
  padding: 0.6rem 0 0 0rem !important;
}
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-female,
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-male,
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-female,
.account-info-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-male,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-female,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-male,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-female,
.account-type-modal
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-male,
.site-sign-in
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-female,
.site-sign-in
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .female
  i.fa-male,
.site-sign-in
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-female,
.site-sign-in
  .modal_body_functions
  .flex
  .gender
  .gender-group
  .male
  i.fa-male {
  border: 0;
  color: #999;
  font-size: 30px;
  height: auto;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 300 !important;
}
.account-info-modal .modal_body_functions .flex .form-input-select,
.account-type-modal .modal_body_functions .flex .form-input-select,
.site-sign-in .modal_body_functions .flex .form-input-select {
  width: 49%;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  height: 56px;
  min-width: auto;
}
.account-info-modal .modal_body_functions .flex .form-input-select select,
.account-type-modal .modal_body_functions .flex .form-input-select select,
.site-sign-in .modal_body_functions .flex .form-input-select select {
  border-bottom: 0;
}

.category-list_item .engage_buttons,
.media-gallery-category_listing .engage_buttons {
  position: relative;
}

.category-list_item .engage_buttons_share_modal,
.media-gallery-category_listing .engage_buttons_share_modal {
  position: absolute;
  left: 0;
  top: 20px;
}

.ProductDetailHeaderBox {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductDetailHeaderBox .ProductDetailHeaderBoxinBox {
  width: 100%;
}
.ProductDetailHeaderBox .ProductDetailHeaderBoxinBox img {
  width: 100%;
  cursor: pointer;
}

.ProductDetailHeaderBox img {
  width: 100%;
}

.ServicesTile {
  background: #fff;
}
.ServicesTile .ServicesTile__BusinessRating {
  margin-top: 1rem;
}
.ServicesTile .ServicesTile__BusinessRating .engage_buttons {
  justify-content: center;
}

.ProductDetailHeaderBox .swiper-slide-active img {
  cursor: pointer;
}

.logged-in-header_to-do .logged-in-header_to-do_swiper {
  background: #fff;
  padding-top: 1rem !important;
  box-shadow: inset -2px 0px 5px 0px rgba(0, 0, 0, 0.1);
}
.logged-in-header_to-do .logged-in-header_to-do_swiper h4 {
  margin-left: 2rem !important;
}

.media-modal_desc .engage_buttons_share_modal {
  border: 0 !important;
  box-shadow: none !important;
}

.category-enterprise-list {
  text-align: left;
  font-size: 1.3rem;
  height: 50px;
  padding: 15px;
}

.category-enterprise-list-colors .row:nth-child(even) {
  background-color: #f8f8f8;
}

.listing-engage-box .engage_buttons {
  display: inline-flex;
}

.print-button {
  margin-left: 3.2rem;
  order: 1;
  color: #777;
  font-size: 1.8rem;
}

.print-button:hover {
  color: #777;
}

.modal-crop .media-modal_desc .media-modal_desc_crop-image input {
  max-width: 300px;
}

.button-small2 {
  padding: 1rem 1rem;
  line-height: 1.1rem !important;
  font-weight: 600;
}
.button-small2:not(:last-child) {
  margin-right: 2px;
}

.listing-preview-user-buttons {
  display: flex;
  justify-content: space-between;
}

.button-enterprise-contact {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff !important;
  text-transform: uppercase;
  border-radius: 5rem;
  transition: all 0.3s ease-in-out;
  margin: 10px, 0, 10px, 0;
  padding: 1.2rem 3.2rem;
  font-size: 1.3rem;
  cursor: pointer;
}

.button-enterprise-contact:hover {
  color: #999 !important;
  background-color: white;
}

.account-info-modal .modal_body select {
  border: 0 !important;
}

.__react_component_tooltip {
  margin-top: 0px !important;
  border: 0 !important;
}

.dealerPlus {
  color: #444;
  text-align: left;
  padding: 2rem;
  padding-top: 0;
}
.dealerPlus ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dealerPlus p {
  margin-bottom: 2rem;
  text-align: center;
}
.dealerPlus a {
  color: #444;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dealerPlus a img {
  border-radius: 4px;
  width: 75px;
  height: 75px;
  margin-bottom: 1rem;
}

.sub-cancel-policy {
  max-width: 300px;
  color: #fff;
  padding: 0 20px;
  text-align: left;
}
.sub-cancel-policy a {
  color: #fff;
  text-decoration: underline;
}

.chat-body #CookiebotWidget {
  display: none !important;
}

.main-100-header p {
  font-size: 1.6rem;
  max-width: 80rem;
}

@media all and (max-width: 600px) {
  .dealer .section-h {
    padding: 0 2rem;
  }
}

.dealer-activate {
  display: flex;
  background: #fff;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}
@media all and (max-width: 1000px) {
  .dealer-activate {
    flex-direction: column;
  }
}
.dealer-activate h2 {
  margin-bottom: 2rem;
}
.dealer-activate img {
  display: block;
  width: 50%;
  order: 2;
}
@media all and (max-width: 1000px) {
  .dealer-activate img {
    order: 0;
    width: 100%;
  }
}
.dealer-activate-details {
  padding: 4rem;
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: space-between;
}
@media all and (max-width: 1000px) {
  .dealer-activate-details {
    width: 80%;
    background: rgba(255, 255, 255, 0.9);
    margin: 0 auto;
    margin-top: -400px;
    overflow: hidden;
    border-radius: 1rem;
    border: 1px solid #e7e7e7;
    margin-bottom: 3rem;
  }
}
@media all and (max-width: 600px) {
  .dealer-activate-details {
    width: 90%;
    padding: 2rem;
  }
}
@media all and (max-width: 450px) {
  .dealer-activate-details {
    width: 100%;
    margin-top: 0px;
    border: 0;
    border-radius: 0rem;
  }
}
.dealer-activate-details .dealer-cost {
  background: #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 220px;
  border-radius: 100%;
  margin-top: -80px;
  margin-left: -60px;
  font-size: 2rem;
  line-height: 1;
}
@media all and (max-width: 1000px) {
  .dealer-activate-details .dealer-cost {
    margin-bottom: 2rem;
    margin-top: -70px;
    margin-left: -70px;
  }
}
@media all and (max-width: 600px) {
  .dealer-activate-details .dealer-cost {
    margin-top: -60px;
    margin-left: -40px;
  }
}
@media all and (max-width: 450px) {
  .dealer-activate-details .dealer-cost {
    margin-top: -64px;
    margin-left: -44px;
  }
}
.dealer-activate-details .dealer-cost .dealer-price {
  font-size: 3.4rem;
}
.dealer-activate-details .dealer-cost .dealer-terms {
  font-size: 1.3rem;
}
.dealer-activate-details .dealer-contact p {
  font-size: 1.8rem;
}
.dealer-activate-details .dealer-contact p.invite {
  margin-bottom: 2rem;
  font-size: 2rem;
  display: block;
}
.dealer-activate-details .dealer-contact p span {
  font-size: 1.4rem;
  color: #777;
  display: inline-block;
  width: 80px;
}
.dealer-activate-details .dealer-contact p a {
  color: #444;
}
.dealer-activate-details .dealer-contact p a:hover {
  border-bottom: 1px solid #444;
}

.dealer-form {
  display: flex;
  background: #fff;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  overflow: hidden;
  margin-bottom: 3rem;
}
@media all and (max-width: 1000px) {
  .dealer-form {
    flex-direction: column;
  }
}
.dealer-form img {
  width: 50%;
  display: block;
}
@media all and (max-width: 1000px) {
  .dealer-form img {
    width: 100%;
  }
}
.dealer-form-footer {
  border-radius: 1rem;
  margin-top: 3rem;
  position: relative;
}
.dealer-form-footer .dealer-cost {
  background: #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 220px;
  border-radius: 100%;
  font-size: 2rem;
  line-height: 1;
  position: absolute;
  top: -50px;
  left: -30px;
}
.dealer-form-footer .dealer-cost .dealer-price {
  font-size: 3.4rem;
}
.dealer-form-footer .dealer-cost .dealer-terms {
  font-size: 1.3rem;
}
.dealer-form .dealer-jotform {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}
@media all and (max-width: 1000px) {
  .dealer-form .dealer-jotform {
    width: 80%;
    background: rgba(255, 255, 255, 0.9);
    margin: 0 auto;
    margin-top: -260px;
    overflow: hidden;
    border-radius: 1rem;
    border: 1px solid #e7e7e7;
    margin-bottom: 3rem;
    height: 600px;
  }
}
@media all and (max-width: 600px) {
  .dealer-form .dealer-jotform {
    width: 90%;
    padding: 2rem;
  }
}
@media all and (max-width: 450px) {
  .dealer-form .dealer-jotform {
    width: 100%;
    margin-top: 0px;
    border: 0;
    border-radius: 0rem;
    height: 600px;
  }
}
.dealer-form .dealer-jotform h2 {
  margin-bottom: 1rem;
}
.dealer-form .dealer-jotform h4 {
  margin-bottom: 2rem;
  background: #c94040;
  color: #fff;
  padding: 1.4rem 2rem;
  border-radius: 5px;
}
.dealer-form .dealer-jotform a,
.dealer-form .dealer-jotform button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem;
  border: 1px solid #444;
  color: #444;
  height: 5rem;
  width: 13rem;
  font-size: 1.6rem;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
}
.dealer-form .dealer-jotform a:hover,
.dealer-form .dealer-jotform button:hover {
  background-color: #444;
  color: #fff;
}
.dealer-form .dealer-jotform .disclaimer {
  margin-top: 2rem;
  max-width: 500px;
  font-size: 1.4rem;
  text-align: left;
}
.dealer-form .dealer-jotform .disclaimer ul {
  margin: 2rem 0;
  display: inline-block;
}
.dealer-form .dealer-jotform .disclaimer ul li {
  text-align: left;
}

.dealer-platforms {
  background: #fff;
  padding: 4rem;
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
@media all and (max-width: 600px) {
  .dealer-platforms {
    padding: 2rem 5%;
  }
}
.dealer-platforms .tag {
  font-size: 1.6rem;
}
.dealer-platforms-items {
  display: flex;
  justify-content: space-between;
  margin-top: 4rem;
}
@media all and (max-width: 850px) {
  .dealer-platforms-items {
    flex-wrap: wrap;
  }
}
.dealer-platforms-item {
  width: 32%;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #f3f4ee;
}
@media all and (max-width: 850px) {
  .dealer-platforms-item {
    width: 100%;
  }
  .dealer-platforms-item:not(:last-child) {
    margin-bottom: 2rem;
  }
}
.dealer-platforms-item img {
  width: 100%;
}
.dealer-platforms-item .content {
  padding: 2rem;
}
.dealer-platforms-item .content .auto-logo {
  max-height: 50px;
  width: auto;
  margin-bottom: 1rem;
}
@media all and (max-width: 1000px) {
  .dealer-platforms-item .content .auto-logo {
    width: 100%;
    max-height: auto;
  }
}
@media all and (max-width: 850px) {
  .dealer-platforms-item .content .auto-logo {
    width: auto;
  }
}
.dealer-platforms-item .content .web-link {
  display: flex;
  color: #444;
  font-size: 1.8rem;
  align-items: center;
  margin-bottom: 2rem;
}
.dealer-platforms-item .content .web-link i {
  font-size: 2.2rem;
  margin-left: 1rem;
  display: block;
}
.dealer-platforms-item .content .apps p {
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-weight: 700;
}
.dealer-platforms-item .content .apps div {
  display: flex;
}
.dealer-platforms-item .content .apps i {
  font-size: 3.6rem;
  color: #444;
  display: inline-flex;
  width: 60px;
  height: 60px;
  border: 2px solid #444;
  align-items: center;
  justify-content: center;
  border-radius: 1.4rem;
  margin-right: 1rem;
  transition: all 0.3s ease-in-out;
}
.dealer-platforms-item .content .apps i:hover {
  background: #444;
  color: #fff;
}
.dealer-platforms-item .content .apps img {
  width: 60px;
  height: 60px;
  border-radius: 1.4rem;
  margin-right: 1rem;
}

.dealer-benefits {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  grid-gap: 4rem;
  margin-top: 3rem;
  background: #ffffff;
  border-radius: 1rem;
  padding: 4rem;
}
.dealer-benefits i {
  font-size: 4rem;
  margin-bottom: 2rem;
  display: block;
  height: 4rem;
}
.dealer-benefits img {
  height: 4rem;
  margin-bottom: 2rem;
  display: block;
}
.dealer-benefits h4 {
  margin-bottom: 1rem;
}
.dealer-benefits p {
  font-size: 1.6rem;
}

.dealer .website-presentation {
  margin-top: 3rem;
  border-radius: 1rem;
  overflow: hidden;
  padding: 4rem;
  background: #fff;
}
@media all and (max-width: 600px) {
  .dealer .website-presentation {
    padding: 4rem 2rem;
  }
}
.dealer .website-presentation .logo-img {
  max-width: 300px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.dealer .website-presentation .main-img {
  width: 100%;
  display: block;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.dealer .website-presentation .content {
  padding: 4rem;
  background: #f3f4ee;
}
.dealer .website-presentation .content p {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 3rem;
  text-align: center;
  font-size: 1.6rem;
}
@media all and (max-width: 600px) {
  .dealer .website-presentation .content p {
    font-size: 1.4rem;
  }
}
.dealer .website-presentation .content .shot-img {
  width: 100%;
  margin-top: 3rem;
  border-radius: 1rem;
}

.auto-legal-disclaimer {
  margin-top: 4rem;
}
.auto-legal-disclaimer p {
  margin-top: 0.4rem;
}

.dealer-welcome {
  background: #fff;
  border-radius: 1rem;
  padding: 4rem;
  margin-top: 2rem;
  border: 2px solid #ee6565;
}
.dealer-welcome .tag {
  margin-bottom: 2rem;
  font-size: 1.6rem;
}
.dealer-welcome li {
  display: flex;
  align-items: center;
  padding: 0.8rem 0;
}
.dealer-welcome li i {
  font-size: 2.5rem;
  color: #999;
  margin-right: 1rem;
  display: block;
  width: 30px;
  text-align: center;
}
.dealer-welcome li a {
  line-height: 1;
  font-size: 1.6rem;
  color: #444;
  font-weight: 700;
}
.dealer-welcome li a:hover {
  color: #469ad4;
}
.dealer-welcome li.done i {
  color: #6ec568;
  font-size: 3rem;
}
.dealer-welcome li.done a {
  text-decoration: line-through;
  color: #999;
  cursor: default;
}

.stripe-badge {
  width: 100%;
  margin-top: 2rem;
}

.StripeElement {
  padding: 2rem 1rem;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
}

.settings-content .modal {
  max-width: 56rem !important;
}
.settings-content .modal .button-regular {
  padding: 2rem 4rem;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.inline-link {
  display: inline-block !important;
  border: 0 !important;
  margin: 0 !important;
  color: #444 !important;
  height: auto !important;
  width: auto !important;
  font-size: 1.4rem !important;
  border-radius: 0 !important;
  transition: all 0.3s ease-in-out;
  text-decoration: underline !important;
}
.inline-link:hover {
  background: none !important;
  color: #469ad4 !important;
}

.image-upload-error {
  color: red;
  font-size: 14px !important;
  padding: 20px;
}

.slp-rating,
.engage_buttons_rating,
.ProductDetailReviews,
.ServiceContent__Reviews {
  display: none !important;
}

.coming-soon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.coming-soon .button {
  font-size: 1.4rem;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-weight: 700;
}
.coming-soon .button:hover {
  background: #425caf;
  border: 1px solid #425caf;
}

.coming-soon-holder {
  position: relative;
  padding-top: 2rem;
}

.coming-soon-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
}

.development-message {
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  border-radius: 1rem;
  max-width: 770px;
  margin: 0 auto;
}
@media all and (max-width: 900px) {
  .development-message {
    padding-top: 3rem;
  }
}
.development-message h4 {
  margin-bottom: 1rem;
}
.development-message p {
  font-size: 1.5rem;
  line-height: 1.5;
  margin-bottom: 1rem;
}

.csp-healthcare {
  padding-top: 3rem !important;
}

@media all and (max-width: 900px) {
  .preview-page {
    padding-top: 3rem !important;
  }
}

.print-button,
.cookie-modal,
.hide-on-m {
  display: none !important;
}

@media all and (max-width: 1200px) {
  body {
    background: #f4f4f4;
  }
  footer,
  .back-top-top {
    display: none;
  }
  .main-100 {
    margin-bottom: 0;
    padding-bottom: 6rem;
  }
  .home-box {
    margin-bottom: 0;
  }
  .mobileNavLegal,
  .mobileAppLanguage {
    display: block;
  }
  header.master-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
  }
  .container {
    margin-top: 0;
    box-shadow: none;
    padding-top: 6rem !important;
  }
  .MobileMenuBox {
    padding-bottom: 12rem;
  }
  .main-sidebar-active {
    padding-bottom: 12rem !important;
  }
}

@media all and (max-width: 340px) {
  .submit-listing .button-white {
    margin-right: 0.5rem;
  }
  .submit-listing-button button {
    padding: 1rem 1.5rem;
    font-size: 1.1rem;
  }
}
